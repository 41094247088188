import React, { useState, useContext } from 'react';
import {
  VStack, Text, Center, Heading, Box, HStack, Button, IconButton, useColorModeValue,
  Accordion, AccordionItem, AccordionButton, AccordionPanel, useStyleConfig,
} from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext.jsx';

import { DragDropContext } from '@hello-pangea/dnd';
import AreaHeader from '../../areas/rows/AreaHeader.jsx';
import ProjectTasks from '../../projects/boxes/ProjectTasks.jsx';
import { useTaskDnd } from '../../../data_hooks/dnd/useTaskDnd.jsx';

import c from '../../../uc_constants/fwd_constants.js';

const WeekAccomplishments = ({ session, ...props }) => {

  const { state, dispatch } = useContext(ForwardContext);
  const {handleDragEndWell} = useTaskDnd();
  const style = useStyleConfig('Box', {variant: 'widgetBox'});

  const bgColor = useColorModeValue('forwardWhite.500','forwardBlue.800')
  const bdColor = useColorModeValue('forwardWhite.700','forwardBlue.900')

    let total_count = 0;
    Object.values(state.tasks).flat().map((area) => {
        if(area && area['accomplishments']) {
        total_count += area['accomplishments'].length;
        }
    })

  /**************************/
  /**************************/
  /* Main body of the element: */
  /**************************/
  /**************************/

  return (
    <>
    <Box 
     width="100%"
      __css={style}
    {...props}>
    <HStack
    width="100%"
    justifyContent="space-between" px={0} pt={0} pb={2}>
    <HStack>
    {c.main_icons.accomplishments} 
    <Heading size="sm">Accomplishments ({total_count})</Heading>
    </HStack>
    <HStack>
    
    
    </HStack>
    </HStack>

    <VStack w="100%" maxWidth="1100px">
      <Center w="100%">
      <DragDropContext onDragEnd={handleDragEndWell}>
      <VStack spacing={0} w="100%" maxWidth="1100px" minWidth="300px">
  {Object.values(state.areas).map((area) => {
    // Get the projects for this area
    let area_tasks = 0;
    const projectsInArea = state.projects[area.id]?.filter((project) => project.status < 3) || [];

    let project_count = 0;
    {projectsInArea.flatMap((project) => {
        if(state.tasks[project.id] && state.tasks[project.id]['accomplishments']) {
          project_count = parseInt(state.tasks[project.id]['accomplishments'].length);
          area_tasks += project_count;
        }
        
    }
    )}

    return (
        <Accordion key={'accordion-'+area.id} allowMultiple width="100%" marginTop="2px !important" >
          <AccordionItem border="0px !important">
                  <AreaHeader key={'header-'+area.id} area={area} addible={false} addType='task' collapsible={true} count_display={true} count={area_tasks} flex="1" textAlign="left"/>
            <AccordionPanel py={0} px={1}>
              {projectsInArea.flatMap((project) => {
                let pCnt = state.tasks?.[project.id]?.['accomplishments']?.length || 0;
                if(pCnt === 0) {
                  return;
                }
                return(
                <ProjectTasks key={'project-'+project.id} 
                project={project} 
                tasks={state.tasks[project.id]} 
                listToShowOverride="accomplishments"/>
              )}
              
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
    );
  })}
</VStack>
        </DragDropContext>
      </Center>
    </VStack>
    </Box>
    </>
  );
}

export default WeekAccomplishments;
