import React, { useContext, useEffect, useState } from 'react';
import { Button, Box, Heading, Text, Center, Flex, VStack, Image, SlideFade, useDisclosure } from '@chakra-ui/react';
import { ForwardContext } from '../../context/ForwardContext';

import { useAreas } from '../../data_hooks/elements/useAreas';
import { useHabits } from '../../data_hooks/elements/useHabits';
import { useProjects } from '../../data_hooks/elements/useProjects';
import { useAreaSharing } from '../../data_hooks/sharing/useAreaSharing';
import { useAccounts } from '../../data_hooks/accounts/useAccounts';


const GetStarted = ({ user, ...props }) => {
  const { state, dispatch } = useContext(ForwardContext);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen } = useDisclosure();

  const {syncAreas} = useAreas();
  const {syncProjects} = useProjects();
  const {syncHabits, updateAllHabitStats} = useHabits();
  const {readMyShares} = useAreaSharing();
  const { createUser } = useAccounts();

  const handleGetStarted = async (user) => {
    console.log(user);
    setLoading(true);
    try {
    const createdUser = await createUser(user);
    if (createdUser) {
          await syncAreas(user.id);
          await syncProjects(user.id);
          const habitsData = await syncHabits(user.id, state.account.account_level);
          if (habitsData) {
            updateAllHabitStats(habitsData, 'all', null);
          }
          const myShares = await readMyShares(user.id);
        setLoading(false);

    }
  }
  catch (error) {
    if (error.message && error.message.includes('Yikes!')) {
      window.alert('Sorry - Anal Parts are strictly prohibited from using Forward.');
    }
    else {
      console.log(error);
      window.alert('Failed to create account:', error.message);
    }
    
  }
};

  useEffect(() => {
    const timer = setTimeout(() => {
      onOpen();
    }, 1000); // 1 second delay

    return () => clearTimeout(timer); // Clean up the timeout if the component is unmounted
  }, [onOpen]);

  return (
    <Flex
      minHeight="100vh"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
      {...props}
    >
      <Box>
        <Center>
          <VStack spacing={2}>
      <Heading>Welcome to Forward!</Heading>
      <Image src="/home/forward_logo_v1_min.png" alt="Logo" boxSize="150px" borderRadius="75px"/>
      <Text fontStyle="oblique">Next Generation Life Management</Text>
      <SlideFade in={isOpen} width="100%">
        <Flex width="100%" flexDirection="column">
      <Text textAlign="center" width="100%">
        Click the button below to initialize your account and start planning your life!
      </Text>
      <Button isLoading={loading} onClick={() => handleGetStarted(user)} borderRadius={0}>
        Get Started
      </Button>
      </Flex>
      </SlideFade>
      </VStack>
      </Center>
    </Box>
    </Flex>
  );
};

export default GetStarted;
