import React, {useEffect, useState, useContext, useMemo, useRef} from 'react';
import { useParams } from 'react-router-dom';
import {
  VStack, Text, HStack, Box, Heading, Flex, Divider, UnorderedList, ListItem, Button, IconButton, useMediaQuery, useColorModeValue
} from '@chakra-ui/react';
import { DragDropContext } from '@hello-pangea/dnd';

import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { FormContext } from '../../../context/FormContext.jsx';

import ProjectRow from '../rows/ProjectRow.jsx';
import GoalContainerBox from '../../goals/boxes/GoalContainerBox.jsx';

import dhf from '../../../uc_utils/dates/dates.js';

import { useTaskDnd } from '../../../data_hooks/dnd/useTaskDnd.jsx';
import ProjectHeaderRow from '../rows/ProjectHeaderRow.jsx';
import StatBox from '../../general/components/StatBox.jsx';
import { useProjects } from '../../../data_hooks/elements/useProjects.jsx';
import ProjectHistoryRow from '../rows/ProjectHistoryRow.jsx';
import LoadingAnimation from '../../general/components/LoadingAnimation.jsx';


const SingleProjectView = ({  session, view="project", ...props }) => {

    const {state, dispatch} = useContext(ForwardContext);
    const { addGoal, editGoal} = useContext(FormContext);
    const hasCalledSyncRef = useRef(false);
    const { id } = useParams();
    const pr= useProjects();

    // Define loading state
    const [isLoading, setIsLoading] = useState(true);

    const my_project = useMemo(() => {
        return Object.values(state.projects).flat().filter(project => parseInt(project.id) === parseInt(id))[0];
    }, [state.projects, id]);

  // useEffect to load project history after projects have loaded
    useEffect(() => {
      if (my_project && !hasCalledSyncRef.current) {
        hasCalledSyncRef.current = true;
          pr.syncProjectHistory(my_project)
            .then(() => setIsLoading(false))
            .catch(error => {
              console.error("Failed to sync area history:", error);
              // Handle error state if necessary
            });
      }
    }, [my_project]);

    const {handleDragEndWell} = useTaskDnd();


        // Calculate statistics using useMemo to avoid unnecessary recalculations
        const { weeks_live, numberOfWeeksWithCompletedTasks, hit_rate, tasks_done, tpw, tasksByWeek } = useMemo(() => {
          const my_tasks = state.live_views.project_history.filter(task => task.item_status !== 'abandoned');
          const tasks_done = my_tasks.length;
          const resolveDates = my_tasks.map(task => task.resolve_date);
          const resAsDates = resolveDates.map(date => dhf.createDateObject(date));
          const firstTaskCompletionDate = new Date(Math.min(...resAsDates));
  
          const firstWeek = dhf.getStartOfWeek(firstTaskCompletionDate);
          const thisWeek = dhf.getStartOfWeek(new Date());
          const weeks_live = dhf.getDaysBetweenDates(thisWeek, firstWeek) / 7;
  
          const uniqueWeeks = new Set(resolveDates.map(date => dhf.formatDate(dhf.getStartOfWeek(dhf.createDateObject(date), 'yyyy-MM-dd'))));
          const numberOfWeeksWithCompletedTasks = uniqueWeeks.size;
  
          const tasksByWeek = {};
          resolveDates.forEach((date, index) => {
              const startOfWeek = dhf.formatDate(dhf.getStartOfWeek(dhf.createDateObject(date)), 'yyyy-MM-dd');
              tasksByWeek[startOfWeek] = [...(tasksByWeek[startOfWeek] || []), my_tasks[index]];
          });
  
          return {
              weeks_live,
              numberOfWeeksWithCompletedTasks,
              hit_rate: (100 * numberOfWeeksWithCompletedTasks / weeks_live).toFixed(1),
              tasks_done,
              tpw: (tasks_done / weeks_live).toFixed(2),
              tasksByWeek
          };
      }, [state.live_views.project_history, id]); // Ensure the dependency list is accurate
  
    // Loading state
    if (isLoading) {
      return <LoadingAnimation />;
  }



return (
  <Flex width="100%" maxWidth="1200px" flexDirection={{'base':'column','md':'row'}}>

    <Flex
      flex="1" // Take up the remaining width
      flexDirection="column"
      width="100%"
    >
        <ProjectHeaderRow project={my_project} />
      
      {/*********** For Projects: Show Stat Boxes **************/}
      <Flex my={1} flexDirection={{'base':'column','md':'row'}} width="100%" justifyContent="stretch">
        <StatBox key="live_for" stat_heading="Live For" stat_value={parseFloat(weeks_live).toFixed(2)} stat_label="weeks" flex="1" mx={{'base':0,'md':2}} my={{'base':1,'md':1}}  width="100%"/>
        <StatBox key="weeks_hit" stat_heading="Weeks Hit" stat_value={numberOfWeeksWithCompletedTasks} stat_label="weeks"  flex="1" mx={{'base':0,'md':2}} my={{'base':1,'md':1}} width="100%"/>
        <StatBox key="hit_rate" stat_heading="Hit Rate" stat_value={hit_rate} stat_label="%"  flex="1" mx={{'base':0,'md':2}} my={{'base':1,'md':1}} width="100%"/>
        <StatBox key="tasks_done" stat_heading="Tasks Done" stat_value={tasks_done} stat_label="tasks" flex="1" mx={{'base':0,'md':2}} my={{'base':1,'md':1}} width="100%"/>
        <StatBox key="tasks_week" stat_heading="Tasks/Week" stat_value={tpw} stat_label="tpw" flex="1" mx={{'base':0,'md':2}} my={{'base':1,'md':1}} width="100%"/>
      </Flex>
      
      <Heading as="h3" size="md" my={2}>Goal(s):</Heading>
      <Divider />
      <GoalContainerBox 
              project={my_project} 
              goals={state.goals} 
              addGoalOpen={addGoal}
              editGoalOpen={editGoal}
              />
      <Heading as="h3" size="md" my={2}>Current State:</Heading>
      <Divider />
      <DragDropContext onDragEnd={handleDragEndWell}>
      <ProjectRow key={'project-'+my_project.id} 
                          project={my_project} 
                          tasks={state.tasks[my_project.id]} 
                          viewMode='sp_backlog' 
                          />
    </DragDropContext>
    <Heading as="h3" size="md" my={2}>Project Timeline:</Heading>
    <Divider />
        <Flex className="history-wrapper" flexDirection="column" alignItems="center">
        {/* Iterate through the tasksByWeek object and render a ProjectHistoryRow for each week */}
        {Object.keys(tasksByWeek).sort().reverse().map((week, index, sortedWeeks) => {
              let time_since_last_week = 0;

              if (index > 0) {
                // Get the previous week's date
                const prevWeekDate = dhf.createDateObject(sortedWeeks[index - 1]);
                const currentWeekDate = dhf.createDateObject(week);

                // Use your dhf.getDaysBetweenDates function to calculate the difference
                time_since_last_week = dhf.getDaysBetweenDates(prevWeekDate, currentWeekDate);
              }
          
          return (
            <ProjectHistoryRow project_id={id} key={week} week={week} weekTasks={tasksByWeek[week]} timeSince={time_since_last_week} />
        )
          }
        )}
        <Box>Start of Project: {dhf.formatDate(new Date(my_project.start_date),'MMM dd, yyyy')}</Box>
    </Flex>
      </Flex>
      </Flex>)
}
export default SingleProjectView;