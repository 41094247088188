import React, { useState, useContext, useMemo } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button, ButtonGroup,
  HStack,
  Accordion, AccordionItem, AccordionButton, AccordionPanel,
  Box, Text, AccordionIcon
} from "@chakra-ui/react";
import DndRow from "../rows/DndRow.jsx";
import { ForwardContext } from "../../../context/ForwardContext.jsx";
import { FormContext } from "../../../context/FormContext.jsx";
import AreaHeader from "../../areas/rows/AreaHeader.jsx";
import ArchiveRow from "../rows/ArchiveRow.jsx";
import { useManageDnd } from "../../../data_hooks/dnd/useManageDnd.jsx";


const ManageModal = ({ session, isOpen, onClose }) => {
    const { state, dispatch } = useContext(ForwardContext);
    const { openForm} = useContext(FormContext);
    const [currentSection, setCurrentSection] = useState('areas'); // Start with 'areas' as the default section
    const {handleManageDragEnd} = useManageDnd({session}, currentSection);

    //const [archivedItems, setArchivedItems] = useState([]);

    let show_options;
    let init_form;
    let options_to_show;

    
    if(currentSection === 'habits') {
        show_options = false;
        init_form = 'habit';
        options_to_show = ['habit'];

    } 
    else if (currentSection === 'projects') {
        show_options = false;
        init_form = 'project';
        options_to_show = ['project'];

    } 
    /*else if (currentSection === 'goals') {
        show_options = false;
        init_form = 'goal';
        options_to_show = {'name':true,'project':true,'area':true};
    }*/
    else if (currentSection === 'areas') {
        show_options = false;
        init_form = 'lifeArea';
        options_to_show = ['lifeArea'];
   }

    const openModal = (editorData) => {
      openForm(init_form, editorData, false, options_to_show);
    }
    
    

    const handleAddEditClick = (editorData) => {
      console.log(editorData);
      //console.log('in add edit');
      //This needs to update the state values, then pull up the modal over top
      if(editorData) {
          //console.log('setting edit data')
          openModal(editorData);
      }
      else {
      openModal({});
      }
  }


    const defaultIndices = state.areas.map((_, index) => index);
    const element_names = {
        'areas':'area_name',
        'habits':'habit_name',
        'projects':'project_name',
        /*'goals':'goal_name',*/
    }

    const archivedItems = useMemo(() => {
      if (currentSection === 'areas') {
        return state.areas.filter(area => area.status >= 3);
      } else {
        console.log('doing the flatMap');
        return state.areas.flatMap((area) => {
          return (state[currentSection][area.id] || []).filter(item => parseInt(item.status) >= 3);
        });
      }
    }, [state.areas, state[currentSection], currentSection]);

  
    const getItemId = (type, id) => `${type}-${id}`;

  
      return (
        <>
        <Modal isOpen={isOpen} onClose={onClose} size="xl" borderRadius="0px" >
          <ModalOverlay />
          <ModalContent borderRadius="0px" >
            <ModalHeader>Organize Your Life!</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <ButtonGroup width="100%" variant="outline" colorScheme="forwardBlue" defaultValue="areas" mb={2}>
            <HStack spacing={0} justifyContent="stretch" width="100%">
            <Button flex="1" onClick={() => setCurrentSection("areas")} isActive={currentSection === "areas"} borderRadius="0px">Areas</Button>
            <Button flex="1" onClick={() => setCurrentSection("habits")} isActive={currentSection === "habits"} borderRadius="0px">Habits</Button>
            <Button flex="1" onClick={() => setCurrentSection("projects")} isActive={currentSection === "projects"} borderRadius="0px">Projects</Button>
            {/*<Button flex="1" onClick={() => setCurrentSection("goals")} isActive={currentSection === "goals"} borderRadius="0px">Goals</Button>*/}
            </HStack>
            </ButtonGroup>
              <DragDropContext onDragEnd={handleManageDragEnd}>

              
                
                {currentSection === "areas" ? (
                <>
                <Droppable droppableId="area-sorter">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {state.areas.filter(area => area.status <= 2).map((area, index) => (
                      <Draggable key={getItemId("areas", area.id)} draggableId={getItemId("areas", area.id)} index={index}>
                        {(provided) => (
                            <div {...provided.draggableProps} ref={provided.innerRef}>
                          <DndRow 
                                    element={area} 
                                    element_name="area_name" 
                                    dragHandle={provided.dragHandleProps}
                                    editFunction={() => handleAddEditClick(area)}
                                    />
                          </div>
                          
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <Button borderRadius="0px" mt={2} width="100%" colorScheme="forwardBlue" onClick={() => handleAddEditClick()}>+ Add</Button>
              </>
                ) : 
                (
                  state.areas.map((area) => { 
                    let default_project = null;
                    if (state.projects[area.id] && state.projects[area.id].length > 0) {
                      default_project = state.projects[area.id][0].id;
                    }
                    const defaultData = { 'area_to_limit': area.id, 'area_id': area.id };

                    return (
                      <Accordion key={area.id} defaultIndex={defaultIndices} allowMultiple>
                        <AccordionItem marginBottom={1}>
                          <Droppable droppableId={String(area.id)} key={area.id}>
                            {(provided) => (
                              <div {...provided.droppableProps} ref={provided.innerRef}>
                                <AreaHeader area={area} 
                                            addible={true} 
                                            addType={init_form}
                                            default_data={defaultData}
                                            collapsible={true} />
                                <AccordionPanel padding={0}>
                                  {(state[currentSection][area.id] || [])
                                    .filter(item => item.status <= 2) // Filter based on status <= 2
                                    .map((item, index) => (
                                      <Draggable key={getItemId(currentSection, item.id)} draggableId={getItemId(currentSection, item.id)} index={index}>
                                        {(provided) => (
                                          <div {...provided.draggableProps} ref={provided.innerRef}>
                                            <DndRow
                                              element={item}
                                              element_name={element_names[currentSection]}
                                              dragHandle={provided.dragHandleProps}
                                              editFunction={() => handleAddEditClick(item)}
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                </AccordionPanel>
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </AccordionItem>
                      </Accordion>
                    );
                  })
                  )}
              </DragDropContext>
              <Box>
                
                <Accordion key={'archive'} defaultIndex={defaultIndices} allowMultiple>
                        <AccordionItem marginBottom={1}>
                        <HStack width="100%" justifyContent="space-between">
                              <Text>Archived {currentSection} ({archivedItems.length})</Text>
                            <AccordionButton maxWidth="30px" padding="2px !important" cursor="pointer" size="sm">
                              <AccordionIcon />
                          </AccordionButton>
                      </HStack>
                      <AccordionPanel padding={0}>
                        {
                          archivedItems.length === 0 ? <Text width="100%" textAlign="center" fontStyle="oblique" fontSize="sm">No archived {currentSection}</Text> :
                        (archivedItems.map((item, index) => ( 
                          <ArchiveRow 
                          key={item.id}
                                  element={item}
                                  element_name={element_names[currentSection]}
                                  modalClose={onClose} />
                        )
                      ))
                      }
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                

              </Box>
            </ModalBody>
    
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose} borderRadius="0px">
                Done
              </Button>
              <Button onClick={onClose} borderRadius="0px">Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>       
        </>
      );
  };
  
export default ManageModal;