import areaReducer from "./areaReducer";
import uiReducer from "./uiReducer";
import goalReducer from "./goalReducer";
import newGoalReducer from "./newGoalReducer";
import eventReducer from "./eventReducer";
import habitReducer from "./habitReducer";
import projectReducer from "./projectReducer";
import taskReducer from "./taskReducer";
import reminderReducer from "./reminderReducer";
import accountReducer from "./accountReducer";
import liveReducer from "./liveReducer";
import statReducer from "./statReducer";
import shareReducer from "./shareReducer";
import gridUiReducer from "./gridUiReducer";
import allTaskReducer from "./allTaskReducer";

const forwardReducer = (state, action) => {
  return {
    ...state,
    ...areaReducer(state.areas,action),
    ...uiReducer(state.ui,action),
    ...taskReducer(state.tasks,action),
    ...allTaskReducer(state.new_tasks,action),
    ...goalReducer(state.goals,action),
    ...newGoalReducer(state.new_goals,action),
    ...eventReducer(state.events,action),
    ...projectReducer(state.projects,action),
    ...habitReducer(state.habits,action),
    ...reminderReducer(state.reminders,action),
    ...accountReducer(state.account,action),
    ...liveReducer(state.live_views,action),
    ...statReducer(state.stats,action),
    ...shareReducer(state.shares,action),
    ...gridUiReducer(state.grid_ui,action)
  }

}
    
  export default forwardReducer;
  