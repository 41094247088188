import { useEffect, useContext, useCallback, useState } from 'react';
import { ForwardContext } from '../../context/ForwardContext.jsx';
import { upsertGoal, updateGoalOrdering } from '../../database_api/goalService.js';
import gdh from '../../uc_utils/goals/goal_drag.js';

export const useGoalDnd = ({session}) => {
  const { state,dispatch } = useContext(ForwardContext);

    /****************************************/
    //Main Sync Function to Grab all the data
    /****************************************/
  const handleGoalDragEnd = async (result) => {
    console.log(result);
    console.log('ya dragged it');
    const { draggableId, source, destination } = result;

    // If nothing changed, do nothing
    if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
      return;
    }

    /* Get variables to determine what to do */
    const source_area = source.droppableId.split('-')[0];
    const dest_area = destination.droppableId.split('-')[0];
    const source_level = source.droppableId.split('-')[1];
    const dest_level = destination.droppableId.split('-')[1];
    const source_status = source.droppableId.split('-')[2];
    const dest_status = destination.droppableId.split('-')[2];

    /* For both the source and destination, I need to grab the existing elements and update the order_id for all elements */
    //TODO
    let data_to_update = {'id':parseInt(draggableId),'user_id':session.user.id}
    /* If the source_status != dest_status, I need to update the status for the dragged element */
    data_to_update['area_id'] = parseInt(source_area);
    if (source_area !== dest_area) {
      if(dest_area === 'completed')
      {
        data_to_update['area_id'] = parseInt(dest_area);
      }
    }

    /* If the source_level != dest_level, I need to update the goal_level for the dragged element */
    if (source_level !== dest_level) {
      data_to_update['goal_level'] = dest_level;
    }

    /* If the source_area != dest_area, I need to update the area_id for the dragged element */
    if (source_area !== dest_area) {
      data_to_update['area_id'] = dest_area;
    }


    console.log(data_to_update);
    //Update State
    dispatch({ type: 'UPSERT_GOAL', payload: [data_to_update] });
    //Update Database
    const new_goal = await upsertGoal(data_to_update);

    if (new_goal) {
      console.log('Goal added!');
  //If not, alert to error
  } else  {
      alert("Error Adding Area!")
  }


  }



  const newHandleGoalDragEnd = async (result) => {
    console.log(result);
    console.log('ya dragged it');
    const { draggableId, source, destination } = result;
    const user_id = session.user.id;

    // If nothing changed, do nothing
    if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
      return;
    }

    /* Get variables to determine what to do */
    /* 'goalbox-area-'+area.id+'-year-'+year+'-quarter-None-points-all-progress-all' */ 
      const source_area = source.droppableId.split('-')[2];
      const dest_area = destination.droppableId.split('-')[2];
      const source_year = source.droppableId.split('-')[4];
      const dest_year = destination.droppableId.split('-')[4];
      const source_quarter = source.droppableId.split('-')[6];
      const dest_quarter = destination.droppableId.split('-')[6];
      const source_points = source.droppableId.split('-')[8];
      const dest_points = destination.droppableId.split('-')[8];  
      const source_progress = source.droppableId.split('-')[10];
      const dest_progress = destination.droppableId.split('-')[10];


      /* Situation #1: Same container - just reorder that container */
      if (source.droppableId === destination.droppableId) {

        console.log (state.goals[source_area]);

        const goalList = gdh.get_filtered_goal_list(state.goals[source_area], source_area, source_year, source_quarter, source_points, source_progress);
        console.log(goalList);

          const items = gdh.reorder(
              goalList,
              source.index,
              destination.index,
          );
          console.log(items);
          
          // Here you should dispatch to the reducer to update the state
            dispatch({
                type: 'UPDATE_GOAL_ORDER',
                payload: { areaId: source_area, goalList: items }
            });
            // Update goals order in backend
            const response = await updateGoalOrdering(user_id, items);

            if (!response.success) {
            console.error('Failed to update task order in backend:', response.message);
            }

      }
      /* Situation #2: Different container - move the goal to the new container */
      else if (source.droppableId !== destination.droppableId) {
        console.log('moved to a new container!')
        //Figure out what properties to update
        let data_to_update = {'id':parseInt(draggableId),'user_id':session.user.id, 'area_id':parseInt(dest_area)};

        if (source_year !== dest_year || source_quarter !== dest_quarter) {
          let new_timeframe = null
          if (dest_year === 'None') {
            new_timeframe = null;
          }
          else if (dest_quarter === 'None') {
            new_timeframe = dest_year;
          }
          else {
            new_timeframe = dest_year+dest_quarter;
            console.log(new_timeframe);
          }

          data_to_update['goal_timeframe'] = new_timeframe;
        }
        if (source_points !== dest_points) {
          data_to_update['goal_points'] = dest_points;
        }
        if (source_progress !== dest_progress) {
          data_to_update['goal_progress'] = dest_progress;
        }
        if (source.index !== destination.index) {
          data_to_update['order_id'] = destination.index;
        }

        console.log(data_to_update);

        //Update those properties in the state
        dispatch({ type: 'UPSERT_GOAL', payload: [data_to_update] });

        //Update those properties in the database
        const new_goal = await upsertGoal(data_to_update);

        //Ensure ordering is correct
        //It should be? I think?
        //Old doesn't need to change
        //New - will need to update the order_id for all elements
        const goalList = gdh.get_filtered_goal_list(state.goals[dest_area], parseInt(dest_area), dest_year, dest_quarter, dest_points, dest_progress);
        console.log(goalList);
        const new_index = destination.index;
        // I need to add my goal to the goalList and update the order_id for all elements
        const newGoal = {id: parseInt(draggableId), order_id: new_index};
        const updatedGoalList = [...goalList];
        updatedGoalList.splice(new_index, 0, newGoal); // Insert newGoal at new_index

        // Step 2: Update order_id for each goal in the updated list
        updatedGoalList.forEach((goal, index) => {
          goal.order_id = index;
        });

        // Step 3: Create a mapping of goal_id to the new order_id
        const goalOrderList = updatedGoalList.map(goal => ({
          id: goal.id,
          user_id: user_id,
          area_id: parseInt(dest_area),
          order_id: goal.order_id
        }));

        console.log(goalOrderList);

        // Step 4: Update state with the new goal order

        dispatch({
          type: 'UPDATE_GOAL_ORDER',
          payload: { user_id: user_id, goalList: goalOrderList }
        });

        // Step 5: Update the goal order in the database
        const response = await updateGoalOrdering(user_id, goalOrderList);

        if (!response.success) {
          console.error('Failed to update task order in backend:', response.message);
        }

      }


  }




  return {handleGoalDragEnd, newHandleGoalDragEnd};
}
