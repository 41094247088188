import React, { useState, useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Select,
  HStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import NewProjectDropdown from '../../projects/components/NewProjectDropdown.jsx';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import th from '../../../uc_utils/tasks/tasks.js'
import dhf from '../../../uc_utils/dates/dates.js';
import TaskListSelector from '../components/TaskListSelector.jsx';
import { RiPushpinFill, RiPushpinLine  } from "react-icons/ri";
import { useTasks } from '../../../data_hooks/elements/useTasks.jsx'
import TaskRecurrenceSelector from '../components/TaskRecurrenceSelector.jsx';

const FullTaskForm = ({
  isOpen, onClose, type, session, editorData, optionsToShow, isDisabled,
}) => {
  const showObject = optionsToShow || {'name':true,'task_list_selector':true,'project':true,'pinned':true,'recur_weeks':false}
  const ut = useTasks();
  const toast = useToast();
  console.log(editorData);

  /*******************/
  //Form Setup and Management
  /*******************/
  const { state, dispatch } = useContext(ForwardContext);

  const [taskName, setTaskName] = useState(editorData.item_name || '');
  const [taskStatus, setTaskStatus] = useState(editorData.item_status || 'planned');
  const [plannedWeek, setPlannedWeek] = useState(editorData.item_planned_week || dhf.formatDate(dhf.getStartOfWeek(new Date()),'yyyy-MM-dd'));
  const [resolveDate, setResolveDate] = useState(editorData.resolve_date || "");
  const [isPinned, setIsPinned] = useState(editorData.is_pinned || false);
  const [selectedProject, setSelectedProject] = useState(editorData.project_id || state.projects[state.areas[0].id].filter(proj => proj.status <= 1)[0].id);
  const [taskList, setTaskList] = useState(editorData.taskList || 'this_week');
  const [recurWeeks, setRecurWeeks] = useState(editorData.recur_weeks || 0);
  const [taskCategory, setTaskCategory] = useState(editorData.task_category || null);

  const handleAddProjectClick = () => {
    console.log('adding a project');
    dispatch({ type: 'SET_EDITORDATA', payload: {'area_id':(editorData.area_id || state.areas[0].id),'submit_text':'Add Project'} })
    dispatch({ type: 'SET_CURRENT_FORM', payload: 'project' })
  }
  

  const handleProjectChange = (value) => {
    setSelectedProject(value);
    //console.log(selectedArea);
  };

  let submit_text = '';
  let submit_mode = '';
  if(Object.keys(editorData).length === 0)
  {
    submit_text = 'Create'
    submit_mode = 'new'
  }
  else if (editorData.submit_text) {
    submit_text = editorData.submit_text;
    submit_mode = editorData.submit_mode;
  }
  else {
    submit_text = 'Update'
    submit_mode = 'edit'
  }
  const user_id = session.user.id;
  const statuses = ['backlog','priority','planned','complete','abandoned','delayed'];

  let area_limit = 'none';
  if(editorData && editorData.area_to_limit) {
    area_limit = editorData.area_to_limit;
  }


  const handleStatusChange = (event) => {
    setTaskStatus(event.target.value);
    //console.log(selectedArea);
  };
  /*******************/
  //Submit to Database
  /*******************/
  async function handleSubmit() {
    //Set the resolve date to null unless one is provided
    let resolveDateToSend = resolveDate;
    let pwToSend = plannedWeek;
    let statusToSend = taskStatus;
    let opw = null;
    let old_opw = editorData?.original_plan_date;
    
    if(resolveDate === null || resolveDate === '' || resolveDate === 'null') {
      resolveDateToSend = null;
    }
    //Now, check taskList
    if(optionsToShow['task_list_selector'] === false && optionsToShow['task_category'] === true) {
      pwToSend = plannedWeek;
      statusToSend = taskStatus;
    }
    else {
    if(taskList === 'this_week') {
      pwToSend=(dhf.formatDate(dhf.getStartOfWeek(new Date()),'yyyy-MM-dd'));
      statusToSend=('planned');
      if(old_opw === null) {
        opw = pwToSend;
      }
      else {
        opw = old_opw;
      }

    }
    else if (taskList === 'next_week') {
      pwToSend=(dhf.formatDate(dhf.getStartOfWeek(dhf.addDaysToDate(new Date(),7)),'yyyy-MM-dd'));
      statusToSend=('planned');
      if(old_opw === null) {
        opw = pwToSend;
      }
      else {
        opw = old_opw;
      }

    }
    else if (taskList === 'priority') {
      pwToSend=(null);
      statusToSend=('priority');

    }
    else if (taskList === 'backlog') {
      statusToSend=('backlog');
      pwToSend=(null);
    }
    else if (taskList === 'toschedule') {
      statusToSend=('toschedule');
      pwToSend=(null);
    }
    else if (taskList === 'complete') {
      statusToSend=('complete');
    }
  }

    //Build data to submit:
    const task_data = {'item_name':taskName, 
                        'project_id':selectedProject, 
                        'item_status':statusToSend, 
                        'is_pinned':isPinned,
                        'item_planned_week':pwToSend,
                        'original_plan_date':opw,
                        'resolve_date':resolveDateToSend,
                        'recur_weeks':recurWeeks,
                        'task_category':taskCategory,
                      }

    const old_list = editorData.taskList;
    const old_project = editorData.project_id;
    let remove_task = [];
    const new_list = th.calculateList(task_data, state.ui.currentWeek, state.ui.thisWeek, state.ui.nextWeek);
    let message_to_show = 'Task Added!';

    if(editorData.id > 0) {
        task_data["id"] = editorData.id;
        remove_task.push({'id':editorData.id,'project_id':editorData.project_id});
        message_to_show = 'Task Updated!';
    }

    console.log(task_data);

    //let {result, message} = await ut.upsertFullTask(task_data,'full',new_list, remove_task, old_list);
    let {result, message} = await ut.addNewTask(task_data);
    //TODO: Need to switch to useTasks

    //Provide Feedback to user based on the outcome
    if(result === 'limit') {
      toast({
        title: 'Error: Task limit reached! Complete a few or upgrade your account!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    else if (result === 'error') {
      toast({
        title: 'Error: '+message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    else {
      onClose();
      toast({
        title: message_to_show,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }

  };

  console.log(state.account)

  /*******************/
  // Return the Form
  /*******************/

  return (
    <>
    <FormControl>
    <ModalBody>
    <FormLabel fontWeight="bold">Task</FormLabel>
      <HStack>
    {showObject['pinned'] && <> 
    <Button  
          borderRadius={0} 
          size="xs" 
          variant={isPinned ? 'solid' : 'ghost'} 
          colorScheme={isPinned ? 'green' : 'default'} 
          onClick={() => setIsPinned(!isPinned)}>{isPinned ? <RiPushpinFill /> : <RiPushpinLine />}
      </Button> </>}
      {showObject['name'] && <>
      
            <Input
              type="text"
              variant="flushed"
              value={taskName}
              placeholder="Reach out to 5 sales contacts"
              onChange={(e) => setTaskName(e.target.value)}
            />
      </>}
      </HStack>
        {showObject['status'] && <>
        <FormLabel fontWeight="bold" mt={4}>Task Status</FormLabel>
            <Select value={taskStatus} onChange={handleStatusChange} variant="flushed">
                {statuses.map((status, index) => (
                <option key={index} value={status}>
                    {status}
                </option>
                ))}
            </Select>
        </>}
        {showObject['task_list_selector'] && 
          <>
          <FormLabel fontWeight="bold" mt={4}>Task List:</FormLabel>
          <TaskListSelector value={taskList} onValueChange={setTaskList} isDisabled={false}/>
          </>
                }
            {showObject['planned_week'] && <>
            <FormLabel fontWeight="bold" mt={4}>Planned Week</FormLabel>
            <Input
              type="date"
              variant="flushed"
              value={plannedWeek}
              onChange={(e) => setPlannedWeek(e.target.value)}
            />
            </>}
            {showObject['resolve_date'] && <> 
            <FormLabel fontWeight="bold" mt={4}>Resolve Date</FormLabel>
            <Input
              type="date"
              variant="flushed"
              value={resolveDate}
              onChange={(e) => setResolveDate(e.target.value)}
            />
            </>}
            {showObject['project'] && <>
            <FormLabel fontWeight="bold" mt={4}>Project:</FormLabel>
            <NewProjectDropdown mt={2} value={selectedProject} onChange={handleProjectChange} variant="flushed" area_limit={area_limit} />
            <HStack my={1}><Text>Or add a </Text><Button borderRadius={0} size="xs" variant="outline" onClick={handleAddProjectClick}>New Project</Button></HStack>
            </>}
            {showObject['recur_weeks'] && <>
            <FormLabel fontWeight="bold" mt={4}>Repeat Every:</FormLabel>
            <TaskRecurrenceSelector value={recurWeeks} onValueChange={setRecurWeeks} />
            <Text fontSize="xs">This task will be scheduled again in {recurWeeks} weeks after you complete the task.</Text>
             </>}
             {showObject['task_category'] && <>
             <FormLabel fontWeight="bold" mt={4}>Category:</FormLabel>
             <ButtonGroup variant="outline" spacing={2}>
                {state.account.task_categories.map((category, index) => (
                  <Button
                    key={index}
                    onClick={() => setTaskCategory(category.id)}
                    colorScheme={taskCategory === category.id ? 'forwardOrange' : 'gray'}
                    variant={taskCategory === category.id ? 'solid' : 'outline'}
                  >
                    {category.value}
                  </Button>
                ))}
              </ButtonGroup>
              </>
              }
        </ModalBody>
        <ModalFooter>
          <Button  borderRadius={0} colorScheme="forwardOrange" mr={3} onClick={handleSubmit} isDisabled={isDisabled}>
            {submit_text}
          </Button>
          <Button  borderRadius={0} variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
        </FormControl>
        </>
  );
};

export default FullTaskForm;