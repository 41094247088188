const habitReducer = (habits, action) => {
  switch (action.type) {
    case 'SET_HABITS':
      return {habits: action.payload};
      
      case 'UPSERT_HABIT': {
        const updatedHabit = action.payload[0];
        const habitId = updatedHabit.id;
        const areaId = updatedHabit.area_id;
        
        // If habit exists, update it
        if (habits[areaId]) {
          const existingHabitIndex = habits[areaId].findIndex(habit => habit.id === habitId);
          if (existingHabitIndex !== -1) {
            return {
              habits: {
                ...habits,
                [areaId]: habits[areaId].map((habit, index) =>
                  index === existingHabitIndex ? { ...habit, ...updatedHabit } : habit
                ),
              },
            };
          }
        }
        // If habit does not exist or the area has no habits yet, add it
        return {
          habits: {
            ...habits,
            [areaId]: habits[areaId] ? [...habits[areaId], updatedHabit] : [updatedHabit],
          },
        };
      }
      

    case 'UPSERT_HABIT_VALUE': {
      const { habit_id, date, value } = action.payload;
      const newHabits = { ...habits };
      Object.values(newHabits).forEach((habits) => {
        habits.forEach((habit) => {
          if (habit.id === habit_id) {
            habit.values[date] = value;
          }
        });
      });
      return {habits: newHabits};
    }
    
    case 'UPSERT_HABIT_TARGET': {
      const newTarget = action.payload;
      const habitId = newTarget.habit_id;
  
      // Create a copy of the state with deep cloning for the relevant parts
      const newHabits = {...habits};
  
      // Iterate through each life area
      for (let areaId in newHabits) {
          // Find the habit that this target belongs to
          const habitIndex = newHabits[areaId].findIndex(habit => habit.id === habitId);
  
          // If the habit is found in the current life area
          if (habitIndex > -1) {
              // Ensure we're working with a copy of the habit and its targets array
              const updatedHabit = { ...newHabits[areaId][habitIndex] };
              const updatedTargets = [...updatedHabit.targets]; // Create a new array for targets
  
              // Find the target that matches the new target's date
              const targetIndex = updatedTargets.findIndex(target => target.date === newTarget.date);
  
              if (targetIndex > -1) {
                  // Target exists for the date, so we update it
                  updatedTargets[targetIndex] = newTarget;
              } else {
                  // Target does not exist for the date, so we add it
                  updatedTargets.push(newTarget);
              }
  
              // Update the habit's targets with the new array
              updatedHabit.targets = updatedTargets;
              newHabits[areaId][habitIndex] = updatedHabit;
  
              // As we have found the habit, we can break the loop
              break;
          }
      }
    
      return { habits: newHabits };
  }
  
    case 'REMOVE_HABIT': {
      const habitId = action.payload.habitId;
    const areaId = action.payload.areaId;
  
    return {
      habits: {
        ...habits,
        [areaId]: habits[areaId].filter(habit => habit.id !== habitId),
      },
    };

    }
    default:
      return {};
  }
};

export default habitReducer;
