import React, {useState} from 'react';
import { HStack, VStack, Text, IconButton, Box, Input, Select,  useColorModeValue, 
        Menu, MenuButton, MenuList, MenuItem, useDisclosure, Flex, Progress, Button, Center} from '@chakra-ui/react';
import { AiOutlineBarChart, AiOutlineSetting } from 'react-icons/ai';
import { useHabits } from '../../../data_hooks/elements/useHabits';
import ConfirmDialog from '../../general/components/ConfirmDialog';
import { useNavigate } from 'react-router-dom';

const HabitRowButtons = ({ habit, onTargetOpen, onGraphOpen, editHabitOpen }) => {

  const {upsertHabitProperty} = useHabits();

  const color_to_use = useColorModeValue("light","dark");
  const isMobile = window.innerWidth <= 768;
  const navigate = useNavigate();


  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);

  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const onCloseDelete = () => setIsOpenDelete(false);


  const unpauseHabit = async () => {
    upsertHabitProperty(habit,'status',1)
  }
  const pauseHabit = async () => {
    upsertHabitProperty(habit,'status',2)
  }
  const archiveHabit = async () => {
    console.log('archiving habit');
    upsertHabitProperty(habit, 'status', 3);
    upsertHabitProperty(habit, 'order_id', 999);
    onClose();
  };

  const deleteHabit = async () => {
    console.log('deleting habit');
    upsertHabitProperty(habit,'date_deleted',new Date())
    onCloseDelete();
  }


    
    return(<>
    <Flex w={{'base':"55px",'md':"27px"}} flexDirection={{'base':'row','md':'column'}} justifyContent="space-between">
    <Menu>
      <MenuButton
        as={IconButton}
        size="xs"
        borderRadius="0px"
        icon={<AiOutlineSetting />}
        aria-label="Settings"
        mt={{'base':0,'md':1}}
        mr={{'base':1,'md':0}}
      />
      <MenuList>
        <MenuItem onClick={() => editHabitOpen(habit)}>Edit</MenuItem>
        <MenuItem onClick={onTargetOpen}>Set Targets</MenuItem>
        {habit.status===2 && <MenuItem onClick={unpauseHabit}>Unpause</MenuItem>}
        {habit.status===1 && <MenuItem onClick={pauseHabit}>Pause</MenuItem>}
        <MenuItem onClick={() => setIsOpen(true)}>Archive</MenuItem>
        <MenuItem onClick={() => setIsOpenDelete(true)}>Delete</MenuItem>
      </MenuList>
    </Menu>
    <IconButton borderRadius="0" size="xs" mb={{'base':0,'md':1}} icon={<AiOutlineBarChart />} onClick={() => navigate('/forward/habit/'+habit.id)}>Graph Me!</IconButton>
    <ConfirmDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={() => archiveHabit()}
      title="Archive Habit"
      description="Are you sure you want to archive this habit?"
      confirmButtonText="Archive"
    />
    <ConfirmDialog
      isOpen={isOpenDelete}
      onClose={onCloseDelete}
      onConfirm={() => deleteHabit()} // Call the deleteHabit function
      title="Delete Habit"
      description="Are you sure you want to delete this habit?"
      confirmButtonText="Delete"
    />
    </Flex>
    </>);


};
export default HabitRowButtons;