import React, {useState} from 'react';
import {
  Box,
  IconButton,
  useDisclosure,
  VStack,
  HStack,
  Text,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillMinusCircle, AiFillCiCircle } from 'react-icons/ai';
import { BsFillCircleFill } from 'react-icons/bs'

const GoalLevelSelector = ({ value, onValueChange, isDisabled, size, ...props }) => {

  const px_size = size || 'md';
  const [selected, setSelected] = useState(value);
  const btnColors = useColorModeValue('forwardWhite','forwardBlue');
  const brdColor = useColorModeValue('forwardWhite.800','forwardBlue.800');
  const txtColor = useColorModeValue('black','white');
  const selectedColor = useColorModeValue('forwardBlue','forwardGold');

    const handleChange = (newValue) => {
        setSelected(newValue);
        onValueChange(newValue);
    };
    

    return (
        <HStack
                spacing={0}>
            <Button 
            size={px_size}
            borderRadius={0}
            textColor={txtColor}
            borderRight="1px"
            borderColor={brdColor}
            onClick={() => handleChange('minor')}
            colorScheme = {selected ==='minor' 
                            ? selectedColor
                            : btnColors}
            >Minor</Button>
            <Button 
            size={px_size}
            borderRadius={0}
            textColor={txtColor}
            borderRight="1px"
            borderColor={brdColor}
            onClick={() => handleChange('major')}
            colorScheme = {selected ==='major' 
                            ? selectedColor
                            : btnColors}
            >Major</Button>

            </HStack>
    );
  };
  export default GoalLevelSelector;


