import React from 'react';
import Select from 'react-select';
import { useColorModeValue } from '@chakra-ui/react';

const TaskViewDropdown = ({currentValue, handleViewChange}) => {
    

    const headerBg = useColorModeValue('var(--chakra-colors-forwardWhite-500)','var(--chakra-colors-forwardBlue-800)');
    const selectBg = useColorModeValue('var(--chakra-colors-forwardBlue-100)','var(--chakra-colors-forwardBlue-700)');
    const selectFont = useColorModeValue('black','white');
    const optionBg = useColorModeValue('var(--chakra-colors-forwardWhite-100)','var(--chakra-colors-forwardBlue-600)');

    let selectStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'var(--chakra-colors-forwardBlue-600)',
            borderRadius: '0px', // Set your desired border radius
            border:'0px',
            fontWeight:'bold',
            textColor:'white',
            minWidth: '150px', // Set your desired minimum width
        }),
        input: (provided) => ({
            ...provided,
            color:'white',
        }),
        singleValue: (provided) => ({
            ...provided,
            color:'white',
        }),
        groupHeading: (provided) => ({
            ...provided,
            backgroundColor: headerBg, // Set your desired background color for headers
            fontWeight: 'bold',
        }),
        option: (provided, { data, isDisabled, isFocused, isSelected}) => ({
            ...provided,
            backgroundColor: isSelected ? selectBg : optionBg,
            color: isSelected ? selectFont : provided.color,
            ':active':{
                fontWeight:'bold',
            },
            ':hover':{
                fontWeight:'bold',
                backgroundColor: selectBg,
            },
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: optionBg,
        }),
    }

    const options = [
        { value: 'backlog', label: 'Kanban View' },
        { value: 'pinned', label: 'Pinned View' },
        { value: 'thisWeek', label: 'This Week' },
        { value: 'nextWeek', label: 'Next Week' },
        { value: 'reminders', label: 'My Reminders'},
        { value: 'project-specific', label: 'Project Detail View'},
        /*{ value: 'project', label: 'Single Week' },*/
    ];

    return (
        <Select 
            value={{value: currentValue.value, label: currentValue.label}} 
            onChange={handleViewChange} 
            options={options}
            styles={selectStyles} />
    );
};

export default TaskViewDropdown;
