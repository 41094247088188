import { supabase } from '../uc_constants/supabaseClient.js';
import { readHabits, upsertHabit } from './habitService';
import { upsertHabitTargets } from './targetService.js';
import { readGoals, upsertGoal } from './goalService';
import { upsertTask } from './taskService';
import cs from '../uc_constants/fwd_constants.js';
import dhf from '../uc_utils/dates/dates.js'

export async function readCurrentUser() {
  const { data: { user } } = await supabase.auth.getUser();

  if (!user) throw new Error('No user is currently logged in');

  let { data: profileData, error: profileError } = await supabase
    .from(`user_profiles`)
    .select(`*,
            stripe_customers (created_at, balance, discount, city, country),
            stripe_subscriptions (current_period_start, current_period_end, plan_id, plan_interval, plan_amount, product_id, latest_invoice_id, cancel_at, cancel_at_period_end, canceled_at)
`)
    .eq('id', user.id);

  if (profileError) throw profileError;

  // merge the data from both tables as needed
  const mergedData = {...profileData[0], ...user}; 

  //console.log(mergedData);

  return mergedData;
}

export async function upsertUserProfile(user_data) {
  console.log(user_data);
    let { data, error } = await supabase
    .from('user_profiles')
    .upsert([user_data], 
            {onConflict: 'id' })
      .select('*');

  if (error) {
      console.error('Error upserting user:', error);
  }
  return data;
}



export async function createUser(user) {
    // Create user account
    console.log('Initializing Forward Account - in function');
    const lt_users = cs.lifetime_members.map(email => String(email).trim().toLowerCase());

    const userEmail = String(user.email).trim().toLowerCase();

    let new_user_level = 0;
    
    if (lt_users.includes(userEmail)) {
      new_user_level = 6;
    }
    else {
      new_user_level = 1;
    }

    if (user.email.includes('analyticpartners') || user.email.includes('ben_billing@fastmail.com')) {
      throw new Error('Yikes! Employees of Anal Parts are not welcome to use Forward. Please do not try to use a personal account - you will be banned.');

    }
  
    if (user) {
      //Check for user account - if not, add one:
      const { data: eAcct, error: eAcctError } = await supabase
        .from('user_profiles')
        .select('*')
        .eq('id', user.id);
        
      if (eAcctError) {
        console.error('Error checking for existing area:', existingAreaError);
      }
      console.log(eAcct);
      if (eAcct.length <= 1) {
        console.log('adding an account to user_profiles');
        const data_to_insert = { id: user.id, account_level: new_user_level, email: user.email};
        console.log(data_to_insert);
        const { data: account, error: accountError } = await supabase
            .from('user_profiles')
            .upsert([
              data_to_insert
            ])
            .select('*');

            if (accountError) {
              console.error('Error creating user account');
            }
      }
      


      // If the user account was created successfully, check if life areas already exist
      const { data: existingArea, error: existingAreaError } = await supabase
        .from('forward_areas')
        .select('*')
        .eq('user_id', user.id)
        .filter('date_deleted','is','null');
        
      if (existingAreaError) {
        console.error('Error checking for existing area:', existingAreaError);
      }
      console.log(existingArea);
  
      // If a user has no life areas, create 3 by default
if (existingArea.length <= 0) {
  console.log('creating life areas');
  const { data: areas, error: areaError } = await supabase
    .from('forward_areas')
    .insert([
      { user_id: user.id, area_name: 'Career', order_id: 0 },
      { user_id: user.id, area_name: 'Friends & Family', order_id: 1 },
      { user_id: user.id, area_name: 'Self-Improvement', order_id: 2 },
    ])
    .select('*');

  if (areaError) {
    console.error('Error creating default area:', areaError);
  } else {

    //Check if we have any projects:
    const { data: existingProjects, error: existingProjectError } = await supabase
        .from('forward_projects')
        .select('*')
        .eq('user_id', user.id)
        .filter('date_deleted','is','null');
        
      if (existingAreaError) {
        console.error('Error checking for existing project:', existingProjectError);
      }
      console.log(existingProjects);

      if(existingProjects.length <= 0) {
    for (let area of areas) {
      const { error: projectError } = await supabase
        .from('forward_projects')
        .insert([
          { user_id: user.id, 
            area_id: area.id, 
            project_name: `${area.area_name} General`,
            description: `General tasks for this life area`, 
            order_id: -1,
            project_type:2, }
        ]);

      if (projectError) {
        console.error(`Error creating default project for ${area.area_name}:`, projectError);
      }
      }
    }
    }
  }
  //Get areaID to use:
  const { data: areaToUse, error: atuError } = await supabase
        .from('forward_areas')
        .select(`*,
                forward_projects (
            id, project_name)
        `)
        .eq('user_id', user.id)
        .eq('area_name','Self-Improvement')
        .filter('date_deleted','is','null');

    if(atuError) {
        console.error(atuError);
    }
    const baseArea = areaToUse[0].id;
    const baseProject = areaToUse[0]['forward_projects'][0].id

    console.log(areaToUse);
    console.log(baseArea);
    console.log(baseProject);

    //Add one accomplishment and one task
    if(baseArea > 0) {
        const taskToAdd = {'item_name':'Add my first tasks!', 
                           'project_id':baseProject, 
                           'item_status':'planned', 
                           'is_pinned':true,
                           'item_planned_week':dhf.formatDate(dhf.getStartOfWeek(new Date()),'yyyy-MM-dd')}
        const accToAdd  = {'item_name':'Started Using Forward!', 
                           'project_id':baseProject, 
                           'item_status':'complete', 
                           'item_planned_week':dhf.formatDate(dhf.getStartOfWeek(new Date()),'yyyy-MM-dd'),
                           'resolve_date':dhf.formatDate(new Date(),'yyyy-MM-dd')}

       const new_task = await upsertTask(taskToAdd);
       const new_acc = await upsertTask(accToAdd);
       if(new_task && new_acc) {
        console.log('Base Tasks created');
    }
    else {
        console.log('Error creating base tasks')
    }
    }

  //Now Let's add a Habit
  const my_habits = await readHabits(user.id);
  console.log(my_habits);
  if (Object.keys(my_habits).length === 0) {
    console.log('in if habit');
    const starter_habit_data = {'user_id':user.id, 'habit_name':'Check into Forward!', 'habit_type':'yesNo', 'area_id':baseArea }
    const new_habit = await upsertHabit(starter_habit_data);
    if(new_habit) {
        console.log('Base Habit created');
          //TODO: Create a target to check in 5 days a week
        const my_target = {target_value:5, is_greater_than:true, date:dhf.formatDate(dhf.getStartOfWeek(new Date()),'yyyy-MM-dd'), habit_id:new_habit[0].id}
        console.log(my_target);
        //Update Database
        const written_target = await upsertHabitTargets(my_target);
        console.log(written_target);
    }
    else {
        console.log('Error creating base habit')
    }
  }

  //Now let's check for shared life areas:
  let { data, error } = await supabase
        .from('life_area_collaborators')
        .select('*')
        .is('target_user_id', null)
        .eq('target_user_email', userEmail);

    if (error) {
      console.log('Error fetching shares:', error);
    }
    console.log(data);
    if(data.length > 0) {
    //Now let's update the life_area_collaborators
    const updateResult = await supabase
      .from('life_area_collaborators')
      .update({ target_user_id: user.id })
      .is('target_user_id',null)
      .eq('target_user_email', userEmail)
      .select();

    if (updateResult.error) {
      console.log('Error updating life_area_collaborators:', updateResult.error);
    } else {
      console.log('Life area collaborators updated successfully');
    }
  }

  //Now Let's add a goal
  const my_goals = await readGoals(user.id);
  console.log(my_goals);
  if (Object.keys(my_goals).length === 0) {
    console.log('in goals if');
    const starter_goal_data = {
                            'user_id':user.id, 
                            'goal_name':'Get more done each week with Forward!', 
                            'area_id':baseArea, 
                            'goal_status':'active',
                            'goal_progress':'green',
                            'goal_timeframe':new Date().getFullYear()}
    const new_goal = await upsertGoal(starter_goal_data);
    if(new_goal) {
        console.log('Base Goal created');
    }
    else {
        console.log('Error creating base goal')
    }


  }


} 
    return { user };
  }