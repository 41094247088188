import React, { useEffect, useState, useContext, useMemo } from 'react';
import Select from 'react-select';
import { useColorModeValue } from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext';

const NewAreaDropdown = ({ value, onChange, variant=null, area_limit = 'none', ...props }) => {
  const { state } = useContext(ForwardContext);

  const [selectedOption, setSelectedOption] = useState(null);
  const headerBg = useColorModeValue('var(--chakra-colors-forwardWhite-500)','var(--chakra-colors-forwardBlue-800)');
  const selectBg = useColorModeValue('var(--chakra-colors-forwardBlue-100)','var(--chakra-colors-forwardBlue-700)');
  const selectFont = useColorModeValue('black','white');
  const optionBg = useColorModeValue('var(--chakra-colors-forwardWhite-100)','var(--chakra-colors-forwardBlue-600)');

  const {isMobile} = {...props};

  const minWidth = isMobile ? '150px' : '270px';

  // Memoizing the grouped options
  const options = useMemo(() => {
    return state.areas
        .filter(area => area.status <= 2)
        .map(area => ({
          value: area.id,
          label: area.area_name + (area.status === 2 ? ' - paused' : '')
        }))
  }, [state.areas]);

  let selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '0px', // Set your desired border radius
      border:'0px',
      fontWeight:'bold',
      minWidth: minWidth, // Set your desired minimum width
      backgroundColor: optionBg,
    }),
    groupHeading: (provided) => ({
      ...provided,
      backgroundColor: headerBg, // Set your desired background color for headers
      fontWeight: 'bold',
    }),
    option: (provided, { data, isDisabled, isFocused, isSelected}) => ({
        ...provided,
        backgroundColor: isSelected ? selectBg : optionBg,
        color: isSelected ? selectFont : provided.color,
        ':active':{
            fontWeight:'bold',
        },
        ':hover':{
            fontWeight:'bold',
            backgroundColor: selectBg,
        },
    }),
    menuList: (provided) => ({
        ...provided,
        backgroundColor: optionBg,
    }),
    singleValue: (provided) => ({
        ...provided,
        color: selectFont,
    }),
    // You can add more style customizations here
  }
  //Append/update styles as needed
  if(variant==='headerNav') {
    selectStyles = {
      ...selectStyles,
      control: (provided) => ({
        ...provided,
        backgroundColor: 'var(--chakra-colors-forwardBlue-600)',
        borderRadius: '0px', // Set your desired border radius
        border:'0px',
        fontWeight:'bold',
        textColor:'white',
        minWidth: minWidth, // Set your desired minimum width
      }),
      input: (provided) => ({
        ...provided,
        color:'white',
      }),
      singleValue: (provided) => ({
        ...provided,
        color:'white',
      })
    };

  }


  // Effect to set the selected option
useEffect(() => {
    if (value) {
        const selected = options.find(option => option.value === parseInt(value));
        setSelectedOption(selected);
    } else {
        setSelectedOption(null);
    }
}, [value, options]);

  // Update for react-select
  const handleChange = selectedOption => {
    console.log(selectedOption);
    setSelectedOption(selectedOption);
    if (onChange && selectedOption) {
      onChange(selectedOption.value);
    }
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      styles={selectStyles}
      options={options}
      {...props}
    />
  );
};

export default NewAreaDropdown;
