import { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, FormControl, FormLabel, Input, Button, Text } from '@chakra-ui/react';
import { useAreaSharing } from '../../../data_hooks/sharing/useAreaSharing';

const ShareAreaForm = ({area, isOpen, onOpen, onClose}) => {
    const [email, setEmail] = useState('');
    const { shareLifeArea } = useAreaSharing();

    const handleCloseModal = () => {
        onClose();
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleShareArea = () => {
        console.log('Sharing area with:', email);
        shareLifeArea(area.user_id, area, email);

        onClose();
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={handleCloseModal} borderRadius={0}>
                <ModalOverlay />
                <ModalContent borderRadius={0}>
                    <ModalHeader>
                        <Text>Share {area.area_name}</Text>
                        <Text fontSize="sm">Use Forward to collaborate with others!</Text>
                    </ModalHeader>
                    <ModalBody>
                        <FormControl mb={2}>
                            <FormLabel>Share with: </FormLabel>
                            <Input placeholder="e-mail address" borderRadius={0} type="email" value={email} onChange={handleEmailChange} />
                        </FormControl>
                        <Text mb={2} fontSize="sm">Sharing an area with someone will give them access to all goals, habits, projects, and tasks within that area.</Text>
                        <Text mb={2}  fontSize="sm">Both users will be able to modify that data. </Text>
                        <Text mb={2}  fontSize="sm">If the e-mail address is not tied to a Forward Account, they will receive an e-mail saying that you have invited them to collaborate. </Text>

                    </ModalBody>
                    <ModalFooter>
                        <Button  borderRadius={0} colorScheme="blue" mr={3} onClick={handleShareArea}>
                            Share
                        </Button>
                        <Button  borderRadius={0} variant="ghost" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ShareAreaForm;
