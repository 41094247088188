import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, Heading, HStack, Center, Checkbox, Link, Text, VStack, Divider, UnorderedList, ListItem } from "@chakra-ui/react";
import MyHeader from "../../general/components/MyHeader";
import Footer from "../../general/components/Footer";
import ForwardSignupForm from '../components/ForwardSignupForm';
import { Link as ReachLink, useParams } from 'react-router-dom';

import { ForwardContext } from '../../context/ForwardContext';
import useAuth from '../hooks/useAuth';


const NewSignup = ({isSecret=false, app_override=null, showForward=true, showIntellectInbox = true}) => {
    const {app} = useParams();
    const { signInWithOtp, signOut, loading } = useAuth();
    let app_code = app_override || "1";
    if (app === 'forward') {
        app_code = "1";
    } else if (app === 'intellectinbox' || isSecret) {
        app_code = "2";
    }
    const [value, setValue] = useState(app_code);

    const handleChange = (val) => setValue(val);

    const {state, dispatch} = useContext(ForwardContext);
    const [forwardUser, setForwardUser] = useState(null);


    useEffect(() => {
        if (state?.account?.email) {
            setForwardUser(state.account.email);
        }
    }, [state]);


    const handleSignup = async (event, email, app_name) => {
        event.preventDefault();
            await signInWithOtp(email, app_name);
    }

    const handleSignout = async (app_name) => {
        console.log('Signing out of '+app_name);
        signOut(app_name);
        if (app_name === 'forward') {
            setForwardUser(null);
        }
    }

    

    return (
        <>
            <MyHeader />
            <Box py={4} px={{ base: 4, md: 8 }} minHeight="90vh">
                <Center width="100%">
                    <Box width="100%" maxWidth="1000px">
                        <Heading as="h2" size="lg" my={4}>Signup For An Account</Heading>
                        <HStack width="100%">
                            
                            <Button flex="1" size="lg" borderRadius={"20px"} onClick={() => handleChange("1")} colorScheme={value === "1" ? "teal" : "gray"}>
                                <VStack spacing={0}>
                                    <Text>Forward</Text>
                                    <Text fontSize="xs" fontWeight="thin">Life Management Platform</Text>
                                </VStack>
                            </Button>
                        </HStack>
                        <Divider my={4} />

                        {value === "1" && !forwardUser && (

                        <ForwardSignupForm 
                        handleSignup={handleSignup} 
                        mode="signin"
                        />

                        )}
                        {value === "1" && forwardUser && (
                            <VStack spacing={2}>
                                <Text>You are already signed up for Forward as {forwardUser} (Not you? <Link onClick={() => handleSignout('forward')} color="teal" fontWeight="bold">Sign out</Link>)</Text>
                                <Link color="teal" fontWeight="bold" as={ReachLink} to="/forward">Go to Forward</Link>
                            </VStack>
                        )}
                        {(value === "1" && !forwardUser) && (<><Divider my={4} />
                        <UnorderedList>
                            <ListItem>We will send you an e-mail to with a link to confirm your registration.</ListItem>
                            <ListItem>If you already have an account, we'll send you a magic link to login. Alternatively, you can login with your password&nbsp;
                                <Link textColor="teal" fontWeight="bold" as={ReachLink} to="/login/forward">here.</Link></ListItem>
                        </UnorderedList>
                        </>)}
                    </Box>
                </Center>
            </Box>
            <Footer />
        </>
    );
};

export default NewSignup;