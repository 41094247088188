import React, {useRef, useContext, useState, useEffect} from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  useColorModeValue,
  useDisclosure, 
  useColorMode,
  useMediaQuery,
  HStack,
  VStack,
} from '@chakra-ui/react';
import {ColorModeSwitcher} from '../../../general/components/ColorModeSwitcher.js';
import { useNavigate } from 'react-router-dom';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import FeedbackModal from '../../../general/components/FeedbackModal.jsx';

import AccountBadge from './AccountBadge.jsx';

import { AiFillMail, AiOutlineExclamationCircle, AiOutlineSmile, AiOutlineQuestionCircle, AiFillBook, AiOutlineSearch } from 'react-icons/ai';

import cs from '../../../uc_constants/fwd_constants.js'

const AppHeader = ({ session, toggleOnboardingModal}) => {
  const {state, dispatch} = useContext(ForwardContext);
  const {isOpen:isFeedbackOpen, onOpen:onFeedbackOpen, onClose:onFeedbackClose} = useDisclosure();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isLessThan480] = useMediaQuery("(max-width: 480px)");

  const navigate=useNavigate();
const menu_font_color = useColorModeValue('black','white');


useEffect(() => {
  setIsSmallScreen(isLessThan480);
}, [isLessThan480]);

const go_home = () => {
  //console.log('Selected area:', value);
  navigate('/forward/home')
  dispatch({ type: "SET_CURRENT_VIEW", payload: 'Home' });
};
/*const handleRefreshClick = async() => {
  await syncAreas(session.user.id);
  await syncProjects(session.user.id);
  await syncHabits(session.user.id);
  console.log(state);
}
*/

const handleGuideClick = async() => {
  console.log('here');
  toggleOnboardingModal();

}

  return (
    <Box bg="forwardBlue.800" w="100%" py={1} px={4} color="white" zIndex="110">
      <VStack width="100%">
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Flex alignItems="center">
          <Image src="/home/forward_logo_v1_min.png" alt="Logo" boxSize="40px" borderRadius="20px" cursor="pointer" onClick={()=>go_home()}/>
          <Box ml={2} fontWeight="bold" fontSize="xl" cursor="pointer" onClick={()=>go_home()}>
            Forward
          </Box>
        </Flex>
        <HStack>
        <AccountBadge />
        <ButtonGroup alignContent="center" colorScheme="white">
          <Button 
          as={IconButton} 
          borderRadius={0} 
          icon={<AiOutlineSearch />} 
          variant="ghost" 
          px={0}
          size="lg" 
          color="white"
          onClick={() => document.dispatchEvent(new Event('openNavigationSearchModal'))} />
          {/* First: help and questions */}
          <Menu colorScheme="white" zIndex="101">
            <MenuButton
              as={IconButton}
              borderRadius={0}
              aria-label="Settings"
              icon={<AiOutlineQuestionCircle />}
              variant="ghost"
              size="lg"
            />
            <ColorModeSwitcher size="md" m={1} />
            <MenuList 
            textColor={menu_font_color}  zIndex="101"
            >
              <MenuItem onClick={() => navigate('/forward/updates')}><HStack><AiOutlineExclamationCircle /><Text>What's New</Text></HStack></MenuItem>
              <MenuItem as="a" href="https://benmumma.notion.site/Forward-da3c2c82d0f84ffd98a65a970db50f7c" target="_blank" rel="noopener noreferrer"><HStack><AiFillBook /><Text>Documentation</Text></HStack></MenuItem>
              <MenuItem onClick={onFeedbackOpen}><HStack><AiFillMail /><Text>Send Feedback</Text></HStack></MenuItem>
              <MenuItem onClick={() => window.open(cs.discord_url, '_blank')}>
                <HStack>
                  <AiOutlineSmile />
                  <Text>Join our Discord!</Text>
                </HStack>
              </MenuItem>
              <MenuItem onClick={() => handleGuideClick()}><HStack><AiOutlineQuestionCircle /><Text>Intro User Guide</Text></HStack></MenuItem>
              
            </MenuList>
          </Menu>


          {/* Second: menu items */}
          {/*<Menu colorScheme="blackAlpha" zIndex="101">
            <MenuButton
              as={IconButton}
              borderRadius={0}
              aria-label="Settings"
              icon={<AiOutlineMenu />}
              variant="ghost"
              size="lg"
            />
            <MenuList 
            textColor={menu_font_color}  zIndex="101"
            >
              <MenuItem onClick={toggleColorMode}><HStack>{cm_icon}<Text>{cm_text}</Text></HStack></MenuItem>
              <MenuItem onClick={() => handleRefreshClick()}><HStack><AiOutlineReload /><Text>Refresh Data</Text></HStack></MenuItem>
              <MenuItem onClick={() => handleDownloadClick()}><HStack><TiExport /><Text>Export My Data</Text></HStack></MenuItem>
              <MenuItem onClick={() => navigate('/account')}><HStack><AiFillSetting /><Text>Manage Account</Text></HStack></MenuItem>
            </MenuList>
  </Menu>*/}
          
        </ButtonGroup>
        </HStack>
      </Flex>
      </VStack>
      <FeedbackModal  isOpen={isFeedbackOpen}
                      onClose={onFeedbackClose}
                      product="Forward" 
                      inputEmail={state.account.email}/>
    </Box>
  );
};

export default AppHeader;