import React, { useState, useContext, useEffect, useMemo} from 'react';
import { Box, HStack, Button, Text, useColorModeValue, IconButton, Flex, VStack, Input, useDisclosure, Image } from '@chakra-ui/react';
import { BiCaretUp , BiCaretDown, BiMenu } from 'react-icons/bi';
import dhf from '../../../uc_utils/dates/dates.js'
import hhf from '../../../uc_utils/habits/habits.js'
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { FormContext } from '../../../context/FormContext.jsx';
import YesNoSkipSelector from '../forms/YesNoSkipSelector.jsx';
import HabitNumberInput from '../forms/HabitNumberInput.jsx';
import HabitRowButtons from '../components/HabitRowButtons.jsx';
import HabitRowTotal from '../components/HabitRowTotal.jsx';
import TargetForm from '../forms/TargetForm.jsx';
import { Link as RouterLink } from 'react-router-dom';
import { useHabits } from '../../../data_hooks/elements/useHabits.jsx';

const HabitRow = ({ habit, dragHandle}) => {
  const {state, dispatch} = useContext(ForwardContext);
  const { editHabit} = useContext(FormContext);
  const {upsertHabitValue} = useHabits();
  const habitStats = state.stats['habit_'+habit.id];
  

  //Modal Management
  const { isOpen: isTargetOpen, onOpen: onTargetOpen, onClose: onTargetClose } = useDisclosure();
  const { isOpen: isGraphOpen, onOpen: onGraphOpen, onClose: onGraphClose } = useDisclosure();
  const isMobile = window.innerWidth <= 768;

  const bgColor = useColorModeValue("gray.100","gray.600");   

    // useMemo for expensive calculations
    const startDate = useMemo(() => state.ui.currentWeek, [state.ui.currentWeek]);
    //console.log(startDate);
    const startDateAsString = useMemo(() => dhf.formatDate(dhf.getStartOfWeek(startDate), 'yyyy-MM-dd'), [startDate]);
    //console.log(startDateAsString);

    //console.log(habitStats);
    const sm = useMemo(() => hhf.newStreakMedal(habitStats?.weekly_stats?.[startDateAsString]?.streak), [habitStats, startDateAsString]);
  
  const getCurrentWeekDates = (startDate) => {
    const weekDates = [];
    const date = new Date(startDate);
  
    for (let i = 0; i < 7; i++) {
      weekDates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return weekDates;
  };

  const weekDates = getCurrentWeekDates(startDate);

  
  const handleBlur = async (habit_id, date, value) => {
    upsertHabitValue(habit_id, date, value);
  };

  {/**************** Appearance of Paused Habits ********************/}

  if (habit.status === 2) {
    return (
      <HStack
      bg={bgColor}
      p={{'base':'0 !important','md':'2'}}
      w="100%"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px"
      borderLeft="1px"
      borderRight="1px"
    >
      <VStack padding="0px">
      <IconButton
        size={{'base':"3xs",'md':"xs"}}
        marginTop="0px"
        borderRadius="0px"
        icon={<BiMenu />}
        aria-label="Move habit up"
        {...dragHandle}
        //onClick={() => moveHabit(habit.id,-1)}
      />
      </VStack>
      <VStack>
      
      <HStack>
        <Text as={RouterLink} cursor="pointer" fontStyle="oblique" textColor="gray" to={"/forward/habit/"+habit.id}>
          {habit.habit_name} <span >(on pause)</span>
        </Text>
      </HStack>
      </VStack>
      <HabitRowButtons    habit={habit} 
                          onTargetOpen={onTargetOpen} 
                          editHabitOpen={editHabit}/>
    </HStack>
    )
  }

    {/**************** Appearance of Normal Habits ********************/}
    //console.log(sm);


  return (
    <HStack
      bg={bgColor}
      p={{'base':'1 !important','md':'2'}}
      w="100%"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px"
      borderLeft="8px"
      borderLeftColor={'streakColor.'+sm.color}
      borderRight="1px"
      spacing={0}
    >
      {/* Sorting Icon */}
      <VStack padding="0px" flex="1">
      <IconButton
        size={{'base':"3xs",'md':"xs"}}
        marginTop="0px"
        padding="0px"
        borderRadius="0px"
        icon={<BiMenu size="16px" />}
        aria-label="Move habit up"
        {...dragHandle}
        //onClick={() => moveHabit(habit.id,-1)}
      />
      </VStack>
      {/* Main Body */}
      <Flex className="mobile-first-shell" 
            alignItems="center"
            justifyContent="flex-start" 

            flexDirection={{'base':'column','md':'row'}} 
            width="100%">
      {/* Habit Name */}
      <HStack
      flex="4.5"  
      marginBottom={{'base':2,'md':0}}
      >
      {sm.name !== 'none' && <Image src={"/forward/medals/"+sm.name+".png"} alt={sm.name} width="20px" height="20px" />}
      {sm.name === 'none' && <Box width={{'base':'10px','md':'20px'}} ></Box>}
      <Text fontSize={{'base':'sm','md':'md'}} 
            as={RouterLink}
            mr={2} 
            flex="5"  
            textAlign="left"
            w={{'base':"100%",'md':'75px'}} 
            cursor="pointer"
            to={"/forward/habit/"+habit.id}
            overflow="hidden">
              {habit.habit_name}</Text>
              {sm.name === 'none' && <Box width={{'base':'10px','md':'0px'}} ></Box>}
      </HStack>
      {/* Data Entry Bar */}
      
      <HStack marginRight="4px" flex="14" width="90%">
          {weekDates.map((date) => {
            //date.setHours(0,0,0,0);
            const dateString = dhf.formatDate(date,'yyyy-MM-dd');
            const value = dateString in habit.values ? habit.values[dateString] : '';
            //const value = (habit.habit_type === 'numeric' ? '' : 'skip');
            const isDisabled = (dhf.isFutureDate(date, startDate) || state.ui.is_readOnly);
            const handleClick = (event) => {
              event.target.select();
            };

            const v_len = String(value).length;
            let nFontSize;
            if (v_len >= 4) {
              nFontSize = {'base':'2xs','md':'sm'};
            }
            else if (v_len >= 3) {
              nFontSize = {'base':'xs','md':'md'};
            }
            else {
              nFontSize = {'base':'md','md':'lg'};
            }

            return (
              <Box key={dateString} flex="2" minWidth={{'base':'40px','md':'0px'}} >
                {habit.habit_type === 'numeric' ? (
                  <HabitNumberInput
                  habitId={habit.id}
                  dateString={dateString}
                  value={value}
                  handleClick={handleClick}
                  fontSize={nFontSize}
                  onBlur={handleBlur}
                  isDisabled={isDisabled}
                />
                ) : (
                  <YesNoSkipSelector
                    value={value}
                    onValueChange={(newValue) => handleBlur(habit.id, dateString, newValue)}
                    isDisabled={isDisabled}
                  />
                )}
              </Box>
            );
          })}
  </HStack>
  <Flex className="wrapper-class" alignItems="center" flex={{'base':7,'md':6}} width="100%" mt={{'base':2,'md':0}}>
  <HabitRowTotal habit={habit} 
                  habitStats={habitStats}
                startDate={startDate} 
                onTargetOpen={onTargetOpen} 
                flex="1"/>

  <HabitRowButtons  habit={habit} 
                    onTargetOpen={onTargetOpen} 
                    editHabitOpen={editHabit} />
  <TargetForm habit={habit} isOpen={isTargetOpen} onClose={onTargetClose}/>
  </Flex>
    </Flex>
    </HStack>
  )
};

export default HabitRow;
