import React, { useState, useEffect } from 'react';
import { Input } from '@chakra-ui/react';
import { trackCustomEvent } from '../../../general/functions/plausibleEvent';

const HabitNumberInput = ({ habitId, dateString, value, isDisabled, handleClick, fontSize, onBlur, newView=false, ...props }) => {
  //console.log(value)
  const [inputValue, setInputValue] = useState(value !== undefined ? value.toString() : '');

  let height_object = {'base':"30px",'md':"40px"};
  if (newView) {
    height_object = {'base':"28px",'md':"28px"};
  }

  useEffect(() => {
    setInputValue(value !== undefined ? value.toString() : '');
  }, [value]);

  const handleBlur = () => {
    if (inputValue !== (value !== undefined ? value.toString() : '')) {
      onBlur(habitId, dateString, Number(inputValue));
      trackCustomEvent('FWD Habit Entry', { habit_type: 'number'});
    }
  };

  return (
    <Input
      type="number"
      cursor="pointer"
      value={inputValue}
      textAlign="center"
      padding={{'base':"1px",'md':"3px"}}
      onChange={(e) => setInputValue(e.target.value)}
      onBlur={handleBlur}
      onClick={handleClick}
      w="100%"
      h={height_object}
      boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
      fontSize={fontSize}
      borderRadius={0}
      disabled={isDisabled}
      _disabled={{ opacity: 0.4 }}
      {...props}
    />
  );
};

export default HabitNumberInput;
