import { useEffect, useContext, useCallback } from 'react';
import { ForwardContext } from '../../context/ForwardContext.jsx';
import { upsertGoal } from '../../database_api/goalService.js';
import { trackCustomEvent } from '../../general/functions/plausibleEvent.js';
import { usePermissions } from '../permissions/usePermissions.jsx';



export const useGoals = () => {
  const { state, dispatch, session } = useContext(ForwardContext)
  const {checkPermissionToAdd} = usePermissions();
  //
  //Add more functions as needed here
  //
  const upsertGoalProperty = async(goal, property, value) => {
    const goal_data = [{'user_id':goal.user_id, 'id':goal.id, 'area_id':goal.area_id, [property]:value }]

    //Submit to Database
    const new_goal = await upsertGoal(goal_data)
    //Update State

    if (property === 'date_deleted' && value !== null) {
        dispatch({ type: 'REMOVE_GOAL', payload: { goalId:goal.id, areaId: goal.area_id } });
    }
    else {
    dispatch({ type: 'UPSERT_GOAL', payload: goal_data });
    }
    
    console.log('Goal added/updated!');
  //If not, alert to error
   if (!new_goal)  {
      alert("Error Updating Goal!")
    }
  }

  
  const upsertFullGoal = async (goal_data) => {
    //Submit to Database
    let mode = 'create';
    if(goal_data.id > 0) {
      mode = 'edit';
    }
    let permission_to_create = checkPermissionToAdd('goals',mode);
    
    try {
      if(permission_to_create) {
    const new_goal = await upsertGoal(goal_data);
    console.log(new_goal);
    trackCustomEvent('FWD Goal Added', {goal_name: goal_data.goal_name});
    if (new_goal) {

      dispatch({ type: 'UPSERT_GOAL', payload: new_goal });
      console.log('Goal added/updated!');
      if(mode === 'edit' > 0) {
        return {result:'success',message:'goal edit successful'}
      }
      else {
        return {result:'success',message:'goal add successful'}
      }
  //If not, alert to error
  } else  {
      alert("Error Adding Goal!")
      return {result:'error',message:'goal add failure at database level'}
  }
  }
    else {
      return {result:'error',message:'goal limit reached.'}
    }
  }
  catch {
    return {result:'error',message:'goal add failed.'}
  }
}

  
  
  
  
  
  
  return {upsertGoalProperty, upsertFullGoal};
};