import React, { useState, useEffect, useContext } from 'react';
import { Box, HStack, Text, Tooltip, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { BiTargetLock } from 'react-icons/bi';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import TargetForm from '../../habits/forms/TargetForm.jsx';
import dhf from '../../../uc_utils/dates/dates.js';
import hhf from '../../../uc_utils/habits/habits.js';
import { showCellWriting } from '../helpers/cells.js';
 

const HabitGoalGridWeeklyCell = ({
  area_id, habit_id, startDate,
  cell_width,
  backgroundColor = false,
  toDoOnClick = false,
  showHoverText = false,
  showValues = true,

  borderWidth = "0.5px",
}) => {

    const { state } = useContext(ForwardContext);
    const habit = state.habits[area_id].find(h => h.id === habit_id);
    const habitStats = state.stats['habit_' + habit_id];
    const day_start = dhf.getLocalMidnightDate(startDate);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentValue, setCurrentValue] = useState(0);
    const [currentTarget, setCurrentTarget] = useState(null);
    const [progressWidth, setProgressWidth] = useState(0);

    // Use consistent color definitions
    const textColor = useColorModeValue('black', 'white');

    // Define style within the component for better readability
    const completedStyle = {
      backgroundImage: 'linear-gradient(45deg, rgba(255,255,255,0.2) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.2) 75%, transparent 75%, transparent)',
      backgroundSize: '1rem 1rem'
  };

    const targetIsSet = habit.targets.length > 0;
    const target = hhf.getMostRecentTarget(habit.targets, day_start);

    //console.log(target);

    const projected_value = hhf.calculateProjectedValue(currentValue, day_start, new Date())

    let { tvp_text, tvp_fs, bar_color, tooltip_text } = hhf.getProgressDetails(targetIsSet, currentTarget, target?.[1], currentValue, projected_value);

    useEffect(() => {
      const newCurrentValue = habitStats?.weekly_stats?.[dhf.formatDate(day_start)]?.total || 0;
      const newTarget = hhf.getMostRecentTarget(habit.targets, day_start);
      const newTargetValue = newTarget?.[0] || null;
      const newPercentComplete = newTargetValue ? (newCurrentValue / newTargetValue) * 100 : 0;

      setCurrentValue(newCurrentValue);
      setCurrentTarget(newTargetValue);
      setProgressWidth(newPercentComplete > 100 ? 100 : newPercentComplete);
  }, [habitStats, startDate, habit.targets.length]);  // React to changes in state and startDate

  let display_text = `${currentValue}/${currentTarget}`

  if(currentTarget === null) {
    bar_color = 'gray.200';
    display_text = `${currentValue}`;
  }
  if(day_start > new Date()) {

    bar_color = 'gray.200';
    display_text = '';

  }


return (
  <>
      <Tooltip label={showHoverText ? tooltip_text : ""} placement="bottom" hasArrow>
          <Box position="relative" w="100%" minHeight="30px" maxWidth="500px"
              bgColor={bar_color} onClick={toDoOnClick === 'set_target' ? onOpen : undefined}
              cursor={toDoOnClick === 'set_target' ? 'pointer' : 'default'}
              overflowX="clip" overflowY="clip">
              
                  {showValues && 
                  <Text fontSize="xs" width="100%" fontStyle="italic" textAlign="center" textColor={textColor}
                      >
                      {showCellWriting(display_text,cell_width) ? display_text : '...'}
                  </Text>}
          </Box>
      </Tooltip>
      <TargetForm habit={habit} isOpen={isOpen} onClose={onClose} />
  </>
);
};

export default HabitGoalGridWeeklyCell;
