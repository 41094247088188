import AccountMain from './AccountMain';
import LoadingAnimation from '../../fwd_ui/general/components/LoadingAnimation';
import ForwardWelcome from '../../fwd_main/intro/ForwardWelcome';

function Account({loadingSession, session}) {
  //Upon load, try to set a session:
  if (loadingSession) {
    return <LoadingAnimation />;
  }

  //If there is not a session, return the authentication page. If not, return the app
  return (
    <>
      {!session ? <ForwardWelcome 
      alert={{'status':'warning','message':'You must be logged in to manage an account','duration':10000}} 
      /> : <AccountMain session={session} />}
    </>
  )
}

export default Account