import React, {useState, useEffect} from "react";
import {
  ChakraProvider,
} from '@chakra-ui/react';
import theme from "./general/theme";
import { Route, Routes} from 'react-router-dom';
import { supabase } from "./uc_constants/supabaseClient.js";
import { ForwardProvider} from "./context/ForwardContext";

import Forward from "./fwd_main/Forward.jsx";
import Password from "./account/pages/Password";
import Account from "./account/pages/Account.jsx";
import UpdatesView from "./fwd_main/views/UpdatesView.jsx";
import Privacy from "./docs/Privacy.jsx";
import TermsOfService from "./docs/TermsOfService.jsx";
import NewSignup from "./account/pages/NewSignup.jsx";
import NewLogin from "./account/pages/NewLogin.jsx";
import NewSetPassword from "./account/pages/NewSetPassword.jsx";
import Pricing from "./docs/Pricing.jsx";
import About from "./docs/About.jsx";

function App() {
    //Upon load, try to set a session:
    const [session, setSession] = useState(null)
    const [loadingSession, setLoadingSession] = useState(true);

    //Core Authentication Method: if there is a session, set loading equal to false
    useEffect(() => {
      supabase.auth.getSession().then(({ data: { session } }) => {
        setSession(session)
        setLoadingSession(false);
      })
      supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session)
        setLoadingSession(false);
      })
    }, []);

  return (
    <ChakraProvider theme={theme}>
      <ForwardProvider>
      <Routes>

            <Route path="/" element={<Forward loadingSession={loadingSession} session={session}/>} />
                      
            <Route path="/docs/forward/privacy" element={<Privacy  session={session}/>} />
            <Route path="/docs/forward/tos" element={<TermsOfService session={session} />} />
            <Route path="/docs/forward/whatsnew" element={<UpdatesView session={session} />} />

            <Route path="/signup" element={<NewSignup />} />
            <Route path="/signup/:app" element={<NewSignup />} />
            <Route path="/login" element={<NewLogin />} />
            <Route path="/login/:app" element={<NewLogin />} />

            <Route path="/setpassword/:app" element={<NewSetPassword />} />
            <Route path="/forward/*" element={<Forward loadingSession={loadingSession} session={session}/>} />
            <Route path="/password" element={<Password loadingSession={loadingSession} session={session}/>} />
            <Route path="/account" element={<Account loadingSession={loadingSession} session={session}/>} />

            <Route path="/pricing" element={<Pricing />} />
            <Route path="/about" element={<About />} />

            <Route path="/tos" element={<TermsOfService session={session} />} />
            <Route path="/privacy" element={<Privacy session={session} />} />

      </Routes>
      </ForwardProvider>
    </ChakraProvider>
  );
}

export default App;
