import { useContext } from 'react';
import { ForwardContext } from '../../context/ForwardContext.jsx';
import { usePermissions } from '../permissions/usePermissions.jsx';
import { countMyShares, insertShare, readShares, updateShare } from '../../database_api/sharingService.js';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import {API_BASE_URL} from '../../uc_constants/constants.js';

export const useAreaSharing = () => {
  const { state, dispatch, session } = useContext(ForwardContext);
  const session_id = state.session_id;
  const user_id = state.account.id;
  const {checkPermissionToAdd} = usePermissions();
  const toast = useToast();

  const shareLifeArea = async (user_id, area, target_email) => {

        //Check for permission to share area
        const can_share = checkPermissionToAdd('shared_areas','add');

        //Insert Row into life_area_collaborators
        if (can_share) {
            console.log(user_id);
            console.log(area);
            console.log(target_email);
            const userEmail = state.account.email;
            const new_share = await insertShare(user_id, userEmail, area.id, area.area_name, target_email);
            console.log(new_share);

            if (new_share) {
                console.log('Share inserted');
                try {
                    //userEmail, toAddress, message_type, message_information
                    console.log('Sending e-mail');
                  const response = await axios.post(API_BASE_URL+'forward/mail/', {userEmail:userEmail, toAddress:target_email, message_type: 'area_share_invite', 
                  message_information: area.area_name});
                  console.log(response);
                    toast({
                        title: "Area Shared",
                        description: "The area has been shared with " + target_email+'! They will receive an e-mail and can accept it in the Sharing Center',
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                        });
                } catch (err) {
                  console.error(err);
                  toast({
                    title: "Error",
                    description: "There was an error sharing the area with " + target_email,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                    });
                }

               
            }
            else {
                toast({
                title: "Error",
                description: "There was an error sharing the area with " + target_email,
                status: "error",
                duration: 9000,
                isClosable: true,
                });
            }
            
        }

        //Send e-mail to the user
    
  }

  const readMyShares = async (user_id) => {
    const shares = await readShares(user_id);
    console.log(shares);
    dispatch({ type: 'SET_SHARES', payload: shares });
    
    return shares;
  }

  //acceptShare, rejectShare, revokeShare, leaveShare, remindShare, cancelShare

  const upsertShareProperty = async(share_object, user_role, property, value, ) => {
    let share_data = {'id':share_object.id, 'area_id':share_object.area_id, [property]:value  }
    if (user_role === 'principal') {
        share_data['principal_user_id'] = user_id;
    }
    else if (user_role === 'target') {
        share_data['target_user_id'] = user_id;
    }

    console.log(share_data);

    //Submit to Database
    const updated_share = await updateShare(share_data)
    //Update State

    return updated_share;


  }
  
  
  
  return {shareLifeArea, readMyShares, upsertShareProperty};
};