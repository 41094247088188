import React, { useState, useEffect } from 'react';
import { Box, Text, Select } from '@chakra-ui/react';

const SettingRow = ({ title, context, initialValue, onUpdate, options }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    onUpdate(newValue);
  };

  return (
    <Box p={5} shadow="md" borderWidth="1px">
      <Text mb="4px" fontWeight="bold">{title}</Text>
      <Text mb="4px" fontSize="sm" fontStyle="oblique">{context}</Text>
      <Select borderRadius="0px !important" value={value} onChange={handleChange}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default SettingRow;
