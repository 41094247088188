import { supabase } from '../uc_constants/supabaseClient';
import { format } from 'date-fns';

export async function upsertGoal(goal_data) {
    let { data, error } = await supabase
    .from('forward_goals')
    .upsert( goal_data, 
            {onConflict: 'id' })
      .select('*');

  if (error) {
      console.error('Error upserting user:', error);
  }
  return data;
}
export async function upsertGoal_base(goal_data) {
    let { data, error } = await supabase
    .from('forward_goals')
    .upsert(goal_data , 
            {onConflict: 'id' })
      .select('*');

  if (error) {
      console.error('Error upserting user:', error);
  }
  return data;
}
export async function readGoals(userId) {
    let { data, error } = await supabase
        .from('forward_goals')
        .select(`*
                `)
        .lte('status',3)
        .filter('date_deleted','is','null')
        .order('order_id')
        ;

    const goalsByArea = {};
    data.forEach((goal) => {
        if (!goalsByArea[goal.area_id]) {
            goalsByArea[goal.area_id] = [];
          }
          goalsByArea[goal.area_id].push(goal);
    });
    if (error) throw error;
    return goalsByArea;
}

export async function updateGoalOrdering(user_id, orderedGoals) {
    console.log(orderedGoals);
    const data = orderedGoals.map(({ id, order_id }) => ({ id, order_id, user_id:user_id}));

    console.log(data);
  
    //console.log(data);
    const { error } = await supabase
      .from('forward_goals')
      .upsert(data, { onConflict: 'id' });
  
    if (error) {
      console.error('Error updating task order:', error);
      return { success: false, message: error.message };
    }
  
    return { success: true };
  };
