
import { Box, HStack, Button, Text, IconButton, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import React, {useContext, useEffect, useState} from 'react';
import { useParams, Link } from 'react-router-dom';
import { ForwardContext } from '../../context/ForwardContext.jsx';
import VisitForwardLink from './VisitForwardLink.jsx';
import HabitCell from '../habits/rows/HabitCell.jsx';
import UpcomingReminders from '../reminders/views/UpcomingReminders.jsx';
import PinnedTasks from '../tasks/views/PinnedTasks.jsx';
import ProjectRow from '../projects/rows/ProjectRow.jsx';
import { ColorModeSwitcher } from '../../general/components/ColorModeSwitcher.js';
import { AiOutlineSwap } from 'react-icons/ai';
import { useTaskDnd } from '../../data_hooks/dnd/useTaskDnd.jsx';
import { DragDropContext } from '@hello-pangea/dnd';

const GeneralEmbedWrapper = ({session, embedded_view, embed=false}) => {

    const { id } = useParams();
    const { state, dispatch } = useContext(ForwardContext);
    const {handleDragEndWell} = useTaskDnd();

    const [my_habit, setMyHabit] = useState(null);
    const [my_project, setMyProject] = useState(null);
    const [isClearToLoad, setIsClearToLoad] = useState(false);
    const viewMode = 'backlog';

    useEffect(() => {
        if(embedded_view === 'habit_cell') {
        const habit = Object.values(state.habits).flat().filter(habit => parseInt(habit.id) === parseInt(id))[0];
        setMyHabit(habit);
        }
        else if(embedded_view === 'project') {
        const project = Object.values(state.projects).flat().filter(project => parseInt(project.id) === parseInt(id))[0];
        setMyProject(project);
        }
        else if(embedded_view === 'pinned_tasks') {
        setIsClearToLoad(true);
        }
        else if(embedded_view === 'upcoming_reminders') {
        setIsClearToLoad(true);
        }


    }, [id, state.habits, state.projects, embedded_view])

    const [currentDate, setCurrentDate] = useState(new Date());
    const goBackOneDay = () => {
        setCurrentDate(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(newDate.getDate() - 1);
            return newDate;
        });
    };

    const goForwardOneDay = () => {
        setCurrentDate(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(newDate.getDate() + 1);
            return newDate;
        });
    };

    /****************************************/
    /* Render the appropriate embedded view */
    /****************************************/
    let contentElement = <></>;
    let switcherElement = <></>;
    let navElement = <></>;
    let maxWidth = '450px';
    if (embedded_view === 'habit_cell') {
        maxWidth='300px';
        contentElement = <HabitCell 
            habit={my_habit}
            liveDay={currentDate}
            embed={embed}
            />
        
        switcherElement = <Menu>
        <MenuButton as={IconButton} icon={<AiOutlineSwap />} size="xs" borderRadius="0px" margin="0px" padding="0px" />
    <MenuList>
    {Object.values(state.habits).flatMap(h => h).filter(h => h.status <= 1)
      .map(h => {
        return(
          <MenuItem as={Link} key={h.id} to={"/forward/embed/habit/"+h.id+"/cell"}>
            {h.habit_name}
          </MenuItem>
          )}
        )}
    </MenuList>
    </Menu>;

        navElement = <><Button borderRadius={0} size="sm" onClick={goBackOneDay}>←</Button>
        <Text mx={4}>{currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</Text>
        <Button borderRadius={0} size="sm" onClick={goForwardOneDay}>→</Button></>
    }
    else if (embedded_view === 'upcoming_reminders') {
        contentElement = <UpcomingReminders session={session} embed={embed} />
    }
    else if (embedded_view === 'project') {
        maxWidth = '1500px';
        contentElement = <DragDropContext onDragEnd={handleDragEndWell}>
                            <ProjectRow 
                            key={'project-'+id} 
                            project={my_project} 
                            tasks={state.tasks[id]} 
                            viewMode={viewMode}
                            embed={embed} />
                            </DragDropContext>

switcherElement = <Menu>
                    <MenuButton as={IconButton} icon={<AiOutlineSwap />} size="xs" borderRadius="0px" margin="0px" padding="0px" />
                    <MenuList>
                    {Object.values(state.projects).flatMap(h => h).filter(h => h.status <= 1)
                    .map(h => {
                    return(
                    <MenuItem as={Link} key={h.id} to={"/forward/embed/project/"+h.id}>
                        {h.project_name}
                    </MenuItem>
                    )}
                    )}
                    </MenuList>
                    </Menu>;
    } 
    else if (embedded_view === 'pinned_tasks') {
        contentElement = <PinnedTasks session={session} embed={embed} />
    }


    return (
        <>
        <Box mt={embed ? 0 : 0} width="100%" maxWidth={maxWidth}>
        {/* Top Row */}
        <ColorModeSwitcher justifySelf="flex-start" size="xs" fontSize="xs" borderRadius="0px" margin="0px" padding="0px"/>
        {switcherElement}
        {/* Optional Nav Row */}
            <HStack width="100%" justifyContent="space-between">
            {navElement}
            </HStack>
        {/* Main Content Row */}
        {(isClearToLoad || my_habit || my_project) && contentElement}
        {embed && <VisitForwardLink />}
        </Box>
        </>
    );
}

export default GeneralEmbedWrapper;
