import React, { useContext } from 'react';
import { Button, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { BiChevronDown } from 'react-icons/bi';
import { FormContext } from '../../../context/FormContext';

const AddButton = ({ entities, styled_as = 'main', editorData = {} }) => {
    const { addTask, addReminder, addProject, addHabit, addGoal, addArea } = useContext(FormContext); // Use FormContext

    const onClickFunctions = {
        task: addTask,
        reminder: addReminder,
        project: addProject,
        habit: addHabit,
        goal: addGoal,
        area: addArea,
        recurring_task: addTask,
    };
    const display_names = {
        task: "Task",
        reminder: "Reminder",
        project: "Project",
        habit: "Habit",
        goal: "Goal",
        area: "Area",
        recurring_task: "Recurring Task",
    }

    let style_props = {}
    if(styled_as === 'main') {
        style_props = {
            className:"forwardButton",
            colorScheme:"forwardOrange",
            size:"sm",
            fontSize:"sm"
        }
    }
    

    if (entities.length === 1) {
        return (
            <Button {...style_props} onClick={() => onClickFunctions[entities[0]](editorData)}>
                + {display_names[entities[0]]}
            </Button>
        );
    } else {
        return (
            <Menu>
            <MenuButton {...style_props} as={Button} rightIcon={<BiChevronDown />}>
                + Add
            </MenuButton>
            <MenuList>
                {entities.map((entity, index) => {
                    let edit_data_to_send = editorData;
                    let fields_to_send = null
                    if (entity === 'recurring_task') {
                        fields_to_send = {
                            'name':true,
                            'project':true,
                            'task_list_selector':true,
                            'pinned':true,
                            'recur_weeks':true,
                        }
                        edit_data_to_send = {...editorData, recur_weeks: 1}
                    }

                    return(
                <MenuItem key={index} onClick={() => onClickFunctions[entity](edit_data_to_send, false, fields_to_send)}>
                    {display_names[entity]}
                </MenuItem>
                )}
                )}
            </MenuList>
            </Menu>
        );
    }
};

export default AddButton;