import React, {useEffect, useState, useContext, useRef, useMemo} from 'react';
import { useParams } from 'react-router-dom';
import {
  VStack, Text, HStack, Box, Heading, Flex, Divider, UnorderedList, ListItem, Button, IconButton, useMediaQuery, useColorModeValue
} from '@chakra-ui/react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { FormContext } from '../../../context/FormContext.jsx';

import ProjectRow from '../../projects/rows/ProjectRow.jsx';

import { useTaskDnd } from '../../../data_hooks/dnd/useTaskDnd.jsx';

import HabitRow from '../../habits/rows/HabitRow.jsx';
import HabitWeekHeader from '../../habits/rows/HabitWeekHeader.jsx';

import LoadingAnimation from '../../general/components/LoadingAnimation.jsx';
import AreaDetailHeader from '../rows/AreaDetailHeader.jsx';

import { useAreas } from '../../../data_hooks/elements/useAreas.jsx';
import dhf from '../../../uc_utils/dates/dates.js';
import ProjectHistoryRow from '../../projects/rows/ProjectHistoryRow.jsx';
import StatBox from '../../general/components/StatBox.jsx';
import { useIsMobile } from '../../../data_hooks/general/useIsMobile.jsx';
import QuarterlyGoalView from '../../goals/views/QuarterlyGoalView.jsx';
import { useGoalDnd } from '../../../data_hooks/dnd/useGoalDnd.jsx';
import { useManageDnd } from '../../../data_hooks/dnd/useManageDnd.jsx';

const SingleAreaView = ({  session, view="area", ...props }) => {

    const {state, dispatch} = useContext(ForwardContext);
    const { addHabit, addGoal} = useContext(FormContext);
    const hasCalledSyncRef = useRef(false);
    const ar= useAreas();
    const { id } = useParams();
    const isMobile = useIsMobile();
    const {newHandleGoalDragEnd} = useGoalDnd({session});
    const {handleManageDragEnd} = useManageDnd({session},'habits','habits');


    // Define loading state
    const [isLoading, setIsLoading] = useState(true);
    
    const my_area = useMemo(() => {
        return Object.values(state.areas).flat().filter(area => parseInt(area.id) === parseInt(id))[0];
    }, [state.areas, id]);

    useEffect(() => {
      if (my_area && !hasCalledSyncRef.current) {
        hasCalledSyncRef.current = true;
        ar.syncAreaHistory(my_area)
          .then(() => setIsLoading(false))
          .catch(error => {
            console.error("Failed to sync area history:", error);
            // Handle error state if necessary
          });
      }
    }, [my_area]); // Assumes my_area is necessary and changes are meaningful
    

    const {handleDragEndWell} = useTaskDnd();

    const { weeks_live, numberOfWeeksWithCompletedTasks, hit_rate, tasks_done, tpw, tasksByWeek } = useMemo(() => {
      const my_tasks = state.live_views.area_history.filter(task => task.item_status !== 'abandoned');
      const tasks_done = my_tasks.length;
      const resolveDates = my_tasks.map(task => task.resolve_date);
      const resAsDates = resolveDates.map(date => dhf.createDateObject(date));
      const firstTaskCompletionDate = new Date(Math.min(...resAsDates));

      const firstWeek = dhf.getStartOfWeek(firstTaskCompletionDate);
      const thisWeek = dhf.getStartOfWeek(new Date());
      const weeks_live = dhf.getDaysBetweenDates(thisWeek, firstWeek) / 7;

      const uniqueWeeks = new Set(resolveDates.map(date => dhf.formatDate(dhf.getStartOfWeek(dhf.createDateObject(date), 'yyyy-MM-dd'))));
      const numberOfWeeksWithCompletedTasks = uniqueWeeks.size;

      const tasksByWeek = {};
      resolveDates.forEach((date, index) => {
          const startOfWeek = dhf.formatDate(dhf.getStartOfWeek(dhf.createDateObject(date)), 'yyyy-MM-dd');
          tasksByWeek[startOfWeek] = [...(tasksByWeek[startOfWeek] || []), my_tasks[index]];
      });

      return {
          weeks_live,
          numberOfWeeksWithCompletedTasks,
          hit_rate: (100 * numberOfWeeksWithCompletedTasks / weeks_live).toFixed(1),
          tasks_done,
          tpw: (tasks_done / weeks_live).toFixed(2),
          tasksByWeek
      };
  }, [state.live_views.area_history, id]);


    // Loading state
    if (isLoading) {
        return <LoadingAnimation />;
    }

  let habitsInArea = [];
  let goalsInArea = [];
  let projectsInArea = [];
  let currentYear = new Date().getFullYear();

    habitsInArea = state.habits[id]?.filter((habit) => habit.status <= 2) || [];
    goalsInArea = state.goals[id]
                  ?.filter((goal) => goal.status <= 2) || [];
    projectsInArea = state.projects[id]?.filter((project) => project.status <= 2) || [];
    

return (
  <Flex width="100%" maxWidth="1200px" flexDirection={{'base':'column','md':'row'}}>

    <Flex
      flex="1" // Take up the remaining width
      flexDirection="column"
      width="100%"
    >
      <AreaDetailHeader area={my_area}  />
      <Flex my={1} flexDirection={{'base':'column','md':'row'}} width="100%" justifyContent="stretch">
        <StatBox key="live_for" stat_heading="Live For" stat_value={parseFloat(weeks_live).toFixed(2)} stat_label="weeks" flex="1" mx={{'base':0,'md':2}} my={{'base':1,'md':1}}  width="100%"/>
        <StatBox key="weeks_hit" stat_heading="Weeks Hit" stat_value={numberOfWeeksWithCompletedTasks} stat_label="weeks"  flex="1" mx={{'base':0,'md':2}} my={{'base':1,'md':1}} width="100%"/>
        <StatBox key="hit_rate" stat_heading="Hit Rate" stat_value={hit_rate} stat_label="%"  flex="1" mx={{'base':0,'md':2}} my={{'base':1,'md':1}} width="100%"/>
        <StatBox key="tasks_done" stat_heading="Tasks Done" stat_value={tasks_done} stat_label="tasks" flex="1" mx={{'base':0,'md':2}} my={{'base':1,'md':1}} width="100%"/>
        <StatBox key="tasks_week" stat_heading="Tasks/Week" stat_value={tpw} stat_label="tpw" flex="1" mx={{'base':0,'md':2}} my={{'base':1,'md':1}} width="100%"/>
      </Flex>

      <Heading as="h3" size="md" my={2}>My Goal{goalsInArea.length > 1 ? 's':''} for {new Date().getFullYear()}:</Heading>
      <Divider />
      <DragDropContext onDragEnd={newHandleGoalDragEnd}>
      <QuarterlyGoalView area={my_area} goals={goalsInArea} session={session} isMobile={isMobile} addGoal={addGoal} liveYear={new Date().getFullYear()}/>
      </DragDropContext>
            

{habitsInArea.length > 0 &&
      <>
      <Heading as="h3" size="md" my={4}>Habits:</Heading>
      <Divider />
      <HabitWeekHeader width="100%" currentWeek={state.ui.currentWeek} />
      <DragDropContext onDragEnd={handleManageDragEnd}>
      <Droppable droppableId={String(id)}>
      {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {habitsInArea.map((habit, index) => (
                    <Draggable key={habit.id} draggableId={String(habit.id)} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <HabitRow 
                          key={habit.id} 
                          habit={habit} 
                          dragHandle={provided.dragHandleProps} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
              </Droppable>
      </DragDropContext>
      </>
      }
      {habitsInArea.length === 0 &&
      <>
      <Heading as="h3" size="md" my={4}>Habits:</Heading>
      <Divider />
      <HStack m={2} width="100%"><Text>No Habits for this life area... Want to</Text><Button borderRadius="0px" onClick={() => addHabit({'area_id':my_area.id})}>Add One?</Button></HStack>
      </>
      }
      
    <Heading as="h3" size="md" my={4}>Projects & Tasks:</Heading>
    <Divider mb={2} />
    <DragDropContext onDragEnd={handleDragEndWell}>
    {projectsInArea.flatMap((project) => (
      <ProjectRow key={'project-'+project.id} 
                  project={project} 
                  tasks={state.tasks[project.id]} 
                  viewMode='backlog' 
                  />))}
</DragDropContext>
{/***********************************************/}
{/* Area History */}
<Heading as="h3" size="md" my={2}>Area Timeline:</Heading>
    <Divider />
        <Flex className="history-wrapper" flexDirection="column" alignItems="center">
        {/* Iterate through the tasksByWeek object and render a ProjectHistoryRow for each week */}
        {Object.keys(tasksByWeek).sort().reverse().map((week, index, sortedWeeks) => {
              let time_since_last_week = 0;

              if (index > 0) {
                // Get the previous week's date
                const prevWeekDate = dhf.createDateObject(sortedWeeks[index - 1]);
                const currentWeekDate = dhf.createDateObject(week);

                // Use your dhf.getDaysBetweenDates function to calculate the difference
                time_since_last_week = dhf.getDaysBetweenDates(prevWeekDate, currentWeekDate);
              }
          
          return (
            <ProjectHistoryRow project_id={id} key={week} week={week} weekTasks={tasksByWeek[week]} timeSince={time_since_last_week} />
        )
          }
        )}
        <Box>Start of Project: {dhf.formatDate(new Date(my_area.created_at),'MMM dd, yyyy')}</Box>
    </Flex>
      </Flex>
      </Flex>)
}
export default SingleAreaView;