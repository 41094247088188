import React, { useState, useEffect, useContext } from 'react';
import { HStack, Text, Flex, Button, Center, useColorModeValue } from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import NewHabitProgress from './NewHabitProgress.jsx';
import dhf from '../../../uc_utils/dates/dates.js';
import hhf from '../../../uc_utils/habits/habits.js';
import calc_habits from '../../../calc/habits.js';

const HabitRowTotal = ({ habit, habitStats, startDate, onTargetOpen, ...props }) => {
    const { state } = useContext(ForwardContext);
    const habitContext = state.habits[habit.area_id].find(h => h.id === habit.id);

    useEffect(() => {
      // This effect will run every time the targets array changes.
      console.log("Targets updated", habitContext.targets);
  }, [habitContext.targets]);  // Listen for changes in the targets


    const targets = habitContext?.targets || [];
    const startDateFormatted = dhf.formatDate(startDate);
    const isCurrentWeek = dhf.isDateInCurrentWeek(Date.now(), new Date(startDateFormatted));
    const pbBgColor = useColorModeValue('forwardWhite.500', 'forwardBlue.800');
    const total = habitStats?.weekly_stats?.[startDateFormatted]?.total || 0;
    const streak = habitStats?.weekly_stats?.[startDateFormatted]?.streak || 0;
    const mostRecentTarget = hhf.getMostRecentTarget(targets, startDate);
    const targetIsSet = !!mostRecentTarget;
    const isTargetHit = mostRecentTarget && (mostRecentTarget[1] ? total >= mostRecentTarget[0] : total <= mostRecentTarget[0]);
    const streakColor = useColorModeValue(isTargetHit ? 'green.700' : 'gray.700', isTargetHit ? 'green.300' : 'gray.300');
    const projectedValue = isCurrentWeek ? (habitStats?.current_stats?.projection || 0) : total;

    return (
        <Flex width={{ base: "100%", md: "180px" }} textAlign="center" alignItems="center" flexDirection={{ base: 'row', md: 'column' }} className="total-box" {...props}>
            <Center>
                <Flex alignItems="flex-end" flexDirection={{ base: 'column', md: 'row' }} mr={{ base: '2', md: '0' }} width={{ base: '45px', md: 'auto' }}>
                    <Text fontSize="lg" fontWeight="bold" mr={{ base: '0', md: '2' }}>{total}</Text>
                    {isCurrentWeek && <Text fontSize="2xs" fontStyle="italic">{projectedValue.toFixed(1)}</Text>}
                </Flex>
            </Center>
            <Flex flexDirection="column" w="100%" mr={{ base: '2', md: '2' }} ml={{ base: '2', md: '2' }} px={1}>
                {!targetIsSet && <Button p={1} borderRadius={0} size="xs" variant="outline" colorScheme="blue" onClick={onTargetOpen}>
                    Set Target
                </Button>}
                {targetIsSet && <NewHabitProgress
                    habit_id={habitContext.id}
                    area_id={habitContext.area_id}
                    startDate={startDate}
                    backgroundColor={pbBgColor}
                    toDoOnClick="set_target"
                    showHoverText={true}
                    borderWidth="1px"
                />}
                <HStack justifyContent="space-between">
                    <Text fontSize="xs" color={streakColor}>Streak: {streak} week{streak > 1 ? "s" : ""}</Text>
                </HStack>
            </Flex>
        </Flex>
    );
};

export default HabitRowTotal;
