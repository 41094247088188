import React, { useState, useContext } from 'react';
import {
  VStack, Text, Center, Heading, Box, HStack, Button, IconButton, useColorModeValue, Checkbox,
  Accordion, AccordionItem, AccordionPanel, Select, useStyleConfig
} from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { FormContext } from '../../../context/FormContext.jsx';
import { useNavigate } from 'react-router-dom';

import AreaHeader from '../../areas/rows/AreaHeader.jsx';
import GoalRow from '../rows/GoalRow.jsx';
import c from '../../../uc_constants/fwd_constants.js'

const GoalList = ({ session, ...props }) => {

  const styles = useStyleConfig('Box', {variant: 'widgetBox'})
  const cy = new Date().getFullYear();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(ForwardContext);
  const { addGoal } = useContext(FormContext);
  const [showCompleted, setShowCompleted] = useState(false);

  const currentDate = new Date();
  const showQ1 = true;
  const showQ2 = parseInt(currentDate.getMonth()) >= 3;
  const showQ3 = parseInt(currentDate.getMonth()) >= 6;
  const showQ4 = parseInt(currentDate.getMonth()) >= 9;
  /*console.log(currentDate);
  console.log(parseInt(currentDate.getMonth()));
  console.log(showQ2);*/


  const bgColor = useColorModeValue('forwardWhite.500','forwardBlue.800')
  const bdColor = useColorModeValue('forwardWhite.700','forwardBlue.900')
  const defaultIndices = state.areas.map((_, index) => index);

  /**************************/
  /**************************/
  /* Main body of the element: */
  /**************************/
  /**************************/

  return (
    <>
    <Box 
     width="100%"
     __css={styles}
    {...props}>
    <VStack>
    <HStack
    width="100%"
    justifyContent="space-between"
    px={0} pt={0} pb={2}>
    <HStack>
      {c.main_icons.goals}
    <Heading size="sm">Active Goals</Heading>
    </HStack>
    <HStack>
    <Button className="forwardButton" colorScheme="forwardOrange" size="sm" fontSize="sm" onClick={() => addGoal()}>+ Goal</Button>
    </HStack>
    </HStack>
    <HStack width="100%" mb={2}>
      <Checkbox  flex="2" size="md" isChecked={showCompleted} onChange={() => setShowCompleted(!showCompleted)}>Show Complete?</Checkbox>
    </HStack>
   </VStack>


    <VStack w="100%" maxWidth="1100px">
      <Center w="100%">
        <VStack spacing={0} w="100%" maxWidth="1100px" minWidth="300px">
              {Object.values(state.areas).map((area) => {
        // Get the projects for this area
        let goalsInArea = state.goals?.[area.id]?.filter((goal) => goal.status < 3) || [];
        if(!showCompleted) {
          goalsInArea = goalsInArea.filter((goal) => goal.goal_progress !== 'complete')
        }

        //Filter goals by selected year
        // Define the custom sort order
        const sortOrder = {
          'green': 4,
          'yellow': 3,
          'orange': 2,
          'red': 1,
          'complete': 5,
          'abandoned': 6,
        };

        goalsInArea = goalsInArea
          .filter((goal) => parseInt(goal.goal_timeframe) === parseInt(cy))
          .filter((goal) => (goal.goal_timeframe.split('Q').length === 1 || 
                            (showQ1 && parseInt(goal.goal_timeframe.split('Q')[1]) <= 1) || 
                            (showQ2 && parseInt(goal.goal_timeframe.split('Q')[1]) <= 2) || 
                            (showQ3 && parseInt(goal.goal_timeframe.split('Q')[1]) <= 3) || 
                            (showQ4 && parseInt(goal.goal_timeframe.split('Q')[1]) <= 4)))
          .sort((a, b) => {
            // Sort by goal_points
            if (b.goal_points !== a.goal_points) {
              return b.goal_points - a.goal_points;
            }
            // If goal_points are equal, sort by goal_progress
            return sortOrder[a.goal_progress] - sortOrder[b.goal_progress];
          });
      
        
        if (goalsInArea.length === 0) {
          return null;
        }

        return (
          
          <Box key={area.id} width="100%" marginTop="2px !important" >
            <Accordion defaultIndex={defaultIndices} allowMultiple>
          <AccordionItem border="0px !important">
                <Box flex="1" textAlign="left">
                  <AreaHeader area={area} addible={true} addType='goal' collapsible={true} />
                </Box>
            <AccordionPanel py={0} px={1}>
            {goalsInArea.flatMap((goal) => (
              <GoalRow key={goal.id} goal={goal} user_id={session.user.id} />
            ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
          </Box>
        );
      })}
        </VStack>
      </Center>
    </VStack>
    </Box>
    </>
  );
}

export default GoalList;
