import { supabase } from '../uc_constants/supabaseClient';

export async function upsertEvent(event_data) {
    console.log(event_data);
    let { data, error } = await supabase
    .from('forward_events')
    .upsert(event_data , 
            {onConflict: 'id' })
      .select('*');

  if (error) {
      console.error('Error upserting user:', error);
  }
  return data;
}


export async function readEvents(userId) {
    let { data, error } = await supabase
        .from('forward_events')
        .select(`*
                `)
        .lte('status',3)
        .order('start_time')
        ;
    if (error) {
        console.error('Error fetching goals:', error);
    }

    return data;
}
