import React, {useState} from 'react'
import { Box, Text, Grid, GridItem, Tooltip, HStack, VStack, IconButton, Switch, 
    FormControl, FormLabel, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, 
    useMediaQuery, Menu, MenuList,
    Flex,
    MenuButton, Select,
    Button} from '@chakra-ui/react';
import ForwardSelect from '../../general/components/ForwardSelect';
import { AiOutlineSetting } from 'react-icons/ai';

const HabitCalendarSettings = ({habit, showHover, setShowHover, showValues, setShowValues, boxSize, setBoxSize, yearShown, setYearShown}) => {
    const [showSettings, setShowSettings] = useState(false)
    
    const years_to_show = habit.values 
        ? [...new Set(Object.keys(habit.values).map((date) => new Date(date).getFullYear()))]
        : [];

    const yearsOptions = years_to_show.sort((a, b) => b - a)
    .map(year => ({
            value: year.toString(),
            label: year.toString()
        }));

    return(
        <>
        <HStack>
        <ForwardSelect options={yearsOptions} borderRadius="0px" defaultValue={yearsOptions[0]} onChange={(e) => setYearShown(e)} />
    <Menu>
      <MenuButton
                as={Button}
                aria-label="Settings"
                borderRadius="0px"
                size="xs"
                onClick={() => setShowSettings(!showSettings)}
                colorScheme="default"
                variant="ghost"
                >
                    <AiOutlineSetting />
                </MenuButton>
                    <MenuList p={2}>
                            <FormControl display="flex" alignItems="center">
                                <FormLabel htmlFor="showHover" mb="0">
                                    Show Hover:
                                </FormLabel>
                                <Switch
                                    id="showHover"
                                    isChecked={showHover}
                                    onChange={() => setShowHover(!showHover)}
                                />
                            </FormControl>
                            <FormControl display="flex" alignItems="center">
                                <FormLabel htmlFor="showValues" mb="0">
                                    Show Values:
                                </FormLabel>
                                <Switch
                                    id="showValues"
                                    isChecked={showValues}
                                    onChange={() => setShowValues(!showValues)}
                                />
                            </FormControl>
                            <FormControl display="flex" alignItems="center">
                                <FormLabel htmlFor="boxSize" mb="0">
                                    Box Size:
                                </FormLabel>
                                <NumberInput
                                    id="boxSize"
                                    value={boxSize}
                                    onChange={(value) => setBoxSize(value)}
                                    min={10}
                                    max={50}
                                >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </FormControl>
                    </MenuList>
                    </Menu>
                    </HStack>
                    </>)
}

export default HabitCalendarSettings;