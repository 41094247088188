//I need a bar that shows the goals within each life area

import React from 'react';
import { Box, Flex, Text, VStack, Tooltip, HStack } from '@chakra-ui/react';
import GoalRow from './GoalRow';
import { ForwardContext } from '../../../context/ForwardContext';


const LifeAreaRow = ({ area, ...props }) => {
    const { state } = React.useContext(ForwardContext);
    const goals = state.new_goals[area.id] || [];

    return (
        <HStack width="100%" bg="gray.100" borderRadius="md" p={2} mb={2} >
            <Box flex="1" minWidth="100px" textAlign="left" fontSize="xs" color="gray.500">{area.area_name}</Box>
            <VStack flex="21" align="stretch" spacing={0} borderWidth={1} borderColor="gray.200" width="100%">
            {goals.map((goal) => {
                return (
                    <GoalRow key={`${goal.id}-${state.grid_ui.selected_level}-${state.grid_ui.visible_base_level}`} goal={goal} />
                )
            }
            )}
            </VStack>
        </HStack>
    )
}


export default LifeAreaRow;