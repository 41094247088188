import ps from '../../calc/period_stats.js';
import cgd from '../../uc_utils/charting/general_data.js';
import cgl from '../../uc_utils/charting/general_layout.js';
import { useChartModal } from '../../context/ChartModalContext.jsx';


export const useChartFunctions = () => {
        const { showModal } = useChartModal();

    const chartHabitIndex = ({my_stats, chartColorMode ='light', isMobile=false}) => {
        const hi_chart_data = ps.calcHabitChart({my_stats:my_stats,aggField:'habit_index',aggMethod:'avg'});

        let data = []
        data.push(cgd.generateSingleDataSeries({
                dataObject: hi_chart_data, 
                seriesName:'Avg. Habit Index',
                seriesDisplayMode:'line', 
                calcMode:'actual', 
        }))
        const layout = cgl.generateGeneralLayout({
                colorMode:chartColorMode, 
                isMobile:isMobile, 
                xAxisLabel:'Week', 
                yAxisLabel:'Habit Index', 
                barMode:'stack'})
        showModal('Habit Index Over Time', data, layout);
    }

    const chartAverageStreak = ({my_stats, chartColorMode ='light', isMobile=false}) => { 
        const avs_chart_data = ps.calcHabitChart({my_stats:my_stats,aggField:'streak',aggMethod:'avg'}); 
        let data = []
        data.push(cgd.generateSingleDataSeries({
                dataObject: avs_chart_data, 
                seriesName:'Avg. Streak',
                seriesDisplayMode:'line', 
                calcMode:'actual', 
        }));
        const layout = cgl.generateGeneralLayout({
                colorMode:chartColorMode, 
                isMobile:isMobile, 
                xAxisLabel:'Week', 
                yAxisLabel:'Average Streak Length (weeks)', 
                barMode:'stack'})
        showModal('Average Streak Over Time', data, layout);
    };

    const chartHitRate = ({my_stats, chartColorMode ='light', isMobile=false}) => {

        const targets_data = ps.calcHabitChart({my_stats:my_stats,aggField:'targets',aggMethod:'sum'});
        const targets_hit_data = ps.calcHabitChart({my_stats:my_stats,aggField:'targets_hit',aggMethod:'sum'});
        //console.log(targets_data);
        //console.log(targets_hit_data);

        const hit_rate_data = ps.calcSeries({series_a:targets_hit_data, operation:'percent', series_b:targets_data});
        //console.log(hit_rate_data);
        let data = []
        data.push(cgd.generateSingleDataSeries({
                dataObject: hit_rate_data, 
                seriesName:'% of Targets Hit',
                seriesDisplayMode:'line', 
                calcMode:'actual', 
        }));
        const layout = cgl.generateGeneralLayout({
                colorMode:chartColorMode, 
                isMobile:isMobile, 
                xAxisLabel:'Week', 
                yAxisLabel:'% of Targets Hit', 
                barMode:'stack'})
        showModal('% of Targets Hit', data, layout);
    }

    const chartTargetHitMiss = ({my_stats, chartColorMode ='light', isMobile=false}) => {
        const targets_data = ps.calcHabitChart({my_stats:my_stats,aggField:'targets',aggMethod:'sum'});
        const targets_hit_data = ps.calcHabitChart({my_stats:my_stats,aggField:'targets_hit',aggMethod:'sum'});
        const targets_missed_data = ps.calcSeries({series_a:targets_data, operation:'subtract', series_b:targets_hit_data});
        let data = []
        data.push(cgd.generateSingleDataSeries({
                dataObject: targets_hit_data, 
                seriesName:'Targets Hit',
                seriesDisplayMode:'bar', 
                calcMode:'actual', 
                displaySettings: {
                        marker: {color: 'green'}
                }
        }));
        data.push(cgd.generateSingleDataSeries({
                dataObject: targets_missed_data, 
                seriesName:'Targets Missed',
                seriesDisplayMode:'bar', 
                calcMode:'actual', 
                displaySettings: {
                        marker: {color: 'red'}
                }
        }));
        const layout = cgl.generateGeneralLayout({
                colorMode:chartColorMode, 
                isMobile:isMobile, 
                xAxisLabel:'Week', 
                yAxisLabel:'# of Targets', 
                barMode:'stack'})
        showModal('Target Performance over Time', data, layout);

    }


    return { chartHabitIndex, chartAverageStreak, chartHitRate, chartTargetHitMiss };
}