import React, {useEffect, useState, useContext} from 'react';
import { ForwardContext } from '../context/ForwardContext.jsx';
import { Box, Center,  useColorMode } from '@chakra-ui/react';

import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import usePreviousLocation from '../data_hooks/general/usePreviousLocation.jsx';

import AppHeader from './navigation/header/AppHeader.jsx';
import AppNavBar from './navigation/navbar/AppNavBar.jsx';

import { useAreas } from '../data_hooks/elements/useAreas.jsx';
import { useProjects } from '../data_hooks/elements/useProjects.jsx';
import { useHabits } from '../data_hooks/elements/useHabits.jsx';
import { useAccounts } from '../data_hooks/accounts/useAccounts.jsx';
import { useAreaSharing } from '../data_hooks/sharing/useAreaSharing.jsx';
import { useTasks } from '../data_hooks/elements/useTasks.jsx';
import { useEvents } from '../data_hooks/elements/useEvents.jsx';

import HomeView from './views/HomeView.jsx';
import NewHomeView from './views/NewHomeView.jsx';
import HabitsView from '../fwd_ui/habits/views/HabitsView.jsx';
import NewGoalsView from '../fwd_ui/goals/views/NewGoalsView.jsx';
import Footer from '../general/components/Footer.jsx';
import SettingsView from '../fwd_ui/settings/views/SettingsView.jsx';

import NavigationSearchModal from './navigation/search/NavigationSearchModal.jsx';

import HabitView from '../fwd_ui/habits/views/SingleHabitView.jsx';

import UpdatesView from './views/UpdatesView.jsx';
import OnboardingModal from './content/OnboardingModal.jsx';
import {ChartModalProvider} from '../context/ChartModalContext.jsx';

import GetStarted from './intro/GetStarted.jsx'
import dhf from '../uc_utils/dates/dates.js'
import ahf from '../uc_utils/accounts/account.js';
import { trackCustomEvent } from '../general/functions/plausibleEvent.js';
import GeneralEmbedWrapper from '../fwd_ui/embed/GeneralEmbedWrapper.jsx';
import SingleProjectView from '../fwd_ui/projects/views/SingleProjectView.jsx';
import SingleAreaView from '../fwd_ui/areas/views/SingleAreaView.jsx';
import TasksView from '../fwd_ui/tasks/views/TasksView.jsx';
import { FormProvider } from '../context/FormContext.jsx';


const ForwardMain = ({session}) => {
  const { state, dispatch } = useContext(ForwardContext);
  const location = useLocation();
  const previousLocation = usePreviousLocation();
  const navigate = useNavigate();
  const [accountSynced, setAccountSynced] = useState(false);
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
  const isEmbed = location.pathname.includes('/embed/');
  const { setColorMode } = useColorMode();

  const {syncAreas} = useAreas();
  const {syncProjects} = useProjects();
  const {syncHabits, updateAllHabitStats} = useHabits();
  const {syncAccount} = useAccounts();
  const {readMyShares} = useAreaSharing();
  const {syncVisibleTasks, syncAllTasks} = useTasks();
  const {syncEvents} = useEvents();

  const [loading, setLoading] = useState(true);


  const toggleOnboardingModal = () => {
    setIsOnboardingModalOpen(!isOnboardingModalOpen);
  };

  useEffect(() => {
    dispatch({ type: 'SET_PROJECT_VIEW', payload: state.account.default_task_view });
    console.log('setting the default view');
    console.log(state.account.default_task_view);
  }, [state.account.default_task_view]);

  useEffect(() => {
    dispatch({ type: 'SET_GOAL_VIEW', payload: state.account.default_goal_period });
    console.log('setting the default view');
    console.log(state.account.default_goal_period);
  }, [state.account.default_goal_period]);


  const dataRefresh = async (userId = null, mode='returning') => {
    if (!userId) {
      userId = session.user.id;
    }
    await syncAccount();
    setAccountSynced(true); // Set accountSynced to true when syncAccount is done
    trackCustomEvent('FWD Main Data Sync', {});
    await syncAreas(userId);
    await syncProjects(userId);
    await syncEvents(userId);
    //await syncAllTasks(userId);
    const habitsData = await syncHabits(userId, state.account.account_level);
    if (habitsData) {
      updateAllHabitStats(habitsData, 'all', null);
    }
    const myShares = await readMyShares(userId);
    console.log(state);
    if (accountSynced && mode==='main') {
          //Set Color Mode to the User's Default
      setColorMode(state.account.default_color_mode);
      //Set the Default Task View in the state.ui
      //dispatch({ type: 'SET_PROJECT_VIEW', payload: state.account.default_task_view });
    }
  };

  


  useEffect(() => {
    const updateHabitsOnAccountLevelChange = async () => {

      if (state.account && state.account.account_level >= 1) {
        //console.log('in the if!');
        const habitsData = await syncHabits(session.user.id, state.account.account_level);
        if (habitsData) {
          updateAllHabitStats(habitsData, 'all', null);
        }
      }
    };
  
    updateHabitsOnAccountLevelChange();
  }, [state.account.account_level]); // Dependency array includes account_level
  

  useEffect(() => {
    let hiddenTime;
  
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // Record the time when the user leaves the page
        hiddenTime = Date.now();
      } else {
        // User returns to the page
        const visibleTime = Date.now();
        const elapsedTime = visibleTime - hiddenTime;
 
        // Check if the page was hidden for at least 60 seconds
        if (elapsedTime >= 60000) {
          console.log('Refreshing the data: user has been away for a bit!');
          dataRefresh(session.user.id,'returning');
        }
      }
    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
  
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [session.user.id, dataRefresh]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      if (now.getHours() === 0 && now.getMinutes() === 0) {
        dataRefresh(session.user.id, 'returning');
      }
    }, 60 * 1000); // Check every minute
  
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [session.user.id, dataRefresh]);
  


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && (event.key === 'p' || event.keyCode === 80)) {
        event.preventDefault();
        // Ideally, your NavigationSearchModal has a method to open it, such as a ref method or a context method.
        // Since we are using a simple approach, ensure your modal handles its visibility internally
        // or consider using a global state / context if the modal needs to be controlled externally.
        document.dispatchEvent(new CustomEvent('openNavigationSearchModal'));
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  /********************************* */
  /********************************* */
  /* Loading Screen if no life areas are found */
  /********************************* */
  /********************************* */
  useEffect(() => {
    // Check if the user has any life areas
    const userHasLifeAreas = state.areas && state.areas.length > 0;
    setLoading(!userHasLifeAreas);
  }, [state.areas]);

  /********************************* */
  /********************************* */
  /* Change Current View if URL Changes */
  /********************************* */
  /********************************* */
  useEffect(() => {
    //console.log(location.pathname);
    // Check if the user has any life areas
    if(location.pathname.includes('/goals')) {
      dispatch({ type: 'SET_CURRENT_VIEW', payload: 'My Goals' });
      document.title= 'My Goals';
    }
    else if (location.pathname.includes('/tasks')) {
      dispatch({ type: 'SET_CURRENT_VIEW', payload: 'My Tasks' });
      document.title= 'My Tasks';
    }
    else if (location.pathname.includes('/backlog')) {
      dispatch({ type: 'SET_CURRENT_VIEW', payload: 'My Backlog' });
      document.title= 'My Backlog';      
    }
    else if (location.pathname.includes('/habits')) {
      dispatch({ type: 'SET_CURRENT_VIEW', payload: 'My Habits' });
      document.title= 'My Habits';
    }
    else if (location.pathname.includes('/habit/')) {
      dispatch({ type: 'SET_CURRENT_VIEW', payload: 'Habit Detail' });
      document.title = 'Habit Detail';
      if(!loading) {
        const id = location.pathname.split('/').pop();
        const habit = Object.values(state.habits).flat().find(habit => parseInt(habit.id)=== parseInt(id));
        if(habit) {
          document.title = habit.habit_name;
        }
      }
    }
    else if (location.pathname.includes('/project/')) {
      dispatch({ type: 'SET_CURRENT_VIEW', payload: 'Project Detail' });
      document.title = 'Project Detail';
      if(!loading) {
        const id = location.pathname.split('/').pop();
        const project = Object.values(state.projects).flat().find(project => parseInt(project.id)=== parseInt(id));
        if(project) {
          document.title = project.project_name;
        }
      }
      
    }
    else if (location.pathname.includes('/area/')) {
      dispatch({ type: 'SET_CURRENT_VIEW', payload: 'Area Detail' });
      document.title = 'Area Detail';
      if(!loading) {
        const id = location.pathname.split('/').pop();
        console.log(id);
        console.log(state.areas);

        const area = state.areas.find(area => parseInt(area.id)=== parseInt(id));
        console.log(area);
        if(area) {
          document.title = area.area_name;
        }
      }

    }
    else if (location.pathname === "/forward/" || location.pathname === "/forward") {
      dispatch({ type: 'SET_CURRENT_VIEW', payload: 'Home' });
      document.title = 'Home';
    }
    else if (location.pathname.includes('/settings')) {
      dispatch({ type: 'SET_CURRENT_VIEW', payload: 'My Settings' });
      document.title = 'Settings';
    }
    else {
      document.title = 'Forward';
    }
  }, [location.pathname, loading]);


  /********************************* */
  /********************************* */
  /* Fetch the user's data           */
  /********************************* */
  /********************************* */
  useEffect(() => {
    //Sync account:
    dataRefresh(session.user.id,'main');

  }, []);  // Empty dependency array so this only runs once on mount
  
  //If no password or account, send to password page:
  useEffect(() => {
    if (accountSynced && !state.account.has_password) { // Check if the account has been synced before checking for password
      navigate('/password');
    }
  }, [accountSynced, state.account.has_password]); // React on changes to either accountSynced or has_password

  useEffect(() => {
    if (accountSynced) { // Check if the account has been synced before checking for password
      const userLevel = ahf.getAccountAccessLevel(state.account);
      const hadTour = true;
      //console.log(userLevel);
      //Set userLevel onto account
      if (state.account.account_type !== userLevel) {
        dispatch({ type: 'SET_USER_PROPERTY', payload: { 'property':'account_type','value':userLevel } });
        //console.log(state.account);
      }
            
      //Update permissions to edit
      if(userLevel === 'Read Only') {
        dispatch({ type: 'SET_READ_ONLY', payload: true });
      }
      else {
        dispatch({ type: 'SET_READ_ONLY', payload: false });
      }

      setIsOnboardingModalOpen(!state.account.had_tour);

    }
  }, [state.account, accountSynced]); // React on changes to either accountSynced or has_password

  /********************************* */
  /********************************* */
  /* Revert to current week if on home page */
  /********************************* */
  /********************************* */
  useEffect(() => {
    // Check if the user has any life areas
    if(state.ui.currentView === 'Home') {
      dispatch({ type: 'SET_CURRENT_WEEK', payload: dhf.getStartOfWeek(new Date()) });
    }
  }, [state.ui.currentView]);

  /********************************* */
  /********************************* */
  /* Upon Week Change                */
  /********************************* */
  /********************************* */
  //On Current Week Change, need to update cwPlannedTasks and accomplishments
  useEffect(() => {
    console.log('changing week');
    if(state.ui.currentView === 'My Tasks' && state.ui.projectView === 'project') {
    //fetchData();
    syncVisibleTasks(session.user.id, state.ui.currentWeek, state.projects);
    }
    //console.log(state.tasks);
  }, [state.ui.currentWeek, state.ui.currentView, state.ui.projectView]); //Run when the currentWeek is changed

  //Define the Transition:
  const defineTransition = (currentPath, nextPath) => {
    // Define your logic to determine the transition based on routes
    // For example:
    if (currentPath.includes('/home') && nextPath.includes('/tasks')) {
      return ['slide',300];
    } else if (currentPath.includes('/tasks') && nextPath.includes('/home')) {
      return  ['slide',300];
    }
    return ['fade',50];
  };

  const transitionType = defineTransition ((previousLocation?.pathname || ''), location.pathname);
  
  if (loading) {
    return <GetStarted user={session.user} />;
  }

  return (
    <>
    <FormProvider session={session}>
    <ChartModalProvider>
    {!isEmbed && <AppHeader session={session} toggleOnboardingModal={toggleOnboardingModal}/>}
    {!isEmbed && <AppNavBar session={session} handleRefreshClick={dataRefresh}/>}
    <NavigationSearchModal />
    <Box py={isEmbed? 0 : 4} px={isEmbed ? 0 : { base: 0, md: 4 }} minHeight="80vh" className="MainBox" width="100%">
      <Center width="100%" p={0}>
        {/*viewComponents[state.ui.currentView]*/}
        <TransitionGroup className="transitionGroup" style={{'width':'100%'}}>
            <CSSTransition
              key={location.key}
              timeout={transitionType[1]}
              classNames={transitionType[0]}
              width="100%"
            >
              <Center width="100%">
              <Routes>
                <Route path="/" element={<HomeView session={session} />} />
                <Route path="newhome" element={<NewHomeView session={session} />} />
                <Route path="home" element={<HomeView session={session}  />} />
                <Route path="habits" element={<HabitsView session={session} />} />
                <Route path="tasks" element={<TasksView session={session} />} />
                <Route path="goals" element={<NewGoalsView session={session} />} />
                <Route path="updates" element={<UpdatesView session={session} />} />
                <Route path="settings" element={<SettingsView session={session} />} />
                <Route path="project/:id" element={<SingleProjectView session={session} view="project" />} />
                <Route path="area/:id" element={<SingleAreaView session={session} view="area" />} />
                <Route path="habit/:id" element={<HabitView session={session} view="habit" />} />
                {/* Embeddables */}
                <Route path="embed/reminders" element={<GeneralEmbedWrapper session={session} embedded_view={'upcoming_reminders'} embed="true" />} />
                <Route path="embed/pinnedtasks" element={<GeneralEmbedWrapper session={session} embedded_view={'pinned_tasks'} embed="true" />} />
                <Route path="embed/project/:id" element={<GeneralEmbedWrapper session={session} embedded_view={'project'} embed="true" />} />
                <Route path="embed/habit/:id/cell" element={<GeneralEmbedWrapper session={session} embedded_view={'habit_cell'} embed="true" />} />
              </Routes>
              </Center>
              </CSSTransition>
              </TransitionGroup>

      </Center>
    </Box>
    {!isEmbed && <Footer />}
    <OnboardingModal session={session} isOpen={isOnboardingModalOpen} setIsOpen={setIsOnboardingModalOpen} />
    </ChartModalProvider>
    </FormProvider>
    </>
  );
};

export default ForwardMain;