import React from 'react';
import { Box, Flex, Text, VStack, Tooltip, HStack } from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext';
import { generateCells } from '../helpers/cells';
import dhf from '../../../uc_utils/dates/dates.js';

const TimelineRow = ({ ...props }) => {
    const { state } = React.useContext(ForwardContext);

    const height = "10px";

    const TimelineCell = ({ period, length, cell_width, itemData }) => {
        const isToday = period.start === dhf.formatDate(new Date());
        const has_past = period.start < dhf.formatDate(new Date());
        let bgColor = 'gray.300';
        if (isToday) {
            bgColor = 'forwardBlue.200';
        }
        else if (has_past) {
            bgColor = 'forwardBlue.500';
        }
        return (
            <Box 
            borderWidth="1px 0px 1px 1px" 
            borderColor="forwardBlue.800" 
            maxHeight={height}
            minHeight={height}
            textAlign="center" 
            fontSize="3xs" 
            bg={bgColor}>
                {isToday ? '*' : ''}
            </Box>
        )
    }

    const timelineCells = generateCells(state.grid_ui.start_date, state.grid_ui.end_date, 'days', TimelineCell);


    return (
        <HStack width="100%" bg="gray.100" borderRadius="md" py={0} mb={2} maxHeight={height} >
            <Box flex="2" minWidth="200px" textAlign="left" fontSize="2xs" ml={2} color="gray.500">Timeline</Box>
            <HStack alignItems="stretch" flex="20" spacing={0} width="100%" justifyContent="stretch" maxHeight={height} borderRight="1px solid" borderColor="forwardBLue.800">
            {timelineCells}
            </HStack>
        </HStack>
    )
}


export default TimelineRow;