import React, { createContext, useContext, useState } from 'react';

import ChartModal from '../fwd_ui/charts/ChartModal';

const ChartModalContext = createContext();

export const useChartModal = () => useContext(ChartModalContext);

export const ChartModalProvider = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [modalLayout, setModalLayout] = useState(null);
    const [modalName, setModalName] = useState(null);

    const showModal = (modal_name, data, layout) => {
        setModalData(data);
        setModalLayout(layout);
        setModalName(modal_name);
        setIsVisible(true);
    };

    const hideModal = () => {
        setIsVisible(false);
    };

    return (
        <ChartModalContext.Provider value={{ showModal, hideModal }}>
            {children}
            {isVisible && (
                <ChartModal modal_name={modalName} data={modalData} layout={modalLayout} />
            )}
        </ChartModalContext.Provider>
    );
};
