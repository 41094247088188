import { supabase } from '../uc_constants/supabaseClient';
import { format } from 'date-fns';

export async function countMyShares(user_id) {
    let { data, error } = await supabase
        .from('life_area_collaborators')
        .select('id')
        .filter('principal_user_id', 'eq', user_id);
    if (error) {
        console.error('Error fetching shares:', error);
    }
    return data.length;
}

export async function updateShare(data) {
    let { data: updatedShare, error } = await supabase
        .from('life_area_collaborators')
        .update(data)
        .match({ id: data.id });

    if (error) {
        console.error('Error updating share:', error);
    }
    return updatedShare;
}

export async function insertShare (user_id, user_email, area_id, area_name, email) {
    const data_to_insert = { principal_user_id: user_id, principal_user_email: user_email, area_id: area_id, target_user_email: email, share_status: 9, area_name: area_name }

    console.log(data_to_insert);

    // User account tied to the email exists
    // Continue with the share insertion logic
    let { data, error } = await supabase
        .from('life_area_collaborators')
        .insert([data_to_insert])
        .select('*');
    if (error) {
        console.error('Error inserting share:', error);
    }
    return data;
}

export async function readShares(user_id) {
    let { data, error } = await supabase
        .from('life_area_collaborators')
        .select('*')
        .or(`principal_user_id.eq.${user_id},target_user_id.eq.${user_id}`);

    if (error) {
        console.error('Error fetching shares:', error);
    }

    return data;
}
