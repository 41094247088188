import React from 'react';

import { Table, Thead, Tbody, Tr, Th, Td, Button, VStack , Flex, useMediaQuery} from "@chakra-ui/react";
import { CSVLink, CSVDownload } from "react-csv";
import { AiOutlineDownload } from 'react-icons/ai';


const HabitWeeklyTable = ({ habit, habit_stats }) => {

    const headerRow = [
        "Week",
        "Performance",
        "Target",
        "Target Hit",
        "Streak",
        "Habit Index",
        // Add more header labels as needed
    ];
    const [isMobile] = useMediaQuery('(max-width: 768px)');

    const habitStatsKeys = Object.keys(habit_stats.weekly_stats);

    const tableRows = habitStatsKeys.map((weekKey) => {
        const week = habit_stats.weekly_stats[weekKey];
        return [
            weekKey,
            week.total,
            week.target?.[0],
            week.targets_hit,
            week.streak,
            week.habit_index,
            // Add more data fields as needed
        ];
    });

    const csv_data = [headerRow, ...tableRows];

    return (
        <>
        <VStack spacing={0} width="100%">
            <Flex width="100%" justifyContent="flex-end">
        <Button
            as={CSVLink}
            data={csv_data}
            size="xs"
            leftIcon={<AiOutlineDownload />}
            download={`habit_stats_${habit.habit_name}.csv`}
            borderRadius={0}
            colorScheme="forwardBlue"
        >
            CSV
        </Button>
        </Flex>
        <Table size="xs" >
            <Thead>
                <Tr>
                    <Th textAlign="center">{isMobile? 'Wk' : 'Week'}</Th>
                    <Th textAlign="center">{isMobile? 'Perf' : 'Performance'}</Th>
                    <Th textAlign="center">{isMobile? 'Target' : 'Target'}</Th>
                    <Th textAlign="center">{isMobile? 'Hit' : 'Target Hit'}</Th>
                    <Th textAlign="center">Streak</Th>
                    <Th textAlign="center">{isMobile? 'HI' : 'Habit Index'}</Th>
                    {/* Add more table headers as needed */}
                </Tr>
            </Thead>
            <Tbody>
                {Object.keys(habit_stats.weekly_stats).map((weekKey) => {
                    const week = habit_stats.weekly_stats[weekKey];
                    return (
                        <Tr key={weekKey}>
                            <Td textAlign="center">{weekKey}</Td>
                            <Td textAlign="center">{week.total}</Td>
                            <Td textAlign="center">{week.target?.[0]}</Td>
                            <Td textAlign="center">{week.targets_hit}</Td>
                            <Td textAlign="center">{week.streak}</Td>
                            <Td textAlign="center">{week.habit_index}</Td>
                            {/* Add more table cells as needed */}
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
        </VStack>
        </>
    );
};

export default HabitWeeklyTable;
