import React, {useState} from 'react';
import {
  HStack,
  VStack,
  Button,
  Input,
  useColorModeValue,
} from '@chakra-ui/react';


const TaskRecurrenceSelector = ({ value, onValueChange, isDisabled = false, size="md", ...props }) => {


    const [customValue, setCustomValue] = useState(value);
    const [selected, setSelected] = useState([0,1,2,4].includes(value) ? value : 'other');


    const handleCustomValueChange = (value) => {
        setCustomValue(value);
        onValueChange(value); // or you can handle it differently
    };

    const handleChange = (newValue) => {
        
        setSelected(newValue);
        onValueChange(newValue === 'other' ? customValue : newValue);
    };
    

  const px_size = size || 'md';

  const btnColors = useColorModeValue('forwardWhite','forwardBlue');
  const brdColor = useColorModeValue('forwardWhite.800','forwardBlue.800');
  const txtColor = useColorModeValue('black','white');
  const selectedColor = useColorModeValue('forwardBlue','forwardGold');



    return (
        <VStack>
        <HStack
                spacing={0}>
            <Button 
            size={px_size}
            borderRadius={0}
            textColor={txtColor}
            borderRight="1px"
            borderColor={brdColor}
            onClick={() => handleChange(0)}
            colorScheme = {parseInt(selected) === parseInt(0)
                            ? selectedColor
                            : btnColors}
            >None</Button>
            <Button 
            size={px_size}
            borderRadius={0}
            textColor={txtColor}
            borderRight="1px"
            borderColor={brdColor}
            onClick={() => handleChange(1)}
            colorScheme = {parseInt(selected) === parseInt(1)
                            ? selectedColor
                            : btnColors}
            >1 week</Button>
            <Button 
            size={px_size}
            borderRadius={0}
            textColor={txtColor}
            borderRight="1px"
            borderColor={brdColor}
            onClick={() => handleChange(2)}
            colorScheme = {parseInt(selected) === parseInt(2)
                            ? selectedColor
                            : btnColors}
            >2 weeks</Button>
            <Button 
            size={px_size}
            borderRadius={0}
            textColor={txtColor}
            borderRight="1px"
            borderColor={brdColor}
            onClick={() => handleChange(4)}
            colorScheme = {parseInt(selected) === parseInt(4)
                            ? selectedColor
                            : btnColors}
            >4 weeks</Button>
            <Button
                size={px_size}
                borderRadius={0}
                textColor={txtColor}
                onClick={() => handleChange('other')}
                colorScheme={selected === 'other' ? selectedColor : btnColors}
            >
                Other
            </Button>
            </HStack>
            {selected === 'other' && (
                <Input
                    size={px_size}
                    type="number"
                    value={customValue}
                    variant="flushed"
                    onChange={(e) => handleCustomValueChange(e.target.value)}
                    placeholder="Enter custom value"
                    textAlign="center"
                    fontSize="lg"
                />
            )}
            </VStack>

            
    );
  };
  export default TaskRecurrenceSelector;


