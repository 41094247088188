import { useState, useEffect, useContext} from 'react'
import { supabase } from '../../uc_constants/supabaseClient.js';
import { upsertUser } from '../api/accountService.js';
import { Box, Input, Heading, Text, Button, FormControl, VStack, Flex, Center, Divider, Checkbox, HStack, Badge } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import MyHeader from '../../general/components/MyHeader.jsx'
import { ForwardContext } from '../../context/ForwardContext.jsx';
import ahf from '../../uc_utils/accounts/account.js'
import dhf from '../../uc_utils/dates/dates.js'
import { useAccounts } from '../../data_hooks/accounts/useAccounts.jsx';
import { trackCustomEvent } from '../../general/functions/plausibleEvent.js';
import ForwardWelcome from '../../fwd_main/intro/ForwardWelcome.jsx';


export default function AccountMain({ session }) {

  const [accountSynced, setAccountSynced] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [showSub, setShowSub] = useState(false);
  const [showPayments, setShowPayments] = useState(false);
  const [showDaysRemaining, setShowDaysRemaining] = useState(false);
  const stripe_mode = process.env.REACT_APP_STRIPE_MODE;
  const server_url = process.env.REACT_APP_SERVER_URL+':'+process.env.REACT_APP_SERVER_PORT;


  const {syncAccount} = useAccounts();


  const {state, dispatch} = useContext(ForwardContext);
  const user = session.user;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    //checkLevel();
    //Sync account:
    syncAccount().then(() => {
      setAccountSynced(true); // Set accountSynced to true when syncAccount is done
      
    })
  },[]);

  useEffect(() => {
    if (accountSynced) { // Check if the account has been synced before checking for password
      console.log(state.account);
      const userLevel = ahf.getAccountAccessLevel(state.account);
      console.log(userLevel);
      //Set userLevel onto account
      if (state.account.account_type !== userLevel) {
        dispatch({ type: 'SET_USER_PROPERTY', payload: { 'property':'account_type','value':userLevel } });
        console.log(state.account);
      }
            
      //Update permissions to edit
      if(userLevel === 'Read Only') {
        dispatch({ type: 'SET_READ_ONLY', payload: true });
      }
      else {
        dispatch({ type: 'SET_READ_ONLY', payload: false });
      }
      setTimeLeft(ahf.getTimeLeft(state.account));

      //Choose what to display
      if (account.stripe_customer_id 
          && (account.stripe_customer_id).length > 0 
          && account.stripe_subscription_id 
          && (account.stripe_subscription_id).length > 0
          )
          {
            setShowSub(true);
          }
      
      if (account.account_level <= 1) {
        setShowPayments(true);
        setShowDaysRemaining(true);
      }
      if (account.account_level >= 6) {
        setShowDaysRemaining(true);
      }
      if (account.account_level === 6 && !showSub) {
        setShowPayments(true)
      }
    }
  }, [state.account, accountSynced]); // React on changes to either accountSynced or has_password


  const account = state.account;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [emailUpdates, setEmailUpdates] = useState(true);
  const [avatar_url, setAvatarUrl] = useState(null);


  useEffect(() => {
    async function getProfile() {
      setLoading(true)
      const { user } = session

      let { data, error } = await supabase
        .from('user_profiles')
        .select(`username, avatar_url, receive_emails`)
        .eq('id', user.id)
        .single()

      if (error) {
        console.warn(error)
      } else if (data) {
        setUsername(data.username);
        setAvatarUrl(data.avatar_url);
        setEmailUpdates(data.receive_emails);
      }

      setLoading(false)
    }

    getProfile();
    
  }, [session])

  async function updateUserProfile(event) {
    event.preventDefault();
    setLoading(true);
    console.log('Update Profile');
    const updates = {
      user_id: user.id,
      username: username,
      receive_emails: emailUpdates,
      updated_at: new Date(),
    }

    const { data, error } =  await upsertUser(updates)
    if (error) {
      alert(error.message)
    }
    else {
      alert("Your Profile has been updated!")
    }
    console.log(data);
    setLoading(false);
  }

  async function updateUserPass(event) {
    event.preventDefault();
    setLoading(true);
    trackCustomEvent('ACCOUNT Password Set',{});
    //const {user} = session;
    //console.log(password);
    const { data, error } =  await supabase.auth.updateUser({password: password})
    if (error) {
      alert(error.message)
    }
    else {
      alert("Your password has been updated!")
    }
    console.log(data);
    setLoading(false);

  }

  const handleSignOut = () => {
    supabase.auth.signOut();
    navigate('/');
  }
  if(!session) {
    return(
      <ForwardWelcome 
      alert={{'status':'warning','message':'You must be logged in to manage an account','duration':10000}} 
      />
    )
  }

  return (
    <>
    <MyHeader />
    <Center>
      <Box width="100%" maxWidth="700px" p={4}>
      <Heading as="h2" size="md">Your Account</Heading>
      <Divider my={2}/>

      <Box width="100%" border="1px" borderColor="gray.200" p={2}>
        <label htmlFor="email">Email</label>
        <Input id="email" type="text" variant="flushed" value={session.user.email} disabled />

        <form onSubmit={updateUserProfile} className="form-widget">

        <label htmlFor="username">Name</label>
        <Input
          id="username"
          type="text"
          required
          variant="flushed"
          placeholder="Your Name"
          value={username || ''}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Checkbox id="emailreceive" isChecked={emailUpdates || true}>Receive Email Updates</Checkbox>
        <Flex flexDirection="row" justifyContent="space-between" m={2}>
        <Button borderRadius="0px" className="button block primary" colorScheme="forwardBlue" variant="outline" width="100%" type="submit" disabled={loading}>
          {loading ? 'Loading ...' : 'Update Profile'}
        </Button>
        </Flex>
        </form>
        </Box>
                <Heading as="h2" size="md" py={2}>Manage Subscription</Heading>
                <Divider mb={2} />
                <Box width="100%">
                <HStack>
                  <Text fontWeight="bold">
                    Membership Status:
                  </Text>
                  <Badge fontSize="lg" colorScheme="green">
                    {account.account_type}
                  </Badge>
                  {showDaysRemaining && <Text>Unlimited Forward Access: ({timeLeft} days left)</Text>}
                </HStack>
                {showSub &&
                <form width="100%" method="POST" action={server_url + "/stripe-customer-portal"}>
                <HStack width="100%" alignItems="flex-start" border="1px" borderColor="gray.200" p={2} my={2}>
                <VStack width="100%" alignItems="flex-start"> 
                
                <input type="hidden" name="customer_id" value={account.stripe_customer_id} />
                <input type="hidden" name="sub_mode" value={account.subscription_mode} />
                  <Text fontWeight="bold">Current Subscription:</Text>
                  <Text textColor="green" fontWeight="bold">Status: Active</Text>
                  <Text>{capitalizeFirstLetter(account.stripe_subscriptions.plan_interval+'ly plan at $'+account.stripe_subscriptions.plan_amount/100)}</Text>
                  <Text>Next Billing Date: {dhf.formatDate(new Date(account.stripe_subscriptions.current_period_end*1000),'MMM dd, yyyy')}</Text>
                  
                  <Text fontSize="xs">Clicking "manage" will take you to Stripe to handle your payment information securely.</Text>
                
              
              </VStack>
              <Button borderRadius="0px" colorScheme="forwardBlue" type="submit">Manage</Button>
              </HStack></form>}
              <Box p={4} my={2} border="1px" borderColor="gray.200">
              {showPayments && account.account_level === 6 && 
              <Text fontWeight="bold">As a lifetime member, your access is free forever. However, we'd greatly appreciate your support if you'd like to subscribe!</Text>}
              {showPayments && stripe_mode === 'test' &&
                <stripe-pricing-table 
                    pricing-table-id="prctbl_1NXUYqKbOaB711N0eoJCfEZE"
                    publishable-key="pk_test_51Mw8HQKbOaB711N0RGSnGD5JWPUlPH8bEEv9QmLl6QBMUiP0810GICuPe3dw7QxAhGrLZEX0d8z4Ab1wx4GwiMG3005Ug533VA"
                    client-reference-id={user.id}
                    >
                </stripe-pricing-table>
                }
                {showPayments && stripe_mode === 'prod' && <stripe-pricing-table
                pricing-table-id='prctbl_1NXSd8KbOaB711N0PwSMBpVq'
                publishable-key="pk_live_51Mw8HQKbOaB711N02v3dnvuNteZGuAjJKIKgHMgYI1kOyjhylSIlaTMlFLWi9bGH0PgJDqo2FEyoPiKT9RonIwlU00RStOWGSS"
                client-reference-id={user.id}
                >
            </stripe-pricing-table>}
            {showPayments && 
                <Text fontSize="sm"  mb={2} textStyle="oblique">Clicking "subscribe" will take you to Stripe to handle your payment information securely.</Text>
            }
            </Box>
                </Box>
        <Divider />
        <Heading as="h2" size="sm" py={2}>Profile Actions:</Heading>
        <Divider my={2}/>
        <Box width="100%" border="1px" borderColor="gray.200" p={2}>
        <form onSubmit={updateUserPass} className="form-widget">
        <label htmlFor="password">Change Password:</label>
        <Input
          id="password"
          type="password"
          required
          variant="flushed"
          value={password || ''}
          onChange={(e) => setPassword(e.target.value)}
        />
      <Flex flexDirection="row" justifyContent="space-between" m={2}>
        <Button borderRadius="0px" className="button block primary" colorScheme="forwardBlue" type="submit" disabled={loading}>
          {loading ? 'Loading ...' : 'Update Password'}
        </Button>
        </Flex>

        
    </form>
    </Box>
    <Button my={2} width="100%" variant="outline" className="button block" type="button" colorScheme="red"  onClick={handleSignOut}>
          Sign Out
        </Button>
    </Box>
    
    </Center>
    </>
  )
}