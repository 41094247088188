import React, { useState, useContext } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  Radio,
  HStack,
  VStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ForwardContext } from "../../../context/ForwardContext.jsx";
import dhf from '../../../uc_utils/dates/dates.js'
import hhf from '../../../uc_utils/habits/habits.js';
import { useHabits } from "../../../data_hooks/elements/useHabits.jsx";

const TargetForm = ({habit, isOpen, onClose, isDisabled }) => {

    const { state, dispatch } = useContext(ForwardContext);
    const { upsertHabitTarget } = useHabits();

    //console.log(habit);
    const most_recent_target = hhf.getMostRecentTarget(habit.targets,new Date());
    const this_wk_date = dhf.getStartOfWeek(new Date()).toLocaleDateString().slice(0, 10)
    const next_wk_date = dhf.addDaysToDate(dhf.getStartOfWeek(new Date()),7).toLocaleDateString().slice(0, 10)

    //console.log(most_recent_target);
    //console.log(most_recent_target);
    let tDefault = "1";
    let mlDefault = "true";
    if (most_recent_target !== null) {
        tDefault = most_recent_target[0];
        mlDefault = most_recent_target[1]? "true":"false";
    }
  const [target, setTarget] = useState(tDefault);
  const [moreOrLess, setMoreOrLess] = useState(mlDefault);
  const [startWeek, setStartWeek] = useState(this_wk_date);

  const handleSubmit = async () => {
    //Create Data Object
    const my_target = {target_value:target, is_greater_than:moreOrLess, date:startWeek, habit_id:habit.id}
    console.log(my_target);

    //Update Database and State
    const written_target = await upsertHabitTarget(my_target);
    console.log(written_target);
    //Update State
    //dispatch({ type: 'UPSERT_HABIT_TARGET', payload: written_target[0] });

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} borderRadius="0px" >
      <ModalOverlay />
      <ModalContent borderRadius="0px" >
        <ModalHeader>Set a Weekly Target<Text fontSize="md" fontWeight="medium">Help hold yourself accountable!</Text></ModalHeader>
        
        <ModalCloseButton />
        <ModalBody>
            <HStack>
          <FormControl>
            <FormLabel>My target for {habit.habit_name} is: </FormLabel>
            <Input
              type="number"
              variant="flushed"
              value={target}
              onChange={(e) => setTarget(e.target.value)}
              min="0"
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>More or Less</FormLabel>
            <RadioGroup
              value={moreOrLess}
              onChange={setMoreOrLess}
              mt={2}
            >
              <HStack>
                <Radio value="true">Or More</Radio>
                <Radio value="false">Or Less</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          </HStack>
          <FormControl mt={4}>
            <FormLabel>Starting:</FormLabel>
            <RadioGroup
              value={startWeek}
              onChange={setStartWeek}
              mt={2}
            >
              <HStack align="start" w="100%">
                <Radio w="50%" value={this_wk_date}>This Week ({this_wk_date})</Radio>
                <Radio w="50%" value={next_wk_date}>Next Week ({next_wk_date})</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button  borderRadius={0} colorScheme="blue" mr={3} onClick={handleSubmit} isDisabled={isDisabled}>
            Set Target
          </Button>
          <Button  borderRadius={0} onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TargetForm;