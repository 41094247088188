import React, { useState } from 'react';
import { Box, Center, VStack, Heading, Text, Button, Divider, UnorderedList, ListItem, Spacer, Image, Link, useMediaQuery, useColorModeValue, useToast } from '@chakra-ui/react';
import MyHeader from '../../general/components/MyHeader';
import Footer from '../../general/components/Footer';
import { useNavigate, Link as ReachLink } from 'react-router-dom';
import ForwardSignupForm from '../../account/components/ForwardSignupForm';
import ForwardLoginForm from '../../account/components/ForwardLoginForm';
import DisplayVideo from '../../general/components/DisplayVideo';
import useAuth from '../../account/hooks/useAuth';
import { supabase } from '../../uc_constants/supabaseClient';

function ForwardWelcome ({alert, arrival_url, fast_signin}) {

    const [toggle, setToggle] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { signInWithOtp, loginWithPassword } = useAuth();
    const toast = useToast();
    const screenWidth = window.innerWidth;
    const yt_width = screenWidth > 900 ? 800 : screenWidth-20;
    const yt_height = yt_width * 0.5625;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);


    const isMobile = useMediaQuery('(max-width: 600px)')[0];
    const boxBg = useColorModeValue("gray.100","gray.700");

    const handleSignup = async (event, email) => {
        event.preventDefault();
        const { user, error } = await signInWithOtp(email, 'forward');
        if (error) {
            //console.error('Error signing in:', error);
            toast({
                title: "Error",
                description: 'Error signing up - please check back soon!',
                status: "error",
                position: 'top',
                isClosable: true,
            });
        }
        else {
             const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
                if (event === 'SIGNED_IN') {
                  // After a successful sign in, call the createUser function.
                  console.log('Initializing Forward Account');
                  //createUser(session.user);
                  setLoading(false);
                  authListener.unsubscribe(); // Dispose of the listener after the user is created
                }
              });

            if(arrival_url) {
                navigate(arrival_url)
              }
            /*else {
                navigate('/forward/')
            }*/
        }
    }  
    const handleLoginForward = async (event) => {
        event.preventDefault();
        const { data, error } = await loginWithPassword(email, password, 'forward');
        if (error) {
            console.error('Error signing in:', error);
        } else {
            console.log('User signed in:', data);
        }
    }

    const handlePasswordReset = async (event) => {
        event.preventDefault();
        console.log('resetting password');
        const { error } = await supabase.auth.resetPasswordForEmail(email, {redirectTo:'https://www.theforwardapp.com/password/'});
        if (error) {
          window.alert('Error sending password reset email:', error);
        } else {
          toast({
            title: "Check your inbox!",
           description: "We've sent an email to get you logged in!",
           status: "success",
           position: 'top',
           duration: 9000,
           isClosable: true,
         })
        }
      };

  return (
    <Box width="100%">
            <MyHeader />
            <Center px={2} width="100%">
                <VStack spacing={2} width="100%">
                    <Center width="100%">
                        <VStack>
                            
                        <Box
                        width="100vw" // Modify this line
                        height={isMobile ? '700px' : '700px'}
                        position="relative"
                    >
                        <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            backgroundImage: "url('/fwd/fwd_hero_v1.png')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'left',
                            opacity: 0.7, // Adjust opacity here
                            zIndex: -1, // Make sure this stays behind the content
                          }}
                          />
                        <VStack
                        position="absolute"
                        top={isMobile ? '50%' : '55%'}
                        left="50%"
                        width="100%"
                        transform="translate(-50%, -50%)"
                        textAlign="center"

                        spacing={isMobile ? 0 : 2}
                        >
                            <Box
                            backgroundColor="rgba(255, 255, 255, 0.8)"
                            py={8}
                            px={16}
                            m={2}
                            boxShadow="rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;"
                            borderRadius={8}
                            >
                        <Heading
                            as="h1"
                            size={isMobile ? 'lg': "xl"}
                            color="black"
                            mb={3}
                        >
                            Set Goals, Track Progress, and Live Your Best Life!
                        </Heading>
                        <Text fontWeight={isMobile ? '' : 'bold'} fontSize={isMobile ? 'sm' : 'lg'}
                                color="black">
                            Forward is a life management platform that helps you achieve your goals, one step at a time.
                            </Text>
                            </Box>
                        
                    {!toggle && (
                        <>
                        <Divider my={2} width="30%" />
                        <Box    width={isMobile ? '95%' : '50%'}
                                backgroundColor="rgba(255, 255, 255, 0.9)"
                                borderRadius={8}
                                px={8}
                                py={12}
                                color="black"
                                boxShadow="rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;"
                                >
                        <ForwardSignupForm 
                            handleSignup={handleSignup} />
                            </Box>
                            </>
                    )}
                    {toggle && (
                        <Box    width={isMobile ? '95%' : '50%'}
                        backgroundColor="rgba(255, 255, 255, 0.7)"
                        borderRadius={8}
                        px={8}
                        py={12}>
                        <ForwardLoginForm 
                            email={email}
                            setEmail={setEmail}
                            password={password}
                            setPassword={setPassword}
                        handleLoginIntellect={handleLoginForward} />
                        </Box>
                    )}

                    <Button size="md" variant="outline" color="white" onClick={() => setToggle(!toggle)}>{toggle ? 'Sign Up' : 'Log In'}</Button>
                        </VStack>
                    </Box>

                        <VStack width="100%" maxWidth="1000px" spacing={12}>

                        <VStack
                        p={8}
                        width="100%"
                        backgroundColor={boxBg}
                        borderRadius={4}
                        boxShadow="rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;"
                        >
                        <Heading as="h2" size="md"  textAlign={'center'}>What is Forward?</Heading>
                        <Divider my={4} borderColor="teal" width="50%" />
                    <UnorderedList px={2} spacing={2}>
                        <ListItem>Forward helps you manage goals, projects, tasks, and habits all in one place!</ListItem>
                        <ListItem>We provide robust organization methods and analytics to help you stay organized and motivated.</ListItem>
                        <ListItem>Our goal is to help you achieve your goals!</ListItem>
                        <ListItem><b>Forward is Free</b> for basic use, no credit card required!</ListItem>
                    </UnorderedList>
                    </VStack>

                    <Box
                    p={8}
                    width="100%"
                    backgroundColor={boxBg}
                    borderRadius={4}
                    boxShadow="rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;"
                    >
        <Image src="/forward/marketing/hero_image_v1.png" width={{'base':'100%','md':'100%'}} boxShadow="rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;"/>
        <Text my={1} fontStyle="oblique">Forward's main dashboard let's you see everything at a single glance: habits, tasks, goals, and more!</Text>
                    </Box>

                    <VStack
                        p={8}
                        width="100%"
                        backgroundColor={boxBg}
                        borderRadius={4}
                        boxShadow="rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;"
                        >
                        <Heading as="h2" size="md"  textAlign={'center'}>Habits</Heading>
                        <Divider my={4} borderColor="teal" width="50%" />
                    <UnorderedList px={2} spacing={2}>
                            <ListItem><b>Easy & Fast</b> - Track basic habits each day with a single tap</ListItem>
                            <ListItem><b>Adjustable Targets</b> - Set and adjust targets for your habits each week</ListItem>
                            <ListItem><b>Streaks & Medals</b> - Hit your target each week to improve your streak, and earn medals along the way to stay motivated!</ListItem>
                            <ListItem><b>Charts & Analysis</b> - Analyze your habit data with detailed charts, export your data to csv whenever you like!</ListItem>
                            <ListItem><b>The Habit Index</b> - Use our proprietary Habit Index to benchmark your habit performance over time!</ListItem>
                    </UnorderedList>
                    </VStack>

                    <VStack
                        p={8}
                        width="100%"
                        backgroundColor={boxBg}
                        borderRadius={4}
                        boxShadow="rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;"
                        >
                        <Heading as="h2" size="md"  textAlign={'center'}>Projects and Tasks</Heading>
                        <Divider my={4} borderColor="teal" width="50%" />
                    <UnorderedList px={2} spacing={2}>
                        <ListItem><b>Stay Organized</b> - Organize tasks within projects, and maintain a backlog for all the tasks on the back of your mind!</ListItem>
                        <ListItem><b>Drag and drop</b> - drag tasks for easy management and reorganization.</ListItem>
                        <ListItem><b>Kanban View</b> - use it to plan out the week ahead.</ListItem>
                        <ListItem><b>Track Project History</b> - Review your task history by project and see your normal throughput to stay motivated!</ListItem>
                        <ListItem><b>Pin tasks</b> - to the top of your list for easy prioritization.</ListItem>
                        <ListItem><b>Recurring tasks</b> - setup on any cadence to ensure you stay on top of infrequent tasks!</ListItem>
                    </UnorderedList>
                    </VStack>

                    <VStack
                        p={8}
                        width="100%"
                        backgroundColor={boxBg}
                        borderRadius={4}
                        boxShadow="rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;"
                        >
                        <Heading as="h2" size="md"  textAlign={'center'}>Goals</Heading>
                        <Divider my={4} borderColor="teal" width="50%" />
                    <UnorderedList px={2} spacing={2}>
                        <ListItem><b>Set Goals</b> - Set short-term and long-term goals to keep focused on what matters.</ListItem>
                        <ListItem><b>Yearly or Quarterly</b> - manage your goals and long-term plans easily to arrange them by year or by quarter.</ListItem>
                        <ListItem><b>Color-coded tracking</b> - Track how you are doing with an easy color-coded system to see where you need to focus.</ListItem>
                        <ListItem><b>Link Goals</b> - Connect your goals to the tasks you need to do to get there by connecting them to a project.</ListItem>
                        <ListItem><b>Review & Assess</b> - Hold yourself accountable by reviewing your goals from prior years and seeing where you succeeded and where you came up short.</ListItem>
                    </UnorderedList>
                    </VStack>

                    <VStack
                        p={8}
                        width="100%"
                        backgroundColor={boxBg}
                        borderRadius={4}
                        boxShadow="rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;"
                        >
                        <Heading as="h2" size="md"  textAlign={'center'}>And More</Heading>
                        <Divider my={4} borderColor="teal" width="50%" />
                    <UnorderedList px={2} spacing={2}>
                        <ListItem><b>Mobile Ready</b> - use our site as a progressive web app on your phone to track everything on the go!</ListItem>
                        <ListItem><b>Reminders</b> - use reminders to handle things that are yearly or very infrequent (e.g. Birthdays, bills, et cetera)</ListItem>
                        <ListItem><b>Embeddables</b> - embed tiny widgets in apps like Notion to track your habits or manage your tasks where you need 'em!</ListItem>
                        <ListItem><b>More to Come</b> - Forward is built by high performers to empower high performers. We won't stop if you won't!</ListItem>
                        
                    </UnorderedList>
                    </VStack>



                    </VStack>
                    




                    </VStack>
                    </Center>
                    <Box my={4}>
      <Heading width="100%" textAlign="center" mb={4} as="h3" size="md">See Forward in action!</Heading>
      <Box boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;">
    <DisplayVideo url="https://www.youtube.com/watch?v=dZLnjhEhaJA" height={yt_height+"px"} width={yt_width+"px"}/>
    </Box>
    </Box>
                </VStack>
            </Center>
            <Footer />
        </Box>
  )
}

export default ForwardWelcome;