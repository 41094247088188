import React, {useState, useEffect, useMemo} from 'react';
import { Box, Text, Grid, GridItem, Tooltip, HStack, VStack, IconButton, Switch, 
    useMediaQuery, Center,
    Flex } from '@chakra-ui/react';
import HabitCalendarSettings from '../components/HabitCalendarSettings.jsx';
import dh from '../../../uc_utils/dates/dates.js';
import hch from '../../../uc_utils/habits/habit_calendar.js'

const HabitCalendar = ({ habit }) => {    
  
    const [showHover, setShowHover] = useState(true);
    const [showValues, setShowValues] = useState(false);
    const [yearShown, setYearShown] = useState({value: new Date().getFullYear().toString(), label: new Date().getFullYear().toString()});

    //console.log(yearShown);

    const [boxSize, setBoxSize] = useState("25");
    const [isMobile] = useMediaQuery("(max-width: 600px)"); // Adjust 600px as needed for your breakpoint
    const my_values = Object.entries(habit.values) // Convert to array of [key, value] pairs
  .filter(([key, value]) => key.split('-')[0] === yearShown.value) // Keep pairs where key's year matches yearShown
  .reduce((obj, [key, value]) => {
    // Reconstruct the object with filtered entries
    obj[key] = value;
    return obj;
  }, {});
  //console.log(my_values);

    const lim = habit.is_less_better;

    const weeks = useMemo(() => hch.processValuesIntoWeeks(my_values, yearShown.value), [my_values]);
    const mons = useMemo(() => hch.processFirstSundays(my_values, yearShown.value), [my_values]);

    const monsToShow = mons.filter((mon) => mon.value > 0).length+1;
    const widthToShow = mons.reduce((sum, mon) => sum + mon.value, 0);

    const [tooltipVisibility, setTooltipVisibility] = useState({});
    const toggleTooltip = (weekIndex, dayIndex) => {
      const uniqueKey = `${weekIndex}-${dayIndex}`;
      setTooltipVisibility(prev => ({
        ...prev, 
        [uniqueKey]: !prev[uniqueKey]
      }));
    };

    useEffect(() => {
        if(isMobile) {
            setBoxSize(40)
        }
        else {
            setBoxSize(25)
        }
    },[isMobile])

  return (
    <Flex flexDir="column" alignItems="center" overflowX="overlay">
        <HStack width="100%" justifyContent="flex-end">
          <HabitCalendarSettings habit={habit} showHover={showHover} setShowHover={setShowHover} 
          showValues={showValues} setShowValues={setShowValues} boxSize={boxSize} setBoxSize={setBoxSize}
          yearShown={yearShown} setYearShown={setYearShown} />
        </HStack>
      <Center>
      <Flex 
      flexDir={isMobile ? 'row' : 'column'}
      maxWidth="90vw"
      justifyContent="center"
      alignItems="flex-start" >
      <Grid 
      templateColumns={isMobile ? "repeat(7, 1fr)" : `repeat(${weeks.length+1}, 1fr)`}
      templateRows={isMobile ? "auto" : `initial`}
      borderTop="1px solid gray" 
    borderLeft="1px solid gray"
      gap={0}
    >
      {/* Weekday Labels */}
      {!isMobile ? <GridItem key="weekday-headers" colSpan={1}>{hch.renderWeekdays(boxSize, isMobile)}</GridItem> : 
                <GridItem key="weekday-values" colSpan={7} display="grid" gridTemplateColumns="repeat(7,1fr)">{hch.renderWeekdays(boxSize, isMobile)}
                </GridItem>}
      
      {weeks.map((week, weekIndex) => (
        isMobile ? (
          /**************************************/
          /**************************************/
          // Mobile layout: each day is a column
          /**************************************/
          /**************************************/
          week.map((day, dayIndex) => {
            const uniqueKey = `${weekIndex}-${dayIndex}`;
            let bBottom = "1px solid gray"
            let bRight= "1px solid gray"
            const dp1m = dh.getMonth(dh.addDaysToDate(day.date,1));
            const dp7m = dh.getMonth(dh.addDaysToDate(day.date,7));
            const dmonth = dh.getMonth(day.date);
            const dday = dh.getWeekday(day.date);

            if(dp1m !== dmonth && dday !== 6) {
                bRight = "2px solid black"
            }
            if (dp7m !== dmonth) {
                bBottom = "2px solid black"
            }
            
            return (
            <GridItem key={`${weekIndex}-${dayIndex}`}>
              <Box
                  onClick={() => toggleTooltip(weekIndex, dayIndex)}
                  key={dayIndex}
                height={boxSize+"px"}
                width={boxSize+"px"}
                borderBottom={bBottom}
                borderRight={bRight}
                fontSize="xs"
                textAlign="center"
                bg={hch.getColorForValue(day.value, lim)}
                _hover={{ border:"2px solid black" }}
                
              >{showValues && day.value}
               {tooltipVisibility[uniqueKey] && (
                <Tooltip label={dh.formatDate(day.date) + ": " + day.value} placement="bottom" hasArrow isOpen>
                  <Box bg={hch.getColorForValue(day.value, lim)} />
                </Tooltip>
              )}
              </Box>
            </GridItem>
          )})
        ) : (
          /**************************************/
          /**************************************/
          // Desktop Layout: each day is a row
          /**************************************/
          /**************************************/
          <GridItem key={weekIndex}>
            {week.map((day, dayIndex) => {
                let bBottom = "1px solid gray"
                let bRight= "1px solid gray"
                const dp1m = dh.getMonth(dh.addDaysToDate(day.date,1));
                const dp7m = dh.getMonth(dh.addDaysToDate(day.date,7));
                const dmonth = dh.getMonth(day.date);
                const dday = dh.getWeekday(day.date);

                if(dp1m !== dmonth && dday !== 6) {
                    bBottom = "2px solid black"
                }
                if (dp7m !== dmonth) {
                    bRight = "2px solid black"
                }

                return(
                  <Tooltip label={showHover ? dh.formatDate(day.date) + ": " + day.value : ""} 
                          placement="bottom"
                          hasArrow
                          key={dayIndex}
                          >
                  <Box
                    key={dayIndex}
                    height={boxSize+"px"}
                    width={boxSize+"px"}
                    borderBottom={bBottom}
                    borderRight={bRight}
                    fontSize="xs"
                    textAlign="center"
                    _hover={{ border:"2px solid black" }}
                    bg={hch.getColorForValue(day.value, lim)}
                    display="flex"
                alignItems="center"
                justifyContent="center"
                  >{showValues && day.value}</Box>
                  </Tooltip>)
            }
            )}
          </GridItem>
        )
      ))}
    </Grid>
    {/**************************************/
    /**************************************/
    // Monthly Labels
    /**************************************/
    /**************************************/}
    {isMobile ? 
    /* Mobile Month Labels */
    (<><VStack
      marginTop="4px"
      width={boxSize + "px"}
      justifyContent="center"
      alignContent="stretch"
      alignItems="stretch"
      m={0}
      spacing={0}  // No gap between items
    >
      <Box
        height={boxSize + "px"}
        width={boxSize + "px"} 
        fontSize="sm"
        borderTop = "1px solid gray"
        borderRight = "1px solid gray"
        borderBottom="1px solid gray"
        textAlign="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        Mo
      </Box>
      {mons.map((day, dayIndex) => {
        if(day.value > 0) {
          return(
            <Box
              key={dayIndex}
              width={boxSize + "px"}
              height={((day.value * boxSize)) + "px"}  // Variable height based on day.value
              borderBottom="1px solid gray"
              borderRight="1px solid gray"
              textAlign="center"
              fontSize="sm"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
                <div style={{ transform: "rotate(-90deg)" }}>
                  {(dh.getMonth(day.date) + 1) + "/" + day.date.getFullYear()}
                </div>
            </Box>
          )
        }
      })}
    </VStack></>)
    /* Desktop Month Labels */
    : 
    (<><Grid marginTop="4px" 
    width={(widthToShow+1)*boxSize}
    templateColumns={`repeat(${monsToShow}, 1fr)`}
    templateRows={isMobile ? "auto" : `initial`}
    gap={0}
    >
    <GridItem 
    height={boxSize+"px"}
    width={boxSize+"px"} 
    border="1px solid gray"
    fontSize="sm"
    textAlign="center"
    display="flex"
                alignItems="center"
                justifyContent="center"
    >Mo</GridItem>
    {mons.map((day, dayIndex) => {
        if(day.value > 0) {
        return(
        <GridItem
        key={dayIndex}
        height={boxSize+"px"}
        width={day.value*boxSize+"px"} 
        borderY="1px solid gray"
        borderRight="1px solid gray"
        textAlign="center"
        fontSize="sm"
        display="flex"
                alignItems="center"
                justifyContent="center"
        >
            {(dh.getMonth(day.date)+1)+"/"+day.date.getFullYear()}
        </GridItem>)
        }
})}
    
    </Grid></>)
    }
    </Flex>
    </Center>
    </Flex>
  );
};

export default HabitCalendar;
