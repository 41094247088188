import React, { useState, useContext } from 'react';
import {
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import NewAreaDropdown from '../../areas/components/NewAreaDropdown.jsx';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { useProjects } from '../../../data_hooks/elements/useProjects.jsx';
import dhf from '../../../uc_utils/dates/dates.js';



const ProjectForm = ({
  isOpen, onClose, type, session, editorData, isDisabled,
}) => {
  const toast = useToast();
  const {upsertFullProject} = useProjects();

  /*******************/
  //Form Setup and Management
  /*******************/
  const { state, dispatch } = useContext(ForwardContext);

  const [projectName, setProjectName] = useState(editorData.project_name || '');
  const [startDate, setStartDate] = useState(editorData.start_date || dhf.formatDate(new Date()));
  const [endDate, setEndDate] = useState(editorData.end_date || '');
  const [description, setDescription] = useState(editorData.description || '');
  const [selectedArea, setSelectedArea] = useState(editorData.area_id || state.areas[0].id);
  

  const handleAreaChange = (value) => {
    setSelectedArea(value);
    //console.log(selectedArea);
  };

  let submit_text = '';
  let submit_mode = '';
  if(Object.keys(editorData).length <=2)
  {
    submit_text = 'Create'
    submit_mode = 'new'
  }
  else {
    submit_text = 'Update'
    submit_mode = 'edit'
  }
  const user_id = session.user.id;

  /*******************/
  //Submit to Database
  /*******************/
  async function handleSubmit() {
    //Build data to submit:
    console.log(editorData);
    const project_data = {'user_id':user_id, 
                          'project_name':projectName, 
                          'area_id':selectedArea, 
                          'description':description,
                          'project_type':1, 
                          'start_date':startDate}
    if(editorData.id > 0) {
        project_data["id"] = editorData.id;
    }

    //Use the UseProject Hook to coordinate actions: adding to database and updating state
    let {result, message} = await upsertFullProject(project_data);

    //Provide Feedback to user based on the outcome
    if(result === 'limit') {
      toast({
        title: 'Error: Please upgrade your account to add more projects!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    else if (result === 'error') {
      toast({
        title: 'Error: '+message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    else {
      onClose();
      toast({
        title: 'Project Updated!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }


    
  };

  /*******************/
  // Return the Form
  /*******************/

  return (
    <>
    <FormControl>
    <ModalBody>
            <FormLabel fontWeight="bold">Project Name</FormLabel>
            <Input
              type="text"
              variant="flushed"
              value={projectName}
              placeholder="Build a Side Hustle"
              onChange={(e) => setProjectName(e.target.value)}
            />
            <FormLabel fontWeight="bold" mt={4}>Start Date</FormLabel>
            <Input
              type="date"
              variant="flushed"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            
            <FormLabel fontWeight="bold" mt={4}>Details (optional)</FormLabel>
            <Textarea
              value={description}
              variant="flushed"
              onChange={(e) => setDescription(e.target.value)}
              resize="vertical"
              placeholder="Note any added details about the project"
            />
            <FormLabel fontWeight="bold" mt={4}>Life Area:</FormLabel>
            <NewAreaDropdown mt={2} value={selectedArea} onChange={handleAreaChange} variant="flushed" />
        </ModalBody>
        <ModalFooter>
          <Button  borderRadius={0} colorScheme="forwardOrange" mr={3} onClick={handleSubmit} isDisabled={isDisabled}>
            {submit_text}
          </Button>
          <Button  borderRadius={0} variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
        </FormControl>
        </>
  );
};

export default ProjectForm;