

const generateGeneralLayout = ({colorMode, isMobile, xAxisLabel, yAxisLabel, barMode = 'stack', fixedRange=true}) => {

    const plotColor = colorMode === "light" ? "#f9f2f2" : "#024350";
    const paperColor = colorMode === "light" ? "#f9f2f2" : "#024350";
    const fontColor = colorMode === "light" ? "black" : "white";

    let layout = {};
    layout = {
        legend: {
        "orientation": "h",
        "yanchor":"bottom",
        "y":1,
        "xanchor":"left",
        "x":0,
        "bgcolor": plotColor,
        "font": {"color":fontColor},
        },
        showlegend: isMobile ? false : true,
        pad: 0,
        margin: {b:45,l:45,r:10,t:10},
        xaxis: {
        title: xAxisLabel,
        font:'Overpass',
        color: fontColor,
        fixedrange: fixedRange
        },
        yaxis: {
        title: yAxisLabel,
        font:'Overpass',
        color: fontColor,
        fixedrange: fixedRange
        },
        barmode: barMode,
        plot_bgcolor: plotColor,
        paper_bgcolor: paperColor,
    }

    return layout;

}

export default {generateGeneralLayout};