import { supabase } from '../../uc_constants/supabaseClient';
import { format } from 'date-fns';

export async function upsertUser(updates) {
    let { data, error } = await supabase
      .from('user_profiles')
      .upsert([{ user_id: updates.user_id, username: updates.username, receive_emails: updates.receive_emails, updated_at: updates.updated_at }], 
              {onConflict: 'user_id' })
        .select()

    if (error) {
        console.error('Error upserting user:', error);
    }
    return data;
}


export async function addUser(userId, userName, emailreceive) {
    let { data: user, error } = await supabase
      .from('user_profiles')
      .select('*')
      .eq('user_id', userId)
      .single();
  
    if (error) {
      console.error('Error fetching user:', error);
      return;
    }
  
    if (!user) {
      // User does not exist, let's add them
      let { data, error: insertError } = await supabase
        .from('users')
        .insert([{ user_id: userId, username: userName, receive_emails: emailreceive }])
        .select();
  
      if (insertError) {
        console.error('Error adding user:', insertError);
      }
      return data;
    }
  }
  
export async function updateUserName(userId, newUserName) {
    let {  data, error  } = await supabase
      .from('user_profiles')
      .update({ username: newUserName })
      .eq('user_id', userId)
      .select();
  
    if (error) {
      console.error('Error updating username:', error);
    }
    return data;
  }
  

  