import React, { useState, useContext } from 'react';
import { HStack, Button, Text, useColorModeValue, VStack, Box, IconButton, useDisclosure, Flex, 
    Tooltip, Menu, MenuList, MenuButton, MenuItem, Badge, Input,
    Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody} from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillPauseCircle} from 'react-icons/ai';
import { BsFillSkipForwardFill} from 'react-icons/bs';
import { AiFillCaretDown } from 'react-icons/ai';

import { FormContext } from '../../../context/FormContext.jsx';
import { ForwardContext } from '../../../context/ForwardContext.jsx';

import dhf from '../../../uc_utils/dates/dates.js';
import rhf from '../../../uc_utils/tasks/reminders.js';

import { useReminders } from '../../../data_hooks/elements/useReminders.jsx';

import ConfirmDialog from '../../general/components/ConfirmDialog.jsx';


const ReminderTaskRow = ({ reminder, style, ...props}) => {
  
  const ur = useReminders();
  const {state, dispatch} = useContext(ForwardContext);
  const { editReminder} = useContext(FormContext);
  const eventDate = dhf.createDateObject(reminder.next_due_date);
  const daysFromNow = dhf.getDaysBetweenDates(eventDate,new Date());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isACOpen, setIsACOpen] = useState(false);
  const onACClose = () => setIsACOpen(false);
  const [isDelayOpen, setIsDelayOpen] = useState(false);
  const [numDays, setNumDays] = useState('');

  const bgMode = useColorModeValue('light','dark');
  const bgColor = rhf.getReminderColor(daysFromNow, bgMode);
  const anniversary_count = rhf.getAnniversaryCount(reminder);


  const toggleDelay = () => setIsDelayOpen(!isDelayOpen);
  const handleInputChange = (event) => setNumDays(event.target.value);


  const handleSubmit = () => {
    //handleDelay(reminder, numDays);
    ur.handleAction(reminder, 'delayed', numDays);
    setIsDelayOpen(false);
  };


  let showActions = false;
  let paddingRight = "4px";
  if(daysFromNow <= 7) {
    //Within a week, present options to Complete | Delay | Skip
    showActions = true
    paddingRight = "0px"
  }

  const archiveReminder = async () => {
    ur.upsertReminderProperty(reminder, 'status', 'abandoned');
    onACClose();
  };

  const my_life_area = state.areas.filter(area => area.id === reminder.area_id)[0];
  const height = style==='pinned' ? '34px !important' : '24px !important';
  const width = style==='pinned' ? '37px !important' : '27px !important';
  const bdColor = useColorModeValue("forwardOrange.300","forwardOrange.600");
  

  return (
    <HStack borderLeft="2px solid" borderColor={bdColor} pl={2} pr={style==='pinned' ? 1 : 0} ml={style==='pinned' ? 0 : 1} alignItems="flex-start" spacing={0}>
        <Popover
            isOpen={isOpen} // Controlled visibility
            onClose={onClose} // Handling close
            placement="bottom"
            closeOnBlur={false}
            >
                <PopoverTrigger>
                            <Box 
                    border="1px solid" 
                    borderRadius={0} 
                    borderColor={'forwardBlue.100'}
                    boxShadow={'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'} 
                    height={height} 
                    width={width}
                    cursor="pointer"
                    onClick={isOpen ? onClose : onOpen}
                    ></Box>
                </PopoverTrigger>
            <PopoverContent borderRadius="0px" >
            <PopoverArrow />
                <PopoverBody width="100%" display="flex" justifyContent="center">
                    <VStack width="100%">
              <HStack
                width="100%"
                py={1}
                spacing={2}
                justifyContent="stretch"
              >
                <VStack flex="1">
                <IconButton borderRadius={0} height="40px" width="100%" size="xs" icon={<AiFillCheckCircle />} colorScheme="green" onClick={() => ur.handleAction(reminder,'done')} />
                <Text fontSize="2xs" mt="0px !important">Complete</Text>
                </VStack>
                
                <VStack flex="1">
                <IconButton borderRadius={0} height="40px" width="100%" size="xs" icon={<BsFillSkipForwardFill />} colorScheme="gray" onClick={() => ur.handleAction(reminder,'skip')}/>
                <Text fontSize="2xs" mt="0px !important">Skip</Text>
                </VStack>
                <VStack flex="1">
                    <IconButton 
                    height="40px" width="100%"
                    borderRadius={0}
                    size="xs" 
                    icon={<AiFillPauseCircle />} 
                    colorScheme="forwardOrange" 
                    onClick={toggleDelay}
                    />
                    <Text fontSize="2xs" mt="0px !important">Delay</Text>
                    </VStack>
              </HStack>
              {isDelayOpen && (
                <VStack>
                    <Input variant="flushed" textAlign="center" fontSize="xl" mb={1} placeholder="Number of days" value={numDays} onChange={handleInputChange} />
                    <Button  borderRadius={0} width="100%"  colorScheme="forwardOrange" onClick={handleSubmit}>Delay</Button>
                    </VStack>
                )}
                </VStack>
              </PopoverBody>
              </PopoverContent>

        </Popover>
        <Menu>
  <MenuButton  
    ml={1}
    mr={1}
    width="100%" 
    height={height}
    pl={2} 
    pr={2}
    py={0}
    cursor="pointer"
    mb={1} 
    boxShadow='rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;'
    backgroundColor={bgColor} 
    {...props}
  >
    {/* Wrap the inner content with an HStack to ensure layout properties are applied */}
    <HStack justifyContent="space-between" width="100%" mr={1}>
      <HStack flex="2" width="100%">
        <VStack spacing={0} alignItems="flex-start">
          <HStack>
            <Text fontSize="sm" fontWeight={daysFromNow <= 7 ? 'semibold' : ''}>{reminder.task_name}</Text>
            {reminder.cadence === 12 && <Badge fontSize="xs" colorScheme="green">{rhf.style_anniversary(anniversary_count)}</Badge>}
          </HStack>
          {style==='pinned' && <Text fontSize="2xs" fontStyle="oblique" textAlign="left">{my_life_area.area_name}</Text>}
        </VStack>
      </HStack>
      <HStack>
        <Tooltip label={"on "+dhf.formatDate(eventDate,'MMM dd')} placement="bottom" hasArrow>
          <Text fontSize="xs" fontStyle="oblique" textAlign="right" fontWeight={daysFromNow <= 1 ? 'bold' : ''}>
            {daysFromNow === 0 && 'Today'}
            {daysFromNow === 1 && 'Tomorrow'}
            {daysFromNow === -1 && 'Yesterday'}
            {daysFromNow > 1 && 'in '+daysFromNow+' days'}
            {daysFromNow < -1 && Math.abs(daysFromNow)+' days ago'}
            </Text>
        </Tooltip>
      </HStack>
      <Box>
        <AiFillCaretDown size="12px" />
      </Box>
    </HStack>
  </MenuButton>
  <MenuList>
    <MenuItem onClick={() => editReminder(reminder)}>Edit</MenuItem>
    <MenuItem onClick={() => setIsACOpen(true)}>Archive</MenuItem>
  </MenuList>
</Menu>

                
          <ConfirmDialog
              isOpen={isACOpen}
              onClose={onACClose}
              onConfirm={archiveReminder}
              title="Archive Project"
              description="Are you sure you want to archive (delete) this reminder?"
              confirmButtonText="Archive"
            />
      </HStack>
  );
  
};

export default ReminderTaskRow;
