import React, {useState, useContext} from 'react';
import {
  VStack, Text, HStack, Box, Heading, Button, Flex, useColorModeValue, Menu, MenuList, MenuItem, MenuButton, IconButton, useToast, Link,
} from '@chakra-ui/react';
import { AiOutlineSetting } from 'react-icons/ai';
import { FormContext } from '../../../context/FormContext.jsx';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { useProjects } from '../../../data_hooks/elements/useProjects.jsx';
import ConfirmDialog from '../../general/components/ConfirmDialog.jsx';
import { useNavigate } from 'react-router-dom';
import dhf from '../../../uc_utils/dates/dates.js';

const ProjectHeaderRow = ({ project, ...props }) => {

    const { editProject } = useContext(FormContext);
    const { state } = useContext(ForwardContext);

    /* Main Imports */
    const pr = useProjects();
    const toast = useToast();
    const navigate = useNavigate();
    const my_area = state.areas.find((area) => area.id === project.area_id)

    const area_name = my_area ? my_area.area_name : 'Unknown Area';

    /*Formatting */
    const bgColor = useColorModeValue('forwardWhite.400','forwardBlue.700');
    const bdColor = useColorModeValue('forwardWhite.600','forwardBlue.800');

    /* Confirm Modal Controls */
    const [isACOpen, setIsACOpen] = useState(false);
    const onACClose = () => setIsACOpen(false);


    const handleAreaClick = () => {
      navigate('/forward/area/'+project.area_id)
    }

  
    
  const unpauseProject = async () => {
    pr.upsertProjectProperty(project,'status',1)
  }
  const pauseProject = async () => {
    pr.upsertProjectProperty(project,'status',2)
  }
  const archiveProject = async () => {
    pr.upsertProjectProperty(project, 'status', 3);
    pr.upsertProjectProperty(project, 'order_id',999)
    onACClose();
  };
  
    return (
        <Flex   width="100%" 
                p={2}
                bgColor={bgColor}
                border="1px solid"
                borderColor={bdColor}
                justifyContent="space-between" 
                alignItems="flex-start"
                {...props}
                >
                <VStack alignItems="flex-start" spacing={1}>
                  <HStack>
                  <Text fontSize="sm" fontStyle="oblique">Area:</Text>
                  <Text as={Link} fontSize="sm" fontStyle="oblique" cursor="pointer"  onClick={() => handleAreaClick()}>
                    {area_name}
                  </Text>
                  </HStack>
                    <HStack>
                      <Heading size="md">{project.project_name}</Heading>
                      <Text fontSize="xs" fontStyle="oblique">Start: {dhf.formatDate(new Date(project.start_date),'MMM dd, yyyy')}</Text>
                    </HStack>
                    <Text fontStyle="oblique" size="sm" marginTop="0px !important">{project.description}</Text>
                </VStack>
                <HStack>
                <Menu>
            <MenuButton
              as={IconButton}
              size="lg"
              borderRadius="0px"
              icon={<AiOutlineSetting />}
              aria-label="Edit"
              mt={{'base':0,'md':0}}
              mr={{'base':1,'md':0}}
            />
            <MenuList>
              <MenuItem onClick={() => editProject(project)}>Edit</MenuItem>
              {project.status===2 && <MenuItem onClick={unpauseProject}>Unpause</MenuItem>}
              {project.status===1 && <MenuItem onClick={pauseProject}>Pause</MenuItem>}
              <MenuItem fontSize="md" onClick={() => pr.handleEmbedLink(project,"kanban")}>Get Embed Link</MenuItem>
              <MenuItem onClick={() => setIsACOpen(true)}>Archive</MenuItem>
            </MenuList>
            </Menu>
                </HStack>
                <ConfirmDialog
                  isOpen={isACOpen}
                  onClose={onACClose}
                  onConfirm={archiveProject}
                  title="Archive Project"
                  description="Are you sure you want to archive this project?"
                  confirmButtonText="Archive"
                />
        </Flex>
    )
}

export default ProjectHeaderRow;
