import { supabase } from '../uc_constants/supabaseClient';
import { format } from 'date-fns';

export async function upsertReminder(reminder_data) {
    let { data, error } = await supabase
    .from('forward_reminders')
    .upsert([ reminder_data ], 
            {onConflict: 'id' })
      .select('*');

  if (error) {
      console.error('Error upserting reminder:', error);
  }
  return data;
}

export async function upsertReminderAction(reminder_action_data) {
    let { data, error } = await supabase
    .from('forward_reminder_actions')
    .upsert([ reminder_action_data ], 
            {onConflict: ['id','action','action_date'] })
      .select('*');

  if (error) {
      console.error('Error upserting user:', error);
  }
  return data;
}

export async function readReminders(userId) {
    let { data, error } = await supabase
        .from('forward_reminders')
        .select(`
                    *,
                    forward_reminder_actions (action, action_date),
                    forward_areas (
                        area_name, user_id
                    )
                `)
        .eq('forward_areas.user_id', userId)
        .neq('status','abandoned')
        .neq('status','complete')
        .filter('date_deleted','is','null');

        //console.log(data);

        const remindersByArea = {};

        if(data !== null) {
        data.forEach((reminder) => {
            if (!remindersByArea[reminder.area_id]) {
                remindersByArea[reminder.area_id] = [];
              }
              remindersByArea[reminder.area_id].push(reminder);
        });
        }
    if (error) throw error;
    return remindersByArea;
}
