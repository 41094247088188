import React from 'react';
import { Box, Heading, Divider, Text} from '@chakra-ui/react';

const HabitDetailHeaderRow = ({ headerValue, habit_name }) => {
    return (
        <Box width="100%" py={2}>
            <Heading as="h2" size="md" mb={0}>
                {headerValue}
            </Heading>
            <Text fontSize="xs" fontStyle="oblique">{habit_name}</Text>
            <Divider />
        </Box>
    );
};

export default HabitDetailHeaderRow;
