import React from 'react';
import Select from 'react-select';
import { useColorModeValue } from '@chakra-ui/react';

const ForwardSelect = ({ options, ...props }) => {

  const headerBg = useColorModeValue('var(--chakra-colors-forwardWhite-500)','var(--chakra-colors-forwardBlue-800)');
const selectBg = useColorModeValue('var(--chakra-colors-forwardBlue-100)','var(--chakra-colors-forwardBlue-700)');
const selectFont = useColorModeValue('black','white');
const optionBg = useColorModeValue('var(--chakra-colors-forwardWhite-100)','var(--chakra-colors-forwardBlue-600)');


const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0px', // Set your desired border radius
    border:'0px',
    fontWeight:'bold',
    backgroundColor: optionBg,
  }),
  option: (provided, { data, isDisabled, isFocused, isSelected}) => ({
        ...provided,
        backgroundColor: isSelected ? selectBg : optionBg,
        color: isSelected ? selectFont : provided.color,
        ':active':{
            fontWeight:'bold',
        },
        ':hover':{
            fontWeight:'bold',
            backgroundColor: selectBg,
        },
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: optionBg,
  }),
  singleValue: (provided) => ({
      ...provided,
      color: selectFont,
  }),
  // Style other parts of the dropdown as needed
};


  return (
    <Select 
      styles={customStyles} 
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
        },
      })}
      options={options} 
      {...props} 
    />
  );
};

export default ForwardSelect;