
import React from 'react';
import { HStack, VStack, Heading, Button } from '@chakra-ui/react';
import GoalViewContainerBox from '../boxes/GoalViewContainerBox';


const QuarterlyGoalView = ({ area, goals, session, isMobile, addGoal, liveYear }) => {
    const years= [liveYear];
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

return (
    <HStack width="100%" alignItems={isMobile ? 'stretch' : 'flex-start'} flexDirection={isMobile ? 'column' : 'row'}>
                
                {/****To Organize Tasks ****/}
                <VStack flex="1" alignItems="stretch">
                {
                    ['None',liveYear].map((year) => {
                        let year_goals = goals.filter((goal) => goal.goal_timeframe === (year==='None' ? null : liveYear.toString())).sort((a, b) => a.order_id - b.order_id);

                        return(
                    
                            <VStack flex="1" alignItems="stretch">
                                <HStack justifyContent="space-between" width="100%" borderBottom="1px solid gray">
                            <Heading size="sm">{year === 'None' ? 'Someday' : year+' TBD'}</Heading>
                            <Button size="xs" variant="unstyled" onClick={() => addGoal({'area_id':area.id,'goal_timeframe':year},false,{'name':true,'area':true,'goal_points':true})}>+ Add</Button>
                            </HStack>
                            <GoalViewContainerBox 
                                goals={year_goals} 
                                session={session} 
                                isMobile={isMobile}
                                droppable_id={'goalbox-area-'+area.id+'-year-'+year+'-quarter-'+'None'+'-points-all-progress-all'}
                                showTimeframe={false} />
                            </VStack>)
                })
            }
            </VStack>
                {years.map((year) => {
                    let year_goals = goals.filter((goal) => parseInt(goal.goal_timeframe) === parseInt(year));
                    if (year === 'None') {
                        year_goals = goals.filter((goal) => goal.goal_timeframe === null).sort((a, b) => a.order_id - b.order_id)
                    }
                    return (
                        /*************************/
                        /*Each Year has a VStack:*/
                        /*************************/
                        <>
                            {year === 'None' && 
                            <VStack flex="1" alignItems="stretch">
                                <HStack justifyContent="space-between" width="100%" borderBottom="1px solid gray">
                            <Heading size="sm">{year === 'None' ? 'Someday' : year}</Heading>
                            <Button size="xs" variant="unstyled" onClick={() => addGoal({'area_id':area.id,'goal_timeframe':year},false,{'name':true,'area':true,'goal_points':true})}>+ Add</Button>
                            </HStack>
                            <GoalViewContainerBox 
                                goals={year_goals} 
                                session={session} 
                                isMobile={isMobile}
                                droppable_id={'goalbox-area-'+area.id+'-year-'+year+'-quarter-'+'None'+'-points-all-progress-all'}
                                showTimeframe={false} />
                            </VStack>
                            }
                            {year !== 'None' && quarters.map((quarter) => {
                                let quarter_goals = year_goals.filter((goal) => 'Q'+goal.goal_timeframe.split('Q')[1] === quarter).sort((a, b) => a.order_id - b.order_id);
                                if (quarter === 'None') {
                                    quarter_goals = year_goals.filter((goal) => goal.goal_timeframe.split('Q').length === 1).sort((a, b) => a.order_id - b.order_id);
                                }
                                return (
                                <VStack flex="1" width="100%" alignItems="stretch">
                                <HStack justifyContent="space-between" width="100%" borderBottom='0.5px solid gray'>
                                <Heading size="sm">{quarter === 'None' ? '2024 TBD' : quarter}</Heading>
                                {<Button size="xs" variant="unstyled" onClick={() => addGoal({'area_id':area.id, 'goal_timeframe':year+quarter},false,{'name':true,'area':true,'goal_points':true})}>+ Add</Button>}
                                </HStack>
                                <GoalViewContainerBox 
                                goals={quarter_goals} 
                                session={session} 
                                isMobile={isMobile}
                                droppable_id={'goalbox-area-'+area.id+'-year-'+year+'-quarter-'+quarter+'-points-all-progress-all'}
                                showTimeframe={false} />
                                </VStack>
                                )
                            })
                            }
                        </>
                    )
                })}
                </HStack>

);
            }

export default QuarterlyGoalView;