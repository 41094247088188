import React from 'react';
import { Box, Flex } from "@chakra-ui/react";
import StatBox from '../../general/components/StatBox';

const HabitStatBoxRow = ({return_type = 'list', style, lw_habit_score, tw_habit_score, hit_rate, avg_streak, avg_habit_index, onClickFunctions = {}}) => {

    const lw_color = lw_habit_score.includes('/') ? ((parseInt(lw_habit_score.split('/')[0]) === parseInt(lw_habit_score.split('/')[1]) && parseInt(lw_habit_score.split('/')[0] > 0)) ? 'gold' : 'basic') : 'basic';
    const tw_color = tw_habit_score.includes('/') ? ((parseInt(tw_habit_score.split('/')[0]) === parseInt(tw_habit_score.split('/')[1]) && parseInt(tw_habit_score.split('/')[0] > 0))? 'gold' : 'basic') : 'basic';
    const streak_color = avg_streak >= 5 ? (avg_streak >= 10 ? (avg_streak >= 15 ? (avg_streak >= 20 ? 'diamond' : 'gold') : 'silver') : 'bronze') : 'basic';
    const l30_color = parseFloat(hit_rate) >= 80 ? (parseFloat(hit_rate) >= 90 ? 'gold' : 'silver') : 'basic';
    const hi_color = avg_habit_index >= 120 ? (avg_habit_index >= 150 ? (avg_habit_index >= 200 ? (avg_habit_index >= 250 ? 'diamond' : 'gold') : 'silver') : 'bronze') : 'basic';

    if(return_type==='habit_header') {
        return(
            <Flex px={2} my={1} flexDirection={{'base':'column','md':'row'}} width="100%">
                <Flex px={0} my={1} flexDirection={{'base':'row','md':'row'}} flex="1">
                    <StatBox stat_heading="Last Week" stat_value={lw_habit_score} stat_label="goals hit" flex="1" colorScheme={lw_color} style="basic" mx={1} cursor="pointer" onClick={onClickFunctions['hit_and_miss']}/>
                    <StatBox stat_heading="This Week" stat_value={tw_habit_score} stat_label="on track" flex="1" colorScheme={tw_color} style="basic" mx={1} cursor="pointer" onClick={onClickFunctions['hit_and_miss']}/>
                </Flex>
                <Flex px={0} my={1} flexDirection={{'base':'row','md':'row'}} flex="1">
                    <StatBox stat_heading="L30 Hit Rate" stat_value={hit_rate} stat_label="%" flex="1" colorScheme={l30_color} style="basic" mx={1} cursor="pointer" onClick={onClickFunctions['hit_rate']}/>
                    <StatBox stat_heading="Avg. Streak" stat_value={avg_streak} stat_label=" weeks" style="basic" colorScheme={streak_color} flex="1" mx={1} cursor="pointer" onClick={onClickFunctions['avg_streak']}/>
                    <StatBox stat_heading="Habit Index" stat_value={avg_habit_index} stat_label="" style="basic" colorScheme={hi_color} flex="1" mx={1} cursor="pointer" onClick={onClickFunctions['habit_index']}/>
                </Flex>
            </Flex>
            )
    }

    
    return (
        <>
    <StatBox stat_heading="Last Week" stat_value={lw_habit_score} stat_label="goals hit" flex="1" style={style} colorScheme={lw_color} mx={1} cursor="pointer" onClick={onClickFunctions['hit_and_miss']}/>
    <StatBox stat_heading="This Week" stat_value={tw_habit_score} stat_label="on track" flex="1" style={style} colorScheme={tw_color} mx={1} cursor="pointer" onClick={onClickFunctions['hit_and_miss']}/>
    <StatBox stat_heading="L30 Hit Pct" stat_value={parseFloat(hit_rate).toFixed(1)+"%"} stat_label="%" flex="1" colorScheme={l30_color} style={style} mx={1}  cursor="pointer" onClick={onClickFunctions['hit_rate']}/>
    <StatBox stat_heading="Avg. Streak" stat_value={avg_streak} stat_label=" weeks" style={style} flex="1" colorScheme={streak_color} mx={1} cursor="pointer" onClick={onClickFunctions['avg_streak']}/>
    <StatBox stat_heading="Habit Index" stat_value={avg_habit_index} stat_label="&nbsp;" style={style} colorScheme={hi_color} flex="1" mx={1} cursor="pointer" onClick={onClickFunctions['habit_index']}/>
    </>
    );
};

export default HabitStatBoxRow;