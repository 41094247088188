import { AiFillCheckCircle, AiFillCloseCircle, AiFillMinusCircle, AiFillCiCircle } from 'react-icons/ai';
import { BsFillCircleFill } from 'react-icons/bs'

const getIcon = (value, px_size) => {
    switch (value) {
      case 'abandoned':
        return <AiFillMinusCircle size={px_size} color="var(--chakra-colors-black)" />;
      case 'red':
        return <AiFillMinusCircle size={px_size} color="var(--chakra-colors-red-600)" />;
      case 'orange':
        return <BsFillCircleFill size={px_size} color="var(--chakra-colors-orange-500)" />;
      case 'yellow':
        return <BsFillCircleFill size={px_size} color="var(--chakra-colors-yellow-500)" />;
      case 'green':
        return <BsFillCircleFill size={px_size} color="var(--chakra-colors-green-500)" />;
      case 'complete':
        return <AiFillCheckCircle size={px_size} color="var(--chakra-colors-green-700)" />;
      default:
        return <BsFillCircleFill size={px_size} color="var(--chakra-colors-gray-500)" />;
    }
  };

  const getColor = (value) => {
    switch (value) {
      case 'abandoned':
        return 'black';
      case 'red':
        return 'red.600';
      case 'orange':
        return 'orange.500';
      case 'yellow':
        return 'yellow.500';
      case 'green':
        return 'green.500';
      case 'complete':
        return 'green.700';
      default:
        return 'gray.500';
    }
  }

  export default {
    getIcon,
    getColor,
}