const accountReducer = (account, action) => {
    switch (action.type) {
        case 'SET_ACCOUNT':
            return { account: action.payload };
        case 'FORWARD_SIGN_OUT':
            return { account: {} };
        case 'SET_USER_PROPERTY':
            return { account: {...account, [action.payload['property']]: action.payload['value'] }};


    default:
        return {};

    }

}

export default accountReducer;