
const getReminderColor = (daysFromNow, mode) => {
    // Flatten the reminders arrays into a single array
      let color;
      let hue = mode === 'light' ? "200" : "600";
      if(daysFromNow < 0) {
      color = "forwardOrange";
      }
      else if (daysFromNow === 0) {
        color="forwardOrange"
      }
      else if (daysFromNow <= 7) {
        color="forwardOrange"
      }
      else {
        if (mode === 'light') {
          color="forwardWhite"
          hue = '300'
        }
        else {
          color="forwardBlue";
          hue="500";
        }
      }
  
      return color+"."+hue;
    }


    const getAnniversaryCount = (reminder) => {
      let anniversaryCount = 0;
      let monthCadence = reminder.cadence;
      let startDate = new Date(reminder.starting_due_date);
      let nextDueDate = new Date(reminder.next_due_date);
    
      let monthDiff = (nextDueDate.getFullYear() - startDate.getFullYear()) * 12 + nextDueDate.getMonth() - startDate.getMonth();
    
      anniversaryCount = Math.floor(monthDiff / monthCadence);
    
      // Adjust count if next_due_date is exactly on an anniversary
      if (monthDiff % monthCadence === 0) {
        anniversaryCount += 1;
      }
    
      return anniversaryCount-1;
    };

    const style_anniversary = (anniversary) => {
      // if the number ends in 1, I want it to return the anniversary + 'st'
      if (anniversary === 11) {
        return `${anniversary}th`;
      }
      else if (anniversary % 10 === 1) {
        return `${anniversary}st`;
      }
      else if (anniversary % 10 === 2) {
        return `${anniversary}nd`;
      }
      else if (anniversary % 10 === 3) {
        return `${anniversary}rd`;
      }
      else {
        return `${anniversary}th`;
      }
    }


  export default {
    getReminderColor,
    getAnniversaryCount,
    style_anniversary,
  }

