import React, { useState, useContext } from 'react';
import {
  VStack, Text, Center, Heading, Box, HStack, Button, IconButton, useColorModeValue, Menu, MenuButton, MenuList, MenuItem, useToast, useStyleConfig,
} from '@chakra-ui/react';
import { AiOutlineSetting } from 'react-icons/ai';

import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { FormContext } from '../../../context/FormContext.jsx';

import ohf from '../../../uc_utils/general/objects.js';
import dhf from '../../../uc_utils/dates/dates.js';
import c from '../../../uc_constants/fwd_constants.js';

import { useReminders } from '../../../data_hooks/elements/useReminders.jsx';
import ReminderRow from '../rows/ReminderRow.jsx';

const UpcomingReminders = ({ session, location="home", embed=false, ...props }) => {

  const { state, dispatch } = useContext(ForwardContext);
  const { addReminder } = useContext(FormContext);
  const ur = useReminders();
  const toast = useToast();
  const styles = useStyleConfig('Box', {variant: 'widgetBox'})

  const [remPeriod, setRemPeriod] = useState('31');
  const viewPeriod = remPeriod === '31' ? 31 : 366;
  const notRemPeriod = remPeriod === '31' ? 'Show All':'This Month';

  const bgColor = useColorModeValue('forwardWhite.500','forwardBlue.800');
  const bdColor = useColorModeValue('forwardWhite.700','forwardBlue.900');


  const toggleRemPeriod = () => {
    if (remPeriod === '31') {
      setRemPeriod('All');
    }
    else {
      setRemPeriod('31');
    }
  }

  const handleAction = async (reminder, action, num_days = 0) => {
    //Actions: delayed | done | skip
    console.log('Taking action: ', action);
    const reminder_action_data = {
      'id':reminder.id,
      'action':action,
      'action_date':dhf.formatDate(new Date(),'yyyy-MM-dd'),
    }
    const newAction = await ur.upsertReminderAction(reminder_action_data);
    const old_due_date = new Date(reminder.next_due_date);
    let new_due_date;
    let new_status;
    if (action === 'delayed') {
      new_due_date = dhf.addDaysToDate(old_due_date,parseInt(num_days));
      new_status = 'active';
    }
    else if(reminder.cadence > 0) {
      new_due_date = dhf.addMonthsToDate(old_due_date,parseInt(reminder.cadence));
      new_status = 'active';
    }
    else {
      //One Time event
      new_due_date = old_due_date;
      if(action === 'skip') {
        new_status = 'abandoned';
      }
      else if (action === 'done') {
        new_status = 'complete';
      }

    }
    const reminder_data = {
      'id':reminder.id,
      'area_id':reminder.area_id,
      'next_due_date':new_due_date,
      'status':new_status,
    }

    const newReminder = await ur.upsertFullReminder(reminder_data);

    if(reminder.cadence > 0) {
      dispatch({type:'UPSERT_REMINDER', payload: newReminder})
    }
    else {
      dispatch({type:'REMOVE_REMINDER', payload: newReminder})
    }

  }

  
  const handleEmbedLink = () => {
    const linkText = c.forward_main_url+'/embed/reminders';
      navigator.clipboard.writeText(linkText)
        .then(() => {
          toast({
            title: 'Link copied to clipboard',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        })
        .catch((error) => {
          console.error('Failed to copy link to clipboard:', error);
          toast({
            title: 'Failed to copy link to clipboard',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        });

  }



  const allReminders = ohf.flattenAndSortIndexedObjectByDate(state.reminders,'next_due_date');
  const todayPlusMonth = dhf.addDaysToDate(new Date(),viewPeriod);
  const upcomingReminders = ohf.filterIndexedObjectByDate(allReminders,todayPlusMonth,false);



  /**************************/
  /**************************/
  /* Main body of the element: */
  /**************************/
  /**************************/

  return (
    <>
    <Box 
     width="100%"
     maxWidth="1000px"
    __css={styles}
    {...props}>
    <HStack
    width="100%"
    justifyContent="space-between"
    mb={1} px={0} pt={0} pb={2}>
    <HStack alignItems="center">
      {c.main_icons.reminders}
    <Heading size="sm">Reminders</Heading>
    </HStack>
    <HStack>
    <Button className="forwardButton" colorScheme="forwardOrange" size="sm" fontSize="sm" onClick={() => addReminder()}>+ Reminder</Button>
    <Menu>
          <MenuButton
              as={IconButton}
              size="sm"
              variant="settingButton"
              colorScheme="forwardWhite"
              borderRadius="0px"
              icon={<AiOutlineSetting />}
              aria-label="Edit"
              mt={{'base':0,'md':0}}
              mr={{'base':1,'md':0}}
            />
          <MenuList>
            {!embed && <MenuItem onClick={handleEmbedLink}>Get Embed Link</MenuItem>}
            <MenuItem onClick={() => toggleRemPeriod()}>{notRemPeriod}</MenuItem>
          </MenuList>

          </Menu>
    </HStack>
    </HStack>

    <VStack w="100%" maxWidth="1100px">
      <Center w="100%">
        <VStack spacing={0} w="100%" maxWidth="1100px" minWidth="300px">
        {upcomingReminders.map((reminder) => (
          <ReminderRow key={reminder.id} 
                        reminder={reminder} 
                        width="100%" 
                        handleAction={handleAction}
                        />
        ))}
        </VStack>
      </Center>
    </VStack>
    </Box>
    </>
  );
}

export default UpcomingReminders;
