import React, {useState} from 'react';
import {
  HStack,
  Text,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';

const TaskListSelector = ({ value, onValueChange, isDisabled, size, ...props }) => {

  const px_size = size || 'md';
  const [selected, setSelected] = useState(value);
  const btnColors = useColorModeValue('forwardWhite','forwardBlue');
  const brdColor = useColorModeValue('forwardWhite.800','forwardBlue.800');
  const txtColor = useColorModeValue('black','white');
  const selectedColor = useColorModeValue('forwardBlue','forwardGold');

    const handleChange = (newValue) => {
        setSelected(newValue);
        onValueChange(newValue);
    };
    

    return (
        <HStack
                spacing={0}>

            <Button 
            size={px_size}
            borderRadius={0}
            textColor={txtColor}
            borderRight="1px"
            borderColor={brdColor}
            onClick={() => handleChange('backlog')}
            colorScheme = {selected ==='backlog' 
                            ? selectedColor
                            : btnColors}
            >Backlog</Button>
            <Button 
            size={px_size}
            borderRadius={0}
            textColor={txtColor}
            borderRight="1px"
            borderColor={brdColor}
            onClick={() => handleChange('priority')}
            colorScheme = {selected ==='priority' 
                            ? selectedColor
                            : btnColors}
            >Priority</Button>
            <Button 
            size={px_size}
            borderRadius={0}
            textColor={txtColor}
            borderRight="1px"
            borderColor={brdColor}
            onClick={() => handleChange('toschedule')}
            colorScheme = {selected ==='toschedule' 
                            ? selectedColor
                            : btnColors}
            >To Schedule</Button>
            <Button 
            size={px_size}
            borderRadius={0}
            textColor={txtColor}
            borderRight="1px"
            borderColor={brdColor}
            onClick={() => handleChange('next_week')}
            colorScheme = {selected ==='next_week' 
                            ? selectedColor
                            : btnColors}
            >Next Week</Button>
            <Button 
            size={px_size}
            borderRadius={0}
            textColor={txtColor}
            onClick={() => handleChange('this_week')}
            colorScheme = {selected ==='this_week' 
                            ? selectedColor
                            : btnColors}
            >This Week</Button>

            

            </HStack>
    );
  };
  export default TaskListSelector;


