import React, { createContext, useContext } from 'react';
import { useCreateForm } from '../data_hooks/create_elements/useCreateForm';
import FormModal from '../fwd_ui/general/forms/FormModal'
// Create Context
export const FormContext = createContext();

// Provider Component
export const FormProvider = ({ session, children }) => {
  const formControls = useCreateForm();

  return (
    <FormContext.Provider value={formControls}>
      {children}
      <FormModal
        isOpen={formControls.isModalOpen}
        onClose={formControls.closeModal}
        initialForm={formControls.currentForm}
        showOptions={formControls.showOptions}
        session={session}
        optionsToShow={formControls.optionsToShow}
        editorData={formControls.editorData}
      />
    </FormContext.Provider>
  );
};
