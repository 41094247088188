import React, { useState, useContext } from 'react';
import { Box, HStack, VStack, Button, Text, useColorModeValue, Menu, MenuButton, IconButton,MenuList, MenuItem, Badge } from '@chakra-ui/react';
import { BiMenu } from 'react-icons/bi';
import { AiOutlineSetting } from 'react-icons/ai';

import { ForwardContext } from '../../../context/ForwardContext';
import { FormContext } from '../../../context/FormContext';
import { trackCustomEvent } from '../../../general/functions/plausibleEvent';

import { useGoals } from '../../../data_hooks/elements/useGoals';
import { useIsMobile } from '../../../data_hooks/general/useIsMobile';

import GoalProgressFastSelect from '../components/GoalProgressFastSelect';
import ConfirmDialog from '../../general/components/ConfirmDialog';

const GoalRow = ({ goal, user_id, view="featured", draggable=false, dragHandle=null, showProject=true, showTimeframe=true}) => {
  const {state, dispatch} = useContext(ForwardContext);
  const { editGoal } = useContext(FormContext);
  const isMobile = useIsMobile();
  const gl = useGoals();

  const [isACOpen, setIsACOpen] = useState(false);
  const onACClose = () => setIsACOpen(false);

  const project = Object.values(state.projects).flat().filter((project) => project.id === goal.project_id);
  let project_name = '';
  if (project.length > 0) {
    project_name = project[0].project_name;
  }

  const archiveGoal = async () => {
    gl.upsertGoalProperty(goal, 'status', 3);
    gl.upsertGoalProperty(goal, 'order_id', 999);
    onACClose();
  };

  const handleProgressUpdate = async (goal_id, new_progress) => {
    //This function will update state and update the database once a value is changed
    const goal_data = {'id':goal_id, 'goal_progress':new_progress, 'user_id':user_id}
    //console.log('here');
    const result = gl.upsertFullGoal(goal_data);
    if (result.result === 'success') {
      console.log('Goal updated!');
      trackCustomEvent('FWD Goal Progress Updated', {goal_name: goal.goal_name, goal_progress: new_progress});
    } else  {
      console.log('Error updating goal');
    }
  };

  const bgColor = useColorModeValue('forwardWhite.300','forwardBlue.500');
  const completedColor = useColorModeValue('green.100','green.600');
  const bdColor = useColorModeValue("forwardWhite.600",'forwardBlue.700');

  let badgeColorScheme = 'gray';
  if (goal.goal_points >=5 ) {
    badgeColorScheme = 'forwardGold';
  }
  else if (goal.goal_points >=3 ) {
    badgeColorScheme = 'forwardBlue';
  }

  if (view==='projects') {
    return (
      <HStack py={0} alignItems="center">
          <GoalProgressFastSelect 
                  goal_id={goal.id} 
                  goal_points={goal.goal_points}
                  value={goal.goal_progress} 
                  onValueChange={handleProgressUpdate} 
                  isDisabled={false} 
                  size={isMobile ? '16px':'25px'} />
      <Text flex="1" fontSize={isMobile ? 'xs':'sm'} key={goal.id}>{goal.goal_name}</Text>
      {showTimeframe && <Badge borderRadius={0} colorScheme={"gray"} fontSize="2xs" marginTop="0px !important">{goal.goal_timeframe}</Badge>}
      <Menu>
          <MenuButton
              as={IconButton}
              size="xs"
              variant="unstyled"
              borderRadius="0px"
              icon={<AiOutlineSetting />}
              aria-label="Edit"
              mt={{'base':0,'md':0}}
              ml={{'base':1,'md':0}}
            />
          <MenuList>
            <MenuItem onClick={() => editGoal(goal)}>Edit</MenuItem>
            <MenuItem onClick={() => editGoal(goal,false,{'name':true,'goal_points':true})}>Set Points</MenuItem>
            <MenuItem onClick={() => setIsACOpen(true)}>Archive</MenuItem>

          </MenuList>
          </Menu>
          <ConfirmDialog
              isOpen={isACOpen}
              onClose={onACClose}
              onConfirm={archiveGoal}
              title="Archive Project"
              description="Are you sure you want to archive (delete) this goal?"
              confirmButtonText="Archive"
            />
      </HStack>);
  }



  return (
    <Box width="100%"
    boxShadow='rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'
    my={1}
      py={0}>
        <HStack width="100%" 
                backgroundColor={goal.goal_progress === 'complete' ? completedColor : bgColor} 
                justifyContent="space-between"
                borderBottom="0px" 
                alignItems="center"
                borderColor={bdColor}
                spacing={{'base':0,'md':2}} 
                pl={1} pr={0} py={1}>
                  {draggable && <IconButton
        size={{'base':"xs",'md':"sm"}}
        marginTop="0px"
        px={0}
        variant="unstyled"
        borderRadius="0px"
        icon={<BiMenu />}
        aria-label="Move goal"
        {...dragHandle}
      />}
        <HStack flex="10" alignItems="center">
          
        <VStack alignItems="flex-start" spacing={1}>
          <HStack>
        <Text fontSize={isMobile ? 'xs':'sm'}>{goal.goal_name}</Text>
        {/*<Badge borderRadius={0} colorScheme={badgeColorScheme} fontSize="2xs" marginTop="0px !important">{goal.goal_points} pts</Badge>*/}
        </HStack>
        <HStack>
        {showTimeframe && <Badge borderRadius={0} colorScheme={"gray"} fontSize="2xs" marginTop="0px !important">{goal.goal_timeframe}</Badge>}
        {(!isMobile && goal.project_id && showProject) && <Badge  borderRadius={0} fontSize="2xs" marginTop="0px !important">{project_name}</Badge>}
        
        </HStack>
        </VStack>
        </HStack>
        <Box>
        <GoalProgressFastSelect 
                  goal_id={goal.id} 
                  goal_points={goal.goal_points}
                  value={goal.goal_progress} 
                  onValueChange={handleProgressUpdate} 
                  size={isMobile ? '25px':'36px'}
                  isDisabled={false} />
        </Box>
        <Menu>
          <MenuButton
              as={IconButton}
              size="xs"
              variant="unstyled"
              borderRadius="0px"
              icon={<AiOutlineSetting />}
              aria-label="Edit"
              mt={{'base':0,'md':0}}
              ml={{'base':1,'md':0}}
            />
          <MenuList>
            <MenuItem onClick={() => editGoal(goal)}>Edit</MenuItem>
            <MenuItem onClick={() => editGoal(goal,false,{'name':true,'goal_points':true})}>Set Points</MenuItem>
            <MenuItem onClick={() => setIsACOpen(true)}>Archive</MenuItem>

          </MenuList>
          </Menu>
          <ConfirmDialog
              isOpen={isACOpen}
              onClose={onACClose}
              onConfirm={archiveGoal}
              title="Archive Project"
              description="Are you sure you want to archive (delete) this goal?"
              confirmButtonText="Archive"
            />
        </HStack>
    </Box>
  );
};

export default GoalRow;
