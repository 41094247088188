

  /***************************/
  /***************************/
  /* Function to reorder tasks within a list */
  /***************************/
  /***************************/
  const reorder = (list, startIndex, endIndex, session_id) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    // Add visible_order_id to each task in the reordered list
    result.forEach((task, index) => {
        task.order_id = index;
        //We are tracking the last session_id because of how we handle real-time updates to prevent "flashes"
        task.last_session_id = session_id;
    });

    return result;
};

const pin_reorder = (list, startIndex, endIndex, session_id) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // Add visible_order_id to each task in the reordered list
    result.forEach((task, index) => {
        task.pin_order_id = index;
        task.last_session_id = session_id;
    });

    return result;
};
  /***************************/
  /***************************/
  /* Function to move tasks to a new list */
  /***************************/
  /***************************/
const move = (source, destination, droppableSource, droppableDestination) => {
    //console.log(source);
    //console.log(destination);
    //console.log(droppableSource);
    //console.log(droppableDestination);
    const sourceClone = Array.from(source || []);
    const destClone = Array.from(destination || []);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    
    destClone.splice(droppableDestination.index, 0, removed);
    
    sourceClone.forEach((task, index) => {
        task.order_id = index;
    });
    
    destClone.forEach((task, index) => {
        task.order_id = index;
    });
    
    const result = {};
    result[droppableSource.droppableId.split('-')[2]] = {};
    result[droppableDestination.droppableId.split('-')[2]] = {};
    result[droppableSource.droppableId.split('-')[2]][droppableSource.droppableId.split('-')[3]] = sourceClone;
    result[droppableDestination.droppableId.split('-')[2]][droppableDestination.droppableId.split('-')[3]] = destClone;
    
    return result;
};

export default {
    move,
    reorder,
    pin_reorder,
}