import { AiOutlineReload, AiOutlineSetting } from "react-icons/ai";
import { FaCheckSquare, FaClock, FaFileExport, FaFlagCheckered, FaHome, FaSort, FaRegCalendarCheck, FaShareAlt } from "react-icons/fa";
import { GoPin } from "react-icons/go";
import { MdAddCircle, MdGroup } from "react-icons/md";
import { GiCycle } from "react-icons/gi";

const lifetime_members = [
    'kemumma@gmail.com',
    'aemumma@gmail.com',
    'anhvu5@gmail.com',
    'jpcalabrese@gmail.com',
    'peter@liddle.network',
    'bgpujanauski@gmail.com',
    'afidler@gmail.com',
    'wang.rachelr@gmail.com',
    'mummala321@gmail.com',
    'smumma@webfx.com',
    'mummala321@gmail.com',
    'matthew.edward.dickson@gmail.com',
    'mcsickel10@gmail.com',
    'agamid@gmail.com',
    'aolson425@gmail.com',
    'mhhrbb@gmail.com',
    'sarahleclaire@gmail.com',
    'jennaswiren@gmail.com',
    'feikai981@gmail.com',
    'viv.rad@gmail.com',
    'bam@fastmail.com',
    'bamgolf1173@gmail.com',
    'abisesh@gmail.com',
    'frank480@ymail.com',
    'justyna.ilczuk@gmail.com',
    'corporatecowboy@mac.com',
    'guoty3310@gmail.com',
    'tsash@windsorthomas.com',
    'keithsnyder@charter.net',
    'lfyy.vanderzande@gmail.com',
    'melissabond.writer@gmail.com',
    'hweaver@sswce.com',
    'thefrankharvey@gmail.com',
    'campbellsaxophone@gmail.com',
    'hppytwc@gmail.com',
    'zachariahsalazar@gmail.com',
    'rodineyfernandes2022@gmail.com',
    'cromartied@gmail.com',
    ]

const discord_url = 'https://discord.gg/TKt2kWYjg6';
const forward_main_url = 'https://www.theforwardapp.com/forward';

const task_options_to_show = {
    'name':true,
    'project':true,
    'task_list_selector':true,
    'pinned':true,
    'recur_weeks':false,
}

const event_options_to_show = {
    'name':true,
    'task_category':true,
    'is_all_day':true,
    'start_time':true,
    'end_time':true,

}

const free_limits = {
    'habits':5,
    'projects':5,
    'areas':5,
    'goals':5,
    'new_goals':10,
    'events':100,
    'reminders':12,
    'tasks':50,
    'stat_history':90,
    'shared_areas':3,
}

const hi_baseline_period = 4;
const hi_lt_multiple = 1.01;
const hi_lt_beat_add = 1;
const hi_lt_miss_sub = -0.5;
const hi_beat_measure = 95;

const main_icons = {
    'home':<FaHome />,
    'pin':<GoPin />,
    'settings':<AiOutlineSetting />,
    'habits':<FaRegCalendarCheck />,
    'tasks':<FaCheckSquare />,
    'accomplishments':<FaCheckSquare />,
    'goals':<FaFlagCheckered />,
    'reminders':<FaClock />,
    'recurrence':<GiCycle />,
    'organize':<FaSort />,
    'create':<MdAddCircle />,
    'share':<FaShareAlt />,
    'shared':<MdGroup />,
    'refresh':<AiOutlineReload />,
    'export':<FaFileExport />,

}

export default {
    lifetime_members,
    discord_url,
    forward_main_url,
    task_options_to_show,
    hi_baseline_period,
    hi_lt_multiple,
    hi_lt_beat_add,
    hi_lt_miss_sub,
    hi_beat_measure,
    free_limits,
    main_icons,
    event_options_to_show
  }