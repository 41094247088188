import React, {useContext, useMemo, useRef, useEffect, useState, useCallback, Suspense, lazy} from 'react';
import { VStack, HStack, useColorModeValue, Box, useMediaQuery, Button} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';

import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { FormContext } from '../../../context/FormContext.jsx';
import { useChartModal } from '../../../context/ChartModalContext.jsx';

import charts from '../../../uc_utils/charting/charts.js'
import habit_calc from '../../../calc/habits.js';
import hh from '../../../uc_utils/habits/habits.js';
import cgl from '../../../uc_utils/charting/general_layout.js';
import cgd from '../../../uc_utils/charting/general_data.js';

import HabitHeaderRow from '../rows/HabitHeaderRow.jsx';
import HabitCalendar from './HabitCalendar.jsx';
import HabitStatTable from './HabitStatTable.jsx';
import HabitMedal from '../components/HabitMedal.jsx';

import HabitWeeklyTable from './HabitWeeklyTable.jsx';
import HabitDetailHeaderRow from '../rows/HabitDetailHeaderRow.jsx';



const PlotlyChart = lazy(() => import('../../charts/PlotlyChart.jsx')); // Adjust the path as necessary


const SingleHabitView = ({ session, create_form_controls, view="habit", ...props }) => {
    const {state, dispatch} = useContext(ForwardContext);
    const { editHabit } = useContext(FormContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const chartBg = useColorModeValue('var(--chakra-colors-forwardWhite-300)','var(--chakra-colors-forwardBlue-700)');
    const chartColorMode = useColorModeValue('light','dark')
    const [habitStats, setHabitStats] = useState([]);
    const [currentMedal, setCurrentMedal] = useState({'name':'none','streak':0,'color':'none'});
    const [bestMedal, setBestMedal] = useState({'name':'none','streak':0,'color':'none'});
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const [chartData, setChartData] = useState(null);
    const [hiChartData, setHiChartData] = useState(null);
    const { showModal } = useChartModal();


    useEffect(() => {
      dispatch({ type: 'SET_CURRENT_VIEW', payload: 'Habit Detail' });
    }, []);

    const handleChangeHabit = (new_habit) => {
        const new_route = '/forward/habit/'+new_habit;
        navigate(new_route);
    };

    const handleGraphGeneration = (habit, color_to_use, isMobile) => {
      if (habit.values && Object.keys(habit.values).length > 0) {
        return charts.generateChartData(habit, color_to_use, isMobile);
      } else {
        console.log('habit.values is empty');
        return null;
      }
  };

  const handleHabitIndexGraphGeneration = (habit, habitStats, color_to_use, isMobile) => {
    if (habit.values && Object.keys(habit.values).length > 0 && habitStats && Object.keys(habitStats).length > 0) {
      return charts.generateHabitIndexData(habit, habitStats, color_to_use, isMobile);
    } else {
      console.log('habit.values is empty');
      return null;
    }
  }

      const my_habit = useMemo(() => {
        if (view === 'habit' && state.habits) {
            return Object.values(state.habits).flat().find(habit => parseInt(habit.id) === parseInt(id)) || {};
        }
        return {};
    }, [state.habits, view, id]);

    const my_area = useMemo(() => {
        if (my_habit && my_habit.area_id) {
            return state.areas.find((area) => area.id === my_habit.area_id) || {};
        } else { return {} }});

    const isHabitLoaded = my_habit && Object.keys(my_habit).length > 0;
    const isStatsLoaded = habitStats && Object.keys(habitStats).length > 0;

    const showMonthlyChart = () => {
        console.log('here');
        let data = []
        data.push(cgd.generateSingleDataSeries({
            dataObject: my_habit.values, 
            seriesName:'Performance',
            seriesDisplayMode:'bar', 
            calcMode:'aggregated', 
            calcSettings:{aggPeriod:'monthly',aggMethod:'sum'},}))
        data.push(cgd.generateSingleDataSeries({
            dataObject: my_habit.values, 
            seriesName:'Performance',
            seriesDisplayMode:'line', 
            calcMode:'aggregated', 
            calcSettings:{aggPeriod:'monthly',aggMethod:'sum',calcMovingAvg:true,maLength:4},}))
        const layout = cgl.generateGeneralLayout({
            colorMode:chartColorMode, 
            isMobile:isMobile, 
            xAxisLabel:'Month', 
            yAxisLabel:'Values', 
            barMode:'stack'})
        showModal(my_habit.habit_name, data, layout);
    }


    const updateStatsAndMedals = useCallback(() => {
        let stats = state.stats['habit_'+id] || habit_calc.calcHabits(my_habit,'all',null);
        setHabitStats(stats);
        setCurrentMedal(hh.newStreakMedal(stats.current_stats.streak));
        setBestMedal(hh.newStreakMedal(stats.current_stats.best_streak));
    }, [my_habit, id, state.stats]);

    useEffect(() => {
        if (my_habit && Object.keys(my_habit).length > 0) {
            updateStatsAndMedals();
        }
    }, [my_habit, updateStatsAndMedals]);

      useEffect(() => {
        handleGraphGeneration(my_habit, chartColorMode, isMobile);
        handleHabitIndexGraphGeneration(my_habit, habitStats, chartColorMode, isMobile)
    }, [my_habit, chartColorMode, habitStats]);

    useEffect(() => {
      if (isHabitLoaded) {
          const generatedChartData = handleGraphGeneration(my_habit, chartColorMode, isMobile);
          setChartData(generatedChartData);
      }
  }, [my_habit, chartColorMode, isMobile, isHabitLoaded]);

  useEffect(() => {
    if (isHabitLoaded) {
        const generatedChartData = handleHabitIndexGraphGeneration(my_habit, habitStats, chartColorMode, isMobile);
        setHiChartData(generatedChartData);
    }
}, [my_habit, chartColorMode, habitStats, isMobile, isHabitLoaded]);


    return (
        <VStack maxWidth="1000px" minWidth="80%" width="100%" spacing={4}>
        <HabitHeaderRow habit={my_habit} handleChangeHabit={handleChangeHabit} editHabitOpen={editHabit} area={my_area} />
        <HabitDetailHeaderRow headerValue={"Streaks"} habit_name={my_habit.habit_name} />
        <HStack width="100%" alignItems="stretch">
        
        <HabitMedal medalImage={'/forward/medals/'+currentMedal.name+'.png'} 
                        text1='Current Streak: ' 
                        text2={(habitStats?.current_stats?.streak || 0)+" weeks"} 
                        text3={currentMedal.name.charAt(0).toUpperCase() + currentMedal.name.slice(1)+(currentMedal.name !== 'none' ? ' Medal':'')} flex="1"
                        borderColor={currentMedal.color}/>
        <HabitMedal medalImage={'/forward/medals/'+bestMedal.name+'.png'} 
                        text1='Best Streak: ' 
                        text2={(habitStats?.current_stats?.best_streak || 0)+" weeks"} 
                        text3={bestMedal.name.charAt(0).toUpperCase() + bestMedal.name.slice(1)+(bestMedal.name !== 'none' ? ' Medal':'')} flex="1" 
                        borderColor={bestMedal.color}/>
        </HStack>
        <HStack width="100%">
        <HabitDetailHeaderRow headerValue={"Weekly Performance"} habit_name={my_habit.habit_name} />
        <Button borderRadius={0} onClick={() => showMonthlyChart()} colorScheme="forwardOrange" size="sm">Monthly</Button>
        </HStack>
        <Suspense fallback={<div>Loading Chart...</div>}>
    {chartData && (
        <Box width="100%" maxWidth="90vw" minHeight="400px" backgroundColor={chartBg} overflowX="auto">
            <PlotlyChart data={chartData.data} layout={chartData.layout} />
        </Box>
    )}
</Suspense>
  {isHabitLoaded ? (
    <>
        <HabitDetailHeaderRow headerValue={"Daily Calendar"} habit_name={my_habit.habit_name} />
        <HabitCalendar habit={my_habit} />
        <HabitDetailHeaderRow headerValue={"Habit Index"} habit_name={my_habit.habit_name} />
        <Suspense fallback={<div>Loading Chart...</div>}>
        {hiChartData && (
            <Box width="100%" maxWidth="90vw" minHeight="400px" backgroundColor={chartBg} overflowX="auto">
                <PlotlyChart data={hiChartData.data} layout={hiChartData.layout} />
            </Box>
        )}
    </Suspense>
        <HabitDetailHeaderRow headerValue={"Performance Stats"} habit_name={my_habit.habit_name} />
        {isStatsLoaded && <><HabitStatTable habit={my_habit} habit_stats={habitStats} />
        <HabitDetailHeaderRow headerValue={"Raw Weekly Data"} habit_name={my_habit.habit_name} />
        <HabitWeeklyTable habit={my_habit} habit_stats={habitStats} /></>}
        </> ): ''}
        </VStack>
    );
};

export default SingleHabitView;
