const projectReducer = (projects, action) => {
    switch (action.type) {
      /*********************************************/
      /*********************************************/
      /* Projects */
      /*********************************************/
      /*********************************************/
      case 'SET_PROJECTS':
        return {
          projects: action.payload,
        };
        case 'UPSERT_PROJECT': {
          let updatedProject = Array.isArray(action.payload) ? action.payload[0] : action.payload;
          const projectId = updatedProject.id;
          const newAreaId = updatedProject.area_id;
        
          // Find the old area ID if it exists
          let oldAreaId = null;
          for (let areaId in projects) {
            if (projects[areaId].find(project => project.id === projectId)) {
              oldAreaId = areaId;
              break;
            }
          }
        
          // Construct new projects object
          let newProjects = {...projects};
        
          // Remove project from old area if it was found
          if (oldAreaId) {
            newProjects[oldAreaId] = newProjects[oldAreaId].filter(project => project.id !== projectId);
          }
        
          // Add or update project in new area
          newProjects[newAreaId] = newProjects[newAreaId] ? [...newProjects[newAreaId]] : [];
          const projectIndex = newProjects[newAreaId].findIndex(project => project.id === projectId);
          if (projectIndex !== -1) {
            // Update existing project
            newProjects[newAreaId][projectIndex] = updatedProject;
          } else {
            // Add new project
            newProjects[newAreaId].push(updatedProject);
          }
        
          // Sort projects in new area by order_id
          newProjects[newAreaId].sort((a, b) => a.order_id - b.order_id);
        
          return { projects: newProjects };
        }
        
        case 'UPDATE_PROJECT_VALUE': {
          const { projectId, key, value } = action.payload;
        
          // Find the area ID that contains the project
          let areaId = null;
          for (let id in projects) {
            if (projects[id].find(project => project.id === projectId)) {
              areaId = id;
              break;
            }
          }
        
          // Return unchanged state if project not found
          if (!areaId) return {projects:projects};
      
          // Construct new projects object
          let newProjects = {...projects};
      
          // Update the value for the specific project
          newProjects[areaId] = newProjects[areaId].map(project => 
            project.id === projectId 
              ? { ...project, [key]: value }
              : project
          );

          //console.log(newProjects);

          return { projects: newProjects };
      }
        
        case 'REMOVE_PROJECT': {
          const projectId = action.payload.projectId;
        const areaId = action.payload.areaId;
      
        return {
          projects: {
            ...projects,
            [areaId]: projects[areaId].filter(project => project.id !== projectId),
          },
        };
        }

        default:
            return {};

 
    }
};
  export default projectReducer;
  