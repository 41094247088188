
import ohf from '../uc_utils/general/objects';

const reminderReducer = (reminders, action) => {
    switch (action.type) {
      /*********************************************/
      /*********************************************/
      /* Reminders */
      /*********************************************/
      /*********************************************/
      case 'SET_REMINDERS':
        return {
          reminders: action.payload,
        };
        case 'UPSERT_REMINDER': {
          const reminder_data = ohf.ensureArray(action.payload);
          
          const updatedReminder = reminder_data[0];

          const reminderId = updatedReminder.id;
          const newAreaId = updatedReminder.area_id;
        
          // Find the old area ID if it exists
          let oldAreaId = null;
          for (let areaId in reminders) {
            if (reminders[areaId].find(reminder => reminder.id === reminderId)) {
              oldAreaId = areaId;
              break;
            }
          }
        
          // Construct new projects object
          let newReminders = {...reminders};
        
          // Remove project from old area if it was found
          if (oldAreaId) {
            newReminders[oldAreaId] = newReminders[oldAreaId].filter(reminder => reminder.id !== reminderId);
          }
        
          // Add project to new area
          newReminders[newAreaId] = newReminders[newAreaId]
            ? [...newReminders[newAreaId], updatedReminder]
            : [updatedReminder];
        
          return { reminders: newReminders };
        }
        
        case 'REMOVE_REMINDER': {
          console.log(action.payload);
          const reminder_data = ohf.ensureArray(action.payload);
          
          const reminderIdToRemove = reminder_data[0].id;
        
          // Find the area ID where the reminder resides
          let areaIdToRemove = null;
          for (let areaId in reminders) {
            if (reminders[areaId].find(reminder => reminder.id === reminderIdToRemove)) {
              areaIdToRemove = areaId;
              break;
            }
          }
        
          // If no matching area ID is found, just return the existing reminders
          if (areaIdToRemove === null) {
            return { reminders };
          }
        
          // Construct new reminders object without the removed reminder
          let newReminders = { ...reminders };
          newReminders[areaIdToRemove] = newReminders[areaIdToRemove].filter(
            reminder => reminder.id !== reminderIdToRemove
          );
        
          return { reminders: newReminders };
        }
        
      

        default:
            return {};

 
    }
};
  export default reminderReducer;
  