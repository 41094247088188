import React from 'react';
import { Box, HStack, Text, useMediaQuery } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const NavBarButton = ({ icon, text, to, isEnd = false, isLive = false}) => {
    const [isMobile] = useMediaQuery("(max-width: 768px)");

    return (
        <Box 
            flex="1" 
            py={2} 
            textColor="white" 
            bg="forwardBlue.700" 
            as={RouterLink} 
            to={to} 
            borderRight={isEnd ? "0px" : "2px solid var(--chakra-colors-forwardBlue-500)"}
            borderBottom={isLive ? "3px solid var(--chakra-colors-forwardGold-500)" : "0px"}
            _hover={{ bg: 'forwardBlue.400', fontWeight:'bold'}} // Change background color on hover
        >
            <HStack width="100%" justifyContent="center">
                {icon}
                <Text fontSize={isMobile ? 'sm' : 'md'}>{text}</Text>
            </HStack>
        </Box>
    );
};

export default NavBarButton;
