import { Td, Text, HStack, } from "@chakra-ui/react";

const HstStatCell = ({ stat, label }) => {
    return (
        <Td>
            <HStack alignItems="baseline">
                <Text fontSize="lg">{stat}</Text>
                <Text fontSize="2xs">{label}</Text>  
            </HStack>
        </Td>
    );
};

export default HstStatCell;
