import React, { useState, useContext, useEffect} from 'react';
import {
  Box,
  Flex,
  Spacer,
  Text,
  VStack,
  Center,
  useDisclosure,
  useMediaQuery,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
import { FaAngleRight} from 'react-icons/fa';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import NavBarButton from './NavBarButton.jsx';
import NavBarDropdown from './NavBarDropdown.jsx';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { FormContext } from '../../../context/FormContext.jsx';
import ManageModal from '../../../fwd_ui/general/views/ManageModal.jsx';
import dhf from '../../../uc_utils/dates/dates.js'
import NewProjectDropdown from '../../../fwd_ui/projects/components/NewProjectDropdown.jsx';
import NewAreaDropdown from '../../../fwd_ui/areas/components/NewAreaDropdown.jsx';
import HabitDropdown from '../../../fwd_ui/habits/components/HabitDropdown.jsx';
import c from '../../../uc_constants/fwd_constants.js'
import TimeNavComponent from './TimeNavComponent.jsx';
import TaskViewDropdown from './TaskViewDropdown.jsx';

import { useAreaSharing } from '../../../data_hooks/sharing/useAreaSharing.jsx';
import ShareRequestsModal from '../../../fwd_ui/sharing/views/ShareRequestsModal.jsx';
import GoalViewDropdown from './GoalViewDropdown.jsx';
import AddButton from '../../../fwd_ui/general/components/AddButton.jsx';

const AppNavBar = ({session, handleRefreshClick}) => {
  const { state, dispatch } = useContext(ForwardContext);
  const { openForm } = useContext(FormContext);

  const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure();
  const [shares, setShares] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const uas = useAreaSharing();

  const share_notifications = state.shares.filter(share => share.share_status === 9).length;
  const active_shares = state?.shares?.filter(share => share.share_status === 1).length;

  const detail_id = location.pathname.split('/')[3]
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const my_views = {'kanban':'Kanban View','thisWeek':'This Week','project-specific':'Project Specific View','pinned':'Pinned Tasks','nextWeek':'Next Week','backlog':'Kanban View','project':'Project View'};
  const goal_views = {'yearly':'Yearly Planner','quarterly':'Quarterly Planner'}
  const [selectedProjectView, setSelectedProjectView] = useState({'value':state.ui.projectView,'label':my_views[state.ui.projectView]});
  const [selectedGoalView, setSelectedGoalView] = useState({'value':state.ui.goalView,'label':goal_views[state.ui.goalView]});

  const navigateToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    navigateToTop();
    if(location.pathname.includes('/tasks') && selectedProjectView.value === 'project-specific') {
      setSelectedProjectView({'value':'kanban','label':'Kanban View'});
      dispatch({ type: 'SET_PROJECT_VIEW', payload: 'kanban' });
    }
  }, [location.pathname, selectedProjectView]);


  /**********************************/
  /**********************************/
  /* Controls for Add Modal         */
  /**********************************/
  /**********************************/
  const is_readOnly = (state.ui.readOnly);

  /**********************************/
  /**********************************/
  /* Controls for Manage Modal      */
  /**********************************/
  /**********************************/
  const { isOpen: isMMOpen, onOpen: onMMOpen, onClose: onMMClose } = useDisclosure();

  const onAnalyze = () => {
    console.log('Analyze');
  }

  /**********************************/
  /**********************************/
  /* Task View Toggles              */
  /**********************************/
  /**********************************/
  const handleViewChange = (selectedOption) => {
    console.log(selectedOption);
    if(selectedOption.value === 'project-specific') {
      const fast_id = state?.projects[state?.areas[0]?.id][0]?.id || 0;

      if (fast_id > 0) {
      navigate('/forward/project/' + state?.projects[state?.areas[0]?.id][0]?.id)
      }
    }
    setSelectedProjectView({value: selectedOption.value, label: selectedOption.label});
    dispatch({ type: 'SET_PROJECT_VIEW', payload: selectedOption.value });
};

const handleGoalViewChange = (selectedOption) => {
  console.log(selectedOption);
  setSelectedGoalView({value: selectedOption.value, label: selectedOption.label});
  dispatch({ type: 'SET_GOAL_VIEW', payload: selectedOption.value });
};


  const handleProjectJump = (newValue) => {
    navigate('/forward/project/' + newValue)
  }

  const handleHabitJump = (newValue) => {
    navigate('/forward/habit/'+newValue)
  }
  const handleAreaJump = (newValue) => {
    navigate('/forward/area/'+newValue)
  }

  const handleWeekChange = (num_weeks) => {
    const new_week = dhf.addDaysToDate(state.ui.currentWeek,7*num_weeks);
    //Set State:
    dispatch({ type: 'SET_CURRENT_WEEK', payload: new_week })
  }

  const handleYearChange = (num_yrs) => {
    const new_year = state.ui.currentYear + num_yrs;
    //Set State:
    dispatch({ type: 'SET_CURRENT_YEAR', payload: new_year })
  }

  const handleCreate = (object_type) => {
    openForm(object_type,{},false,false)

  }

  const settingsFunction = () => {
    navigate('/forward/settings')
  }

const handleDownloadClick = async() => {

      //TODO: Ensure we pull a user's full accomplishment history as well
      // Convert the user's data to a JSON string
      const dataStr = JSON.stringify(state, null, 2);
      // Create a Blob with the JSON data
      const blob = new Blob([dataStr], { type: 'application/json' });
      // Create a link element
      const downloadLink = document.createElement('a');
      // Set the download filename
      downloadLink.download = 'UserData.json';
      // Create a URL for the Blob and set it as the href of the link
      downloadLink.href = window.URL.createObjectURL(blob);
      // Append the link to the document
      document.body.appendChild(downloadLink);
      // Trigger the download
      downloadLink.click();
      // Remove the link after downloading
      document.body.removeChild(downloadLink);
}

const handleShareClick = async () => {
  try {
    const sharesData = await uas.readMyShares(state.account.id);
    setShares(sharesData);
    onShareOpen();
  } catch (error) {
    console.error('Error fetching shares:', error);
    // Handle error appropriately
  }
};

  const currentView = state.ui.currentView;
  const projectView = state.ui.projectView;
  let show_weekly_nav = false;
  if (  currentView === 'My Habits' || 
        location.pathname.includes('/habits')) {
    show_weekly_nav = true;
  }
  else if (   currentView === 'My Tasks' || 
          location.pathname.includes('/tasks')) {
      if(projectView === 'project') {
        show_weekly_nav = true;
      }

      else {
      show_weekly_nav = false;
      }
}
else if (currentView === 'Habit Detail') {
  show_weekly_nav = false;
}
  let show_yearly_nav = false;
  if (currentView === 'My Goals' || location.pathname.includes('/goals')) {
    show_yearly_nav = true;
  }

  return (
    <Box bg="forwardBlue.600" position="sticky" zIndex="100" top="0">
      {/*******************/}
      {/****Main Nav Bar*****/}
      {/*******************/}
  <Box bg="forwardBlue.600" py={0} px={0} as={Flex} width="100%">
    <NavBarButton icon={c.main_icons.home} text="Home" to="/forward/" isLive={currentView==="Home"} />
    {/*<NavBarButton icon={c.main_icons.areas} text="New Home" to="/forward/newhome" isLive={currentView==="New Home"} />*/}
    <NavBarButton icon={c.main_icons.habits} text="Habits" to="/forward/habits"  isLive={(currentView==="My Habits" || currentView==="Habit Detail")}/>
    <NavBarButton icon={c.main_icons.tasks} text="Tasks" to="/forward/tasks" isLive={(currentView==="My Tasks" || currentView==="Project Detail")} />
    <NavBarButton icon={c.main_icons.goals} text="Goals" to="/forward/goals" isLive={currentView==="My Goals"} />
    <NavBarDropdown icon={c.main_icons.settings} text="More" to="/forward/" isLive={currentView==="My Settings"} isEnd={true} 
            organizeFunction={onMMOpen} 
            createFunction={() => openForm('lifeArea',{},true,false)} 
            shareFunction={handleShareClick}
            refreshFunction={handleRefreshClick}
            exportFunction={handleDownloadClick}
            analyzeFunction={onAnalyze}
            settingsFunction={settingsFunction}
            share_notifications={share_notifications}
            active_shares={active_shares}
            />
  </Box>
      {/**********************/}
      {/*****Secondary Nav****/}
      {/**********************/}
      <Center bg="forwardBlue.600" py={1} px={4} as={Flex} alignItems="center" width="100%" justifyContent="space-between" spacing={1}>
          <Flex alignItems="baseline" flexDirection={isMobile ? 'row' : 'row'}>
        {/*<ViewDropdown borderRadius={0} />*/}
        
        {(currentView === 'My Tasks' && location.pathname.includes('/tasks')) && 
        <><TaskViewDropdown currentValue={selectedProjectView} handleViewChange={handleViewChange} /></>
        }
        {(currentView === 'My Goals' && location.pathname.includes('/goals')) &&
        <><GoalViewDropdown currentValue={selectedGoalView} handleViewChange={handleGoalViewChange} /></>
        }
        {(currentView === 'Project Detail' && location.pathname.includes('/project/')) &&
        <>
        <Text fontWeight="semibold" textColor="white" as={RouterLink} cursor="pointer" to="/forward/tasks">My Tasks&nbsp;</Text>
        <FaAngleRight color="white" />
        <NewProjectDropdown value={parseInt(detail_id)} defaultValue={parseInt(detail_id)} onChange={handleProjectJump} variant="headerNav" isMobile={isMobile} />
        </> }
        {(currentView === 'Habit Detail' && location.pathname.includes('/habit/')) &&
        <>
        <Text fontWeight="semibold" textColor="white" as={RouterLink} cursor="pointer" to="/forward/habits">My Habits&nbsp;</Text>
        <FaAngleRight color="white" />
        <HabitDropdown value={parseInt(detail_id)} defaultValue={parseInt(detail_id)} onChange={handleHabitJump} variant="headerNav"  isMobile={isMobile}/>
        </> }
        {(currentView === 'Area Detail' && location.pathname.includes('/area/')) &&
        <>
        <Text textColor="white">Area Detail&nbsp;</Text>
        <FaAngleRight color="white" />
        <NewAreaDropdown value={parseInt(detail_id)} defaultValue={parseInt(detail_id)} onChange={handleAreaJump} variant="headerNav" isMobile={isMobile} />
        </> }

        </Flex>
        <Spacer />
            {(show_weekly_nav || show_yearly_nav) && (
                    <TimeNavComponent 
                    mode={show_weekly_nav ? "weekly" : "yearly"} 
                    handleChange={show_weekly_nav ? handleWeekChange : handleYearChange} 
                    currentTime={show_weekly_nav ? state.ui.currentWeek : state.ui.currentYear} />
            )}
        <Spacer />
        <ButtonGroup>
          {(currentView==='My Tasks' || currentView==="Project Detail") && <Button className="forwardButton" colorScheme="forwardOrange" onClick={() => handleCreate('task')}>+ Task</Button>}
          {(currentView==='My Tasks')&& <Button colorScheme="forwardOrange" className="forwardButton"  onClick={() => handleCreate('project')}>+ Project</Button>}
          {(currentView==='My Habits') && <Button colorScheme="forwardOrange" className="forwardButton"  onClick={() => handleCreate('habit')}>+ Habit</Button>}
          {(currentView==='My Goals') && <Button colorScheme="forwardOrange" className="forwardButton"  onClick={() => handleCreate('goal')}>+ Goal</Button>}
          {(currentView==='Area Detail') && <AddButton styled_as="main" entities={['task','project','habit','goal']} editorData={{area_id:detail_id}} />}
        </ButtonGroup>
      </Center>
      <ManageModal session={session} isOpen={isMMOpen} onClose={onMMClose} />
      <ShareRequestsModal session={session} shares={shares} setShares={setShares} isOpen={isShareOpen} onClose={onShareClose} />
    </Box>
  );
};

export default AppNavBar;
