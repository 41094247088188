import { Box, VStack, Image, Text, useColorModeValue } from "@chakra-ui/react";

const HabitMedal = ({ medalImage, text1, text2, text3, borderColor, ...props}) => {
    const bgColor = useColorModeValue('forwardGold.50','forwardBlue.900')
    return (
        <Box display="flex" alignItems="center" border={'6px solid var(--chakra-colors-streakColor-'+borderColor+')'} borderRadius="0px" p={2} boxShadow="lg" backgroundColor={bgColor} {...props}>
            <Image src={medalImage} boxSize="75px" mr={4} />
            <VStack spacing={0}>
                <Text fontSize="xl" fontWeight="bold">{text1}</Text>
                <Text fontSize="lg" fontWeight="bold">{text2}</Text>
                <Text>{text3}</Text>
            </VStack>
        </Box>
    );
};

export default HabitMedal;
