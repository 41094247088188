import { useEffect, useContext, useCallback } from 'react';
import { ForwardContext } from '../../context/ForwardContext.jsx';
import { usePermissions } from '../permissions/usePermissions.jsx';
import { trackCustomEvent } from '../../general/functions/plausibleEvent.js';
import { upsertTask, taskDbUpdate, readAccomplishments, readCurrentWeekTasks, readFutureWeekTasks, readTasks, readTasksByDate, readNewTaskBacklog} from '../../database_api/taskService.js';
import { useToast } from '@chakra-ui/react';
import dhf from '../../uc_utils/dates/dates.js';
import th from '../../uc_utils/tasks/tasks.js';

export const useTasks = () => {
  const { state, dispatch, session } = useContext(ForwardContext);
  const session_id = state.session_id;
  const {checkPermissionToAdd} = usePermissions();
  const toast = useToast();

  const upsertFullTask = async (task_data, add_method, task_list, remove_task, old_list) => {
    let mode = 'create';
    if(task_data.id > 0) {
      mode = 'edit';
    }
    let permission_to_create = checkPermissionToAdd('tasks',mode);
    console.log(permission_to_create);

    
    try {
        if(permission_to_create) {

          task_data = {...task_data, 'last_session_id':session_id};
          const new_task = await upsertTask(task_data);
          trackCustomEvent('FWD Task Added', {'add_method':add_method});
          
    if (new_task) {
        if (remove_task.length > 0) {
        dispatch({ type: 'REMOVE_TASK', payload: {task:remove_task[0], listName:old_list }})
        }
        const new_list = th.calculateList(new_task[0], state.ui.currentWeek, state.ui.thisWeek, state.ui.nextWeek);
        console.log(new_list);
        console.log(new_task[0]);
        dispatch({ type: 'UPSERT_TASK', payload: {task:new_task[0], listName:new_list }})
        //If not, alert to error
        if(mode === 'edit') {
            return {result:'success',message:'task edit successful'}
          }
          else {
            return {result:'success',message:'task add successful'}
          }
        } 
        else  {
            alert("Error Adding Project!")
            return {result:'error',message:'task add failure at database level'}
            }
        }
        else {
        return {result:'limit',message:'task limit reached!'}
        }
        }
        catch {
        return {result:'error',message:'task add failed.'}
        }




  }

    //
  //Add more functions as needed here
  //
  const upsertTaskProperty = async(task, property, value) => {
    console.log('here');
  }

  const completeTask = async(task_id, project_id, task, taskList) => {

    const recur_weeks = task.recur_weeks;
    const resolve_date = dhf.formatDate(new Date());

    //Remove the task from the current State
    dispatch({
      type: 'REMOVE_TASK',
      payload: { task: task, listName: taskList },
    });

    //Send a toast to the user

    //Log the event
    trackCustomEvent('FWD Task Complete', { });

    //Update the database:
    const new_task = await taskDbUpdate(task.id, task.project_id, {'item_status':'complete','resolve_date':resolve_date, 'last_session_id':session_id})

    // Add task to destination list
    //Only do this if currentWeek = this Week
    if(state.ui.currentWeek.toISOString() === state.ui.thisWeek.toISOString()) {
    dispatch({
      type: 'UPSERT_TASK',
      payload: { task: new_task, listName: 'accomplishments' },
    });
    }

    if(recur_weeks > 0) {
      console.log(resolve_date);
      const resolve_sow = dhf.getStartOfWeek(new Date(resolve_date));
      console.log(resolve_sow);
      const new_date = dhf.getStartOfWeek(dhf.addDaysToDate(resolve_sow,7*recur_weeks)).toLocaleString();
      console.log(new_date);

      //Get the data we need to input
      const task_data = { ...task, item_planned_week: new_date, original_plan_date: new_date, resolve_date: null, item_status: 'planned', last_session_id: session_id};
      if (task.id) {
        delete task_data.id;
      }
      if (task.forward_projects) {
        delete task_data.forward_projects;
      }
      console.log(task_data);
      //Upsert the task
      const new_task = await upsertTask(task_data);
      console.log(new_task);

      //Dispatch it
      const new_date_formatted = new Date(new_date).toLocaleDateString();
      const nextWeek_formatted = new Date(state.ui.nextWeek).toLocaleDateString();
      const thisWeek_formatted = new Date(state.ui.thisWeek).toLocaleDateString();
      const current_formatted = new Date(state.ui.currentWeek).toLocaleDateString();
      console.log(new_date_formatted);
      console.log(nextWeek_formatted);
      console.log(thisWeek_formatted);

      if (new_date_formatted === nextWeek_formatted) {
        console.log('adding to next week');
        dispatch({
          type: 'UPSERT_TASK',
          payload: { task: new_task[0], project_id: project_id, listName: 'next_week' },
        });
      }
      if (new_date_formatted === thisWeek_formatted) {
        console.log('adding to this week');
        dispatch({
          type: 'UPSERT_TASK',
          payload: { task: new_task[0], project_id: project_id, listName: 'this_week' },
        });
      }
      if (new_date_formatted === current_formatted) {
        console.log('adding to current week');
        dispatch({
          type: 'UPSERT_TASK',
          payload: { task: new_task[0], project_id:project_id, listName: 'current_tasks' },
        });
      }


      

    }

  }

  const pinTask = async (task, taskList, pin = true) => {  
    const new_task = await taskDbUpdate(task.id, task.project_id, {'is_pinned':pin, 'last_session_id':session_id})
    dispatch({
      type: 'UPSERT_TASK',
      payload: { task: new_task, listName: taskList },
    });
    //Use New Tasks as well
    dispatch({
      type: 'NEW_UPDATE_TASK_CONTENT',
      payload: {taskId:task.id, updatedContent:{is_pinned:pin}},
  });
}

  const delayTask = async (task, taskList) => {
    const old_date = task.item_planned_week;
    console.log(old_date);
    //Get days to add to the task to delay it:
    let new_date = dhf.addDaysToDate(dhf.createDateObject(old_date),7);
    if (new Date(old_date) <= state.ui.thisWeek) {
      new_date = state.ui.nextWeek;
    }
    const formatted_new_date = dhf.formatDate(new_date,'yyyy-MM-dd');
    console.log(formatted_new_date);
    const new_task = await taskDbUpdate(task.id, task.project_id, {'item_status':'delayed', 'item_planned_week':new_date, 'last_session_id':session_id});

    console.log(new_task);
    const new_list = th.calculateList(new_task, state.ui.currentWeek, state.ui.thisWeek, state.ui.nextWeek);
    dispatch({
      type: 'REMOVE_TASK',
      payload: { task: new_task, listName: taskList },
    });
    dispatch({
      type: 'UPSERT_TASK',
      payload: { task: new_task, listName: new_list },
    });
  }

  const abandonTask = async (task, taskList) => {
    const new_task = await taskDbUpdate(task.id, task.project_id, {'item_status':'abandoned','resolve_date':new Date(), 'last_session_id':session_id})
    dispatch({
      type: 'UPSERT_TASK',
      payload: { task: new_task, listName: taskList },
    });
    dispatch({
      type: 'NEW_UPDATE_TASK_CONTENT',
      payload: { taskId: task.id, updatedContent: { item_status: 'abandoned', resolve_date: new Date() } },
    });
  }

  const deleteTask = async (task, taskList) => {
    const new_task = await taskDbUpdate(task.id, task.project_id, {'item_status':'deleted','resolve_date':new Date(), 'last_session_id':session_id})
    dispatch({
      type: 'UPSERT_TASK',
      payload: { task: new_task, listName: taskList },
    });
    dispatch({
      type: 'NEW_UPDATE_TASK_CONTENT',
      payload: { taskId: task.id, updatedContent: { item_status: 'deleted', resolve_date: new Date() } },
    });
  }

  const reopenTask = async (task, taskList) => {
    const new_task = await taskDbUpdate(task.id, task.project_id, {'item_status':'planned','resolve_date':new Date(), 'last_session_id':session_id})
    dispatch({
      type: 'REMOVE_TASK',
      payload: { task: new_task, listName: taskList },
    });
    const new_list = th.calculateList(new_task, state.ui.currentWeek, state.ui.thisWeek, state.ui.nextWeek);
    dispatch({
      type: 'UPSERT_TASK',
      payload: { task: new_task, listName: new_list },
    });
  }


  const syncVisibleTasks = async (user_id, currentWeek, projects) => {
    try {
      let cwPlannedTasks;
      const accomplishments = await readAccomplishments(user_id, currentWeek);
      if(state.ui.currentWeek <= new Date()) {
        cwPlannedTasks = await readCurrentWeekTasks(user_id, currentWeek);
      }
      else {
        cwPlannedTasks = await readFutureWeekTasks(user_id, currentWeek);
      }
      // Flatten the projectsData into an array of projects
      const projectsArray = Object.values(projects).flat();
      // Create an initial tasks object with an empty array for each project
      const initialTasks = projectsArray.reduce((acc, project) => {
        acc[project.id] = {};
        return acc;
      }, {});
      Object.entries(cwPlannedTasks).forEach(([projectId, tasks]) => {
        if(initialTasks && initialTasks[projectId]) {
        initialTasks[projectId]['current_tasks'] = tasks || [];
        }
        
      });

      Object.entries(accomplishments).forEach(([projectId, tasks]) => {
        if(initialTasks && initialTasks[projectId]) {
        initialTasks[projectId]['accomplishments'] = tasks || [];
        }
      });

      dispatch({ type: 'SET_CW_TASKS', payload: initialTasks });
    
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  }

  /* Works with New Tasks*/
  const syncTasksByDateRange = async (start_date, end_date) => {
    //For use with New Home View
    //Pull from database all tasks
    //TODO: Add a check to see if the dates are already shown
    const allTasks = await readTasksByDate(start_date, end_date);
    console.log(allTasks);
    //Partition by day and save to objects keyed on day, reflected as item_planned_week
    const allTasksByDay =  allTasks.reduce((acc, task) => {
      const week = task.item_planned_week;
      if (!acc[week]) {
        acc[week] = [];
      }
      acc[week].push(task);
      return acc;
    }, {});
    //Dispatch the tasks to the state
    dispatch({ type: 'SET_TASKS_DATE_RANGE', payload: allTasksByDay }); 

  }

  const syncNewBacklog = async () => {
    const newTasks = await readNewTaskBacklog();
    console.log(newTasks);
    const allTasksByDay =  newTasks.reduce((acc, task) => {
      const week = task.item_planned_week;
      if (!acc[week]) {
        acc[week] = [];
      }
      acc[week].push(task);
      return acc;
    }, {});

    dispatch({ type: 'SET_TASKS_DATE_RANGE', payload: allTasksByDay });
  }




  const completeNewTask = async (task) => {
    
    const recur_weeks = task.recur_weeks;
    const resolve_date = dhf.formatDate(new Date());
    //Log the event
    trackCustomEvent('FWD Task Complete', { });


    //Update the database:
    const new_task = await taskDbUpdate(task.id, task.project_id, {'item_status':'complete','resolve_date':resolve_date, 'last_session_id':session_id})
    // Update in the state:
    console.log(new_task);
    dispatch({type:'NEW_UPDATE_TASK_CONTENT', payload:{taskId:task.id, updatedContent:{item_status:'complete',resolve_date:resolve_date}}});
    console.log(state.new_tasks);

    if(recur_weeks > 0) {
      //console.log(resolve_date);
      const resolve_sow = dhf.getStartOfWeek(new Date(resolve_date));
      //console.log(resolve_sow);
      const new_date = dhf.getStartOfWeek(dhf.addDaysToDate(resolve_sow,7*recur_weeks)).toLocaleString();
      //console.log(new_date);

      //Get the data we need to input
      const task_data = { ...task, item_planned_week: new_date, original_plan_date: new_date, resolve_date: null, item_status: 'planned', last_session_id: session_id};
      if (task.id) {
        delete task_data.id;
      }
      if (task.forward_projects) {
        delete task_data.forward_projects;
      }
      console.log(task_data);
      //Upsert the task
      const new_task = await upsertTask(task_data);
      console.log(new_task);

      //Dispatch it
      const new_date_formatted = new Date(new_date).toLocaleDateString();

      console.log(new_date_formatted);
      //Add to state:
      dispatch({type:'NEW_ADD_TASK', payload:new_task[0]});
      

    }



  }


  const addNewTask = async (task_data) => {
    let mode = task_data.id > 0 ? 'edit' : 'create';
    let permission_to_create = checkPermissionToAdd('tasks', mode);
    
    try {
      if (permission_to_create) {
        task_data = { ...task_data, 'last_session_id': session_id };
        const new_task = await upsertTask(task_data);
        trackCustomEvent('FWD Task Added', { 'add_method': null });
  
        if (new_task) {
          if (mode === 'edit') {
            const new_date = task_data.item_planned_week;
            const old_date = state.new_tasks.task_id_to_date[task_data.id];
  
            if (new_date !== old_date) {
              // Instead of removing and re-adding, update the task's date
              console.log('Updating the task date from ' + old_date + ' to ' + new_date);
              dispatch({ 
                type: 'NEW_UPDATE_TASK_DATE', 
                payload: { taskId: parseInt(task_data.id), newDate: dhf.formatDate(new_date) }
              });
            }
            
            // Always update the task content, regardless of date change
            console.log('Updating the task content');
            console.log('New Task:', task_data);
            dispatch({ 
              type: 'NEW_UPDATE_TASK_CONTENT', 
              payload: { taskId: parseInt(task_data.id), updatedContent: task_data }
            });
  
            return { result: 'success', message: 'task edit successful' };
          } else {
            dispatch({ type: 'NEW_ADD_TASK', payload: new_task[0] });
            toast({
              title: "Task Added",
              description: "Your task has been added.",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            return { result: 'success', message: 'task add successful' };
          }
        } else {
          alert("Error Adding Task!");
          return { result: 'error', message: 'task add failure at database level' };
        }
      } else {
        return { result: 'limit', message: 'task limit reached!' };
      }
    } catch (error) {
      console.error("Error in addNewTask:", error);
      return { result: 'error', message: 'task add failed.' };
    }
  };




  
  
  return {upsertTaskProperty, upsertFullTask, 
          completeTask, pinTask, delayTask, abandonTask, deleteTask, reopenTask, 
          syncVisibleTasks, 
          syncTasksByDateRange, syncNewBacklog, completeNewTask, addNewTask, };
};