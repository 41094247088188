import { supabase } from '../uc_constants/supabaseClient.js';
import dhf from '../uc_utils/dates/dates.js'


export const taskDbUpdate = async (task_id, project_id, data_updates) => {
  //console.log('updating task...');
  //Update Database
  const task_data_to_send = data_updates;
  task_data_to_send['id'] = task_id;
  task_data_to_send['project_id'] = project_id;

  const update_task = await upsertTask(task_data_to_send)

  //Update State
  if (update_task) {
    console.log('Task updated!');
    return update_task[0];
//If not, alert to error
} else  {
    alert("Error Updating Task in Database!")
}

}



export async function upsertTask(task_data) {
    let { data, error } = await supabase
    .from('forward_tasks')
    .upsert([ task_data ], 
            {onConflict: 'id' })
      .select('*');

  if (error) {
      console.error('Error upserting user:', error);
  }
  return data;
}

// updateAreaOrderInDB function
export async function updateTaskOrdering(project_id, orderedTasks) {
  console.log(orderedTasks);
  const data = orderedTasks.map(({ id, order_id, last_session_id }) => ({ id, order_id, last_session_id, project_id:project_id}));

  //console.log(data);
  const { error } = await supabase
    .from('forward_tasks')
    .upsert(data, { onConflict: 'id' });

  if (error) {
    console.error('Error updating task order:', error);
    return { success: false, message: error.message };
  }

  return { success: true };
};

export async function updatePinTaskOrdering(orderedTasks) {
  console.log(orderedTasks);

  const { error } = await supabase
    .from('forward_tasks')
    .upsert(orderedTasks, { onConflict: 'id' });

  if (error) {
    console.error('Error updating task order:', error);
    return { success: false, message: error.message };
  }

  return { success: true };
};

export async function readTasks(user_id) {
  let { data, error } = await supabase
    .from('forward_tasks')
    .select(`
      *
    `)
    .order('order_id');
  
  if (error) {
    console.error('Error fetching tasks:', error);
  }
  console.log(data);

  return data;
}

export async function readNewTaskBacklog() {
  let { data, error } = await supabase
    .from('forward_tasks')
    .select(`
      *
    `)
    .in('item_status',['backlog','priority','toschedule'])
    .order('order_id');
  
  if (error) {
    console.error('Error fetching tasks:', error);
  }

  return data;
}

export async function readTasksByDate(start_date, end_date) {
  let { data, error } = await supabase
    .from('forward_tasks')
    .select(`
      *
    `)
    .gte('item_planned_week', start_date)
    .lte('item_planned_week', end_date)
    .order('order_id');
  
  if (error) {
    console.error('Error fetching tasks:', error);
  }

  return data;
}


export async function readCurrentWeekTasks(user_id,currentWeek) {
  const cw = dhf.formatDate(currentWeek,"yyyy-MM-dd")
  //console.log(cw);
  let { data, error } = await supabase
    .from('forward_tasks')
    .select(`
      *,
      forward_projects (
        id, user_id
      )
    `)
    .eq('forward_projects.user_id', user_id)
    .in('item_status',['delayed','planned'])
    .lte('item_planned_week',cw)
    .order('order_id');
  
  if (error) {
    console.error('Error fetching tasks:', error);
  }

  // Organize tasks by project
  const tasksByProject = {};
  data.forEach((task) => {
    if (!tasksByProject[task.project_id]) {
      tasksByProject[task.project_id] = [];
    }
    tasksByProject[task.project_id].push(task);
  });
  //console.log(tasksByProject);
  return tasksByProject;
}


export async function readThisWeekTasks(user_id) {
  const cw = dhf.formatDate(dhf.getStartOfWeek(new Date()),"yyyy-MM-dd")
  //console.log(cw);
  let { data, error } = await supabase
    .from('forward_tasks')
    .select(`
      *,
      forward_projects (
        id, user_id, project_name
      )
    `)
    .eq('forward_projects.user_id', user_id)
    .in('item_status',['delayed','planned'])
    .lte('item_planned_week',cw)
    .order('order_id');
  
  if (error) {
    console.error('Error fetching tasks:', error);
  }

  // Organize tasks by project
  const tasksByProject = {};
  data.forEach((task) => {
    if (!tasksByProject[task.project_id]) {
      tasksByProject[task.project_id] = [];
    }
    tasksByProject[task.project_id].push(task);
  });
  //console.log(tasksByProject);
  return tasksByProject;
}


export async function readNextWeekTasks(user_id) {
  const cw = dhf.formatDate(dhf.getStartOfWeek(dhf.addDaysToDate(new Date(),7)),"yyyy-MM-dd")
  //console.log(cw);
  let { data, error } = await supabase
    .from('forward_tasks')
    .select(`
      *,
      forward_projects (
        id, user_id
      )
    `)
    .eq('forward_projects.user_id', user_id)
    .in('item_status',['delayed','planned'])
    .eq('item_planned_week',cw)
    .order('order_id');
  
  if (error) {
    console.error('Error fetching tasks:', error);
  }

  // Organize tasks by project
  const tasksByProject = {};
  data.forEach((task) => {
    if (!tasksByProject[task.project_id]) {
      tasksByProject[task.project_id] = [];
    }
    tasksByProject[task.project_id].push(task);
  });
  //console.log(tasksByProject);
  return tasksByProject;
}

export async function readFutureWeekTasks(user_id,currentWeek) {
  const cw = dhf.formatDate(currentWeek,"yyyy-MM-dd")
  const eocw = dhf.formatDate(dhf.addDaysToDate(currentWeek,6),"yyy-MM-dd");
  //console.log(cw);
  let { data, error } = await supabase
    .from('forward_tasks')
    .select(`
      *,
      forward_projects (
        id, user_id
      )
    `)
    .eq('forward_projects.user_id', user_id)
    .in('item_status',['delayed','planned'])
    .gte('item_planned_week',cw)
    .lte('item_planned_week',eocw)
    .order('order_id');
  
  if (error) {
    console.error('Error fetching tasks:', error);
  }

  // Organize tasks by project
  const tasksByProject = {};
  data.forEach((task) => {
    if (!tasksByProject[task.project_id]) {
      tasksByProject[task.project_id] = [];
    }
    tasksByProject[task.project_id].push(task);
  });
  //console.log(tasksByProject);
  return tasksByProject;
}


export async function readBacklogTasks(user_id) {
  let { data, error } = await supabase
    .from('forward_tasks')
    .select(`
      *,
      forward_projects (
        id, user_id
      )
    `)
    .eq('forward_projects.user_id', user_id)
    .in('item_status',['backlog'])
    .order('order_id');
  
  if (error) {
    console.error('Error fetching tasks:', error);
  }

  // Organize tasks by project
  const tasksByProject = {};
  data.forEach((task) => {
    if (!tasksByProject[task.project_id]) {
      tasksByProject[task.project_id] = [];
    }
    tasksByProject[task.project_id].push(task);
  });

  return tasksByProject;
}


export async function readPriorityTasks(user_id) {
  let { data, error } = await supabase
    .from('forward_tasks')
    .select(`
      *,
      forward_projects (
        id, user_id
      )
    `)
    .eq('forward_projects.user_id', user_id)
    .in('item_status',['priority'])
    .order('order_id');
  
  if (error) {
    console.error('Error fetching tasks:', error);
  }

  // Organize tasks by project
  const tasksByProject = {};
  data.forEach((task) => {
    if (!tasksByProject[task.project_id]) {
      tasksByProject[task.project_id] = [];
    }
    tasksByProject[task.project_id].push(task);
  });

  return tasksByProject;
}



export async function readAccomplishments(user_id, currentWeek) {
  const endOfWeek = dhf.addDaysToDate(currentWeek,6);
  const cw = dhf.formatDate(currentWeek,"yyyy-MM-dd");
  const ew = dhf.formatDate(endOfWeek,"yyyy-MM-dd");
  let { data, error } = await supabase
    .from('forward_tasks')
    .select(`
      *,
      forward_projects (
        id, user_id
      )
    `)
    .eq('forward_projects.user_id', user_id)
    .in('item_status',['complete'])
    .lte('resolve_date',ew)
    .gte('resolve_date',cw)
    .order('order_id');
  
  if (error) {
    console.error('Error fetching tasks:', error);
  }

  //console.log(data);

  // Organize tasks by project
  const tasksByProject = {};
  data.forEach((task) => {
    if (!tasksByProject[task.project_id]) {
      tasksByProject[task.project_id] = [];
    }
    tasksByProject[task.project_id].push(task);
  });

  return tasksByProject;
}

export async function readProjectHistory(user_id, project_id) {

  console.log('pulling project history');

  let { data, error } = await supabase
    .from('forward_tasks')
    .select(`
      *,
      forward_projects (
        id, user_id
      )
    `)
    .eq('project_id',project_id)
    .in('item_status',['complete','abandoned'])
    .order('order_id');

    //console.log(data);
  
  if (error) {
    console.error('Error fetching tasks:', error);
  }


  return data;
}