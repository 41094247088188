
import React, {useContext} from 'react';
import {
  VStack, Text, HStack, Box, Heading, Flex, Divider, UnorderedList, ListItem, Button, useColorModeValue,
} from '@chakra-ui/react';
import dhf from '../../../uc_utils/dates/dates.js';

import { FormContext } from '../../../context/FormContext.jsx';


const ProjectHistoryRow = ({ project_id, week, weekTasks, timeSince, ...props }) => {

    const {addTask, editTask} = useContext(FormContext);
    const bgColor = useColorModeValue('forwardWhite.200','forwardBlue.500');
    const bdColor = useColorModeValue('forwardBlue.400','forwardBlue.700');

    const completed_tasks = weekTasks.filter(task => task.item_status === 'complete').length;

return (
    <>
    {timeSince > 0 && 
    <Box width="0px" height={(3*timeSince)+'px'} border="1px"></Box>}
    <Box key={week}
             px={4}
             py={4}
             margin={3}
             border="1px"
             backgroundColor={bgColor}
             borderColor={bdColor}
             maxWidth="100%"
             minWidth="300px"
             boxShadow="md"
             >
            <HStack width="100%" justifyContent="space-between">
            <Heading as="h3" size="sm">Week of {dhf.formatDate(dhf.createDateObject(week),'MMM dd, yyyy')}</Heading>
            <Text fontSize="sm">{completed_tasks} tasks</Text>
            </HStack>
            <Divider my={1} />
            <UnorderedList>
            {weekTasks.map(task => {
                
                
                return (
                <ListItem key={task.id}>{task.item_name}</ListItem>
            )
                }
            )}
            </UnorderedList>
            <Button size="xs" variant="outline" borderRadius={0} width="100%" 
            onClick={() => addTask({item_status:'complete', taskList:'complete',resolve_date:week, project_id:parseInt(project_id), submit_text:'Add Accomplishment',submit_mode:'add'}
                                    ,false
                                    ,{name:true,project:true})}>+ Accomplishment</Button>
        </Box>
        
        </>
        
)

            }

            export default ProjectHistoryRow;