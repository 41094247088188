import React from 'react';
import { Box, Button, HStack } from "@chakra-ui/react";

import { useContext } from 'react';
import { ForwardContext } from '../../../context/ForwardContext';
import { FormContext } from '../../../context/FormContext';
import ReminderTaskRow from '../../reminders/rows/ReminderTaskRow';

const AreaRemindersBox = ({ style="task_box", areaId, days }) => {
    const { state } = useContext(ForwardContext);
    const { addReminder } = useContext(FormContext);
    let reminders = [];
    if (areaId === 'all') {
        reminders = Object.values(state.reminders).flat();
    }
    else {
        reminders = state.reminders[areaId] || [];
    }

    const filteredReminders = reminders.filter(reminder => {
        const nextDueDate = new Date(reminder.next_due_date);
        const currentDate = new Date();
        const differenceInDays = Math.ceil((nextDueDate - currentDate) / (1000 * 60 * 60 * 24));
        return differenceInDays <= days;
    });
    filteredReminders.sort((a, b) => {
        const dateA = new Date(a.next_due_date);
        const dateB = new Date(b.next_due_date);
        return dateA - dateB;
    });


    if (filteredReminders.length === 0) {
        return null;
    }

    return (
        <Box>
            {style === "task_box" && 
            <HStack width="100%" justifyContent="space-between" alignItems="baseline">
            <Button 
                variant="unstyled"
                cursor="auto"
                colorScheme="forwardBlue"
                size="2xs"
                p={1}
                borderRadius={0}
                disabled={true}
                fontSize="xs" 
                textAlign="left"
                fontStyle="oblique">
                    Reminders
                </Button>
                <Button 
                variant="ghost"
                colorScheme="forwardBlue"
                size="2xs"
                p={1}
                borderRadius={0}
                fontSize="xs" 
                textAlign="left" 
                fontStyle="oblique"
                cursor="pointer"
                onClick={() => addReminder({area_id: areaId})}
                >
                    + Reminder
                </Button>
                </HStack>}
            {filteredReminders.map(reminder => (
                <ReminderTaskRow key={reminder.id} reminder={reminder} style={style} />
            ))}
        </Box>
    );
};

export default AreaRemindersBox;