import { format } from 'date-fns'
/**************************/
/**************************/
// Core Date Functions
/**************************/
/**************************/
const formatDate = (date, d_format='yyyy-MM-dd') => {
  //Check if date is a string in d_format, if so, return it
  if (typeof date === 'string' && date.length === d_format.length) {
    return date;
  }
  // If date is not a Date object, return null
    return format(date,d_format);
}

const getMidnightDate = (dateString) => {
  // Split the date string into year, month, and day
  if(dateString === null){
    return null;
  }
  //if dateString is a date object, work from that.
  if(dateString instanceof Date){
    return new Date(dateString.setHours(0,0,0,0));
  }

  const dateToSplit = formatDate(formatDate(dateString));
  const [year, month, day] = dateToSplit.split('-').map(Number);
  
  // Create a new Date object using UTC to avoid timezone issues
  const date = new Date(Date.UTC(year, month - 1, day));
  
  return date;
}

const getLocalMidnightDate = (dateString) => {
  // Split the date string into year, month, and day
  if(dateString === null){
    return null;
  }
  //if dateString is a date object, work from that.
  if(dateString instanceof Date){
    return new Date(dateString.setHours(0,0,0,0));
  }

  const [year, month, day] = dateString.split('-').map(Number);
  
  // Create a new Date object using local time
  const date = new Date(year, month - 1, day);
  
  // Set the time to midnight (00:00:00.000)
  date.setHours(0, 0, 0, 0);
  
  return date;
}

const getLocalLastSecondDate = (dateString) => {
  if(dateString === null){
    return null;
  }
  //if dateString is a date object, work from that.
  if(dateString instanceof Date){
    return new Date(dateString.setHours(23, 59, 59, 999));
  }

  const [year, month, day] = dateString.split('-').map(Number);
  
  // Create a new Date object using local time
  const date = new Date(year, month - 1, day);
  
  // Set the time to a second before midnight (23:59:59.999)
  date.setHours(23, 59, 59, 999);
  
  return date;
}

const getLastSecondDate = (dateString) => {
  if(dateString === null){
    return null;
  }
  //if dateString is a date object, work from that.
  if(dateString instanceof Date){
    return new Date(dateString.setHours(0,0,0,0));
  }

  const [year, month, day] = dateString.split('-').map(Number);
  
  // Create a new Date object using local time
  const date = new Date(year, month - 1, day);
  
  // Set the time to a second before midnight (23:59:59.999)
  date.setHours(23, 59, 59, 999);
  
  return date;
} 

const setStartOfDay = (date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

const setEndOfDay = (date) => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
}

const createDateObject = (dateString) => {
  // If dateString is null or undefined, return null
if (!dateString) {
  return null;
}

const [year, month, day] = dateString.split('-').map(Number);

// If year, month, or day is not a number, return null
if (isNaN(year) || isNaN(month) || isNaN(day)) {
  return null;
}
const new_date = new Date(year, month - 1, day);

//console.log(new_date);

return new_date;
}


const getStartOfWeek = (date) => {
  var firstday = new Date(date);
  const dayOfWeek = firstday.getDay();

  // If it's Monday, return the date as is
  if (dayOfWeek === 1) {
    firstday.setHours(0, 0, 0, 0);
    return firstday;
  }

  // If it's Sunday, subtract 6 days to get the previous Monday
  // Otherwise, subtract the appropriate number of days to get to the most recent Monday
  const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  firstday.setDate(firstday.getDate() - daysToSubtract);
  firstday.setHours(0, 0, 0, 0);

  return firstday;
}



/**************************/
/**************************/
// Add/Remove Day Functionality
/**************************/
/**************************/
const addDaysToDate = (date, daysToAdd) => {
    if (!date || typeof daysToAdd !== 'number') {
      throw new Error("You must provide a date and a number of days to add.");
    }
  
    const inputDate = new Date(date);
  
    if (isNaN(inputDate)) {
      throw new Error("The provided date is invalid.");
    }
  
    const newDate = new Date(inputDate);
    newDate.setDate(inputDate.getDate() + daysToAdd);
  
    return newDate;
  };

  const addMonthsToDate = (date, monthsToAdd) => {
    
    if (!date || typeof monthsToAdd !== 'number') {
      throw new Error("You must provide a date and a number of months to add.");
    }
  
    const inputDate = new Date(date);
  
    if (isNaN(inputDate)) {
      throw new Error("The provided date is invalid.");
    }
  
    const newDate = new Date(inputDate);
    newDate.setMonth(inputDate.getMonth() + monthsToAdd);
  
    // The date might get shifted if original day of the month doesn't exist in the new month
    // For example, Jan 31 + 1 month would become Mar 3 in a non-leap year, because there's no Feb 31
    // So, if that happens, we shift the date back to the last day of the previous month
    if (newDate.getMonth() !== (inputDate.getMonth() + monthsToAdd) % 12) {
      newDate.setDate(0);
    }
  
    return newDate;
  };
/**************************/
/**************************/
// Get Date Information
/**************************/
/**************************/
  const isDateInCurrentWeek = (date, currentWeekStart) => {
    const weeksInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const weekEnd = new Date(currentWeekStart.getTime() + weeksInMilliseconds);
    return date >= currentWeekStart && date < weekEnd;
  };

  const isFutureDate = (dateString, startDate) => {
    const today = new Date();
    today.setHours(0, 0, 0,0);
  
    const date = new Date(dateString);
    const weekStartDate = new Date(startDate);
    weekStartDate.setHours(0,0,0,0);
    //console.log(weekStartDate);
    //console.log(date);
    //console.log((date - today)/(24*60*60));
  
    return (date-today)/(24*60*60)>999 || date < weekStartDate;
  };

  const getMonth = (date) => {
    const month = date.getMonth();
    return month;
}
  
const getWeekday = (date) => {
  return date.getDay() === 0 ? 6 : date.getDay() - 1;
}

const getPercentOfWeek = (date, startDate) => {
  if (!date || !startDate) {
    throw new Error("Both date and startDate are required.");
  }

  const inputDate = new Date(date);
  const inputStartDate = new Date(startDate);

  if (isNaN(inputDate) || isNaN(inputStartDate)) {
    throw new Error("One or both of the provided dates are invalid.");
  }

  const oneHour = 60 * 60 * 1000;
  const hoursInWeek = 7 * 24;
  const hoursPast = (inputDate - inputStartDate) / oneHour;

  if (hoursPast < 0) {
    return 0;
  }

  const percentOfWeek = hoursPast / hoursInWeek;

  if (percentOfWeek >= 1) {
    return 1;
  }
  //console.log(parseFloat(percentOfWeek.toFixed(2)))
  return parseFloat(percentOfWeek.toFixed(2));
};


const getDaysBetweenDates = (date1, date2) => {
  const oneDayInMillis = 1000 * 60 * 60 * 24;
  const firstDate = new Date(date1).getTime();
  const secondDate = new Date(date2).getTime();

  const diffInMillis = (firstDate - secondDate);
  const days = Math.ceil(diffInMillis / oneDayInMillis);

  return days;
};

const isLevelLarger = (level1, level2) => {
  const levels = ['years', 'quarters', 'months', 'weeks', 'days'];
  return levels.indexOf(level1) < levels.indexOf(level2);
}

const getEventDisplayLevel = (level) => {
  switch(level) {
    case 'years':
      return 'months';
    case 'quarters':
      return 'weeks';
    case 'months':
      return 'weeks';
    case 'weeks':
      return 'days';
    case 'days':
      return 'days';
    default:
      throw new Error('Invalid level');
  }
}

const getPnPeriod = (startDate, level, direction) => {
// Convert startDate to Date object if it's not already
const date = startDate instanceof Date ? startDate : new Date(startDate);
  
// Helper function to get local midnight date
const getLocalMidnight = (d) => getLocalMidnightDate(d);

// Helper function to add/subtract periods
const adjustDate = (d, amount, unit) => {
  const newDate = new Date(d);
  switch(unit) {
    case 'years':
      newDate.setFullYear(newDate.getFullYear() + amount);
      break;
    case 'quarters':
      newDate.setMonth(newDate.getMonth() + amount * 3);
      break;
    case 'months':
      newDate.setMonth(newDate.getMonth() + amount);
      break;
    case 'weeks':
      newDate.setDate(newDate.getDate() + amount * 7);
      break;
    case 'days':
      newDate.setDate(newDate.getDate() + amount);
      break;
  }
  return newDate;
};

// Helper function to get start of period
const getStartOfPeriod = (d, unit) => {
  const newDate = new Date(d);
  switch(unit) {
    case 'years':
      newDate.setMonth(0, 1);
      break;
    case 'quarters':
      newDate.setMonth(Math.floor(newDate.getMonth() / 3) * 3, 1);
      break;
    case 'months':
      newDate.setDate(1);
      break;
    case 'weeks':
      const day = newDate.getDay();
      newDate.setDate(newDate.getDate() - day + (day === 0 ? -6 : 1)); // Adjust to Monday
      break;
    case 'days':
      // No adjustment needed for days
      break;
  }
  return newDate;
};

// Helper function to get end of period
const getEndOfPeriod = (d, unit) => {
  const newDate = getStartOfPeriod(d, unit);
  return adjustDate(adjustDate(newDate, 1, unit), -1, 'days');
};

let result;
switch(direction) {
  case 'next':
    result = getStartOfPeriod(adjustDate(date, 1, level), level);
    break;
  case 'prev':
    result = getStartOfPeriod(adjustDate(date, -1, level), level);
    break;
  case 'current':
    result = getStartOfPeriod(date, level);
    break;
  default:
    throw new Error('Invalid direction');
}

return {
  start: getLocalMidnight(result),
  end: getLocalMidnight(getEndOfPeriod(result, level))
};
};

function decomposeTimestamp(timestampz) {
  const dateObj = new Date(timestampz);
  
  // Extract date in 'YYYY-MM-DD' format
  const date = dateObj.toISOString().split('T')[0];

  // Extract time in 'HH:MM' format from timestampz, adjusted for local timezone
  const time = dateObj.toTimeString().slice(0, 5);
  console.log(time);
  
  return { date, time };
}

function recombineDateTime(date, time) {
  // Combine date and time into a single string
  const combined = `${date}T${time}:00`;

  // Convert to ISO string with offset (+00:00)
  const timestampz = new Date(combined).toISOString().replace('.000Z', '+00:00');
  
  return timestampz;
}

function adjustForTimezone(date, timeZone) {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const offsetDate = new Date(date.toLocaleString('en-US', { timeZone }));

  const offsetInMilliseconds = offsetDate.getTime() - utcDate.getTime();

  return new Date(date.getTime() + offsetInMilliseconds);
}


  export default {
    getStartOfWeek,
    getMidnightDate,
    getLocalMidnightDate,
    getLocalLastSecondDate,
    setStartOfDay,
    setEndOfDay,
    addDaysToDate,
    addMonthsToDate,
    getPercentOfWeek,
    isDateInCurrentWeek,
    getDaysBetweenDates,
    formatDate,
    createDateObject,
    isFutureDate,
    getMonth,
    getWeekday,
    isLevelLarger,
    getPnPeriod,
    getEventDisplayLevel,
    decomposeTimestamp,
    recombineDateTime,
    adjustForTimezone
  }