import React, {useState} from 'react';
import {
  Box,
  IconButton,
  useDisclosure,
  VStack,
  HStack,
  Text,
} from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillMinusCircle, AiFillCiCircle } from 'react-icons/ai';
import { BsFillCircleFill } from 'react-icons/bs'


    const GoalButton = ({ color, label, value, selected, handleChange, px_size }) => {
      return (
      <VStack alignItems="center" mr={1}>
        <IconButton
          icon={
            selected === value
              ? <BsFillCircleFill size={px_size} color={`var(--chakra-colors-${color}-800)`}/>
              : <BsFillCircleFill size={px_size} color={`var(--chakra-colors-${color}-800)`}/>
          }
          colorScheme={color}
          borderRadius="0px"
          border = {
            selected === value
            ? `4px solid var(--chakra-colors-${color}-900)`
            : "0px"
          }
          onClick={() => handleChange(value)}
          aria-label="Yes"
          alignItems="center"
        />
        <Text mt="2px !important" fontSize="xs">{label}</Text>
      </VStack>
    )
        };

    const GoalProgressSelector = ({ goal_id, value, onValueChange, isDisabled, size, ...props }) => {
      const px_size = size || '25px';
      const [selected, setSelected] = useState(value);

      const handleChange = (newValue) => {
        setSelected(newValue);
        onValueChange(goal_id, newValue);
      };

      const buttons = [
        { color: 'gray', label: 'Abandoned', value: 'abandoned' },
        { color: 'red', label: 'Red', value: 'red' },
        { color: 'orange', label: 'Orange', value: 'orange' },
        { color: 'yellow', label: 'Yellow', value: 'yellow' },
        { color: 'green', label: 'Green', value: 'green' },
        { color: 'darkGreen', label: 'Completed!', value: 'complete' },
      ];

      return (
        <HStack>
          {buttons.map(button => (
            <GoalButton
              key={button.value}
              color={button.color}
              label={button.label}
              value={button.value}
              selected={selected}
              handleChange={handleChange}
              px_size={px_size}
            />
          ))}
        </HStack>
      );
    };

  export default GoalProgressSelector;


