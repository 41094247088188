import { Box, Button, Text, AccordionButton, AccordionIcon, HStack, useColorModeValue, UnorderedList, ListItem, Divider } from '@chakra-ui/react';
import React, { useState, useContext } from 'react';
import { ForwardContext } from '../../../context/ForwardContext';
import { useNavigate } from 'react-router-dom';

const UpdateRow = ({ update }) => {

    const bgColor = useColorModeValue('forwardWhite.500','forwardBlue.700');
    const bdColor = useColorModeValue('forwardWhite.700','forwardBlue.800');



  return (
    <Box p={4} m={2} backgroundColor={bgColor} border="1px" borderColor={bdColor} boxShadow="lg" key={"update"+update.version}>
        <Text fontSize="lg" fontWeight="bold">{update.date}</Text>
        <Divider />
        <Text fontWeight="semibold">Version: {update.version}</Text>
        <UnorderedList>
        {
            update.items.map((item, index) => {
                
                return (<ListItem key={index}>{item}</ListItem>)
            })
        }
        </UnorderedList>
        </Box>
  );
};

export default UpdateRow;
