const areaReducer = (areas, action) => {
  switch (action.type) {
    case 'SET_AREAS':
      return { areas: action.payload };
    case 'UPSERT_AREA':
      const existingArea = areas.find(area => area.id === action.payload[0].id);
      if (existingArea) {
        // If area exists, update it
        return {
          areas: areas.map(area => area.id === action.payload[0].id ? action.payload[0] : area)
        };
      } else {
        // If area does not exist, add it
        return {
          areas: [...areas, action.payload[0]],
        };
      }
      case 'DELETE_AREA': {
        return {
          areas: areas.filter(area => area.id !== action.payload),
        };
      }
      case 'ARCHIVE_AREA': {
        return {
          areas: areas.map(area => area.id === action.payload ? { ...area, status: 3, order_id:999 } : area),
        };
      }
      case 'UNARCHIVE_AREA': {
        return {
          areas: areas.map(area => area.id === action.payload ? { ...area, status: 1 } : area),
        };
      }
      case "UPDATE_AREA_ORDER": {
        const newAreas = [...areas]; // Make a copy of the areas array
        action.payload.forEach((area_map) => {
            const areaIndex = newAreas.findIndex((area) => area.id === area_map.id); // Find the index of the area in the array
            if (areaIndex !== -1) {
                newAreas[areaIndex] = { ...newAreas[areaIndex], ...area_map }; // Merge the old area data with the new data
            }
        });
        newAreas.sort((a, b) => a.order_id - b.order_id); // Sort the areas by order_id
        return {
            areas: newAreas,
        };
    }

    default:
          return {};
    
  }
};
export default areaReducer;
