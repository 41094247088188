import { useState } from 'react';
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, IconButton, Input, Button, Text, VStack } from "@chakra-ui/react";
import { AiFillPauseCircle } from "react-icons/ai";

function DelayButton({ reminder, handleAction, height, width, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const [numDays, setNumDays] = useState('');

  const openPopover = () => setIsOpen(true);
  const closePopover = () => setIsOpen(false);

  const handleInputChange = (event) => setNumDays(event.target.value);

  const handleSubmit = () => {
    handleAction(reminder, 'delayed', numDays);
    closePopover();
  };

  return (
    <Popover isOpen={isOpen} onClose={closePopover}>
      <PopoverTrigger>
        <VStack {...props}>
        <IconButton 
          height={height}  
          width={width}
          borderRadius={0}
          size="xs" 
          icon={<AiFillPauseCircle />} 
          colorScheme="forwardOrange" 
          onClick={openPopover} 
        />
        <Text fontSize="2xs" mt="0px !important">Delay</Text>
        </VStack>
      </PopoverTrigger>
      <PopoverContent  borderRadius={0}>
        <PopoverBody>
          <Input variant="flushed" textAlign="center" fontSize="xl" mb={1} placeholder="Number of days" value={numDays} onChange={handleInputChange} />
          <Button  borderRadius={0} width="100%"  colorScheme="forwardOrange" onClick={handleSubmit}>Delay</Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default DelayButton;
