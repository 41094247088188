import React, { useState, useEffect, useContext } from 'react';
import { Box, HStack, Text, Tooltip, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { BiTargetLock } from 'react-icons/bi';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import TargetForm from '../forms/TargetForm.jsx';
import dhf from '../../../uc_utils/dates/dates.js';
import hhf from '../../../uc_utils/habits/habits.js';

const NewHabitProgress = ({
  area_id, habit_id, startDate,
  backgroundColor = false,
  toDoOnClick = false,
  showHoverText = false,
  showValues = true,
  borderWidth = "0.5px",
  newView = false
}) => {

    const { state } = useContext(ForwardContext);
    const habit = state.habits[area_id].find(h => h.id === habit_id);
    const habitStats = state.stats['habit_' + habit_id];

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentValue, setCurrentValue] = useState(0);
    const [currentTarget, setCurrentTarget] = useState(null);
    const [progressWidth, setProgressWidth] = useState(0);

    // Use consistent color definitions
    const progressBgColor = useColorModeValue('gray.300', 'gray.600');
    const progressBdColor = useColorModeValue('gray.500', 'gray.500');
    const textColor = useColorModeValue('black', 'white');

    // Define style within the component for better readability
    const completedStyle = {
      backgroundImage: 'linear-gradient(45deg, rgba(255,255,255,0.2) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.2) 75%, transparent 75%, transparent)',
      backgroundSize: '1rem 1rem'
  };

    const targetIsSet = habit.targets.length > 0;
    const target = hhf.getMostRecentTarget(habit.targets, startDate);

    const projected_value = hhf.calculateProjectedValue(currentValue, startDate, new Date())

    const { tvp_text, tvp_fs, bar_color, tooltip_text } = hhf.getProgressDetails(targetIsSet, currentTarget, target?.[1], currentValue, projected_value);

    useEffect(() => {
      const newCurrentValue = habitStats?.weekly_stats?.[dhf.formatDate(startDate)]?.total || 0;
      const newTarget = hhf.getMostRecentTarget(habit.targets, startDate);
      const newTargetValue = newTarget?.[0] || null;
      const newPercentComplete = newTargetValue ? (newCurrentValue / newTargetValue) * 100 : 0;

      setCurrentValue(newCurrentValue);
      setCurrentTarget(newTargetValue);
      setProgressWidth(newPercentComplete > 100 ? 100 : newPercentComplete);
  }, [habitStats, startDate, habit.targets.length]);  // React to changes in state and startDate

  


  if (currentTarget === null) {
    return (
        <Box position="relative" w="100%" maxWidth="300px"
            backgroundColor={backgroundColor}
            onClick={toDoOnClick === 'set_target' ? onOpen : undefined}
            cursor={toDoOnClick === 'set_target' ? 'pointer' : 'default'}>
            <HStack w="100%" border="1px" height="18px"
                borderColor="gray.500" justifyContent="center">
                <Text textAlign="center" fontSize="2xs">Set</Text>
                <BiTargetLock margin="0px !important" size="12px" />
            </HStack>
            <TargetForm habit={habit} isOpen={isOpen} onClose={onClose} />
        </Box>
    );
}

return (
  <>
      <Tooltip label={showHoverText ? tooltip_text : ""} placement="bottom" hasArrow>
          <Box position="relative" w="100%" minHeight="16px" maxWidth="500px"
              bgColor={progressBgColor} borderWidth={borderWidth}
              borderColor={progressBdColor} onClick={toDoOnClick === 'set_target' ? onOpen : undefined}
              cursor={toDoOnClick === 'set_target' ? 'pointer' : 'default'}>
              <Box minHeight="16px" width={`${progressWidth}%`} maxWidth="100%" bg={bar_color}
                  boxShadow="inset 0 2px 6px  rgba(255,255,255,0.3), inset 0 -2px 3px rgba(0,0,0,0.3);"
                  transition="width 0.5s ease-in-out, background-color 0.5s ease-in-out" borderRadius={0}
                  style={currentValue >= currentTarget ? completedStyle : {}}>
                  {showValues && <Text fontSize="sm" fontStyle="italic" textAlign="right" textColor={textColor}
                      position="absolute" top="50%" left="90%" transform="translate(-50%, -50%)">
                      {`${currentValue}/${currentTarget}`}
                  </Text>}
              </Box>
          </Box>
      </Tooltip>
      <TargetForm habit={habit} isOpen={isOpen} onClose={onClose} />
  </>
);
};

export default NewHabitProgress;
