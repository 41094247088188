import React, {useEffect, useState, useContext} from 'react';
import { useParams } from 'react-router-dom';
import {
  VStack, Text, HStack, Box, Heading
} from '@chakra-ui/react';

import up from '../../uc_content/updates';
import UpdateRow from '../../fwd_ui/general/rows/UpdateRow';

const UpdatesView = ({ session, ...props }) => {
    const updates = up.updates;

return (<VStack p={8} width="100%" alignItems="stretch">
    <Heading size="lg">Forward Release Notes</Heading>
    {updates.map((update) => {
        return (
            <UpdateRow key={update.version} update={update} />
        )
    })}
      
      </VStack>)
}
export default UpdatesView;
