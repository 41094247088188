import { supabase } from '../uc_constants/supabaseClient';
import { format } from 'date-fns';

export async function upsertArea(area_data) {
    console.log(area_data);
    let { data, error } = await supabase
    .from('forward_areas')
    .upsert([area_data], 
            {onConflict: 'id' })
      .select('*');

  if (error) {
      console.error('Error upserting user:', error);
  }
  return data;
}

export async function readAreas(userId) {
        let { data, error } = await supabase
            .from('forward_areas')
            .select('*')
            .filter('date_deleted','is','null')
            .order('order_id');
        if (error) throw error;
        console.log(data);
        return data;
}

// updateAreaOrderInDB function
export async function updateAreaOrdering(user_id, orderedAreas) {
    const data = orderedAreas.map(({ id, order_id }) => ({ id, order_id, user_id: user_id }));

    console.log(data);
    const { error } = await supabase
      .from('forward_areas')
      .upsert(data, { onConflict: 'id' });
  
    if (error) {
      console.error('Error updating area order:', error);
      return { success: false, message: error.message };
    }
  
    return { success: true };
  };
  

  export async function deleteDbArea(user_id, area_id) {
    // Check how many areas the user has
      const { data: areas, error: areasError } = await supabase
      .from('forward_areas')
      .select('id')
      .eq('user_id', user_id)
      .filter('date_deleted','is','null');

    if (areasError) {
      console.error('Error checking areas:', areasError);
      throw areasError;
    }
    
    if (areas.length <= 1) {
      // If the user only has one area, return an error message
      return { error: 'You must have at least one area.' };
    }
  

    const { data, error } = await supabase
        .from('forward_areas')
        .update({ date_deleted: new Date() })
        .eq('id', area_id)
        .eq('user_id',user_id)
        
    if (error) {
        console.error('Error deleting area:', error);
        return { success: false, message: error.message };
    }
    
    return { success: true, data };
}
export async function readAreaHistory( area_id) {

  console.log('pulling area history');

  let { data, error } = await supabase
  .from('forward_tasks')
  .select(`
    *,
    forward_projects!inner(
      id, user_id, area_id
    )
  `).eq('forward_projects.area_id', area_id)
  // This assumes 'forward_tasks' has a reference to 'forward_projects' ID
  .in('item_status', ['complete', 'abandoned']);

    if (error) {
        console.error('Error deleting area:', error);
        return { success: false, message: error.message };
    }
    //console.log(data);
    
    return data;

}

