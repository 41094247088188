import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { endOfDay, startOfDay, differenceInDays, startOfYear, endOfYear, startOfQuarter, endOfQuarter, startOfMonth, endOfMonth, startOfWeek, endOfWeek, isSameDay, max, min, setHours, setMinutes, setSeconds, setMilliseconds, addDays} from 'date-fns';
import dhf from '../../../uc_utils/dates/dates.js';

const startOfMondayWeek = (date) => startOfWeek(date, { weekStartsOn: 1 });
const endOfSundayWeek = (date) => endOfWeek(addDays(date, 1), { weekStartsOn: 1 });

const setMidnight = (date) => {
    return setHours(setMinutes(setSeconds(setMilliseconds(date, 0), 0), 0), 0);
  };
// Helper function to generate periods based on the baseLevel

const generatePeriods = (start, end, level) => {

    let current = new Date(start);
    const periods = [];
    const endDate = new Date(end);
  
    while (current <= endDate) {
      let periodStart, periodEnd, fullPeriodStart, fullPeriodEnd;
      switch (level) {
        case 'years':
          periodStart = max([startOfYear(current), start]);
          periodEnd = min([endOfYear(current), endDate]);
          fullPeriodStart = startOfYear(current);
          fullPeriodEnd = endOfYear(current);
          break;
        case 'quarters':
          periodStart = max([startOfQuarter(current), start]);
          periodEnd = min([endOfQuarter(current), endDate]);
          fullPeriodStart = startOfQuarter(current);
          fullPeriodEnd = endOfQuarter(current);
          break;
        case 'months':
          periodStart = max([startOfMonth(current), start]);
          periodEnd = min([endOfMonth(current), endDate]);
          fullPeriodStart = startOfMonth(current);
          fullPeriodEnd = endOfMonth(current);
          break;
        case 'weeks':
          periodStart = max([startOfMondayWeek(current), start]);
          periodEnd = min([endOfSundayWeek(current), endDate]);
          fullPeriodStart = startOfMondayWeek(current);
          fullPeriodEnd = endOfSundayWeek(current);
          break;
        case 'days':
          periodStart = current;
          periodEnd = current;
          fullPeriodStart = current;
          fullPeriodEnd = current;
          break;
      }
      periods.push({
        visibleStart: new Date(periodStart),
        visibleEnd: new Date(periodEnd),
        fullStart: new Date(fullPeriodStart),
        fullEnd: new Date(fullPeriodEnd)
      });
      current = addDays(new Date(periodEnd), 1);
    }
    return periods;
  };

  
  // Helper function to generate cells
  const generateCells = (startDate, endDate, baseLevel, CellComponent, itemData) => {
    //console.log("Goal Cadence: ", goal_cadence);
    //console.log("Base Level: ", baseLevel);

    const periods = generatePeriods(startDate, endDate, baseLevel);
    //console.log("Periods: ", periods);
    const totalDays = differenceInDays(new Date(endDate), new Date(startDate)) + 1;

  
    return periods.map((period, index) => {
      //console.log("Period: ", period);
      const periodStart = dhf.formatDate(period.visibleStart);
      const periodEnd = dhf.formatDate(period.visibleEnd);
      const periodDays = differenceInDays(dhf.getLocalMidnightDate(periodEnd), dhf.getLocalMidnightDate(periodStart)) + 1;
      const width = `${(periodDays / totalDays) * 100}%`;

      //Filter data passed to component to just be tasks within the date range

      return (
        <Box key={periodStart} width={width} height="100%" minHeight="32px">
          <CellComponent
            period={{ start: periodStart, end: periodEnd, fullStart: period.fullStart, fullEnd: period.fullEnd }}
            length={periodDays}
            cell_width={width}
            itemData={itemData}
          />
        </Box>
      );
    });
  };

  const showCellWriting = (entry, width) => {
    //Should see if the entry is short enough given the cell width in percent of display, and return true if it can show writing, false if not.
    //console.log("Entry: ", entry);
    //console.log("Width: ", width);
    //convert width to a number 
    width = parseFloat(width);
    //console.log("Width: ", width);
    if(width < 6) {
        if (entry && entry.length < 5) {
            return true;
        }
        return false;
    }
    return true;
  }


    export { startOfMondayWeek, endOfSundayWeek, setMidnight,  generatePeriods, generateCells, showCellWriting };