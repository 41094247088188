import React, { Suspense, lazy } from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,  Box } from '@chakra-ui/react';
import { useChartModal } from '../../context/ChartModalContext.jsx';

const PlotlyChart = lazy(() => import('../charts/PlotlyChart.jsx')); // Adjust the path as necessary

const ChartModal = ({ modal_name="Chart", data, layout }) => {
    const { hideModal } = useChartModal(); // Use context to close the modal

    return (
        <Modal isOpen={true} onClose={hideModal} size="full">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{modal_name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Suspense fallback={<Box>Loading...</Box>}>
                        <PlotlyChart data={data} layout={layout} />
                    </Suspense>
                </ModalBody>
                <ModalFooter>
                    <Button borderRadius={0} colorScheme="gray" mr={3} onClick={hideModal}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};


export default ChartModal;