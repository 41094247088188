import React from 'react';
import { VStack, Text, Flex, IconButton } from '@chakra-ui/react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import dhf from '../../../uc_utils/dates/dates.js'

const TimeNavComponent = ({ mode, handleChange, currentTime }) => {
    let textContext = 'Week of';
    let displayDate = dhf.formatDate(currentTime,'MM/dd/yyyy')
    if (mode === 'yearly') 
    {
        textContext = 'Year'
        displayDate = currentTime
    }
    return (
        <VStack alignItems="stretch" alignContent="stretch">
            
            <Flex alignItems="center">
                <IconButton
                    icon={<AiOutlineArrowLeft />}
                    size={{'base':'sm','md':"md"}}
                    backgroundColor="forwardBlue.800"
                    borderRadius={0}
                    textColor="white"
                    onClick={() => handleChange(-1)}
                />
                <VStack spacing={0}>
                <Text fontSize={{'base':'xs','md':"sm"}} color="white" fontWeight="semibold">
                {textContext}
                </Text>
                <Text fontSize={{'base':'xs','md':"sm"}} color="white" mx={2}>
                    {displayDate}
                </Text>
                </VStack>
                <IconButton
                    icon={<AiOutlineArrowRight />}
                    size={{'base':'sm','md':"md"}}
                    backgroundColor="forwardBlue.800"
                    borderRadius={0}
                    textColor="white"
                    onClick={() => handleChange(1)}
                />
            </Flex>
        </VStack>
    );
};

export default TimeNavComponent;
