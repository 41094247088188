import { Spinner } from '@chakra-ui/react';

const LoadingAnimation = () => {
  return (
    <Spinner 
      thickness='4px'
      speed='1s'
      emptyColor='gray.200'
      color='forwardBlue.600'
      size='lg'
      p={2}
      m={2}
    />
  );
};

export default LoadingAnimation;
