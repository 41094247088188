const liveReducer = (live_views, action) => {
    switch (action.type) {
      /*********************************************/
      /*********************************************/
      /* Reminders */
      /*********************************************/
      /*********************************************/
      case 'SET_PROJECT_HISTORY':
        return {
            live_views: {
                ...live_views,
                project_history: action.payload,
              },
        };
        case 'SET_LIVE_PROJECT_ID':
        return {
            live_views: {
                ...live_views,
                project_id: action.payload,
              },
        };
        case 'SET_AREA_HISTORY':
        return {
            live_views: {
                ...live_views,
                area_history: action.payload,
              },
        };
        case 'SET_LIVE_AREA_ID':
        return {
            live_views: {
                ...live_views,
                area_id: action.payload,
              },
        };
        
        default:
            return {};

 
    }
};
  export default liveReducer;
  