import React, {useContext, useState, useMemo}  from 'react';
import {
  VStack, Text, Center, Box, HStack, Flex,
  Accordion, AccordionItem, AccordionPanel, useColorModeValue, useMediaQuery, Button
} from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import HabitRow from '../rows/HabitRow.jsx';
import HabitWeekHeader from '../rows/HabitWeekHeader.jsx';
import AreaHeader from '../../areas/rows/AreaHeader.jsx';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import calc_habits from '../../../calc/habits.js';

import { useManageDnd } from '../../../data_hooks/dnd/useManageDnd.jsx';
import HabitStatBoxRow from '../rows/HabitStatBoxRow.jsx';
import { useChartFunctions } from '../../../data_hooks/charting/useChartFunctions.jsx';
import { useChartModal } from '../../../context/ChartModalContext.jsx';




const HabitsView = ({ session, ...props }) => {
    
  const { state, dispatch } = useContext(ForwardContext);
  const {handleManageDragEnd} = useManageDnd({session},'habits','habits');
  const { showModal } = useChartModal();
  const chartColorMode = useColorModeValue('light','dark');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const cf = useChartFunctions();


  const my_stats = Object.values(state.stats)
                          .filter((stat) => (stat.entity === 'habit' && stat.status <= 1));
  const defaultIndices = state.areas.map((_, index) => index);
  const dvs = useMemo(() => calc_habits.derivedHabitCalc(my_stats, state.ui.currentWeek), [my_stats, state.ui.currentWeek]);
  //console.log(dvs);
  
  return (<VStack minWidth="60%" maxWidth="1200px" borderWidth={0} borderRadius="0" overflow="hidden" spacing={0}>
    {/* Stat Boxes - add later */}
    <HabitStatBoxRow 
          return_type="habit_header" 
          lw_habit_score={dvs.lw_habit_score} 
          tw_habit_score={dvs.tw_habit_score} 
          hit_rate={dvs.hit_rate} 
          avg_streak={dvs.avg_streak} 
          avg_habit_index={dvs.avg_habit_index}
          onClickFunctions={{
            'avg_streak':() => cf.chartAverageStreak({my_stats:my_stats, chartColorMode:chartColorMode, isMobile:isMobile}), 
            'habit_index':() => cf.chartHabitIndex({my_stats:my_stats, chartColorMode:chartColorMode, isMobile:isMobile}),
            'hit_rate':() => cf.chartHitRate({my_stats:my_stats, chartColorMode:chartColorMode, isMobile:isMobile}),
            'hit_and_miss':() => cf.chartTargetHitMiss({my_stats:my_stats, chartColorMode:chartColorMode, isMobile:isMobile}),
          }}
            />
    {/* Habit Header */}
    <Box  borderColor="gray">
    <HabitWeekHeader currentWeek={state.ui.currentWeek} border="1px solid gray" />

    <DragDropContext onDragEnd={handleManageDragEnd}>

      {/* Iterate Through Habits - need to show by life area*/}
      {Object.values(state.areas).map((area) => {
        // Get the projects for this area
        let habitsInArea = state.habits[area.id] || [];
        habitsInArea = habitsInArea.filter(habit => habit.status <= 2);
        // If none, return nothing
        if (habitsInArea.length === 0) {
          return null;
        }
        
        return (
          <Box key={area.id} width="100%">
            <Accordion defaultIndex={defaultIndices} allowMultiple>
            <AccordionItem>

            <AreaHeader area={area} collapsible={true} addible={true} addType="habit" addText="+" size="large" />
            <AccordionPanel py={0} px={0}>
            <Droppable droppableId={String(area.id)}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {habitsInArea.map((habit, index) => (
                    <Draggable key={habit.id} draggableId={String(habit.id)} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <HabitRow 
                          key={habit.id} 
                          habit={habit} 
                          dragHandle={provided.dragHandleProps} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            </AccordionPanel>
            </AccordionItem>
            </Accordion>
          </Box>
        );
      })}
    </DragDropContext>
</Box>
  </VStack>)
}
export default HabitsView;