import React from "react";
import { Box, Flex, Text, Button, useColorModeValue } from "@chakra-ui/react";
import { useAreaSharing } from "../../../data_hooks/sharing/useAreaSharing";


const SharingRow = ({ share, area_name, email, type, status, handleRemind, handleCancel, handleAccept, handleReject, handleRevoke, handleLeave }) => {

    const bgColor = useColorModeValue('forwardWhite.200', 'forwardBlue.700');
    return (
        <Flex alignItems="center" justifyContent="space-between" py={2} px={4} bg={bgColor} mb={2} 
        gap={2} boxShadow='rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'>
            <Box flex="1">
                <Text fontSize="xs">Area:</Text>
                <Text fontWeight="bold">{area_name}</Text>
                <Text fontSize="xs">Shared with:</Text>
                <Text fontSize="sm">{email}</Text>
            </Box>
            <Box>
                <Text fontSize="xs">My Role:</Text>
                <Text>{type.charAt(0).toUpperCase() + type.slice(1)}</Text>
                <Text fontSize="xs">Status:</Text>
                {status === 9 && <Text color="red">Pending</Text>}
                {status === 1 && <Text color="green">Live</Text>}
            </Box>
            <Box as={Flex} gap={2}>
                {status === 9 && type==='owner' && <Button className="forwardButton" colorScheme="forwardOrange" size="sm" onClick={() => handleRemind(share)}>Remind</Button>}
                {status === 9 && type==='owner' && <Button className="forwardButton" colorScheme="red" size="sm" onClick={() => handleCancel(share)}>Cancel</Button>}
                {status === 9 && type==='guest' && <Button className="forwardButton" colorScheme="green" size="sm" onClick={() => handleAccept(share)}>Accept</Button>}
                {status === 9 && type==='guest' && <Button className="forwardButton" colorScheme="red" size="sm" onClick={() => handleReject(share)}>Reject</Button>}

                {status === 1 && type==='owner' && <Button className="forwardButton" colorScheme="red" size="sm" onClick={() => handleRevoke(share)}>Revoke</Button>}
                {status === 1 && type==='guest' && <Button className="forwardButton" colorScheme="red" size="sm" onClick={() => handleLeave(share)}>Leave</Button>}
            </Box>
        </Flex>
    );
};

export default SharingRow;