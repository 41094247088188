import ps from '../../calc/period_stats.js';

const generateSingleDataSeries = ({dataObject, seriesName, seriesDisplayMode='line', dataType='timeseries', calcMode='actual', calcSettings={}, displaySettings={}}) => {
    //TODO: Implement null as zero
    //TODO: Implement projection of current period (if timeseries)

    //console.log(dataObject);
    let xValues = [];
    let yValues = [];
    if(dataType === 'timeseries') {
        ({xValues, yValues} = ps.timeSeriesAggregation({dataObject, dataType, calcMode, calcSettings}));
    }
      
    let dataSeries = {
        x: xValues,
        y: yValues,
        type: seriesDisplayMode,
        name: seriesName,
        ...displaySettings,
    };

    return dataSeries;

}

export default {generateSingleDataSeries};