import React, { useState, useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  ButtonGroup,
  useToast,
} from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext';
import NewAreaDropdown from '../../areas/components/NewAreaDropdown';
import HabitDropdown from '../../habits/components/HabitDropdown';
import { useNewGoals } from '../../../data_hooks/elements/useNewGoals';

const NewGoalForm = ({
  isOpen, onClose, type, session, editorData, optionsToShow, isDisabled
}) => {
    const { state } = useContext(ForwardContext);
    const { upsertNewGoalFull } = useNewGoals();

  const showObject = optionsToShow || {'name':true,'area':true,'project':false,'timeframe':true, 'goal_points':false,}

  /*******************/
  //Form Setup and Management
  /*******************/
  const toast = useToast();
/*
      area_id: state.areas[0].id,
        goal_name: '',
        timeframe: 'months',
        habit_id: -1,
        order_id: -1,
        goal_type:'standard',
        goal_status:1,
        */
  const [newName, setNewName] = useState(editorData.goal_name || '');
  const [timeframe, setTimeframe] = useState(editorData.timeframe || 'months');
  const [selectedHabit, setSelectedHabit] = useState(editorData.habit_id || -1);
  const [orderId, setOrderId] = useState(editorData.order_id || -1);
  const [goalType, setGoalType] = useState(editorData.goal_type || 'standard');
  const [status, setStatus] = useState(editorData.goal_status || 1);
  const [selectedArea, setSelectedArea] = useState(editorData.area_id || state.areas[0].id);


  const handleAreaChange = (value) => {
    setSelectedArea(value);
    //console.log(selectedArea);
  };
  const handleHabitChange = (value) => {
    setSelectedHabit(value);
    };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  }

  let submit_text = '';
  let submit_mode = '';
  if(Object.keys(editorData).length === 0)
  {
    submit_text = 'Create'
    submit_mode = 'new'
  }
  else {
    submit_text = 'Update'
    submit_mode = 'edit'
  }

  let area_limit = 'none';
  if(editorData && editorData.area_to_limit) {
    area_limit = editorData.area_to_limit;
  }

  const user_id = session.user.id;

  /*******************/
  //Submit to Database
  /*******************/
  async function handleSubmit() {
    let goal_data = {
                      'user_id':user_id, 
                      'goal_name':newName, 
                      'area_id':selectedArea, 
                      'order_id':orderId,
                      'goal_status':status,
                      'timeframe':timeframe,
                      'goal_type':goalType,
                      'habit_id':selectedHabit,
                    }
    if(editorData.id > 0) {
      goal_data["id"] = editorData.id;
    }
    if(goalType !== 'habit') {
      //Remove the habit_id
      delete goal_data.habit_id;
    }
    else if(goalType === 'habit') {
      //Set area_id to the area_id of the habit
      const habit_area = Object.values(state.habits).find(habit => habit.id === selectedHabit).area_id;
        goal_data.area_id = habit_area;
    }

    console.log(goal_data);

    let {result, message} = await upsertNewGoalFull({ goal_data: goal_data });
    //Submit to Database
    //const new_goal = await upsertGoal(goal_data);
    //trackCustomEvent('FWD Goal Added', {goal_name: newName});
    console.log(result);
    if(result === 'error') {
      toast({
        title: 'Error: Please upgrade your account to add more goals!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    else {
      onClose();
      toast({
        title: 'Goal Updated!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }

  };

  /*******************/
  // Return the Form
  /*******************/

  return (
    <>
    <FormControl>
        <ModalBody>
            {showObject['name'] && <>
            <FormLabel fontWeight="bold">Goal:</FormLabel>
            <Input
              value={newName}
              variant="flushed"
              placeholder="Save $50k for a down payment"
              onChange={(e) => setNewName(e.target.value)}
            />
            </>}
            {showObject['goal_type'] && <>
                <FormLabel fontWeight="bold" mt={4}>Type:</FormLabel>
                                <Select
                                name="goal_type"
                                value={goalType}
                                onChange={setGoalType} isDisabled={false} size={'lg'}
                                >
                                    <option value="standard">Standard</option>
                                    <option value="habit">Habit</option>
                                </Select>
                            </>}

            {goalType === 'standard' &&
            <>
            <FormLabel fontWeight="bold" mt={4}>Life Area:</FormLabel>
            <NewAreaDropdown mt={2} value={selectedArea} onChange={handleAreaChange} variant="flushed" />
            </>
            }
            
            {goalType === 'habit' && (
                    <>
                    <FormLabel fontWeight="bold" mt={4}>Habit:</FormLabel>
                    <HabitDropdown value={selectedHabit} onChange={handleHabitChange} />
                    </>)}

            {showObject['timeframe'] && <>
            <FormLabel fontWeight="bold" mt={4}>Timeframe:</FormLabel>
            <Select 
                                name="timeframe"
                                value={timeframe}
                                onChange={setTimeframe} isDisabled={false} size={'lg'}>
                                    <option value="weeks">Weekly</option>
                                    <option value="months">Monthly</option>
                                    <option value="quarters">Quarterly</option>
                                    <option value="years">Yearly</option>
                                </Select>
            </>
              }
                {showObject['status'] && <>
                    <FormLabel fontWeight="bold" mt={4}>Status:</FormLabel>
                    <Select
                                name="goal_status"
                                value={status}
                                onChange={handleStatusChange} isDisabled={false} size={'lg'}
                                >
                                    <option value="1">Active</option>
                                    <option value="2">Paused</option>
                                    <option value="3">Cancelled</option>
                                </Select>
                </>}
        </ModalBody>

        <ModalFooter>
          <Button borderRadius="0px" colorScheme="forwardOrange" mr={3} onClick={handleSubmit} isDisabled={isDisabled}>
            {submit_text}
          </Button>
          <Button borderRadius="0px" variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
        </FormControl>
        </>
  );
};

export default NewGoalForm;