import { VStack, HStack, useColorModeValue, Flex, Heading, Text, Menu, MenuButton, IconButton, MenuList, MenuItem, useDisclosure} from '@chakra-ui/react';
import React, { useState, useContext } from 'react';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { FormContext } from '../../../context/FormContext.jsx';
import { useNavigate } from 'react-router-dom';
import NewProjectDropdown from '../../projects/components/NewProjectDropdown.jsx';
import HabitDropdown from '../../habits/components/HabitDropdown.jsx';
import c from '../../../uc_constants/fwd_constants.js';
import { useAreas } from '../../../data_hooks/elements/useAreas.jsx';
import ShareAreaForm from '../../sharing/forms/ShareAreaForm.jsx';


const AreaDetailHeader = ({  area, ...props }) => {

  const {state, dispatch} = useContext(ForwardContext);
  const { addHabit, addProject, addGoal, editArea } = useContext(FormContext);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const navigate = useNavigate();
  const ua = useAreas();
  const detail_id = props.detail_id ? props.detail_id : 0;
  let default_project = null;
  if( state.projects[area.id] && 
      state.projects[area.id].length > 0) {
    default_project = state.projects[area.id].filter(proj => proj.status <= 1)[0].id;
  }
 
    /*Formatting */
    const bgColor = useColorModeValue('forwardWhite.400','forwardBlue.700');
    const bdColor = useColorModeValue('forwardWhite.600','forwardBlue.800');

  let fontWeight = 'bold';
  let boxShadow="md";
  let fontSize ="lg";
  let px=2;
  let py=2;
  let ml = "0px";


  const handleProjectJump = (newValue) => {
    navigate('/forward/project/' + newValue)
  }

  const handleHabitJump = (newValue) => {
    navigate('/forward/habit/'+newValue)
  }

  const handleShareClick = () => {
    onOpen();

  }

  const handleAreaArchive = () => {
    ua.upsertAreaProperty(area,'status',3);
    ua.upsertAreaProperty(area,'order_id',999);
      }


  return (
    <HStack 
    width="100%"
    py={py} 
    px={px}
    ml={ml}
    backgroundColor={bgColor}
      flexWrap="nowrap" 
      boxShadow={boxShadow} 
      borderBottom="2px"
      borderColor={bdColor}
      justifyContent="space-between"
      alignItems="baseline"
      cursor="default"
      {...props}>
        <VStack alignItems="flex-start" width="100%" spacing={0}>
        <Heading size={fontSize} fontWeight={fontWeight}>{area.area_name}</Heading>
        <Text fontStyle="oblique" fontSize="xs" marginTop="0px !important">Life Area Summary</Text>
        <Flex flexDirection={{'base':'column','md':'row'}} justifyContent="flex-start" width="100%" gap={1}>
            <HStack my={1}>
                <Text>Projects:</Text>
                <NewProjectDropdown value={parseInt(detail_id)}  defaultValue={parseInt(detail_id)} onChange={handleProjectJump}  area_limit = {area.id} />
                </HStack>
                <HStack>
                <Text>Habits:&nbsp;&nbsp;&nbsp;</Text>
                <HabitDropdown value={parseInt(detail_id)}  defaultValue={parseInt(detail_id)} onChange={handleHabitJump}  area_limit = {area.id} />
                </HStack>
        </Flex>
        </VStack>
        <Menu>
                    <MenuButton as={IconButton} icon={c.main_icons.settings} variant="ghost" size="lg" borderRadius={0} />
                    <MenuList>
                        <MenuItem onClick={() => editArea(area)}>Edit</MenuItem>
                        <MenuItem onClick={() => handleShareClick()}>Share this Area</MenuItem>
                        <MenuItem onClick={handleAreaArchive}>Archive</MenuItem>
                    </MenuList>
                </Menu>
                <ShareAreaForm area={area} isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>
                
        
    </HStack>
  );
};

export default AreaDetailHeader;
