import React, {useState} from 'react';
import {
  Box,
  IconButton,
  useDisclosure,
  VStack,
  HStack,
  Text,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';

const GoalPointSelector = ({ value, onValueChange, isDisabled, size, ...props }) => {

  const px_size = size || 'md';
  const [selected, setSelected] = useState(value);
  const btnColors = useColorModeValue('forwardWhite','forwardBlue');
  const brdColor = useColorModeValue('forwardWhite.800','forwardBlue.800');
  const txtColor = useColorModeValue('black','white');
  const selectedColor = useColorModeValue('forwardBlue','forwardGold');

    const handleChange = (newValue) => {
        setSelected(newValue);
        onValueChange(newValue);
    };
    

    return (
        <HStack spacing={0} width="100%">
            {[1, 2, 3, 5, 8].map((num) => (
                <Button 
                    key={num}
                    flex="1"
                    size={px_size}
                    borderRadius={0}
                    textColor={txtColor}
                    borderRight={num < 8 ? "1px" : "0" }
                    borderColor={brdColor}
                    onClick={() => handleChange(num)}
                    colorScheme={selected === num ? selectedColor : btnColors}
                >
                    {num}
                </Button>
            ))}
        </HStack>
    );
  };
  export default GoalPointSelector;


