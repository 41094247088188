import React from 'react';
import { Box, Heading, Text, VStack, Center, useMediaQuery, HStack, UnorderedList, ListItem, useColorModeValue, Divider} from '@chakra-ui/react';
import MyHeader from '../general/components/MyHeader';
import Footer from '../general/components/Footer';



const Pricing = () => {
    const isMobile = useMediaQuery('(max-width: 600px)')[0];
    const bgColor = useColorModeValue('forwardWhite.100', 'gray.700');

    return (
        <Box width="100%">
            <MyHeader />
            <Center px={2} width="100%">
                <VStack spacing={2} width="100%">
                    <Center width="100%">
                        <VStack>
                            
                        <Box
                        width="100vw" // Modify this line
                        height={isMobile ? '200px' : '200px'}
                        position="relative"
                    >
                        <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            backgroundImage: "url('/fwd/fwd_hero_v1.png')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'left',
                            opacity: 0.7, // Adjust opacity here
                            zIndex: -1, // Make sure this stays behind the content
                          }}
                          />
                        <VStack
                        position="absolute"
                        top={isMobile ? '50%' : '55%'}
                        left="50%"
                        width="100%"
                        transform="translate(-50%, -50%)"
                        textAlign="center"

                        spacing={isMobile ? 0 : 2}
                        >
                            <Box
                            backgroundColor="rgba(255, 255, 255, 0.8)"
                            py={8}
                            px={16}
                            m={2}
                            boxShadow="rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;"
                            borderRadius={8}
                            >
                        <Heading
                            as="h1"
                            size={isMobile ? 'lg': "xl"}
                            color="black"
                            mb={3}
                        >
                            Forward Pricing
                        </Heading>
                        <Text fontWeight={isMobile ? '' : 'bold'} fontSize={isMobile ? 'sm' : 'lg'}
                                color="black">
                            </Text>
                            </Box>
                            </VStack>
                        </Box>
                        </VStack>
                    </Center>
                    </VStack>
            </Center>
            {/* Pricing Content */}
            <Center as="VStack" flexDir="column">
                <Text my={4} fontSize="xl" fontStyle="oblique">Use Forward for free to start, upgrade anytime!</Text>
            <HStack p={4} alignItems="flex-start" maxWidth="900px" spacing={8}>
                <Box p={4} width="100%" border="1px solid gray" boxShadow="md" backgroundColor={bgColor} borderRadius="10px">
                    <Heading as="h2" size="lg" mb={0} >
                        Free Plan
                    </Heading>
                    <Text fontStyle="oblique" borderBottom="1px solid" mb={2}>Enough for most users.</Text>
                    <UnorderedList>
                        <ListItem>Up to 5 Live Habits</ListItem>
                        <ListItem>Up to 50 Live Tasks in 5 Live Projects</ListItem>
                        <ListItem>Up to 5 Live Goals</ListItem>
                        <ListItem>90 Days of History Visible</ListItem>
                        <ListItem>Share up to 3 Life Areas with Others</ListItem>
                    </UnorderedList>
                    <Text>&nbsp;</Text>
                    <Text>&nbsp;</Text>
                    <Divider my={4}/>
                    <Text textAlign="center" my={4} fontSize="xl" fontWeight="bold">Free Forever!</Text>
                    </Box>
                    <Box p={4} width="100%" border="1px solid gray" boxShadow="md"  backgroundColor={bgColor}  borderRadius="10px">
                    <Heading as="h2" size="lg" mb={0}>
                        Premium
                    </Heading>
                    <Text fontStyle="oblique" borderBottom="1px solid" mb={2}>No Limits, Fully Powered!</Text>
                    <UnorderedList>
                        <ListItem>Unlimited Habits, Tasks, Projects, Goals, and Life Areas!</ListItem>
                        <ListItem>Unlimited History Visible</ListItem>
                        <ListItem>Unlimited Sharing of your Life Areas with others</ListItem>
                        <ListItem>Premium Support</ListItem>
                        <ListItem>Early Access to New Features</ListItem>
                    </UnorderedList>
                    <Divider my={4}/>
                    <Text textAlign="center" my={4} fontSize="xl" fontWeight="bold">$3.00 per month</Text>
                    </Box>
                
            </HStack>
            <Text my={4} fontSize="xl" fontStyle="oblique">Upgrade to Premium at any time via your account page!</Text>
            </Center>
            <Box height="15vh"></Box>
            <Footer />
        </Box>
    );
};

export default Pricing;