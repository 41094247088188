import { Box, HStack, Button, Text, useColorModeValue, IconButton, ButtonGroup, useDisclosure } from '@chakra-ui/react';
import React, { useState, useContext } from 'react';
import { BiEdit, BiMenu, BiTrash } from 'react-icons/bi';
import ConfirmDialog from '../components/ConfirmDialog';
import { AiOutlinePause } from 'react-icons/ai';

import { useAreas } from '../../../data_hooks/elements/useAreas';
import { useHabits } from '../../../data_hooks/elements/useHabits';
import { useProjects } from '../../../data_hooks/elements/useProjects';
import { useGoals } from '../../../data_hooks/elements/useGoals';
import { FaArchive } from 'react-icons/fa';


const DndRow = ({ element, element_name, dragHandle, editFunction, deleteFunction}) => {

    const ar = useAreas();
    const ha = useHabits();
    const pr = useProjects();
    const go = useGoals();

    const bgColor = useColorModeValue("forwardWhite.500","forwardBlue.700");
    const btnColor = useColorModeValue("forwardWhite.300","forwardBlue.800");

    const { isOpen: isOpenCD, onOpen: onOpenCD, onClose: onCloseCD } = useDisclosure();

    const e_type = element_name.split('_')[0];

    const archiveHandler = async () => {
        console.log('archiving');
        if(e_type === 'area') {
            //console.log('in if');
            ar.archiveArea(element.user_id, element.id)
        }
        else if(e_type ==='project') {
            pr.upsertProjectProperty(element,'status',3);
            pr.upsertProjectProperty(element,'order_id',999);

        }
        else if (e_type === 'habit') {
            ha.upsertHabitProperty(element,'status', 3)
            ha.upsertHabitProperty(element,'order_id',999);


        }
        else if (e_type === 'goal') {
            go.upsertGoalProperty(element,'status', 3)
            go.upsertGoalProperty(element,'order_id',999);


        }
        onCloseCD();
    }
  
  const status_name = 'status'

  let text_element = <Text
  textOverflow="clip"
  isTruncated
  overflow="hidden"
  maxHeight="40px"
  flex="6"
  >{element[element_name]}</Text>;
  if (parseInt(element[status_name]) === 2) {
    text_element = <HStack>
                    <AiOutlinePause />
                    <Text textColor="gray" fontStyle="italic">{element[element_name]} (paused)</Text>
                    </HStack>
  }


  return (
    <HStack 
    height="40px" 
    width="100%" 
    bgColor={bgColor} 
    justifyContent="space-between"
    alignContent="baseline">
        <HStack flex="1"
        justifyContent="stretch"
        maxWidth="70%"
        alignContent="baseline"
        >

<Button
  size="lg"
  marginTop="0px"
  padding="0px"
  borderRadius="0px"
  height="40px"
  width="40px"
  aria-label={"Move "+e_type}
  backgroundColor={btnColor}
  {...dragHandle}
>
  <BiMenu size="30px" /> {/* Adjust size here */}
</Button>

      {text_element}
      {e_type === 'project' && <Text fontSize="xs" fontStyle="oblique">{element.project_type===2 ? 'Default' : ''}</Text>} 
      </HStack>
      <ButtonGroup>
      <IconButton
        size={{'base':"sm",'md':"md"}}
        marginTop="0px"
        borderRadius="0px"
        icon={<BiEdit />}
        aria-label={'Edit '+e_type+''}
        backgroundColor={btnColor}
        onClick={() => editFunction()}
      />
      <IconButton
        size={{'base':"sm",'md':"md"}}
        marginTop="0px"
        borderRadius="0px"
        icon={<FaArchive />}
        aria-label={'Archive '+e_type+''}
        backgroundColor={btnColor}
        onClick={onOpenCD}
      />
      </ButtonGroup>
      <ConfirmDialog
            isOpen={isOpenCD}
            onClose={onCloseCD}
            onConfirm={archiveHandler}
            title={'Archive '+e_type}
            description={'Are you sure you want to archive this '+e_type+'? '}
            confirmButtonText='Archive'
            />
    </HStack>
  );
};

export default DndRow;
