import dates from '../dates/dates';
import { Text } from '@chakra-ui/react';

const getTotal = (habit_type, values, startDate) => {

  let total = 0;
  if(!values) {
    return 0;
  }
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startDate);
    currentDate.setHours(0, 0, 0, 0);
    //console.log(currentDate);
    currentDate.setDate(currentDate.getDate() + i);
    const dateString = dates.formatDate(currentDate,'yyyy-MM-dd');
    //console.log(dateString);
    if (!values[dateString]) {
      total = total;
    } else if (habit_type === 'yesNo') {
      total += (values[dateString] >= 1 ? 1 : 0);
    } 
    else if(habit_type === 'numeric' && !isNaN(Number(values[dateString]))) {
      total += Number(values[dateString]);
    }
  }
  total = parseInt(total * 100);
  total = total/100.
  return String(total);
};

const getMostRecentTarget = (targets, startDate) => {
  //TODO FIX FOR ANNOYING BUG WITH FIRST START OF WEEK
  let mostRecentTarget = null;
  let mostRecentDate = null;

  for (const i in targets) {
      const targetDateStr = targets[i].date;
      // Split the date string into components
      const [year, month, day] = targetDateStr.split('-').map(Number);
      // Create a new Date object representing midnight in the local time zone
      const targetDate = new Date(year, month - 1, day);

      if (targetDate <= startDate && (mostRecentDate === null || targetDate > mostRecentDate)) {
          mostRecentTarget = [targets[i].target_value, targets[i].is_greater_than];
          mostRecentDate = targetDate;
          //console.log(mostRecentDate);
      }
  }
  return mostRecentTarget;
};
  const isTargetHit = (target, total) => {
    if (!target) {
      return false;
    }
    const is_cw_hit = target[1] === false ? parseFloat(total) <= parseFloat(target[0]) : parseFloat(total) >= parseFloat(target[0]);
    return is_cw_hit;
  }

  const newStreakMedal = (streak_length) => {
    if(streak_length <= -10) {
      return {name:'brown',color:'100',textColor:'white'};
    }
    else if (streak_length <= -5) {
      return {name:'red',color:'200',textColor:'white'};
    }
    else if (streak_length >= 20) {
      return {name:'diamond',color:'700',textColor:'white'};
    }
    else if (streak_length >= 15) {
      return {name:'gold',color:'600',textColor:'white'};
    }
    else if (streak_length >= 10) {
      return {name:'silver',color:'500',textColor:'white'};
    }
    else if (streak_length >= 5) {
      return {name:'bronze',color:'400',textColor:'white'};
    }
    else {
      return {name:'none',color:'300',textColor:'black'};
    }

  }

  const getHabitCellColor = (habit, value, mode, lim = false) => {
    let color;
    let hue = mode === 'light' ? "100" : "600";
    if(value === '') {
    color = "gray";
    }
    else {
      if (habit.habit_type === 'yesNo') {
        if (value === 1 || (value===-1 && lim)) {
          color = "green";
        }
        else if (value === 0) {
          color = "gray";
        }
        else if (value === -1 || (value===1 && !lim)) {
          color = "red";
          if (mode === 'dark') {
            hue = "400";
          }
        }
      }
      else {
        if(!lim) {
        color = value > 0 ? "green" : "red";
        }
        else {
          color = value > 0 ? "red" : "green";
        }
      }
    }

    return [color,hue];
  }

const getProgressDetails = (targetIsSet, current_target, target_direction, total, projected_value) => {
  let tooltip_text = [
    <Text key={1}>Now: {parseFloat(total).toFixed(2)}</Text>,
    <Text key={2}>Projected: {parseFloat(projected_value).toFixed(2)}</Text>,
    <Text key={3}>Target: {current_target}</Text>,
    <Text key={4}>Click to adjust</Text>
  ]; 
  let tvp_fs = '2xs';
    let tvp_text = '';
    let bar_color = 'gray';
    if (targetIsSet) {
      const tdm = target_direction ? 1 : -1;
      //Now we can set bar color and projection
      //tvp_text = ': '+projected_value.toFixed(1)+'/'+String(current_target);
      tvp_text = String(current_target);
      const target = current_target * tdm;
      if (tdm === 1 && total >= target) {
          bar_color = 'green.700';
      } else {
          if (projected_value*tdm >= (1 + (0.3 * tdm)) * target) {
              bar_color = 'green.600';
          } else if (projected_value*tdm >= (1 + (0.1 * tdm)) * target) {
              bar_color = 'green.500';
          } else if (projected_value*tdm >= (1 + (-0.05 * tdm)) * target) {
              bar_color = 'green.400';
          } else if (projected_value*tdm >= (1 + (-0.3 * tdm)) * target) {
              bar_color = 'yellow.400';
          } else if (projected_value*tdm >= (1 + (-0.5 * tdm)) * target) {
              bar_color = 'red.300';
          } else {
              bar_color = 'red.500';
          }
      }

      if (tvp_text.length >= 15) {
        tvp_fs = '4xs';
      }
      else if (tvp_text.length >= 12) {
        tvp_fs = '3xs';
      }

    }

    //console.log(bar_color);

    return {tvp_text, tvp_fs, bar_color, tooltip_text}
}

const calculateProjectedValue = (existingValue, weekStart, baseDate) => {
  let projected_value = 0;
  const percent_of_week = dates.getPercentOfWeek(baseDate,weekStart);
  if(percent_of_week > 0) {
      projected_value = existingValue * (1/parseFloat(percent_of_week));
    }
    return projected_value
}


const getMinStartDateSingle = (habit) => {  
  let minDate = null;
  if (!habit.targets || Object.keys(habit.values).length === 0) {
    return minDate;
  } else {
    Object.keys(habit.values).forEach((key) => {
      const dateParts = key.split('-'); // Splitting the date string into parts
      // The month in JavaScript Date constructor is 0-indexed (0 for January, 1 for February, etc.)
      const targetDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

      // If minDate is null (first iteration) or targetDate is earlier than minDate, update minDate
      if (minDate === null || targetDate < minDate) {
        minDate = targetDate;
      }
    });
  }
  //console.log(minDate);

  return minDate;
}

export default {
    getTotal,
    getMostRecentTarget,
    isTargetHit,
    newStreakMedal,
    getHabitCellColor,
    getMinStartDateSingle,
    getProgressDetails,
    calculateProjectedValue,
}