import { Alert, AlertIcon, Box, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';

const AlertBar = ({ status = "info", message = "", duration = 5000 }) => {
  const toast = useToast();

  useEffect(() => {
    if (message) {
      toast({
        duration: duration,
        isClosable: true,
        render: () => (
          <Alert status={status} variant="left-accent">
            <AlertIcon />
            <Box flex="1">{message}</Box>
          </Alert>
        ),
      });
    }
  }, [message, status, duration, toast]);

  return null;
};

export default AlertBar;
