
const flattenAndSortIndexedObjectByDate = (indexedObject, sortBy) => {
    // Flatten the reminders arrays into a single array
    const allItems = Object.values(indexedObject).flat();
  
    // Sort the reminders by next_due_date
    allItems.sort((a, b) => new Date(a[sortBy]) - new Date(b[sortBy]));
  
    return allItems;
  }

  const filterIndexedObjectByDate = (indexedObject, date, isAfter = true) => {
    // Flatten the objects arrays into a single array
    const allItems = Object.values(indexedObject).flat();
  
    // Filter the items by the given date
    const filteredItems = allItems.filter(item => 
        isAfter 
            ? new Date(item.next_due_date) >= new Date(date) 
            : new Date(item.next_due_date) <= new Date(date)
    );
  
    return filteredItems;
}

const ensureArray = (input) => {
  if (Array.isArray(input)) {
    return input;
  } else {
    return [input];
  }
};

  export default {
    flattenAndSortIndexedObjectByDate,
    filterIndexedObjectByDate,
    ensureArray,
  }

