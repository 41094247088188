
import React, { useState } from 'react';
import { Box, HStack, Text, Center, useColorModeValue} from '@chakra-ui/react';
import { AiOutlineSetting } from 'react-icons/ai';

const HabitWeekHeader = ({ currentWeek, ...props}) => {

    const bgColor = useColorModeValue("forwardWhite.100","gray.800");

    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const weekDates = [];
    for (let i = 0; i < 7; i++) {
        const currentDate = new Date(currentWeek);
        currentDate.setDate(currentDate.getDate() + i);
        weekDates.push(
        currentDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
        );
    }

  return (
    <HStack bg={bgColor} p={1} w="100%" justifyContent="right" borderBottom="1px" {...props}>
                  {/*Empty Space at the front*/}
                  <Text flex={{'base':'1','md':"6"}}> 
                  </Text>
                  {/* Each Day of the week */}
                  {daysOfWeek.map((day, index) => (
                  day !== 'Sun' ?
                  <Box key={day} flex="2" textAlign="center" borderRight={'1px solid gray'}>
                    <Text fontSize={{'base':'xs','md':'md'}}>{day}</Text>
                    <Text fontSize={{'base':'2xs','md':'md'}}>{weekDates[index]}</Text>
                  </Box> : 
                  <Box key={day} flex="2" textAlign="center" borderRight={{'base':'0px','md':'1px solid gray'}}>
                  <Text fontSize={{'base':'xs','md':'md'}}>{day}</Text>
                  <Text fontSize={{'base':'2xs','md':'md'}}>{weekDates[index]}</Text>
                </Box>
                ))}
                {/* Progress bar space */}
                <Box display={{'base':'none','md':'block'}} flex="6" textAlign="center" borderRight="1px solid gray">
                <Center>
                <HStack alignItems="flex-end">
                <Text fontSize="lg">Total</Text>
                <Text fontSize="2xs" fontStyle="italic">Proj</Text>
                </HStack>
                </Center>
                </Box>
                {/* Settings Icon */}
                <Box display={{'base':'none','md':'block'}} flex="0.5" textAlign="center">
                  <AiOutlineSetting />
                </Box>
                </HStack>
  );
};

export default HabitWeekHeader;
