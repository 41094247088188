/* 
This is a function that will calculate and populate a stat object that we can assign to 'habit_'+id within the stat context.
It should, given a habit object, return the stat object.
A stat object will look like the following:
 - entity (string)
 - id (int)
 - la_id (int)
 - weekly_stats (obj)
    - start of week (obj)
        - stat 1: value
        - stat 2: value
 - current_stats (obj)
    - stat 3: value
    - stat 4: value
    ...

The idea then is that all stats will be pulled from this object, thus centralizing calculation. 
If/when a habit is updated (new data is added), then this can run to update the stats that are displayed.
As such, we should have two filters: 
- whether all stats, just current, or just weekly are calculated
- and if weekly is, then from what date moving forward is recalculated
The stat context reducer can then handle updating only provided data fairly easily.
*/
import hh from "../uc_utils/habits/habits.js"
import dh from "../uc_utils/dates/dates.js"
import c from "../uc_constants/fwd_constants.js"

const calcHabits = (habit, calc_mode, week_from = null) => {

    //console.log(habit)
    let my_stats = {'entity':'habit',
                    'id':habit.id,
                    'la_id':habit.area_id,
                    'status':habit.status}

    if (['all', 'weekly'].includes(calc_mode)) {
        my_stats['weekly_stats'] = {}
        //Now, loop through from the week_from date and add in statistics

        //First, let's set the start date
        let startDate = hh.getMinStartDateSingle(habit);
        if(startDate === null) {
          startDate = new Date();
        }
        if(week_from !== null && calc_mode !== 'all' )
        {
            startDate = dh.getStartOfWeek(week_from);
        }
        //And the end date
        const endDate = new Date();
        let week_count = 0;
        let benchmark = 0;

        //console.log(startDate);

        for (let weekStart = dh.getStartOfWeek(startDate); weekStart <= endDate; weekStart = dh.addDaysToDate(dh.getStartOfWeek(weekStart), 7)) {

            week_count = week_count + 1;
            const previous_week = dh.formatDate(dh.addDaysToDate(weekStart,-7))
            const p2_week = dh.formatDate(dh.addDaysToDate(weekStart,-14))
            const p3_week = dh.formatDate(dh.addDaysToDate(weekStart,-21))
            const p4_week = dh.formatDate(dh.addDaysToDate(weekStart,-28))
            const isCurrentWeek = dh.isDateInCurrentWeek(new Date(), weekStart)


            //Get the Basic Stuff
            const total = hh.getTotal(habit.habit_type, habit.values, weekStart);
            const target = hh.getMostRecentTarget(habit.targets, weekStart);
            const targetsHit = target !== null && hh.isTargetHit(target, total) ? 1 : 0;
            const totalTargets = target !== null ? 1 : 0;
            const totalValues = parseFloat(total);


            //Get the streak calc
            let live_streak = 0;
            let previous_streak = my_stats.weekly_stats?.[previous_week]?.streak ?? 0;
            if (targetsHit === 1) {
                if(previous_streak >= 0) {
                    live_streak = previous_streak + 1;
                }
                else {
                    live_streak = 1;
                }
            }
            else if (targetsHit === 0 && totalTargets === 1) {
                if(isCurrentWeek) {
                    live_streak = previous_streak;
                }
                else if(previous_streak >= 0) {
                    live_streak = -1;
                }
                else {
                    live_streak = previous_streak - 1;
                }
            }

            //Habit Index
            let index_value = 100;
            let base_value = 0;
            let lt_value = 0;
            const lim = habit.is_less_better;
            if(week_count <= c.hi_baseline_period) {
                index_value = 100;
            }
            else {
                if (week_count === (c.hi_baseline_period+1)) {
                    benchmark = (my_stats.weekly_stats[previous_week].total+
                                 my_stats.weekly_stats[p2_week].total+
                                 my_stats.weekly_stats[p3_week].total+
                                 my_stats.weekly_stats[p4_week].total)/4.
                }
                if(week_count > (c.hi_baseline_period+1)) {
                  if (my_stats.weekly_stats[previous_week].habit_index  < my_stats.weekly_stats[p2_week].habit_index) {
                  benchmark = benchmark * (lim ? 1.02 : 0.97);
                }
                  else if (my_stats.weekly_stats[previous_week].habit_index >= my_stats.weekly_stats[p2_week].habit_index) {
                    benchmark = benchmark * (lim ? 0.97 : 1.02);
                  }
              }

                if(!lim) {
                base_value = 100.*((0.4*my_stats.weekly_stats[previous_week].total)+
                             (0.3*my_stats.weekly_stats[p2_week].total)+
                             (0.2*my_stats.weekly_stats[p3_week].total)+
                             (0.1*my_stats.weekly_stats[p4_week].total))/benchmark;
                }
                else {
                  base_value = 100.*benchmark/((0.4*my_stats.weekly_stats[previous_week].total)+
                             (0.3*my_stats.weekly_stats[p2_week].total)+
                             (0.2*my_stats.weekly_stats[p3_week].total)+
                             (0.1*my_stats.weekly_stats[p4_week].total));
                }

                if(!lim) {
                lt_value = c.hi_lt_multiple * (my_stats.weekly_stats[previous_week].hi_lt + (base_value >= c.hi_beat_measure ? c.hi_lt_beat_add : c.hi_lt_miss_sub))
                lt_value = lt_value < -1 * base_value ? -1*base_value : lt_value;
                }
                else {
                  lt_value = c.hi_lt_multiple * (my_stats.weekly_stats[previous_week].hi_lt + (base_value <= (c.hi_beat_measure+10) ? c.hi_lt_beat_add : c.hi_lt_miss_sub))
                lt_value = lt_value < -1 * base_value ? -1*base_value : lt_value;
                }
                index_value = parseInt((base_value + lt_value).toFixed(0));
            }


            // Store the stats for this week for this habit
            my_stats['weekly_stats'][dh.formatDate(weekStart)] = {
                'total':totalValues,
                'target':target,
                'targets':totalTargets,
                'targets_hit':targetsHit,
                'streak':live_streak,
                'week':week_count,
                'hi_base':base_value,
                'hi_lt':lt_value,
                'habit_index':index_value
            };
          }



    }
    if (['all', 'current'].includes(calc_mode)) {
        my_stats.current_stats = {}
        const thisWeekToGrab = dh.formatDate(dh.getStartOfWeek(new Date()));
        
        my_stats.current_stats.total = my_stats.weekly_stats[thisWeekToGrab].total;
        my_stats.current_stats.target = my_stats.weekly_stats[thisWeekToGrab].target;
        my_stats.current_stats.projection = hh.calculateProjectedValue(my_stats.weekly_stats[thisWeekToGrab].total,dh.getStartOfWeek(new Date()),new Date());
        my_stats.current_stats.streak = my_stats.weekly_stats[thisWeekToGrab].streak;
        my_stats.current_stats.best_streak = Math.max(...Object.values(my_stats.weekly_stats).map(week => week.streak));
        my_stats.current_stats.worst_streak = Math.min(...Object.values(my_stats.weekly_stats).map(week => week.streak));
        my_stats.current_stats.targets = Object.values(my_stats.weekly_stats)
            .filter(week => dh.formatDate(dh.getStartOfWeek(new Date())) !== week)
            .reduce((sum, week) => sum + week.targets, 0);
        my_stats.current_stats.targets_hit = Object.values(my_stats.weekly_stats)
            .filter(week => dh.formatDate(dh.getStartOfWeek(new Date())) !== week)
            .reduce((sum, week) => sum + week.targets_hit, 0);

    }

    //console.log(my_stats);

    return my_stats;
}

const derivedHabitCalc = (my_stats, ui_currentWeek) => {
  //Calculate Stats based off of state.stats
  //console.log('Calculating Derived Habit Stats');
  const cw = dh.formatDate(ui_currentWeek)
  const pw = dh.formatDate(dh.addDaysToDate(ui_currentWeek,-7))
  const p2w = dh.formatDate(dh.addDaysToDate(ui_currentWeek,-14))
  const p3w = dh.formatDate(dh.addDaysToDate(ui_currentWeek,-21))
  const p4w = dh.formatDate(dh.addDaysToDate(ui_currentWeek,-28))
  
  let lw_hits = my_stats.reduce((total, obj) => {
    if (obj.weekly_stats && obj.weekly_stats[pw]) {
      return total + obj.weekly_stats[pw].targets_hit;
    }
    return total;
  }, 0);
  let lw_targets = my_stats.reduce((total, obj) => {
    if (obj.weekly_stats && obj.weekly_stats[pw]) {
      return total + obj.weekly_stats[pw].targets;
    }
    return total;
  }, 0);

  let lw_habit_score = lw_hits + '/' + lw_targets;
  let cw_hits =  my_stats.reduce((total, obj) => {
    if (obj.weekly_stats && obj.weekly_stats[cw]) {
      return total + obj.weekly_stats[cw].targets_hit;
    }
    return total;
  }, 0);

  let cw_hits_projected = my_stats.reduce((total, obj) => {
    if (obj.current_stats && obj.current_stats.target) {
      let sign = obj.current_stats.target?.[1] ? 1 : -1;
      let is_projected = 0;
      if (sign*obj.current_stats.projection >= 0.95*sign*obj.current_stats.target?.[0]) {
        is_projected =  1;
      }
      return total + is_projected;
    }
    return total;
  }, 0);
  
  let cw_targets = my_stats.reduce((total, obj) => {
    if (obj.weekly_stats && obj.weekly_stats[cw]) {
      return total + obj.weekly_stats[cw].targets;
    }
    return total;
  }, 0);


  let tw_habit_score = dh.formatDate(ui_currentWeek) === dh.formatDate(dh.getStartOfWeek(new Date())) ? 
        cw_hits_projected + '/' + cw_targets : 
        cw_hits + '/'+cw_targets;

  let p2_hits = my_stats.reduce((total, obj) => {
    if (obj.weekly_stats && obj.weekly_stats[p2w]) {
      return total + obj.weekly_stats[p2w].targets_hit;
    }
    return total;
  }, 0);
  
  let p3_hits = my_stats.reduce((total, obj) => {
    if (obj.weekly_stats && obj.weekly_stats[p3w]) {
      return total + obj.weekly_stats[p3w].targets_hit;
    }
    return total;
  }, 0);

  
  let p4_hits = my_stats.reduce((total, obj) => {
    if (obj.weekly_stats && obj.weekly_stats[p4w]) {
      return total + obj.weekly_stats[p4w].targets_hit;
    }
    return total;
  }, 0);


  let p2_targets = my_stats.reduce((total, obj) => {
    if (obj.weekly_stats && obj.weekly_stats[p2w]) {
      return total + obj.weekly_stats[p2w].targets;
    }
    return total;
  }, 0);
  
  let p3_targets = my_stats.reduce((total, obj) => {
    if (obj.weekly_stats && obj.weekly_stats[p3w]) {
      return total + obj.weekly_stats[p3w].targets;
    }
    return total;
  }, 0);

  
  let p4_targets = my_stats.reduce((total, obj) => {
    if (obj.weekly_stats && obj.weekly_stats[p4w]) {
      return total + obj.weekly_stats[p4w].targets;
    }
    return total;
  }, 0);

  let hit_rate = (100.*(lw_hits + p2_hits + p3_hits + p4_hits)/(lw_targets + p2_targets+p3_targets+p4_targets)).toFixed(2)


  let total_streak = my_stats.reduce((total, obj) => {
    if (obj.weekly_stats && obj.weekly_stats[cw]) {
      return total + obj.weekly_stats[cw].streak;
    }
    return total;
  }, 0);
  let avg_streak = (total_streak / cw_targets).toFixed(2);

  let total_hi = my_stats.reduce((total, obj) => {
    if (obj.weekly_stats && obj.weekly_stats[cw]) {
      return total + parseFloat(obj.weekly_stats[cw].habit_index);
    }
    return total;
  }, 0);

  let count_hi = my_stats.reduce((total, obj) => {
    if (obj.weekly_stats && obj.weekly_stats[cw]) {
      return total + (obj.weekly_stats[cw].habit_index >= 0 ? 1 : 0);
    }
    return total;
  }, 0);

  let average_hi = (total_hi / count_hi).toFixed(0);


  return {
    'lw_hits':lw_hits,
    'lw_targets':lw_targets,
    'lw_habit_score':lw_habit_score,
    'cw_hits':cw_hits,
    'cw_hits_projected':cw_hits_projected,
    'tw_habit_score':tw_habit_score,
    'hit_rate':hit_rate,
    'avg_streak':avg_streak,
    'avg_habit_index': average_hi,
  }
}

const singleHabitHistory = (habitStats) => {
  //console.log('calculating single habit history');
  //console.log(habitStats);
  const thisWeek = dh.formatDate(dh.getStartOfWeek(new Date()));
  
    const targetsHit = Object.entries(habitStats?.weekly_stats || {})
      .filter(([date, data]) => date !== thisWeek)
      .reduce((total, [date, week]) => {
        const target = week.targets_hit || 0;
        return total + (target ? 1 : 0);
      }, 0);
  
      const totalTargets = Object.entries(habitStats?.weekly_stats || {})
      .filter(([date, data]) => date !== thisWeek)
      .reduce((total, [date, week]) => {
        const target = week.targets || 0;
        return total + (target ? 1 : 0);
      }, 0);
  
      const totalTotals = Object.entries(habitStats?.weekly_stats || {})
      .filter(([date, data]) => date !== thisWeek)
      .reduce((total, [date, week]) => {
        const t1 = week.total || 0;
        return total + (t1);
      }, 0);

      const totalTargetValue = Object.entries(habitStats?.weekly_stats || {})
      .filter(([date, data]) => date !== thisWeek)
      .reduce((total, [date, week]) => {
        const t1 = week.target?.[0] || 0;
        return total + (t1);
      }, 0);
  
  
      const totalCount = Object.entries(habitStats?.weekly_stats || {})
      .filter(([date, data]) => date !== thisWeek)
      .reduce((total, [date, week]) => {
        const t1 = week.week || 0;
        return total + (t1>0 ? 1 : 0);
      }, 0);

      const totalStreak  = Object.entries(habitStats?.weekly_stats || {})
      .filter(([date, data]) => date !== thisWeek)
      .reduce((total, [date, week]) => {
        const t1 = week.streak || 0;
        return total + (t1);
      }, 0);
  
      const averageValue = (totalTotals/totalCount).toFixed(2);
      const averageTarget = (totalTargetValue/totalTargets).toFixed(2);
      const averageStreak = (totalStreak/totalTargets).toFixed(2);

      return {
        'targetsHit':targetsHit,
        'totalTargets':totalTargets,
        'totalTotals':totalTotals,
        'totalCount':totalCount,
        'averageValue':averageValue,
        'averageTarget':averageTarget,
        'averageStreak':averageStreak,
      }
}



export default {
    calcHabits,
    derivedHabitCalc,
    singleHabitHistory
}