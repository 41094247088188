import { useEffect, useContext, useCallback } from 'react';
import { ForwardContext } from '../../context/ForwardContext.jsx';
import { readAreas, updateAreaOrdering, upsertArea, deleteDbArea, readAreaHistory } from '../../database_api/areaService.js';
import { readGoals } from '../../database_api/goalService.js';
import { readNewGoals } from '../../database_api/newGoalService.js';
import { readReminders } from '../../database_api/reminderService.js';
import { readProjectsForArea } from '../../database_api/projectService.js';
import { trackCustomEvent } from '../../general/functions/plausibleEvent.js';
import { usePermissions } from '../permissions/usePermissions.jsx';
import { useProjects } from './useProjects.jsx';

export const useAreas = () => {
  const { state, dispatch, session } = useContext(ForwardContext)
  const { checkPermissionToAdd } = usePermissions();
  const { upsertFullProject } = useProjects();
  const syncAreas = useCallback(async (userId) => {
    console.log('Fetching user Areas');
    // your fetching and dispatching logic here
    try {
        const areasData = await readAreas(userId);
        const goalsData = await readGoals(userId);
        const newGoalsData = await readNewGoals(userId);
        const remindersData = await readReminders(userId);
        //console.log(goalsData);
        //console.log(remindersData);

        dispatch({ type: 'SET_AREAS', payload: areasData });

        //Now, build the goals object indexed by life area
        const areasArray = Object.values(areasData).flat();

        const initial_goals = areasArray.reduce((acc, area) => {
          acc[area.id] = [];
          return acc;
        }, {});
        Object.entries(goalsData).forEach(([index, goals]) => {
          initial_goals[index] = goals || [];
        });

        //console.log(initial_goals);
        dispatch({ type: 'SET_GOALS', payload: initial_goals });
        dispatch({ type: 'SET_NEW_GOALS', payload: newGoalsData });

        //Now, build the goals object indexed by life area
        const initial_reminders = areasArray.reduce((acc, area) => {
          acc[area.id] = [];
          return acc;
        }, {});
        Object.entries(remindersData).forEach(([index, reminders]) => {
          initial_reminders[index] = reminders || [];
        });

        //console.log(initial_reminders);
        dispatch({ type: 'SET_REMINDERS', payload: initial_reminders });


      } catch (error) {
        console.error('Failed to fetch areas:', error);
      }

  }, [dispatch, session]);

  /*******************************/
  /* Upsert an Area */
  /*******************************/

  const archiveArea = async(user_id, area_id) => {
    console.log('archive area '+area_id+' for '+user_id);
    dispatch({ type: 'ARCHIVE_AREA', payload: area_id });
    // Call your supabase function to archive the area
    const area_data = {id: area_id, user_id: user_id, status: 3, order_id:999};
    const area_update = upsertArea(area_data)
    //If not, alert to error
    if (!area_update)  {
        alert("Error Archiving Area!")
    }
    else {
      console.log('Area Archived!');
    }
  }

  const unarchiveArea = async(user_id, area_id) => {
    console.log('unarchive area '+area_id+' for '+user_id);
    dispatch({ type: 'UNARCHIVE_AREA', payload: area_id });
    // Call your supabase function to archive the area
    const area_data = {id: area_id, user_id: user_id, status: 1};
    const area_update = upsertArea(area_data)
    //If not, alert to error
    if (!area_update)  {
        alert("Error Archiving Area!")
    }
    else {
      console.log('Area Archived!');
    }
  }

  
  const deleteArea = async(user_id, area_id) => {
    console.log('deleting area '+area_id+' for '+user_id);
    dispatch({ type: 'DELETE_AREA', payload: area_id });
    // Call your supabase function to delete the area from the database
    const deleted_area = deleteDbArea(user_id, area_id);
    //If not, alert to error
    if (!deleted_area)  {
        alert("Error Deleting Area!")
    }
    else {
      console.log('Area Deleted!');
    }
  }

  /*******************************/
  /* Reorder Areas */
  /*******************************/
  const reorderAreas = async(user_id, area_id, old_spot, new_spot) => {
    //Construct object to send
    // Clone the current areas array from state
    let newAreas = [...state.areas];
    // Extract the area moved
    const movedArea = newAreas[old_spot];
    // Remove the movedArea from its old spot
    newAreas.splice(old_spot, 1);
    // Insert it at the new spot
    newAreas.splice(new_spot, 0, movedArea);

    // Create a new array to hold the updated areas with new order_ids
    let updatedAreas = newAreas.map((area, index) => {
      return { id: area.id, order_id: index+1 };
    });

    //Update State
    dispatch({ type: 'UPDATE_AREA_ORDER', payload: updatedAreas });

    //Update the database
    const area_update = await updateAreaOrdering(user_id, updatedAreas);

    console.log('Area order updated!');
    //If not, alert to error
    if (!area_update)  {
        alert("Error Updating Habit!")
    }

  }

  const upsertFullArea = async (user_id, area_data) => {
    let mode = 'create';
    if(area_data.id > 0) {
      mode = 'edit';
    }
    let permission_to_create = checkPermissionToAdd('areas',mode);
    console.log(permission_to_create);

    try {
      if(permission_to_create) {
        const new_area = await upsertArea(area_data);
        trackCustomEvent('FWD Area Added', {area_name: area_data.area_name});
        if (new_area) {
            dispatch({ type: 'UPSERT_AREA', payload: new_area });
            console.log('Area added/updated!');

            //Now, We Add a Ghost Project if desired
            const current_projects = await readProjectsForArea(user_id, parseInt(new_area[0]['id']));
            console.log(current_projects);
            if (current_projects.length <= 0) {
              console.log('adding ghost project')
              //Create a project to send
              //Dispatch it
              //Upsert it
              const project_data = {'user_id':user_id, 
                                'project_name':new_area[0]['area_name']+' General', 
                                'area_id':new_area[0]['id'], 
                                'description':'General Project for this Life Area',
                                'status':1,
                                'project_type':2, //Ghost Project Denotation
                                'start_date':new Date()}

                console.log(project_data);
                let res = await upsertFullProject(project_data);
                console.log(res);
                if (res.result === 'limit') {
                  return {result: 'project_limit',message:'project limit has been reached'}
                }
                else if (res.result === 'error') {
                  return {result:'project_error',message:res.message}
                }
            }
            //End Ghost Project Add
        if(mode === 'edit') {
          return {result:'success',message:'area edit successful'}
        }
        else {
          return {result:'success',message:'area add successful'}
        }
  //If not, alert to error
  } else  {
      alert("Error Adding Area!")
      return {result:'error',message:'area add failure at database level'}
  }
  }
  else {
    return {result:'limit',message:'area limit reached'}
  }
  }
  catch (error) {
    console.log('Area add failed:', error.message);
    return {result:'error',message:'area add failed.'}
  }

  }

  const syncAreaHistory = async (area) => {
    //Pull the data

    try {
    const area_history = await readAreaHistory(area.id)
    
    //console.log(area_history)
    //Update State
    dispatch({ type: 'SET_AREA_HISTORY', payload: area_history });
    dispatch({ type: 'SET_LIVE_AREA_ID', payload: area.id });
    } catch (error) {
      console.error('Failed to fetch area history:', error);
    }


  };



  /*
  const upsertFullArea = async (habit_data) => {

    
}*/

  return {syncAreas, archiveArea, unarchiveArea, deleteArea, reorderAreas, upsertFullArea, syncAreaHistory};
};