import { useContext } from 'react';
import { ForwardContext } from '../../context/ForwardContext';
import { upsertReminder as dbUpsertReminder, upsertReminderAction as dbUpsertReminderAction } from '../../database_api/reminderService';
import { usePermissions } from '../permissions/usePermissions';
import { trackCustomEvent } from '../../general/functions/plausibleEvent';
import dhf from '../../uc_utils/dates/dates';


export const useReminders = () => {
  const { state, dispatch, session } = useContext(ForwardContext)
  const {checkPermissionToAdd} = usePermissions();

  //
  //Add more functions as needed here
  //
  const upsertReminderProperty = async(reminder, property, value) => {
    const reminder_data = {'area_id':reminder.area_id, 'id':reminder.id, [property]:value  }

    //Submit to Database
    const new_goal = await dbUpsertReminder(reminder_data)
    //Update State

    if(property === 'status' && (value === 'abandoned' || value === 'complete')) {
        //Remove the habit
        dispatch({ type: 'REMOVE_REMINDER', payload: { id:reminder.id, areaId: reminder.area_id } });
    }
    else if (property === 'date_deleted' && value !== null) {
        dispatch({ type: 'REMOVE_REMINDER', payload: { id:reminder.id, areaId: reminder.area_id } });
    }
    else {
    dispatch({ type: 'UPSERT_REMINDER', payload: reminder_data });
    }
    
    console.log('Reminder added/updated!');
  //If not, alert to error
   if (!new_goal)  {
      alert("Error Updating Reminder!")
    }
  }

  
  const upsertFullReminder = async (reminder_data) => {
    let mode = 'create';
    if(reminder_data.id > 0) {
      mode = 'edit';
    }
    let permission_to_create = checkPermissionToAdd('reminders',mode);
    console.log(permission_to_create);

    try {
          if(permission_to_create) {
            const new_reminder = await dbUpsertReminder(reminder_data);
            trackCustomEvent('FWD Reminder Added', {reminder_name:reminder_data.task_name, cadence:reminder_data.cadence});
            if (new_reminder) {
              dispatch({ type: 'UPSERT_REMINDER', payload: new_reminder });
              console.log('Reminder added/updated!');
              if(mode === 'edit') {
                return {result:'success',message:'project edit successful'}
              }
              else {
                return {result:'success',message:'project add successful'}
              }
          //If not, alert to error
          } 
          else  {
              alert("Error Adding Project!")
              return {result:'error',message:'project add failure at database level'}
          }
      }
      else {
        return {result:'limit',message:'project limit reached!'}
      }
      }
      catch {
        return {result:'error',message:'habit add failed.'}
      }




  }

  const upsertReminderAction = async (reminder_action_data) => {
    try { 
      const newAction = await dbUpsertReminderAction(reminder_action_data);
      return {result:'success',message:'action add successful'}
    }
    catch {
      return {result:'error',message:'action add failed.'}
    }
  }

  const handleAction = async (reminder, action, num_days = 0) => {
    //Actions: delayed | done | skip
    console.log('Taking action: ', action);
    const reminder_action_data = {
      'id':reminder.id,
      'action':action,
      'action_date':dhf.formatDate(new Date(),'yyyy-MM-dd'),
    }
    const newAction = await upsertReminderAction(reminder_action_data);
    const old_due_date = new Date(reminder.next_due_date);
    let new_due_date;
    let new_status;
    if (action === 'delayed') {
      new_due_date = dhf.addDaysToDate(old_due_date,parseInt(num_days));
      new_status = 'active';
    }
    else if(reminder.cadence > 0) {
      new_due_date = dhf.addMonthsToDate(old_due_date,parseInt(reminder.cadence));
      new_status = 'active';
    }
    else {
      //One Time event
      new_due_date = old_due_date;
      if(action === 'skip') {
        new_status = 'abandoned';
      }
      else if (action === 'done') {
        new_status = 'complete';
      }

    }
    const reminder_data = {
      'id':reminder.id,
      'area_id':reminder.area_id,
      'next_due_date':new_due_date,
      'status':new_status,
    }

    const newReminder = await upsertFullReminder(reminder_data);

    if(reminder.cadence > 0) {
      dispatch({type:'UPSERT_REMINDER', payload: newReminder})
    }
    else {
      dispatch({type:'REMOVE_REMINDER', payload: newReminder})
    }

  }

  
  
  
  
  
  
  return {upsertReminderProperty, upsertFullReminder, upsertReminderAction, handleAction};
};