const newGoalReducer = (new_goals, action) => {
    switch (action.type) {
      case 'SET_NEW_GOALS':
        return { new_goals: action.payload };

        case 'UPSERT_NEW_GOAL': {
            console.log(action.payload)
            const updatedGoalProperties = action.payload[0];
            const goalId = updatedGoalProperties.id;
            const newAreaId = updatedGoalProperties.area_id;
          
            // Find the old area ID if it exists
            let oldAreaId = null;
            let existingGoal = null;
            for (let areaId in new_goals) {
              const goalIndex = new_goals[areaId].findIndex(goal => goal.id === goalId);
              if (goalIndex !== -1) {
                oldAreaId = areaId;
                existingGoal = new_goals[areaId][goalIndex];
                break;
              }
            }
          
            // Merge existing goal properties with updated properties
            const mergedGoal = existingGoal
              ? { ...existingGoal, ...updatedGoalProperties }
              : updatedGoalProperties;
          
            // Construct new goals object
            let newGoals = { ...new_goals };
          
            // Remove goal from old area if it was found
            if (oldAreaId) {
              newGoals[oldAreaId] = newGoals[oldAreaId].filter(goal => goal.id !== goalId);
            }
          
            // Add goal to new area
            newGoals[newAreaId] = newGoals[newAreaId]
              ? [...newGoals[newAreaId], mergedGoal]
              : [mergedGoal];
          
            return { new_goals: newGoals };
          }
          
          case 'REMOVE_NEW_GOAL': {
            const goalId = action.payload.goalId;
          const areaId = action.payload.areaId;
        
          return {
            new_goals: {
              ...new_goals,
              [areaId]: new_goals[areaId].filter(goal => goal.id !== goalId),
            },
          };
      
          }
          case 'UPSERT_NEW_GOAL_ENTRY': {
            const goalEntry = action.payload[0];
            const goalId = goalEntry.goal_id;
            const areaId = goalEntry.area_id;
        
            // Find the goal
            const areaGoals = new_goals[areaId];
            const goalIndex = areaGoals.findIndex(goal => goal.id === goalId);
            const goal = areaGoals[goalIndex];

            if(!goal.forward_new_goal_entries) {
                goal.forward_new_goal_entries = [];
            }
        
            // Update or add the goal entry
            let forwardNewGoalEntries = goal.forward_new_goal_entries.map(entry =>
                entry.id === goalEntry.id ? goalEntry : entry
            );
        
            // If the entry.id is not found, add the entry to the goal
            if (!goal.forward_new_goal_entries.find(entry => entry.id === goalEntry.id)) {
                forwardNewGoalEntries.push(goalEntry);
            }
        
            // Create the updated goal with the new entries
            const updatedGoal = {
                ...goal,
                forward_new_goal_entries: forwardNewGoalEntries
            };
        
            // Construct new goals object
            const newGoals = {
                ...new_goals,
                [areaId]: [
                    ...areaGoals.slice(0, goalIndex),
                    updatedGoal,
                    ...areaGoals.slice(goalIndex + 1)
                ]
            };
        
            return { new_goals: newGoals };
        }
        
     
      default:
        return {};
    }
  };
  export default newGoalReducer;
  