import { useEffect, useContext, useCallback } from 'react';
import { ForwardContext } from '../../context/ForwardContext.jsx';
import { usePermissions } from '../permissions/usePermissions.jsx';
import { useToast } from '@chakra-ui/react';
import { upsertEvent} from '../../database_api/eventService.js';

import { trackCustomEvent } from '../../general/functions/plausibleEvent.js';
import { readEvents } from '../../database_api/eventService.js';


export const useEvents = () => {
    const { state, dispatch, session } = useContext(ForwardContext);
    const session_id = state.session_id;
    const {checkPermissionToAdd} = usePermissions();
    const toast = useToast();

    const upsertEventFull = async ({event_data}) => {
        //Submit to Database
        let mode = 'create';
        if(event_data?.id && event_data.id > 0) {
          mode = 'edit';
        }
        let permission_to_create = checkPermissionToAdd('events',mode);
        
        try {
          if(permission_to_create) {
            console.log(event_data);
        const new_event = await upsertEvent(event_data);
        console.log(new_event);
        trackCustomEvent('FWD Event Added', {event_name: event_data.event_name});
        if (new_event) {
    
          //dispatch({ type: 'UPSERT_NEW_GOAL', payload: new_goal });
          dispatch({ type: 'UPSERT_EVENT', payload: new_event });
          console.log('Event added/updated!');
          if(mode === 'edit' > 0) {
            toast(  {   
                title: "Event Updated!",
                description: "Your Event has been updated successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
                }
            )
            return {result:'success',message:'Event edit successful'}
          }
          else {
            toast(  {   
                title: "Event Added!",
                description: "Your Event has been added successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
                }
            )
            return {result:'success',message:'Event add successful'}
            
          }
      //If not, alert to error
      } else  {
          alert("Error Adding Event!")
          return {result:'error',message:'Event add failure at database level'}
      }
      }
        else {
          return {result:'error',message:'Event limit reached.'}
        }
      }
      catch {
        return {result:'error',message:'Event add failed.'}
      }
    }

    const syncEvents = useCallback(async (userId) => {
        console.log('Fetching user Events');
    // your fetching and dispatching logic here
    try {
        const eventsData = await readEvents(userId);

        dispatch({ type: 'SET_EVENTS', payload: eventsData });

      } catch (error) {
        console.error('Failed to fetch events:', error);
      }

  }, [dispatch, session]);
  
    return {upsertEventFull, syncEvents};
};
