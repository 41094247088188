import React, { useState, useContext } from 'react';
import { Box, Button,  Heading, HStack, Center, Checkbox, Link, Text, VStack, Divider, UnorderedList} from "@chakra-ui/react";
import MyHeader from "../../general/components/MyHeader";
import Footer from "../../general/components/Footer";
import { Link as ReachLink } from 'react-router-dom';
import { ForwardContext } from '../../context/ForwardContext';

import { useParams } from 'react-router-dom';
import ForwardLoginForm from '../components/ForwardLoginForm';

import useAuth from '../hooks/useAuth';

const NewLogin = ({arrival_url=false}) => {
  const {app} = useParams();

    let app_code = "1";
    const [value, setValue] = useState(app_code);
    const {state, dispatch} = useContext(ForwardContext);

    const forwardUser = state?.account?.email || null;

    const { signOut } = useAuth();

    const handleChange = (val) => setValue(val);

    const handleSignoutForward = async () => {
        signOut('forward');
      }

    return (
        <>
        <MyHeader />
        <Box py={4} px={{ base: 4, md: 8 }} minHeight="90vh">
          <Center width="100%">
        <Box width="100%" maxWidth="1000px">
            <Heading as="h2" size="lg" my={4}>Login To Your Account</Heading>
            <Text my={4}  fontSize="sm">Need an account?&nbsp;<Link as={ReachLink} fontWeight="bold" color="teal" to={"/signup/"+app}>Create One!</Link></Text>
            <HStack width="100%">
                    <Button flex="1" size="lg" borderRadius={"20px"}  onClick={() => handleChange("1")} colorScheme={value === "1" ? "teal" : "gray"}>
                    <VStack spacing={0}>
                        <Text>Forward</Text>
                        <Text fontSize="xs" fontWeight="thin">Life Management Platform</Text>
                        </VStack>
                        </Button>
            </HStack>
            <Divider my={4} />

            {value === "1" && !forwardUser && (
                <ForwardLoginForm
                arrival_url={arrival_url} 
                mode="login"
            />
            )}
            {value === "1" && forwardUser && (
                <VStack spacing={2}>
                <Text>You are already logged in as {forwardUser} (Not you? <Link onClick={handleSignoutForward} color="teal" fontWeight="bold">Sign out</Link>)</Text>
                <Link color="teal" fontWeight="bold" as={ReachLink} to="/forward">Go to Forward</Link>
                </VStack>
            )}

            <UnorderedList>
            </UnorderedList>
        </Box>
        </Center>
        </Box>
        <Footer />
    </>
    );
};

export default NewLogin;