import dhf from '../dates/dates.js';

const getAccountAccessLevel = (account) => {
    //Return Either: 'Trial Access', 'Read Only', 'Subscriber', 'Lifetime Member', 'Owner'
    const now = new Date();


    if (account.account_level === 9) {
        return 'Owner';
    }
    else if (account.account_level === 8) {
        return 'Employee';
    }
    else if (account.account_level >= 7 ) {
        return 'Lifetime Gold';
    }
    else if (account.account_level >= 6) {
        return 'Lifetime';
    }
    else if (account.stripe_subscriptions 
            && account.stripe_subscriptions.canceled_at === null 
            && account.stripe_subscriptions.plan_amount > 0
            && now <= new Date(account.stripe_subscriptions.current_period_end*1000)
            ) {
                return 'Member';
        //Subscriber
    }
    else if (now <= dhf.addDaysToDate(new Date(account.created_at),31)) {
        //Trial Access
        return 'Free';

    }
    else {
        //Read Only
        return 'Free';

    }
}

const getTimeLeft = (account) => {
    //Return Either: 'Trial Access', 'Read Only', 'Subscriber', 'Lifetime Member', 'Owner'
    const now = new Date();

    /*console.log(account);
    console.log(account.stripe_subscriptions);
    console.log(account.stripe_subscriptions.canceled_at);
    console.log(account.stripe_subscriptions.current_period_end);
    console.log(new Date(account.stripe_subscriptions.current_period_end* 1000));
    console.log(account.stripe_subscriptions.plan_amount);
    console.log(dhf.addDaysToDate(new Date(account.created_at),31));
    console.log(now <= new Date(account.stripe_subscriptions.current_period_end* 1000));
    console.log(now <= dhf.addDaysToDate(new Date(account.created_at),31));
    */


    if (account.account_level === 9) {
        return 999;
    }
    else if (account.account_level === 8) {
        return 999;
    }
    else if (account.account_level === 7) {
        return 999;
    }
    else if (account.account_level === 6) {
        return 999;
    }
    else if (account.stripe_subscriptions 
            && account.stripe_subscriptions.canceled_at === null 
            && account.stripe_subscriptions.plan_amount > 0
            && now <= new Date(account.stripe_subscriptions.current_period_end)
            ) {
                return dhf.getDaysBetweenDates(new Date(account.stripe_subscriptions.current_period_end), now);
        //Subscriber
    }
    else if (now <= dhf.addDaysToDate(new Date(account.created_at),31)) {
        //Trial Access
        return dhf.getDaysBetweenDates(dhf.addDaysToDate(new Date(account.created_at),31), now);

    }
    else {
        //Read Only
        return 0;

    }
}

export default {
    getAccountAccessLevel,
    getTimeLeft,
  }