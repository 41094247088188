import React, { useState, useContext } from 'react';
import { HStack, Button, Text, useColorModeValue, VStack, Box, IconButton, useDisclosure, Flex, 
    Tooltip, Menu, MenuList, MenuButton, MenuItem, Badge, Input,
    Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody} from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillPauseCircle} from 'react-icons/ai';
import { BsFillSkipForwardFill} from 'react-icons/bs';
import { AiFillCaretDown } from 'react-icons/ai';

import { FormContext } from '../../../context/FormContext.jsx';
import { ForwardContext } from '../../../context/ForwardContext.jsx';

import dhf from '../../../uc_utils/dates/dates.js';
import rhf from '../../../uc_utils/tasks/reminders.js';

import { useReminders } from '../../../data_hooks/elements/useReminders.jsx';

import ConfirmDialog from '../../general/components/ConfirmDialog.jsx';


const NewReminderRow = ({ reminder, style, ...props}) => {
    //Styles are "long" and "day"
    //Use Day as default
  
  const ur = useReminders();
  const {state, dispatch} = useContext(ForwardContext);
  const { editReminder} = useContext(FormContext);
  const eventDate = dhf.createDateObject(reminder.next_due_date);
  const daysFromNow = dhf.getDaysBetweenDates(eventDate,new Date());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isACOpen, setIsACOpen] = useState(false);
  const onACClose = () => setIsACOpen(false);
  const [isDelayOpen, setIsDelayOpen] = useState(false);
  const [numDays, setNumDays] = useState('');

  const bgMode = useColorModeValue('light','dark');
  const bgColor = rhf.getReminderColor(daysFromNow, bgMode);
  const anniversary_count = rhf.getAnniversaryCount(reminder);

  const toggleDelay = () => setIsDelayOpen(!isDelayOpen);
  const handleInputChange = (event) => setNumDays(event.target.value);

  const handleSubmit = () => {
    //handleDelay(reminder, numDays);
    ur.handleAction(reminder, 'delayed', numDays);
    setIsDelayOpen(false);
  };


  const archiveReminder = async () => {
    ur.upsertReminderProperty(reminder, 'status', 'abandoned');
    onACClose();
  };

  const my_life_area = state.areas.filter(area => area.id === reminder.area_id)[0];

  let height = '16px !important';
  let width = '18px !important';
  let bdColor = useColorModeValue("forwardOrange.300","forwardOrange.600");
  let paddingLeft  = 0;
  let paddingRight = 0;
  let marginLeft = 0;
  let fontSize = 'xs';
  
  let showActions = false;
  if(daysFromNow <= 7) {
    //Within a week, present options to Complete | Delay | Skip
    showActions = true
    paddingRight = "0px"
  }

  if (style === 'long') {
  }
  
  

  return (
    <Tooltip label={reminder.task_name+" on "+dhf.formatDate(eventDate,'MMM dd')} placement="top" hasArrow>
    <HStack
    pl={paddingLeft}
    pr={paddingRight} 
    ml={marginLeft} 
    mr={0}
    width="100%"
    alignItems="flex-start" 
    justifyContent="stretch"
    spacing={0}>
        <Popover
            isOpen={isOpen} // Controlled visibility
            onClose={onClose} // Handling close
            placement="bottom"
            closeOnBlur={false}
            >
                <PopoverTrigger>
                            <Box 
                    border="1px solid" 
                    borderRadius={0} 
                    borderColor={'forwardBlue.100'}
                    boxShadow={'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'} 
                    height={height} 
                    minWidth={width}
                    cursor="pointer"
                    onClick={isOpen ? onClose : onOpen}
                    ></Box>
                </PopoverTrigger>
            <PopoverContent borderRadius="0px" >
            <PopoverArrow />
                <PopoverBody width="100%" display="flex" justifyContent="center">
                    <VStack width="100%">
              <HStack
                width="100%"
                py={1}
                spacing={2}
                justifyContent="stretch"
              >
                <VStack flex="1">
                <IconButton borderRadius={0} height="40px" width="100%" size="xs" icon={<AiFillCheckCircle />} colorScheme="green" onClick={() => ur.handleAction(reminder,'done')} />
                <Text fontSize="2xs" mt="0px !important">Complete</Text>
                </VStack>
                
                <VStack flex="1">
                <IconButton borderRadius={0} height="40px" width="100%" size="xs" icon={<BsFillSkipForwardFill />} colorScheme="gray" onClick={() => ur.handleAction(reminder,'skip')}/>
                <Text fontSize="2xs" mt="0px !important">Skip</Text>
                </VStack>
                <VStack flex="1">
                    <IconButton 
                    height="40px" width="100%"
                    borderRadius={0}
                    size="xs" 
                    icon={<AiFillPauseCircle />} 
                    colorScheme="forwardOrange" 
                    onClick={toggleDelay}
                    />
                    <Text fontSize="2xs" mt="0px !important">Delay</Text>
                    </VStack>
              </HStack>
              {isDelayOpen && (
                <VStack>
                    <Input variant="flushed" textAlign="center" fontSize="xl" mb={1} placeholder="Number of days" value={numDays} onChange={handleInputChange} />
                    <Button  borderRadius={0} width="100%"  colorScheme="forwardOrange" onClick={handleSubmit}>Delay</Button>
                    </VStack>
                )}
                </VStack>
              </PopoverBody>
              </PopoverContent>

        </Popover>
        <Menu flex="1">
  <MenuButton  
    ml={0}
    mr={0}
    height={height}
    maxWidth="calc(100% - 20px)"
    pl={0} 
    pr={0}
    py={0}
    cursor="pointer"
    mb={1} 
    flex="1"
    boxShadow='rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;'
    backgroundColor={bgColor} 
    {...props}
  >
    {/* Wrap the inner content with an HStack to ensure layout properties are applied */}
    <HStack justifyContent="space-between" width="100%" mr={1}>
      <HStack flex="2" width="100%">
          <HStack width="100%" alignItems="center">
          <Text 
            maxHeight="20px" 
            overflow="hidden" 
            fontSize={fontSize} 
            fontWeight={daysFromNow <= 7 ? 'semibold' : ''} 
            whiteSpace="nowrap" 
            textOverflow="ellipsis"
            >
            {reminder.task_name}
            </Text>
            {reminder.cadence === 12 && 
            <Badge fontSize={fontSize} colorScheme="green">{rhf.style_anniversary(anniversary_count)}</Badge>
            }
          </HStack>
      </HStack>
      <Box>
        <AiFillCaretDown size="12px" />
      </Box>
    </HStack>
  </MenuButton>
  <MenuList>
    <MenuItem onClick={() => editReminder(reminder)}>Edit</MenuItem>
    <MenuItem onClick={() => setIsACOpen(true)}>Archive</MenuItem>
  </MenuList>
</Menu>

                
          <ConfirmDialog
              isOpen={isACOpen}
              onClose={onACClose}
              onConfirm={archiveReminder}
              title="Archive Project"
              description="Are you sure you want to archive (delete) this reminder?"
              confirmButtonText="Archive"
            />
      </HStack>
    </Tooltip>
  );
  
};

export default NewReminderRow;
