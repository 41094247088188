import React from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Box,
  IconButton,
  useDisclosure,
  VStack,
  Text,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillMinusCircle, AiFillCiCircle } from 'react-icons/ai';
import { BsFillCircleFill } from 'react-icons/bs'
import GoalProgressSelector from './GoalProgressSelector.jsx';
import ghs from '../../../uc_utils/goals/goals.js';

const GoalProgressFastSelect = ({ goal_id, goal_points, value, onValueChange, isDisabled, size, ...props }) => {


  const toast = useToast();
  const px_size = size || '38px';
  const bdColor = useColorModeValue("forwardWhite.600",'forwardBlue.700');
  let textColor = useColorModeValue('black','white');
  if (value === 'abandoned') {
    textColor = 'red';
  }
    
    const { isOpen, onToggle, onClose } = useDisclosure()

  const handleChange = (goal_id, newValue) => {
    onValueChange(goal_id, newValue);
    if(newValue === 'complete') {
      toast({
        title: "Goal Completed!",
        description: "Congratulations on completing a goal!",
        status: "success",
        duration: 4000,
        isClosable: true,
      })
    }
    else {
      toast({
        title: "Goal Updated",
        description: "Your goal progress has been updated.",
        status: "info",
        duration: 2000,
        isClosable: true,
      })
    
    }
    onClose();
  };
  

    return (
      <Popover
          isOpen={isOpen}
          onClose={onClose}
      >
        <PopoverTrigger>
          <Box  
              p={0} 
              borderRadius={"0px"} 
              w={px_size} 
              h={px_size} 
              border="2px solid"
              borderColor={bdColor}
              boxShadow="rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
              display="flex" 
              justifyContent="center" 
              onClick={() => onToggle()} 
              disabled={isDisabled} 
              alignItems="center"
              bg={ghs.getColor(value)}
              cursor="pointer"
              _disabled={{ opacity: 0.4 }}>
            <Text fontWeight="bold" textColor={textColor}>{goal_points}</Text>
          </Box>
        </PopoverTrigger>
        <PopoverContent borderRadius={0} width="100%"> {/* Adjust the width as needed */}
          <PopoverArrow />
          <PopoverBody display="flex" justifyContent="center">
            <GoalProgressSelector goal_id={goal_id} value={value} onValueChange={handleChange} isDisabled={isDisabled} size={size} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };
  export default GoalProgressFastSelect;


