import { useEffect, useContext, useCallback } from 'react';
import { ForwardContext } from '../../context/ForwardContext.jsx';
import { readProjects, upsertProject } from '../../database_api/projectService.js';
import { readBacklogTasks, readPriorityTasks, readThisWeekTasks, 
         readNextWeekTasks, readAccomplishments, readCurrentWeekTasks, 
         readProjectHistory } from '../../database_api/taskService.js';
import { usePermissions } from '../permissions/usePermissions.jsx';
import { trackCustomEvent } from '../../general/functions/plausibleEvent.js';
import c from '../../uc_constants/fwd_constants.js';
import { useToast } from '@chakra-ui/react';

export const useProjects = () => {
  const { state, dispatch, session } = useContext(ForwardContext);
  const {checkPermissionToAdd} = usePermissions();
  const toast = useToast();

  const syncProjects = useCallback(async (userId) => {
    console.log('Fetching user Projects');
    try {
        const projectsData = await readProjects(userId);
        const cwPlannedTasks = await readCurrentWeekTasks(userId, state.ui.currentWeek);
        const backlogTasks = await readBacklogTasks(userId);
        const priorityTasks = await readPriorityTasks(userId);
        const thisWeekTasks = await readThisWeekTasks(userId);
        const nextWeekTasks = await readNextWeekTasks(userId);
        const accomplishments = await readAccomplishments(userId, state.ui.currentWeek);

        // Flatten the projectsData into an array of projects
        const projectsArray = Object.values(projectsData).flat();
        // Create an initial tasks object with an empty array for each project
        const initialTasks = projectsArray.reduce((acc, project) => {
          acc[project.id] = {
            'current_tasks': [],
            'backlog': [],
            'priority': [],
            'this_week': [],
            'next_week': [],
            'accomplishments': []
          };
          return acc;
        }, {});

        Object.entries(cwPlannedTasks).forEach(([projectId, tasks]) => {
          initialTasks[projectId]['current_tasks'] = tasks || [];
        });

        Object.entries(backlogTasks).forEach(([projectId, tasks]) => {
          initialTasks[projectId]['backlog'] = tasks || [];
        });

        Object.entries(priorityTasks).forEach(([projectId, tasks]) => {
          initialTasks[projectId]['priority'] = tasks || [];
        });

        Object.entries(thisWeekTasks).forEach(([projectId, tasks]) => {
          initialTasks[projectId]['this_week'] = tasks || [];
        });

        Object.entries(nextWeekTasks).forEach(([projectId, tasks]) => {
          initialTasks[projectId]['next_week'] = tasks || [];
        });

        Object.entries(accomplishments).forEach(([projectId, tasks]) => {
          initialTasks[projectId]['accomplishments'] = tasks || [];
        });
  
        dispatch({ type: 'SET_PROJECTS', payload: projectsData });
        dispatch({ type: 'SET_TASKS', payload: initialTasks });
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }

  }, [dispatch, session]);



  //
  //Add more functions as needed here
  //
  const upsertProjectProperty = async(project, property, value) => {
    const project_data = {'user_id':project.user_id, 'area_id':project.area_id, 'id':project.id, [property]:value  }

    //Submit to Database
    const new_project = await upsertProject(project_data)
    //Update State


    if (property === 'date_deleted' && value !== null) {
        dispatch({ type: 'REMOVE_PROJECT', payload: { projectId:project.id, areaId: project.area_id } });
    }
    else {
      dispatch({
        type: 'UPDATE_PROJECT_VALUE',
        payload: {
          projectId: project.id,
          key: property,
          value: value,
        }
      });
    }
    
    console.log('Project added/updated!');
  //If not, alert to error
   if (!new_project)  {
      alert("Error Updating Project!")
    }
  }

  const syncProjectHistory = async (project) => {
    //Pull the data

    try {
    const project_history = await readProjectHistory(project.user_id, project.id)
    console.log(project_history);
    //Update State
    dispatch({ type: 'SET_PROJECT_HISTORY', payload: project_history });
    dispatch({ type: 'SET_LIVE_PROJECT_ID', payload: project.id });
    } catch (error) {
      console.error('Failed to fetch project history:', error);
    }


  };


  const upsertFullProject = async (project_data) => {
    let mode = 'create';
    if(project_data.id > 0) {
      mode = 'edit';
    }
    let permission_to_create = checkPermissionToAdd('projects',mode);
    console.log(permission_to_create);

    try {
          if(permission_to_create) {
            const new_project = await upsertProject(project_data);
            trackCustomEvent('FWD Project Added', {project_name:project_data.project_name});
            if (new_project) {
              dispatch({ type: 'UPSERT_PROJECT', payload: new_project });
              console.log('Project added/updated!');
              if(mode === 'create')
              {
                console.log('setting tasks to empty')
                dispatch({ type: 'SET_TASKS_NEW_PROJECT', payload: new_project[0].id});
              }
              if(mode === 'edit') {
                return {result:'success',message:'project edit successful'}
              }
              else {
                return {result:'success',message:'project add successful'}
              }
          //If not, alert to error
          } 
          else  {
              alert("Error Adding Project!")
              return {result:'error',message:'project add failure at database level'}
          }
      }
      else {
        return {result:'limit',message:'project limit reached!'}
      }
      }
      catch {
        return {result:'error',message:'project add failed.'}
      }




  }

  const handleEmbedLink = (project, type="kanban") => {

    let linkText = c.forward_main_url+'/embed/project/'+project.id+'';
    navigator.clipboard.writeText(linkText)
      .then(() => {
        toast({
          title: 'Link copied to clipboard',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error('Failed to copy link to clipboard:', error);
        toast({
          title: 'Failed to copy link to clipboard',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  }





  return {syncProjects, upsertProjectProperty, syncProjectHistory, upsertFullProject, handleEmbedLink};
};