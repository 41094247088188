import { useEffect, useContext, useCallback, useState } from 'react';
import { ForwardContext } from '../../context/ForwardContext.jsx';
import { upsertGoal_base } from "../../database_api/goalService.js";
import { upsertHabit_base } from "../../database_api/habitService.js";
import { upsertProject_base } from "../../database_api/projectService.js";
import { useHabits } from '../elements/useHabits.jsx';
import { useAreas } from '../elements/useAreas.jsx';


export const useManageDnd = ({session}, currentSection, mode = 'manage') => {
  const {state,dispatch } = useContext(ForwardContext);
  const user_id = session.user.id;
  const ha  = useHabits();
  const ar = useAreas();
    /****************************************/
    //Main Sync Function to Grab all the data
    /****************************************/
  const handleManageDragEnd = async (result) => {
    console.log(result);
        const { draggableId, destination, source } = result;
        let dragType = draggableId.split('-')[0];
        if(mode === 'habits') {
          dragType = 'habits';
        } 
        
        console.log(dragType);
        const source_id = source.droppableId;
        const dest_id = destination.droppableId;

        if (!destination || 
            (source_id === dest_id && destination.index === source.index)) {
          return;
        }
        else if (destination.droppableId === 'area-sorter') {
            //We are sorting areas
            console.log(currentSection);
            const area_id = draggableId.split('-')[1]
            await ar.reorderAreas(user_id, area_id, source.index, destination.index);
        }
        else {
            let sourceList = state[currentSection][source_id];
            let destList = state[currentSection][dest_id];

            // Remove the item from the source list
            let [removed] = sourceList.splice(source.index, 1);
            removed.area_id = dest_id; // Update the area_id if it's moved to a different area

            // Insert it into the destination list at the correct position
            destList.splice(destination.index, 0, removed);

            //Update the state
            dispatch({
                type: `UPSERT_${currentSection.toUpperCase()}`,
                payload: [
                  { ...removed, order_id: destination.index + 1 }, // +1 because your order_id starts from 1 not 0
                  ...sourceList.map((item, index) =>
                    item.id === removed.id ? item : { ...item, order_id: index + 1 } // Adjust order_ids of other items
                  ),
                  ...destList.map((item, index) =>
                    item.id === removed.id ? item : { ...item, order_id: index + 1 } // Adjust order_ids of other items
                  ),
                ],
              });

              let updateMap = [
                {   
                  id: removed.id,
                  user_id: user_id,
                  order_id: destination.index + 1, 
                  area_id: parseInt(dest_id),
                  status: removed.status,
                },
                ...sourceList.map((item, index) => ({ 
                  id: item.id, 
                  user_id: user_id, 
                  order_id: index + 1, 
                  area_id: parseInt(item.area_id),
                  status: item.status,
                })),
                ...destList.map((item, index) => ({ 
                  id: item.id, 
                  user_id: user_id, 
                  order_id: index + 1, 
                  area_id: parseInt(item.area_id),
                  status: item.status,
                })),
              ];
              
              // Convert the array into a Map, using the item IDs as keys
              let itemMap = new Map(updateMap.map(item => [item.id, item]));
              console.log(itemMap)
              
              // Convert the Map back into an array
              let uniqueData = Array.from(itemMap.values()).filter((item) => item.status < 3);
              console.log(uniqueData);
              
              let updatedData;
              if (dragType === 'habits') {
                updatedData = await upsertHabit_base(uniqueData);
              } 
              else if (dragType === 'goals') {
                updatedData = await upsertGoal_base(uniqueData);
              }
              else if (dragType === 'projects') {
                updatedData = await upsertProject_base(uniqueData);
              }
              console.log(updatedData);


              

        }

  }




  return {handleManageDragEnd};
}
