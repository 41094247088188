const uiReducer = (ui, action) => {
  switch (action.type) {
    case 'SET_EDITORDATA':
      return { ui: { ...ui, editorData: action.payload }};
    case 'SET_OPTIONSTOSHOW':
      return { ui: { ...ui, optionsToShow: action.payload }};
    case "SET_CURRENT_VIEW":
      return { ui: { ...ui, currentView: action.payload }};
    case "SET_CURRENT_FORM":
      return { ui: { ...ui, currentForm: action.payload }};
    case "SET_CURRENT_WEEK":
      return { ui: { ...ui, currentWeek: action.payload }};
    case "SET_CURRENT_YEAR":
      return { ui: { ...ui, currentYear: action.payload }};
    case "SET_REMINDER_DAYS":
      return { ui: {...ui, reminderDays: action.payload }};
    case "SET_READ_ONLY":
      return { ui: {...ui, readOnly: action.payload }};
    case "SET_PROJECT_VIEW":
      return { ui: {...ui, projectView: action.payload }};
    case "SET_GOAL_VIEW":
      return { ui: {...ui, goalView: action.payload }};
    default:
      return {};
  }
};
export default uiReducer;
