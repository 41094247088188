import React, { useState } from 'react';
import { Box, HStack, Text, useMediaQuery, Menu, MenuButton, MenuList, MenuItem, Badge } from '@chakra-ui/react';
import { FaPrint } from 'react-icons/fa';
import c from '../../../uc_constants/fwd_constants.js';

const NavBarDropdown = ({ icon, text, isEnd = false, isLive = false, createFunction, organizeFunction, shareFunction, settingsFunction, refreshFunction, exportFunction, share_notifications, active_shares}) => {
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => setIsOpen(true);
    const handleMouseLeave = () => setIsOpen(false);

    return (
        <Menu  isOpen={isOpen} onClose={handleMouseLeave} width="100%" gutter={0} cursor="pointer">
            <MenuButton
                as={Box}
                flex="1"
                py={2}
                textColor="white"
                bg="forwardBlue.700"
                cursor="pointer"
                borderRight={isEnd ? "0px" : "2px solid var(--chakra-colors-forwardBlue-500)"}
                borderBottom={isLive ? "3px solid var(--chakra-colors-forwardGold-500)" : "0px"}
                _hover={{ bg: 'forwardBlue.400', fontWeight: 'bold' }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <HStack width="100%" justifyContent="center" alignContent="center">
                    {icon}
                    <Text fontSize={isMobile ? 'xs' : 'md'}>{text}</Text>
                    {share_notifications > 0 && <Badge colorScheme="red" variant="solid" borderRadius="full" fontSize={{'base':'0.5em','md':"0.7em"}} ml={0} px={{'base':1,'md':2}}>{share_notifications}</Badge>}
                </HStack>
            </MenuButton>
            <MenuList 
            bg="forwardBlue.700" 
            flex="1"
            borderColor="forwardBlue.500" 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave} 
            width="100%" 
            placement="bottom-end" 
            padding={0}
            marginTop="0px !important"
            >    
                    <MenuItem onClick={() => settingsFunction()} bg="forwardBlue.700"  textColor="white" _hover={{ bg: 'forwardBlue.400', fontWeight:'bold'}}>
                    <HStack>{c.main_icons.settings}
                    <Text>My Settings</Text>
                    </HStack>
                    </MenuItem>    
                <MenuItem onClick={() => organizeFunction()} bg="forwardBlue.700"  textColor="white" _hover={{ bg: 'forwardBlue.400', fontWeight:'bold'}}>
                    <HStack>{c.main_icons.organize}
                    <Text>Organize...</Text>
                    </HStack>
                    </MenuItem>
                <MenuItem onClick={() => createFunction()}  bg="forwardBlue.700" textColor="white" _hover={{ bg: 'forwardBlue.400', fontWeight:'bold'}}>
                <HStack>{c.main_icons.create}
                    <Text>Create...</Text>
                    </HStack>
                    </MenuItem>
                <MenuItem onClick={() => shareFunction()} bg="forwardBlue.700" textColor="white" _hover={{ bg: 'forwardBlue.400', fontWeight:'bold'}}>
                <HStack>{c.main_icons.share}
                    <Text>Share...</Text>
                    <Badge colorScheme="red" variant="solid" borderRadius="full" fontSize="0.6em" ml={2} px={1}>{share_notifications} requests</Badge>
                    <Badge colorScheme="forwardBlue" variant="solid" borderRadius="full" fontSize="0.6em" ml={2} px={1}>{active_shares} active</Badge>
                    </HStack>
                    </MenuItem>
                <MenuItem onClick={() => refreshFunction()}  bg="forwardBlue.700" textColor="white" _hover={{ bg: 'forwardBlue.400', fontWeight:'bold'}}>
                <HStack>{c.main_icons.refresh}
                    <Text>Refresh App</Text>
                    </HStack>
                    </MenuItem>
                <MenuItem onClick={() => exportFunction()}  bg="forwardBlue.700" textColor="white" _hover={{ bg: 'forwardBlue.400', fontWeight:'bold'}}>
                <HStack>{c.main_icons.export}
                    <Text>Export Data</Text>
                    </HStack>
                    </MenuItem>

                {/*<MenuItem onClick={() => analyzeFunction()}  bg="forwardBlue.700" textColor="white" _hover={{ bg: 'forwardBlue.400', fontWeight:'bold'}}>Analyze...</MenuItem>*/}
            </MenuList>
        </Menu>
    );
};

export default NavBarDropdown;
