import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function usePreviousLocation() {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState('');

  useEffect(() => {
    // Update the previous location when the current location changes
    setPrevLocation((current) => {
      // If the current location hasn't changed, return the existing previous location
      if (current?.pathname === location.pathname) {
        return current;
      }
      return location;
    });
  }, [location]);

  return prevLocation;
}

export default usePreviousLocation;