import React, { useEffect} from 'react';
import { supabase } from '../../uc_constants/supabaseClient.js';
import th from '../../uc_utils/tasks/tasks.js';
import { useHabits } from '../elements/useHabits.jsx';

const useRealtimeTasks = (dispatch, stateRef) => {

    const { realtimeHabitValueUpdate } = useHabits();
    const session_id = stateRef.current.session_id;

    
  useEffect(() => {
    // Set up real-time subscription for tasks
    console.log('setting up subscription');
    const taskSubscription = supabase
      .channel('forward_realtime')
      // Task Insertion
      .on('postgres_changes', {
        event: 'INSERT',
        schema: 'public',
        table: 'forward_tasks',
      }, payload => {
        //Need to check if the task is one we want to show.
        const project_id = payload.new.project_id;
        const my_pid_flatmap = new Set(Object.values( stateRef.current.projects).flatMap(arr => arr.map(p => p.id)));

        if (my_pid_flatmap.has(project_id)) {
        console.log('hearing an event coming in!');
        const listName = th.calculateList (payload.new,  stateRef.current.ui.currentWeek,  stateRef.current.ui.thisWeek,  stateRef.current.ui.nextWeek)
        dispatch({
          type: 'UPSERT_TASK',
          payload: { task: payload.new, listName: listName },
        });
      }
      })
      // Task Updates
      .on('postgres_changes', {
        event: 'UPDATE',
        schema: 'public',
        table: 'forward_tasks',
      }, payload => {

        if (payload.new.last_session_id !== session_id) {
        console.log(payload)
        console.log(payload.old.id);
        const old_project_id = th.findProjectIdByTaskId( stateRef.current.tasks, payload.old.id);
        console.log(old_project_id);
        const new_project_id = payload.new.project_id;
        const my_pid_flatmap = new Set(Object.values( stateRef.current.projects).flatMap(arr => arr.map(p => p.id)));


        if (my_pid_flatmap.has(new_project_id)) {
          console.log('hearing an event coming in!');

        console.log('old project id is: '+old_project_id);       
        if (old_project_id !== new_project_id) {
          console.log('project id changed');
          const task_to_remove = {...payload.old, project_id: old_project_id};
          dispatch({
            type: 'REMOVE_TASK_ALL_LISTS',
            payload: { task: task_to_remove },
          });
        }
        else {
          dispatch({
            type: 'REMOVE_TASK_ALL_LISTS',
            payload: { task: payload.new },
          });
        }
        const listName = th.calculateList (payload.new, stateRef.current.ui.currentWeek, stateRef.current.ui.thisWeek, stateRef.current.ui.nextWeek)
        console.log(listName);
        dispatch({
          type: 'UPSERT_TASK',
          payload: { task: payload.new, listName: listName },
        });
      }
        }
      
      })
      // Habit Value Inserts
      .on('postgres_changes', {
        event: 'INSERT',
        schema: 'public',
        table: 'forward_habit_values',
      }, payload => {
        //Need to check if the task is one we want to show.
        console.log('hearing an event coming in!');
        //console.log(payload.new);
        realtimeHabitValueUpdate(payload.new.habit_id, payload.new.date, payload.new.value);

      })
      .on('postgres_changes', {
        event: 'UPDATE',
        schema: 'public',
        table: 'forward_habit_values',
      }, payload => {
        //Need to check if the task is one we want to show.
        console.log('hearing an event coming in!');
        //console.log(payload.new);
        realtimeHabitValueUpdate(payload.new.habit_id, payload.new.date, payload.new.value);

      })
      // Habit Value Updates
      .subscribe();


      //console.log(taskSubscription);

    // Cleanup the subscription on component unmount
    return () => {
      console.log('unsubscribing');
      taskSubscription.unsubscribe();
    };
  }, [dispatch]);

};

export default useRealtimeTasks;