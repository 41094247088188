
import { useContext } from 'react';
import { ForwardContext } from '../../context/ForwardContext.jsx';
import { countMyShares } from '../../database_api/sharingService.js';
import c from '../../uc_constants/fwd_constants.js';

export const usePermissions = () => {
  const { state,dispatch, session } = useContext(ForwardContext);

  const account_level = state.account.account_level;
  const limits = c.free_limits;
    /****************************************/
    //Main Sync Function to Grab all the data
    /****************************************/
  const checkPermissionToAdd = (entity, mode) => {
       //Function: Returns True if you have permission to add a new entity of that type, false otherwise
       let current_object_count = 0;
       if(account_level >= 2 || mode === 'edit') {
        return true;
       }
       else {
        //This works for habits, goals, reminders, and projects:
        if(['habits','goals','projects','new_goals'].includes(entity)) {
            current_object_count =  Object.values(state[entity]).flatMap(objArray => objArray).filter((obj) => obj.status < 3).length;
        }
        else if (entity === 'events') {
            current_object_count = state.events.length;

        }
        else if (entity === 'shared_areas') {
            current_object_count = countMyShares(state.account.id);
        }
        else if (entity === 'reminders') {
            current_object_count =  Object.values(state[entity]).flatMap(objArray => objArray).filter((obj) => obj.status === 'active').length;
        }
        else if (entity === 'areas') {
            current_object_count =  state[entity].filter((obj) => obj.status < 3).length; 
        }
        else if (entity === 'tasks') {
            current_object_count =  Object.values(state[entity])
                                    .flatMap(objArray => objArray)
                                    .map((obj) => obj.this_week.concat(obj.next_week, obj.priority, obj.backlog))
                                    .flatMap(obj => obj)
                                    .filter((obj) => ['planned','backlog','priority']
                                    .includes(obj.item_status) )
                                    .length;
        }
        else {
            current_object_count = 999;
        }

        console.log(current_object_count);
        console.log(limits[entity])

        if(current_object_count >= limits[entity]) {
            return false;
        }
        else {
            return true;
        }
       }
    
  }




  return {checkPermissionToAdd};
}
