import { useEffect, useContext, useCallback, useState } from 'react';
import { ForwardContext } from '../../context/ForwardContext.jsx';
import tdh from '../../uc_utils/tasks/tasks_drag.js';
import dh from '../../uc_utils/dates/dates.js';
import { updateTaskOrdering, updatePinTaskOrdering, upsertTask } from '../../database_api/taskService.js';

export const useTaskDnd = () => {
  const { state,dispatch, session } = useContext(ForwardContext);
  const session_id = state.session_id;

    /****************************************/
    //Main Sync Function to Grab all the data
    /****************************************/
    const handleDragEndWell = async (result) => {
        console.log(result);
        const { source, destination, draggableId } = result;
        //Source: has a droppableId tc-project-[pid]-[container] and an index (location in the list)
        //Destination: same as source
        //DraggableId = the task id


        //No choice but to handle this as a bunch of situations, though we could move each into its own function.
        /*************************************************/
        /*************************************************/
        // SITUATION 0: No change in destination or index
        /*************************************************/
        /*************************************************/
        if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
            return;
        }
        /*************************************************/
        /*************************************************/
        // SITUATION 1: Dropped within same list => reorder the list
        /*************************************************/
        /*************************************************/
        if (source.droppableId === destination.droppableId) {
          const projectId = source.droppableId.split('-')[2]; // It assumes the structure is always "tc-project-{projectId}-todo"
          const listId = source.droppableId.split('-')[3];
          const tasksList = state.tasks[projectId][listId]; // Assuming 'state' is your global state from the Context
          const items = tdh.reorder(
              tasksList,
              source.index,
              destination.index,
              session_id,
          );
          console.log(items);
            // Here you should dispatch to the reducer to update the state
            dispatch({
                type: 'UPDATE_TASK_ORDER',
                payload: { droppableId: source.droppableId, tasksOrdered: items }
            });
            // Update task order in backend
            //const filteredItems = items.map(({ id, order_id }) => ({ id, order_id }));
            const response = await updateTaskOrdering(projectId, items);

            if (!response.success) {
            console.error('Failed to update task order in backend:', response.message);
            }

        }
        /*************************************************/
        /*************************************************/
        // SITUATION 2: Dropped in the same project, but a different status
        /*************************************************/
        /*************************************************/
        else if (source.droppableId.split('-')[2] === destination.droppableId.split('-')[2]) {
          //Moving to a different status in the same project
          console.log('here - updating a single project, multiple lists')
          const projectId = source.droppableId.split('-')[2];
          const destination_list = destination.droppableId.split('-')[3];
          const source_list = source.droppableId.split('-')[3];

          const existing_item = state.tasks[projectId][source_list].find(item => item.id === parseInt(draggableId));
          console.log(existing_item);
          //Manipulate the data
          let new_status = '';
          let resolve_date = null;
          let project_id = parseInt(source.droppableId.split('-')[2]);
          let new_plan_date = 'unchanged';

          const existing_opw = existing_item['original_plan_date'];
          console.log(existing_opw)

          let change_opw = false;
          let new_opw = existing_opw;

          if(existing_opw === null) {
            console.log('setting opw change to true');
            change_opw = true;
          }


          //Done based off what list we are going to
          if(destination_list === 'accomplishments') {
            new_status = 'complete';
            resolve_date = new Date();
          }
          else if (destination_list === 'current_tasks') {
            new_status = 'planned';
            new_plan_date = dh.formatDate(state.ui.currentWeek,'yyyy-MM-dd');
          }
          else if (destination_list === 'backlog') {
            new_status = 'backlog';
            new_plan_date = null;

          }
          else if (destination_list === 'priority') {
            new_status = 'priority';
            new_plan_date = null;
          }
          else if (destination_list === 'toschedule') {
            new_status = 'toschedule';
            new_plan_date = null;
          }
          else if (destination_list === 'this_week') {
            new_status = 'planned';
            new_plan_date = dh.formatDate(state.ui.thisWeek,'yyyy-MM-dd');
            if(change_opw) {
              new_opw = new_plan_date;
            }
          }
          else if (destination_list === 'next_week') {
            new_status = 'planned';
            new_plan_date = dh.formatDate(state.ui.nextWeek,'yyyy-MM-dd');
            if(change_opw) {
              new_opw = new_plan_date;
            }
          }

          //Create task data object
          let task_data;
            if(new_plan_date === 'unchanged') {
            task_data = {'id':parseInt(draggableId),
            'item_status':new_status, 
            'resolve_date':resolve_date, 
            'project_id':project_id,
            'last_session_id':session_id,
            }}
            else {
              task_data = {'id':parseInt(draggableId),
            'item_status':new_status, 
            'resolve_date':resolve_date, 
            'project_id':project_id,
            'item_planned_week':new_plan_date,
            'last_session_id':session_id,
            }
            if(change_opw) {
              task_data['original_plan_date']=new_opw;
            }
          }

          //Create payload to send to Reducer
          const dispatch_payload = 
          {
            projectId: parseInt(source.droppableId.split('-')[2]),
            sourceListId: source.droppableId.split('-')[3],
            destinationListId: destination.droppableId.split('-')[3],
            sourceIndex: source.index,
            destinationIndex: destination.index,
            newItemStatus:new_status,
            newResolveDate:resolve_date,
            newPlanDate: new_plan_date,
            newOPW: new_opw,
          }
          
          console.log(dispatch_payload);
          //Send a dispatch to the reducer
          //This should remove the item from the source list and add it to the destination list
          dispatch({
            type:'MOVE_TASK_WITHIN_PROJECT',
            payload: dispatch_payload
          });
          console.log(state.tasks);

          //Send update to the backend to change the task's status
          console.log(task_data);
          const updatedTask = await upsertTask(task_data);
          console.log(updatedTask);
        }
        /*************************************************/
        /*************************************************/
        //SITUATION 3/4: Moving the task to a different project (right now, it assumes the same status)
        /*************************************************/
        /*************************************************/
        else {

            const task_lists = tdh.move(
              state.tasks[source.droppableId.split('-')[2]][source.droppableId.split('-')[3]], 
              state.tasks[destination.droppableId.split('-')[2]][destination.droppableId.split('-')[3]], 
              source, 
              destination
          );
          console.log(task_lists);

            //Check if the destination is the same bucket:
            if (source.droppableId.split('-')[3] !== destination.droppableId.split('-')[3]) 
            {
            /*************************************************/
            //SITUATION 3: This is a double move: both bucket and project
            /*************************************************/

            //Need to update the state to change the lists but also the characteristics of the task.
            //Need to update the database correctly
            console.log('updating the task');
            const destination_list = destination.droppableId.split('-')[3];
            const projectId = source.droppableId.split('-')[2];
            const source_list = source.droppableId.split('-')[3];
  

            const existing_item = state.tasks[projectId][source_list].find(item => item.id === parseInt(draggableId));

            //Manipulate the data
            let new_status = '';
            let resolve_date = null;
            let source_project_id = parseInt(source.droppableId.split('-')[2]);
            let dest_project_id = parseInt(destination.droppableId.split('-')[2]);
            let new_plan_date = 'unchanged';

            console.log(existing_item);

            const existing_opw = existing_item['original_plan_date'];
            console.log(existing_opw)

            let change_opw = false;
            let new_opw = existing_opw;

            if(existing_opw === null) {
              console.log('setting opw change to true');
              change_opw = true;
            }

            if(destination_list === 'accomplishments') {
              new_status = 'complete';
              resolve_date = new Date();
            }
            else if (destination_list === 'current_tasks') {
              new_status = 'planned';
              new_plan_date = dh.formatDate(state.ui.currentWeek,'yyyy-MM-dd');
              if(change_opw) {
                new_opw = new_plan_date;
              }
            }
            else if (destination_list === 'backlog') {
              new_status = 'backlog';
              new_plan_date = null;
            }
            else if (destination_list === 'priority') {
              new_status = 'priority';
              new_plan_date = null;
            }
            else if (destination_list === 'toschedule') {
              new_status = 'toschedule';
              new_plan_date = null;
            }
            else if (destination_list === 'this_week') {
              new_status = 'planned';
              new_plan_date = dh.formatDate(state.ui.thisWeek,'yyyy-MM-dd');
              if(change_opw) {
                new_opw = new_plan_date;
              }
            }
            else if (destination_list === 'next_week') {
              new_status = 'planned';
              new_plan_date = dh.formatDate(state.ui.nextWeek,'yyyy-MM-dd');
              if(change_opw) {
                new_opw = new_plan_date;
              }
            }

          //Create payload to send to Reducer
          const dispatch_payload = 
            {
              sourceProjectId: source_project_id,
                destinationProjectId: dest_project_id,
                sourceListId: source.droppableId.split('-')[3],
                destinationListId: destination_list,
                sourceIndex: source.index,
                destinationIndex: destination.index,
                newItemStatus: new_status,
                newResolveDate: resolve_date,
                newPlanDate: new_plan_date,
                newOPW: new_opw,
            }
          
          console.log(dispatch_payload);
          //Send a dispatch to the reducer
          //This should remove the item from the source list and add it to the destination list
          dispatch({
            type:'MOVE_TASK_DOUBLE',
            payload: dispatch_payload
          });
          console.log(state.tasks);

              //Create task data object
              let task_data;
            if(new_plan_date === 'unchanged') {
            task_data = {'id':parseInt(draggableId),
            'item_status':new_status, 
            'resolve_date':resolve_date, 
            'project_id':dest_project_id,
            'last_session_id':session_id,
            }}
            else {
              task_data = {'id':parseInt(draggableId),
            'item_status':new_status, 
            'resolve_date':resolve_date, 
            'project_id':dest_project_id,
            'item_planned_week':new_plan_date,
            'last_session_id':session_id,
            }
            if(change_opw) {
              task_data['original_plan_date'] = new_opw;
            }
          }

            const updatedTask = await upsertTask(task_data);
            console.log(updatedTask);
           


            }
            else {
            /*************************************************/
            //SITUATION 4: Moving between different projects
            /*************************************************/
            // Here you should dispatch to the reducer to update the state
            dispatch({
                type: 'MOVE_TASK_BETWEEN_PROJECTS',
                payload: task_lists
            });
            // Update task order in backend for both lists
            const sourceProjectId = source.droppableId.split('-')[2];
            const sourceProjectType = source.droppableId.split('-')[3];
            const destinationProjectId = destination.droppableId.split('-')[2];
            const destinationProjectType = destination.droppableId.split('-')[3];

            const sourceResponse = await updateTaskOrdering(sourceProjectId, task_lists[sourceProjectId][sourceProjectType]);
            const destinationResponse = await updateTaskOrdering(destinationProjectId, task_lists[destinationProjectId][destinationProjectType]);

            if (!sourceResponse.success || !destinationResponse.success) {
            console.error('Failed to update task order in backend:', sourceResponse.message, destinationResponse.message);
            }
          }
        }
   
  }

  const handlePinnedDragEnd = async (result) => {
    console.log(result);
    const { source, destination, draggableId } = result;
    /*************************************************/
        /*************************************************/
        // SITUATION 0: No change in destination or index
        /*************************************************/
        /*************************************************/
        if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
          return;
      }
      /*************************************************/
      /*************************************************/
      // SITUATION 1: Dropped within same list => reorder the list
      /*************************************************/
      /*************************************************/
      if (source.droppableId === destination.droppableId) {
        //const projectId = source.droppableId.split('-')[2]; // It assumes the structure is always "tc-project-{projectId}-todo"
        //This will be "pinned" in these cases
        const listId = source.droppableId.split('-')[3];

        let updatedPinnedTasks = Object.values(state.tasks).reduce((accumulator, project) => {
          if (project.this_week && project[listId].length > 0) {
            accumulator.push(...project[listId].filter((task) => task.is_pinned === true));
          }
          return accumulator;
        }, []);
        updatedPinnedTasks.sort((a, b) => a.pin_order_id - b.pin_order_id);
        updatedPinnedTasks = updatedPinnedTasks.map(({ id, project_id, pin_order_id }) => ({ id, project_id, pin_order_id}));

        const items = tdh.pin_reorder(updatedPinnedTasks, source.index, destination.index, session_id);

          dispatch({
              type: 'UPDATE_PIN_TASK_ORDER',
              payload: { items: items }
          })
          // Update task order in backend
          const response = await updatePinTaskOrdering(items);

          if (!response.success) {
          console.error('Failed to update task order in backend:', response.message);
          }

      }

  }




  return {handleDragEndWell, handlePinnedDragEnd};
}
