import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { FaExternalLinkSquareAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import c from '../../uc_constants/fwd_constants.js';

const VisitForwardLink = () => {
  return (
    <Flex m={0} p={2} width="100%" alignItems="center" justifyContent="flex-end" as={Link} to={c.forward_main_url} target="_blank">
      <Text mx={1}>Visit Forward </Text><FaExternalLinkSquareAlt />
    </Flex>
  );
}

export default VisitForwardLink;