import React from 'react';
import { Box, Button, VStack, Text, Grid, HStack } from '@chakra-ui/react';
import dhf from '../../../uc_utils/dates/dates.js';
import { FormContext } from '../../../context/FormContext.jsx';

const EventCell = ({ period, length, cell_width, itemData, dayHeight = 600 }) => { // Added dayHeight prop
    const isToday = period.start <= dhf.formatDate(new Date()) && period.end >= dhf.formatDate(new Date());
    const { addEvent, editEvent } = React.useContext(FormContext);

    const display_mode = length <= 1 ? 'day' : length <= 7 ? 'week' : length <= 31 ? 'month' : 'year';

    const has_past = period.start < dhf.formatDate(new Date());
    let bgColor = 'gray.100';
    let hoverBg = 'gray.300';
    if (isToday) {
        bgColor = 'forwardGold.50';
        hoverBg = 'forwardGold.200';
    } else if (has_past) {
        bgColor = 'gray.400';
        hoverBg = 'gray.600';
    }

    const handleEditClick = ({thisEvent}) => {
        console.log('Edit event:', thisEvent);
        let editorData = thisEvent;
        const { date:start_date, time:start_time } = dhf.decomposeTimestamp(thisEvent.start_time);
        const { date:end_date, time:end_time } = dhf.decomposeTimestamp(thisEvent.end_time);
        editorData.start_date = start_date;
        editorData.start_time = start_time;
        editorData.end_date = end_date;
        editorData.end_time = end_time;
        editEvent(editorData);
    };

    const handleAddButtonClick = (day) => {
        console.log('Add event:', day);
        const editorData = {
            start_date: day,
            start_time: '08:00',
            end_date: day,
            end_time: '09:00',
        }
        addEvent(editorData);
    }

    const handleCalendarAddClick = (day, hour) => {
        console.log('Add event:', day, hour);
        const start_time = hour < 10 ? `0${hour}:00` : `${hour}:00`;
        const end_time = hour + 1 < 10 ? `0${hour + 1}:00` : `${hour + 1}:00`;
        const editorData = {
            start_date: day,
            start_time: start_time,
            end_date: day,
            end_time: end_time,
        }
        addEvent(editorData);
    };


    const eventsInPeriod = itemData
        .map(event => {
            const event_start = new Date(event.start_time);
            const event_end = new Date(event.end_time);
            return { ...event, event_start, event_end };
        })
        .filter(event => event.event_start >= dhf.getLocalMidnightDate(period.start) && event.event_start <= dhf.getLocalLastSecondDate(period.end))
        .sort((a, b) => a.event_start - b.event_start);

    const renderDayView = (period) => {
        const day = dhf.formatDate(period.start);
        let rows = Array.from({ length: 24 }, (_, i) => ({
            hour: i,
            events: []
        }));

        eventsInPeriod.forEach(event => {
            const startHour = event.event_start.getHours();
            const endHour = event.event_end.getHours() + (event.event_end.getMinutes() > 0 ? 1 : 0); // Include additional hour if it spills over
            for (let hour = startHour; hour < endHour; hour++) {
                if (rows[hour]) {
                    rows[hour].events.push(event);
                }
            }
        });

        // Determine the position and height of each event block
        const eventTiles = eventsInPeriod.map(event => {
            const startPixel = ((event.event_start.getHours() * 60 + event.event_start.getMinutes()) / (24 * 60)) * 100;
            const endPixel = ((event.event_end.getHours() * 60 + event.event_end.getMinutes()) / (24 * 60)) * 100;
            const height = endPixel - startPixel;

            return {
                ...event,
                top: startPixel,
                height,
            };
        });

        // Determine width and position to handle overlapping events
        const maxOverlaps = Math.max(...rows.map(row => row.events.length));
        const eventWidth = 100 / maxOverlaps;

        return (
            <Box position="relative" height={`${dayHeight}px`} width="100%">
                {rows.map(row => (
                    <Box key={day+'-'+row.hour} 
                        padding={1} 
                        borderBottom="1px solid gray" 
                        position="relative" 
                        height={`${dayHeight / 24}px`}
                        cursor="pointer"
                        _hover= {{bg: hoverBg}}
                        onClick={() => handleCalendarAddClick(day,row.hour)}>
                        <Text fontSize="xs" color="gray.500">{`${row.hour}:00`}</Text>
                    </Box>
                ))}
                {eventTiles.map((event, index) => {
                    // Calculate the width and position for overlapping events
                    const overlapIndex = rows[event.event_start.getHours()].events.findIndex(e => e.id === event.id);
                    const leftPos = (overlapIndex * eventWidth) + '%';
                    const zIndex = overlapIndex + 1;

                    return (
                        <Box
                            key={event.id}
                            bg="forwardWhite.500"
                            boxShadow="md"
                            border="1px solid"
                            borderColor="forwardBlue.800"
                            p={1}
                            mb={1}
                            borderRadius={2}
                            fontSize="2xs"
                            position="absolute"
                            top={`${(event.top / 100) * dayHeight}px`}
                            height={`${(event.height / 100) * dayHeight}px`}
                            width={`${eventWidth - 3}%`} // Adjust for spacing
                            left={leftPos}
                            zIndex={zIndex}
                            cursor="pointer"
                            onClick={() => handleEditClick({thisEvent:event})}
                        >
                            <Text fontWeight="bold">{event.event_name}</Text>
                        </Box>
                    );
                })}
            </Box>
        );
    };

    return (
        <>
            <Box 
                borderWidth="1px 0px 1px 1px"
                borderColor="forwardBlue.800"
                textAlign="center"
                minHeight="100px"
                fontSize="3xs" 
                bg={bgColor}
                position="relative"
            >
                {display_mode === 'day' && renderDayView(period)}
                {display_mode !== 'day' &&
                    <VStack align="stretch" spacing={0} width="100%">
                        {eventsInPeriod.map(event => 
                        {
                            const is_past_event = new Date(event.end_time) < new Date();
                            const is_active_event = new Date(event.start_time) < new Date() && new Date(event.end_time) > new Date();
                            let bg_color = "gray.200";
                            if (is_past_event) {
                                bg_color = "gray.400";
                            } else if (is_active_event) {
                                bg_color = "forwardGold.50";
                            }
                            return(
                            <HStack 
                            key={event.id} 
                            bg={bg_color} 
                            p={1} mb={0} 
                            borderBottom="1px solid" 
                            borderColor="gray.400" 
                            borderRadius={0} 
                            fontSize="2xs"
                            cursor="pointer"
                            onClick={() => handleEditClick({thisEvent:event})}>
                                <Text flex={2} fontWeight="bold">{event.event_name}</Text>
                                <Text fontSize="2xs">{event.event_start.toLocaleString('en-US', {
                                month: 'short', // "Aug"
                                day: 'numeric', // "8"
                                hour: 'numeric', // "H"
                                minute: 'numeric', // "mm"
                                hour12: true // "AM/PM"
                                })}</Text>
                            </HStack>
                        )}
                        )}
                    </VStack>}
            </Box>
            <Button width="100%" variant="outline" borderRadius={0} size="xs" colorScheme="blue" onClick={()=> handleAddButtonClick(dhf.formatDate(period.start))}>+ Event</Button>
        </>
    );
}

export default EventCell;