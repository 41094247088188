

const reorder = (list, startIndex, endIndex) => {
    console.log(list);
    console.log(startIndex);
    console.log(endIndex);
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    // Add visible_order_id to each goal in the reordered list
    result.forEach((goal, index) => {
        goal.order_id = index;
    });

    console.log(result);

    return result;
};


const get_filtered_goal_list = (area_goals, source_area, source_year, source_quarter, source_points, source_progress) => {
    console.log(area_goals);
    let goalList = area_goals || [];
    if (goalList.length === 0) {
        return [];
    }

        if(source_year !== 'None') {
            if(source_quarter === 'None' || source_quarter === null) { 
                goalList = goalList.filter((goal) => goal.goal_timeframe === source_year) || [];
                }
                else {
                    
                    goalList = goalList.filter((goal) => parseInt(goal?.goal_timeframe?.split('Q')[0]) === parseInt(source_year)) || [];
                }

        }
        else if(source_year === 'None' || source_year === null) {
          goalList = goalList.filter((goal) => goal.goal_timeframe === null) || [];

        }
        if(source_quarter !== 'None') {
          goalList = goalList.filter((goal) => 'Q'+goal.goal_timeframe.split('Q')[1] === source_quarter) || [];

        }
        else if(source_quarter === 'None' || source_quarter === null) {
          if(source_year !== 'None' && source_year !== null) {
          goalList = goalList.filter((goal) => goal.goal_timeframe.split('Q').length === 1) || [];
          }
          else {
            goalList = goalList.filter((goal) => goal.goal_timeframe === null) || [];
          }

        }
        if(source_points !== 'all') {
          goalList = goalList.filter((goal) => goal.goal_points === source_points) || [];
        }
        if(source_progress !== 'all') {
          goalList = goalList.filter((goal) => goal.goal_progress === source_progress) || [];
        }

        goalList = goalList.sort((a, b) => a.order_id - b.order_id);


        return goalList;
    }


    export default {
        reorder,
        get_filtered_goal_list,
    }