import { Box, HStack, Button, Text, useColorModeValue, IconButton, ButtonGroup, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useState, useContext } from 'react';
import { BiEdit, BiMenu, BiTrash } from 'react-icons/bi';
import ConfirmDialog from '../components/ConfirmDialog';

import { useAreas } from '../../../data_hooks/elements/useAreas';
import { useHabits } from '../../../data_hooks/elements/useHabits';
import { useProjects } from '../../../data_hooks/elements/useProjects';
import { useGoals } from '../../../data_hooks/elements/useGoals';
import { usePermissions } from '../../../data_hooks/permissions/usePermissions';

import { FaArchive } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';


const ArchiveRow = ({ element, element_name, modalClose}) => {
    const navigate = useNavigate();
    const toast = useToast();

    const ar = useAreas();
    const ha = useHabits();
    const pr = useProjects();
    const go = useGoals();
    const {checkPermissionToAdd} = usePermissions();


    const bgColor = useColorModeValue("forwardWhite.500","forwardBlue.700");
    const btnColor = useColorModeValue("forwardWhite.300","forwardBlue.800");

    const { isOpen: isOpenCD, onOpen: onOpenCD, onClose: onCloseCD } = useDisclosure();

    const e_type = element_name.split('_')[0];

    const visitHandler = (id) => {

        let path = '';
        if(e_type === 'area' || e_type === 'project') {
        path = '/forward/'+e_type+'/'+id;
        }
        else {

        }
        console.log(path);
        //Close the modal
        modalClose();
        navigate(path);

    }

    const reviveItem = async () => {
        console.log('reviving');
        const allowed = checkPermissionToAdd(e_type+'s','create')
            if(allowed) {
            if(e_type === 'area') {
                //console.log('in if');
                ar.unarchiveArea(element.user_id, element.id)
            }
            else if(e_type ==='project') {
                pr.upsertProjectProperty(element,'status',1);

            }
            else if (e_type === 'habit') {
                ha.upsertHabitProperty(element,'status', 1)

            }
            else if (e_type === 'goal') {
                go.upsertGoalProperty(element,'status', 1)
            }
        }
        else {
            toast({
                title: 'Error: Please upgrade your account to have more active '+e_type+'s!',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
        }
    }

    const deleteHandler = async () => {
        console.log('archiving');
        if(e_type === 'area') {
            //console.log('in if');
            ar.deleteArea(element.user_id, element.id)
        }
        else if(e_type ==='project') {
            pr.upsertProjectProperty(element,'date_deleted',new Date().toISOString());

        }
        else if (e_type === 'habit') {
            ha.upsertHabitProperty(element,'date_deleted',new Date().toISOString());

        }
        else if (e_type === 'goal') {
            go.upsertGoalProperty(element,'date_deleted',new Date().toISOString());

        }
        onCloseCD();
    }
  
  const status_name = 'status'

  let text_element = <HStack>
                    <FaArchive />
                    <Text textColor="gray" fontStyle="italic">{element[element_name]}</Text>
                    {(e_type === 'area' || e_type === 'project') && <Text cursor="pointer" fontSize="2xs" fontStyle="oblique" onClick={() => visitHandler(element['id'])}>View</Text>}
                    </HStack>;

  return (
    <HStack 
    height="40px" 
    width="100%" 
    bgColor={bgColor} 
    justifyContent="space-between"
    alignContent="baseline">
        <HStack flex="1"
        justifyContent="stretch"
        maxWidth="70%"
        alignContent="baseline"
        mx={1}
        >
      {text_element}
      </HStack>
      <ButtonGroup>
      <Button borderRadius={0} size="xs" colorScheme="forwardBlue" onClick={reviveItem}>
        Re-Open
      </Button>
      <IconButton
        size={{'base':"xs",'md':"xs"}}
        marginTop="0px"
        borderRadius="0px"
        mx={1}
        colorScheme="red"
        variant="outline"
        icon={<BiTrash />}
        aria-label="Delete Area"
        backgroundColor={btnColor}
        onClick={onOpenCD}
      />
      </ButtonGroup>
      <ConfirmDialog
            isOpen={isOpenCD}
            onClose={onCloseCD}
            onConfirm={deleteHandler}
            title={'Delete '+e_type}
            description={'Are you sure you want to delete this '+e_type+'? \n '+element[element_name]+' and any of its child elements will not be available to you moving forward.'}
            confirmButtonText='Delete'
            />
    </HStack>
  );
};

export default ArchiveRow;
