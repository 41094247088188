import { supabase } from '../uc_constants/supabaseClient';
import { format } from 'date-fns';


export async function upsertHabitValues(habit_value_data) {
    let { data, error } = await supabase
    .from('forward_habit_values')
    .upsert([ habit_value_data ])
      .select('*');

  if (error) {
      console.error('Error upserting user:', error);
  }
  return data;
}

export async function readHabitValues(userId) {
  console.log('reading habit values');
  console.log(userId);
  let { data, error } = await supabase
  .from('forward_habit_values')
  .select(`
          habit_id, date, value,
          forward_habits (
            id, user_id
          )
        `)
  .eq('forward_habits.user_id', userId)

if (error) {
    console.error('Error upserting user:', error);
}
console.log(data);
return data;
}