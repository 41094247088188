import React, { useState, useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  ButtonGroup,
  useToast,
} from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext';
import NewAreaDropdown from '../../areas/components/NewAreaDropdown';
import NewProjectDropdown from '../../projects/components/NewProjectDropdown';
import GoalStatusDropdown from '../components/GoalStatusDropdown';
import GoalProgressSelector from '../components/GoalProgressSelector';
import GoalLevelSelector from '../components/GoalLevelSelector';
import GoalPointSelector from '../components/GoalPointSelector';
import GoalTimeframeSelector from '../components/GoalTimeframeSelector';
import { trackCustomEvent } from '../../../general/functions/plausibleEvent';
import { useGoals } from '../../../data_hooks/elements/useGoals';

const GoalForm = ({
  isOpen, onClose, type, session, editorData, optionsToShow, isDisabled
}) => {

  const showObject = optionsToShow || {'name':true,'area':true,'project':false,'timeframe':true, 'goal_points':false,}

  /*******************/
  //Form Setup and Management
  /*******************/
  const { state, dispatch } = useContext(ForwardContext);
  const toast = useToast();
  const { upsertFullGoal } = useGoals();

  const cy = new Date().getFullYear();

  const [newName, setNewName] = useState(editorData.goal_name || '');
  const [timeframe, setTimeframe] = useState(editorData.goal_timeframe || cy);
  const [goalLevel, setGoalLevel] = useState(editorData.goal_level || 'minor');
  const [goalPoints, setGoalPoints] = useState(editorData.goal_points || 1);
  const [progress, setProgress] = useState(editorData.goal_progress || 'green');
  const [status, setStatus] = useState(editorData.goal_status || 'active');
  const [selectedArea, setSelectedArea] = useState(editorData.area_id || state.areas[0].id);
  const [selectedProject, setSelectedProject] = useState(editorData.project_id || null);

  const handleAreaChange = (value) => {
    setSelectedArea(value);
    //console.log(selectedArea);
  };

  const handleProgressChange = (goal_id, newValue) => {
    //console.log(newValue);
    setProgress(newValue);
  }

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  }
  const handleProjectChange = (res) => {
    setSelectedProject(res);
    //console.log(selectedArea);
  };

  let submit_text = '';
  let submit_mode = '';
  if(Object.keys(editorData).length === 0)
  {
    submit_text = 'Create'
    submit_mode = 'new'
  }
  else {
    submit_text = 'Update'
    submit_mode = 'edit'
  }

  let area_limit = 'none';
  if(editorData && editorData.area_to_limit) {
    area_limit = editorData.area_to_limit;
  }

  const user_id = session.user.id;

  /*******************/
  //Submit to Database
  /*******************/
  async function handleSubmit() {
    const goal_data = {
                      'user_id':user_id, 
                      'goal_name':newName, 
                      'area_id':selectedArea, 
                      'goal_status':status,
                      'goal_progress':progress,
                      'goal_points':goalPoints,
                      'project_id':selectedProject,
                      'goal_timeframe':timeframe,
                      'goal_level':goalLevel}
    if(editorData.id > 0) {
      goal_data["id"] = editorData.id;
    }
    if(goal_data.goal_timeframe === 'None') {
      goal_data.goal_timeframe = null;
    }
    // Validate goal_timeframe
    const timeframeRegex = /^(3000|2[0-9]{3})(Q[1-4])?$/;
    if (goal_data.goal_timeframe !== null && !timeframeRegex.test(goal_data.goal_timeframe)) {
      toast({
        title: 'Error: Invalid timeframe.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    console.log(goal_data);

    let {result, message} = await upsertFullGoal(goal_data);
    //Submit to Database
    //const new_goal = await upsertGoal(goal_data);
    //trackCustomEvent('FWD Goal Added', {goal_name: newName});
    console.log(result);
    if(result === 'error') {
      toast({
        title: 'Error: Please upgrade your account to add more goals!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    else {
      onClose();
      toast({
        title: 'Goal Updated!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }

  };

  /*******************/
  // Return the Form
  /*******************/

  return (
    <>
    <FormControl>
        <ModalBody>
            {showObject['name'] && <><FormLabel fontWeight="bold">Goal:</FormLabel>
            <Input
              value={newName}
              variant="flushed"
              placeholder="Save $50k for a down payment"
              onChange={(e) => setNewName(e.target.value)}
            /></>}
            {showObject['area'] &&
            <>
            <FormLabel fontWeight="bold" mt={4}>Life Area:</FormLabel>
            <NewAreaDropdown mt={2} value={selectedArea} onChange={handleAreaChange} variant="flushed" />
            </>
            }
            {showObject['timeframe'] && <>
            <FormLabel fontWeight="bold" mt={4}>Timeframe:</FormLabel>
            <GoalTimeframeSelector value={timeframe} onValueChange={setTimeframe} isDisabled={false} size={'lg'} />

            </>
              }
              {showObject['goal_level'] && <>
            <FormLabel fontWeight="bold" mt={4}>Goal Level:</FormLabel>
            <GoalLevelSelector value={goalLevel} onValueChange={setGoalLevel} isDisabled={false} size={'lg'} />
            </>
              }
              {showObject['goal_points'] && <>
            <FormLabel fontWeight="bold" mt={4}>Goal Points:</FormLabel>
            <GoalPointSelector value={goalPoints} onValueChange={setGoalPoints} isDisabled={false} size={'lg'} />
            </>
              }
              {showObject['status'] && <>
                <FormLabel fontWeight="bold" mt={4}>Status:</FormLabel>
                <GoalStatusDropdown mt={2} value={status} onChange={handleStatusChange} variant="flushed" />  
              </>}
              {showObject['progress'] && <>
              <FormLabel fontWeight="bold" mt={4}>Progress:</FormLabel>
              <GoalProgressSelector goal_id={null} value={progress} onValueChange={handleProgressChange} isDisabled={false} size="25px"/>
              </>}   
              {showObject['project'] && <> 
              <FormLabel fontWeight="bold" mt={4}>Project:</FormLabel>
              <NewProjectDropdown mt={2} value={selectedProject} onChange={handleProjectChange} area_limit={area_limit} />  
              </>}

        </ModalBody>

        <ModalFooter>
          <Button borderRadius="0px" colorScheme="forwardOrange" mr={3} onClick={handleSubmit} isDisabled={isDisabled}>
            {submit_text}
          </Button>
          <Button borderRadius="0px" variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
        </FormControl>
        </>
  );
};

export default GoalForm;