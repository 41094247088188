import dates from "../dates/dates.js";
import habits from "../habits/habits.js";

const blue = "#3B9EDB";
const light_blue = "#3BD3DB";
const green = "#46D840";
const red = "#E85151";


  const generateChartData = (habit, colorMode, isMobile) => {
    //console.log('ChartGEN')
    const { habit_name, values, targets } = habit;

    const plotColor = colorMode === "light" ? "#f9f2f2" : "#024350";
    const paperColor = colorMode === "light" ? "#f9f2f2" : "#024350";
    const fontColor = colorMode === "light" ? "black" : "white";
    const maColor = colorMode === "light" ? "gray" : "white";

    const getEarliestDate = (dates) => {
      // Sort the array of date strings
      const sortedDates = dates.sort();
    
      // Return the earliest date (the first element in the sorted array)
      return sortedDates[0] || null;
    };
  

        const getWeeksRange = (startDate, endDate) => {
          //console.log(endDate)
          //console.log(startDate);
            const weeks = [];
            let current = startDate;
            current.setHours(0, 0, 0, 0);
          
            // Set the currentDate to the first Monday on or before the startDate
            while (current.getDay() !== 1) {
              current.setDate(current.getDate() - 1);
            }
          
            while (current <= endDate) {
              const weekStart = new Date(current);
              current.setDate(current.getDate() + 6); // Move to the next Sunday
              const weekEnd = new Date(current);
              weeks.push({ start: weekStart, end: weekEnd });
              current.setDate(current.getDate() + 1); // Move to the next Monday
            }

            //console.log(weeks);
          
            return weeks;
          };

  const startDate = getEarliestDate(Object.keys(values));
  const [year, month, day] = startDate.split('-').map(Number);
  const newStartDate = new Date(year, month - 1, day); // Months are 0-indexed in JavaScript Date objects

  const weeks = getWeeksRange(newStartDate, new Date());

  const weekStartDates = weeks.map((week) => week.start);
 
  const aggregatedValues = weekStartDates.map((start) => habits.getTotal(habit.habit_type, values, start));
  const projectedValues = weekStartDates.map((start) => { 
          const real_start = start.setHours(0, 0, 0,0);
          //console.log(real_start);

          const rem_of_week = parseFloat(dates.getPercentOfWeek(Date.now(),real_start));
          //console.log(rem_of_week);

          const existing_work = habits.getTotal(habit.habit_type, values, real_start);   
          //console.log(existing_work); 
            
          return ((habits.getTotal(habit.habit_type, values,real_start)
                  *(1.0/rem_of_week))
                  -(existing_work)).toFixed(2)  ;
          });

  const targetValues = weekStartDates.map((start) => {
    const mostRecentTarget = habits.getMostRecentTarget(targets, start);
    return mostRecentTarget ? parseFloat(mostRecentTarget[0]) : null;
  });

  

  const barColors = aggregatedValues.map((value, index) => {
    const mostRecentTarget = habits.getMostRecentTarget(targets, weekStartDates[index]);
    //Return blue if there is no goal or if the week is in progress
    if (mostRecentTarget === null || 
      (dates.getPercentOfWeek(new Date(), weekStartDates[index]) > 0 &&  dates.getPercentOfWeek(new Date(), weekStartDates[index]) < 1 )) {
      return blue;
    }
    const isMoreGoal = mostRecentTarget[1];
    return isMoreGoal
      ? value >= parseFloat(mostRecentTarget[0]) ? green : red
      : value <= parseFloat(mostRecentTarget[0]) ? green : red;
  });


  const movingAverage = [];
  for (let i = 0; i < aggregatedValues.length; i++) {
    let sum = 0;
    let count = 0;
    for (let j = i; j >= Math.max(0, i - 3); j--) {
        sum += parseFloat(aggregatedValues[j])+parseFloat(projectedValues[j]);
        count++;
      
    }
    const average = sum / count;
    movingAverage.push(average.toFixed(2));
  }


  const data = [
    {
      x: weekStartDates,
      y: movingAverage,
      type: 'line',
      name: 'Moving Average (4 weeks)',
      line: {
        dash: 'dot'
      },
      marker: {
        color: maColor,
      },
    },
    {
      x: weekStartDates,
      y: aggregatedValues,
      type: 'bar',
      name: 'Habit Values',
      marker: {
        color: barColors,
      },
    },
    {
      x: weekStartDates,
      y: projectedValues,
      type: 'bar',
      name: 'Projection (Current Week)',
      marker: {
        color: light_blue,
      },
    },
    {
      x: [weekStartDates[0]],
      y: [0],
      type: 'bar',
      name: 'Target Hit!',
      marker: {
        color: green,
      },
    },
    {
      x: [weekStartDates[0]],
      y: [0],
      type: 'bar',
      name: 'Target Missed',
      marker: {
        color: red,
      },
    },
    {
        x: weekStartDates,
        y: targetValues,
        type: 'scatter',
        mode: 'lines+markers',
        name: 'Targets',
        line: {
          shape: 'hvh', // This ensures that the target line stays horizontal between two target points
          dash: 'dash'
        },
        marker: {
          size: 6,
          color: 'black',
        },
    },
  ];
  let layout = {};

  if (isMobile) {
    layout = {
      showlegend: false,
      pad: 0,
    margin: {b:45,l:45,r:10,t:10},
    xaxis: {
      title: 'Date',
      font:'Overpass',
      color: fontColor,
      fixedrange: true
    },
    yaxis: {
      title: 'Performance',
      font:'Overpass',
      color: fontColor,
      fixedrange: true
    },
    barmode: 'stack',
    plot_bgcolor: plotColor,
    paper_bgcolor: paperColor,
    }

  }
  else {
  layout = {
    legend: {
      "orientation": "h",
      "yanchor":"bottom",
      "y":1,
      "xanchor":"left",
      "x":0,
      "bgcolor": plotColor,
      "font": {"color":fontColor},
    },
    pad: 0,
    margin: {b:45,l:45,r:10,t:10},
    xaxis: {
      title: 'Date',
      font:'Overpass',
      color: fontColor,
      fixedrange: true
    },
    yaxis: {
      title: 'Performance',
      font:'Overpass',
      color: fontColor,
      fixedrange: true
    },
    barmode: 'stack',
    plot_bgcolor: plotColor,
    paper_bgcolor: paperColor,
  }
  }

  return { data, layout };
};











const generateHabitIndexData = (habit, habitStats, colorMode, isMobile) => {
  //console.log('ChartGEN')
  const { habit_name, values, targets } = habit;

    const plotColor = colorMode === "light" ? "#f9f2f2" : "#024350";
    const paperColor = colorMode === "light" ? "#f9f2f2" : "#024350";
    const fontColor = colorMode === "light" ? "black" : "white";
    const maColor = colorMode === "light" ? "gray" : "white";

        const getWeeksRange = (startDate, endDate) => {
            const weeks = [];
            let current = new Date(startDate);
            current.setHours(0, 0, 0, 0);
          
            // Set the currentDate to the first Monday on or before the startDate
            while (current.getDay() !== 1) {
              current.setDate(current.getDate() - 1);
            }
          
            while (current <= endDate) {
              const weekStart = new Date(current);
              current.setDate(current.getDate() + 6); // Move to the next Sunday
              const weekEnd = new Date(current);
              weeks.push({ start: weekStart, end: weekEnd });
              current.setDate(current.getDate() + 1); // Move to the next Monday
            }
          
            return weeks;
          };



          const getEarliestDate = (dates) => {
            // Sort the array of date strings
            const sortedDates = dates.sort();
          
            // Return the earliest date (the first element in the sorted array)
            return sortedDates[0] || null;
          };

  const startDate = getEarliestDate(Object.keys(values));
  const [year, month, day] = startDate.split('-').map(Number);
  const newStartDate = new Date(year, month - 1, day); // Months are 0-indexed in JavaScript Date objects        
  
  const weeks = getWeeksRange(new Date(newStartDate), new Date());
  const weekStartDates = weeks.map((week) => week.start);
  const aggregatedValues = weekStartDates.map((start) => {
    const stats = habitStats.weekly_stats[dates.formatDate(start)];
    return stats ? parseInt(stats.habit_index) : null; // or some other default value
});

  const data = [
    {
      x: weekStartDates,
      y: aggregatedValues,
      type: 'line',
      name: 'Habit Index',
      marker: {
        color: green,
      },
    },
  ];
  let layout = {};

  if (isMobile) {
    layout = {
      showlegend: false,
      pad: 0,
    margin: {b:45,l:45,r:10,t:10},
    xaxis: {
      title: 'Date',
      font:'Overpass',
      color: fontColor,
      fixedrange: true
    },
    yaxis: {
      title: 'Performance',
      font:'Overpass',
      color: fontColor,
      fixedrange: true
    },
    barmode: 'stack',
    plot_bgcolor: plotColor,
    paper_bgcolor: paperColor,
    }

  }
  else {
  layout = {
    legend: {
      "orientation": "h",
      "yanchor":"bottom",
      "y":1,
      "xanchor":"left",
      "x":0,
      "bgcolor": plotColor,
      "font": {"color":fontColor},
    },
    pad: 0,
    margin: {b:45,l:45,r:10,t:10},
    xaxis: {
      title: 'Date',
      font:'Overpass',
      color: fontColor,
      fixedrange: true
    },
    yaxis: {
      title: 'Performance',
      font:'Overpass',
      color: fontColor,
      fixedrange: true
    },
    barmode: 'stack',
    plot_bgcolor: plotColor,
    paper_bgcolor: paperColor,
  }
  }

  return { data, layout };


}


export default {
    generateChartData,
    generateHabitIndexData
  }