import { useState, useEffect } from 'react';
import { supabase } from '../uc_constants/supabaseClient';

import ForwardMain from './ForwardMain';

import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import LoadingAnimation from '../fwd_ui/general/components/LoadingAnimation';
import ForwardWelcome from './intro/ForwardWelcome';

function Forward() {
  const location = useLocation();
  let fast_signin = location.pathname.includes('/embed');
  let arrival_url = location.pathname;
  const [session, setSession] = useState(null);
  const [loadingSession, setLoadingSession] = useState(true);
  
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      setLoadingSession(false);
    })
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
      setLoadingSession(false);
    })
  }, []);

  if (loadingSession) {
    return <LoadingAnimation />;
  }

  const alertProps = session ? {} : {alert: {'status':'warning',
                                             'message':'You must be logged in to use Forward',
                                             'duration':10000}};
  return (
    <>
      {!session ? <>
                  <ForwardWelcome alert={alertProps} arrival_url = {arrival_url} fast_signin={fast_signin}/>
                
                  </>
                : 
                <ForwardMain session={session}>
                  <Outlet />
                  </ForwardMain>}


    </>

  )
}

export default Forward;
