const statReducer = (stats, action) => {
    switch (action.type) {
        case 'UPSERT_ENTITY': {
            //console.log('upserting a statistics entity')
            const updatedEntity = action.payload;
            //console.log(updatedEntity)
            const entityId = updatedEntity.id;
            const entityType = updatedEntity.entity;
            const stat_id = entityType + '_' + entityId;

            // Check if the entity exists in stats
            if (stats[stat_id]) {
                // Merge new data from updatedEntity into the existing entity
                const existingEntity = stats[stat_id];
                const mergedEntity = {
                    ...existingEntity,
                    ...updatedEntity,
                    // Assume weekly_stats and current_stats are objects, merge them separately
                    weekly_stats: { 
                        ...existingEntity.weekly_stats, 
                        ...updatedEntity.weekly_stats 
                    },
                    current_stats: { 
                        ...existingEntity.current_stats, 
                        ...updatedEntity.current_stats 
                    },
                };

                return {
                    stats: {
                    ...stats,
                    [stat_id]: mergedEntity,
                    }
                };
            } else {
                // If the entity does not exist, add it
                return {
                    stats: {
                    ...stats,
                    [stat_id]: updatedEntity,
                    }
                };
            }
        }
        default:
            return {stats: {
                ...stats,
            }};
    }
};

export default statReducer;