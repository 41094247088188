import React from 'react';
import { Flex, VStack, useColorModeValue} from '@chakra-ui/react';
import GoalRow from '../rows/GoalRow';
import { Droppable, Draggable } from '@hello-pangea/dnd';

const GoalViewContainerBox = ({ session, goals, isMobile, droppable_id, showProject=true, showTimeframe=true }) => {
    const bgColor = useColorModeValue('gray.100','forwardBlue.500');
    return (
        <Droppable  droppableId={droppable_id} >
            {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
        {goals.length > 0 && <VStack  alignItems="stretch" spacing={0}>
            {goals.map((goal, index) => (

                <Draggable key={goal.id} draggableId={String(goal.id)} index={index}>
                      {(provided) => (
                        <div  ref={provided.innerRef} {...provided.draggableProps}>
                          <GoalRow  goal={goal} user_id={session.user.id} view="featured" draggable={true} dragHandle={provided.dragHandleProps} showProject={showProject} showTimeframe={showTimeframe} />
                        </div>
                      )}
                    </Draggable>
            ))}
        </VStack>}
        {goals.length === 0 && <Flex width="100%" height="50px" justifyContent="center" alignItems="center" bg={bgColor} color="gray.400">No Goals</Flex>}
        {provided.placeholder}
                </div>
            )} 
        </Droppable>
    );
};

export default GoalViewContainerBox;