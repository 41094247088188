import React, {useContext, useState, useMemo}  from 'react';
import {
  VStack, Text, Center, Box, HStack, Flex, Heading, Button, IconButton, useColorModeValue, useMediaQuery, useStyleConfig
} from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { FormContext } from '../../../context/FormContext.jsx';
import HabitCell from '../rows/HabitCell.jsx';
import AreaHeader from '../../areas/rows/AreaHeader.jsx';
import dhf from '../../../uc_utils/dates/dates.js';
import c from '../../../uc_constants/fwd_constants.js';
import calc_habits from '../../../calc/habits.js';
import HabitStatBoxRow from '../rows/HabitStatBoxRow.jsx';
import {useChartFunctions} from '../../../data_hooks/charting/useChartFunctions.jsx';

const DayHabits = ({ session, ...props }) => {
  const {addHabit} = useContext(FormContext);  
  const cf = useChartFunctions();
  const { state, dispatch } = useContext(ForwardContext);
  const styles = useStyleConfig('Box', {variant: 'widgetBox'})
  const borderColor = useColorModeValue('forwardWhite.800','gray.600');
  const chartColorMode = useColorModeValue('light','dark');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const my_stats = Object.values(state.stats)
                          .filter((stat) => (stat.entity === 'habit' && stat.status <= 1));

  const dvs = useMemo(() => calc_habits.derivedHabitCalc(my_stats, state.ui.currentWeek), [my_stats, state.ui.currentWeek]);
  

  const liveDay = new Date();
  //liveDay.setHours(0,0,0,0);
  const [habitDay, setHabitDay] = useState('Today');

  const is_readOnly = false;

  const dateToShow = habitDay === 'Today' ? new Date(liveDay) : new Date(dhf.addDaysToDate(liveDay,-1));
  //console.log(dateToShow);
  const notHabitDay = habitDay === 'Today' ? 'Yesterday':'Today';

  const bgColor = useColorModeValue('forwardWhite.500','forwardBlue.800')
  const bdColor = useColorModeValue('forwardWhite.700','forwardBlue.900')



  const toggleHabitDay = () => {
    //console.log(liveDay);
    if (habitDay === 'Today') {
        setHabitDay('Yesterday');
    }
    else {
        setHabitDay('Today');
    }
  }

  return (
    <Box 
    __css={styles}
    width="100%"
    >
    <HStack
    justifyContent="space-between"
    pb={2}
    >
      <HStack>
        {c.main_icons.habits}
        <VStack>
        <Heading size="sm">{habitDay}'s Habits</Heading>
        </VStack>
        <Button size="xs" fontWeight="thin" variant="unstyled" onClick={() => toggleHabitDay()}>Edit {notHabitDay}</Button>
      </HStack>
    <HStack alignItems="stretch">
      {!isMobile && 
      <HabitStatBoxRow style="small" 
      lw_habit_score={dvs.lw_habit_score} tw_habit_score={dvs.tw_habit_score} hit_rate={dvs.hit_rate} avg_streak={dvs.avg_streak} avg_habit_index={dvs.avg_habit_index} 
      onClickFunctions={{
        'avg_streak':() => cf.chartAverageStreak({my_stats:my_stats, chartColorMode:chartColorMode, isMobile:isMobile}), 
        'habit_index':() => cf.chartHabitIndex({my_stats:my_stats, chartColorMode:chartColorMode, isMobile:isMobile}),
        'hit_rate':() => cf.chartHitRate({my_stats:my_stats, chartColorMode:chartColorMode, isMobile:isMobile}),
        'hit_and_miss':() => cf.chartTargetHitMiss({my_stats:my_stats, chartColorMode:chartColorMode, isMobile:isMobile}),
      }} 
      />
      }
      <Box>
    <Button className="forwardButton" height="100%" colorScheme="forwardOrange" size="sm" fontSize="sm" onClick={() => addHabit()}>+ Habit</Button>
    </Box>
    </HStack>
    </HStack>
    <HStack alignItems="stretch" my={1}>
    {isMobile && 
    <HabitStatBoxRow style="small" 
    lw_habit_score={dvs.lw_habit_score} tw_habit_score={dvs.tw_habit_score} hit_rate={dvs.hit_rate} avg_streak={dvs.avg_streak} avg_habit_index={dvs.avg_habit_index}
    onClickFunctions={{
      'avg_streak':() => cf.chartAverageStreak({my_stats:my_stats, chartColorMode:chartColorMode, isMobile:isMobile}), 
      'habit_index':() => cf.chartHabitIndex({my_stats:my_stats, chartColorMode:chartColorMode, isMobile:isMobile}),
      'hit_rate':() => cf.chartHitRate({my_stats:my_stats, chartColorMode:chartColorMode, isMobile:isMobile}),
      'hit_and_miss':() => cf.chartTargetHitMiss({my_stats:my_stats, chartColorMode:chartColorMode, isMobile:isMobile}),
    }} 
    />
    }
    </HStack>
  <HStack   
            my={1}
            p={0}
            spacing="0px !important"
            gap="0px !important"
            alignItems="stretch" 
            maxWidth={{'base':'94vw','md':'100%'}}
            overflowX="scroll" // Add this line
            justifyContent="flex-start"
            border="0px"
            borderColor={bdColor}
            boxShadow="lg">
      {/* Iterate Through Habits - need to show by life area*/}
      {Object.values(state.areas).map((area) => {
        // Get the projects for this area
        let habitsInArea = state.habits[area.id] || [];
        habitsInArea = habitsInArea.filter(habit => habit.status <= 2);
        // If none, return nothing
        if (habitsInArea.length === 0) {
          return null;
        }

        //Else - return the area as a header and its habits       
        return (
          <VStack key={area.id} alignItems="stretch" borderRight="1px" gap="0px !important">
            <AreaHeader area={area} fixedHeight="true" />
                <HStack 
                      marginTop="0px !important"
                      alignItems="stretch"
                      justifyContent="flex-start"
                      spacing={0}
                      flex="1">
                  {habitsInArea
                  .map((habit, index) => (
                          <HabitCell 
                          key={habit.id} 
                          habit={habit} 
                          liveDay={dateToShow}
                          borderRight={index === habitsInArea.length-1 ? '' : '0.5px solid'}
                          borderColor={borderColor} />
                      ))}
                </HStack>
          </VStack>
        );
      })}

  </HStack>
  </Box>)
}
export default DayHabits;