const gridUiReducer = (grid_ui, action) => {
    switch (action.type) {
      case 'SET_GRIDUI':
        return { grid_ui: action.payload};
        case 'UPDATE_GRIDUI':
            console.log("Updating grid ui: ", action.payload);
            return { grid_ui: { ...grid_ui, ...action.payload }};
        case 'SET_SHOW_ALL_TASKS':
            console.log("Setting show all tasks: ", action.payload);
        return { grid_ui: { ...grid_ui, show_all_tasks: action.payload }};
        case 'SET_GRIDUI_START_DATE':
            console.log("Setting start date: ", action.payload);
        return { grid_ui: { ...grid_ui, start_date: action.payload }};
        case 'SET_GRIDUI_END_DATE':
            console.log("Setting end date: ", action.payload);
        return { grid_ui: { ...grid_ui, end_date: action.payload }};
        case 'SET_GRIDUI_SELECTED_LEVEL':
            console.log("Setting selected level: ", action.payload);
        return { grid_ui: { ...grid_ui, selected_level: action.payload }};
        case 'SET_GRIDUI_VISIBLE_BASE_LEVEL':
            console.log("Setting visible base level: ", action.payload);
            return { grid_ui: { ...grid_ui, visible_base_level: action.payload }};
        case 'SET_GRIDUI_VISIBLE_LEVELS':
            console.log("Setting visible levels: ", action.payload);
            return { grid_ui: { ...grid_ui, visible_levels: action.payload }};
        case 'SET_FETCHED_TASK_RANGES':
            console.log("Setting fetched ranges: ", action.payload);
            return { grid_ui: { ...grid_ui, fetched_task_ranges: action.payload }};
        
      default:
        return { grid_ui };
    }
  };
  export default gridUiReducer;
  