import dhf from '../dates/dates.js';

const get_color = (task, currentWeek, colorMode) => {
    const plannedWeek = dhf.createDateObject(task.item_planned_week);
    const weeksOverdue = Math.floor((currentWeek - plannedWeek) / (7 * 24 * 60 * 60 * 1000));

    if (task.item_status === 'complete') {
        return colorMode === 'dark' ? 'green.600' : 'green.300';
    } else if (task.item_status === 'delayed' || (task.item_status === 'planned' && plannedWeek < currentWeek)) {
        if (weeksOverdue > 4) {
            return colorMode === 'dark' ? 'red.800' : 'red.400';
        } else if (weeksOverdue >= 2) {
            return colorMode === 'dark' ? 'forwardOrange.900' : 'forwardOrange.500';
        } else {
            return colorMode === 'dark' ? 'forwardOrange.600' : 'forwardOrange.200';
        }
    } else if (task.item_status === 'abandoned') {
        return colorMode === 'dark' ? 'forwardWhite.900' : 'forwardWhite.800';
    }
    return colorMode === 'dark' ? 'forwardBlue.400' : 'forwardBlue.50';
}


  const get_colorScheme = (task, currentWeek) => {
    if (task.item_status === 'complete') {
        return 'green';
    }
    else if (task.item_status === 'delayed' || (task.item_status === 'planned' && dhf.createDateObject(task.item_planned_week) < currentWeek)) {
        return 'forwardOrange';
    }
    else if (task.item_status === 'abandoned') {
        return 'gray';
    }
    return 'forwardBlue';
  }
  
const is_currentWeek = (task, currentWeek) => {
    return dhf.isDateInCurrentWeek(dhf.createDateObject(task.item_planned_week),currentWeek);
}
const is_past_undone = (task, currentWeek) => {
    return (task.item_status !== 'complete' && dhf.createDateObject(task.item_planned_week) <= currentWeek && currentWeek <= new Date());
}
const is_currentWeek_resolve = (task, currentWeek) => {
    return dhf.isDateInCurrentWeek(dhf.createDateObject(task.resolve_date),currentWeek);
}



const get_delay_weeks = (task,currentWeek) => {
    if (task.item_status !== 'delayed') {
        return 0;
    }
    else {
        
        //Calc number of weeks from the start of week
        const week_diff = dhf.getDaysBetweenDates(
                                    dhf.getStartOfWeek(currentWeek),
                                    dhf.getStartOfWeek(dhf.createDateObject(task.item_planned_week))
                                    )/7;
        //console.log(week_diff);

        return week_diff;
    }
}
const calculateTaskPlannedWeek = (task_list, currentWeek, thisWeek, nextWeek) => {
    if (task_list === 'accomplishments') {
        return dhf.formatDate(currentWeek)
    }
    else if (task_list === 'backlog') {
        return null;
    }
    else if (task_list === 'priority') {
        return null;
    }
    else if (task_list === 'next_week') {
        return dhf.formatDate(nextWeek);
    }
    else if (task_list === 'this_week') {
        return dhf.formatDate(thisWeek);
    }
    else if (task_list === 'current_tasks') {
        return dhf.formatDate(currentWeek);
    }
    else {
        return null;
    }
}

const calculateList = (task, currentWeek, thisWeek, nextWeek) => {

    //Get Date Objects
    const cw = currentWeek.toISOString();
    const ecw = dhf.addDaysToDate(currentWeek,6).toISOString();
    const tw = thisWeek.toISOString();
    const etw = dhf.addDaysToDate(thisWeek,6).toISOString();
    const nw = nextWeek.toISOString();
    const enw = dhf.addDaysToDate(nextWeek,6).toISOString();
    const ipw = task.item_planned_week===null ? null : dhf.createDateObject(task.item_planned_week).toISOString();    
    //const rd = dhf.createDateObject(task.resolve_date).toISOString();
    let rd;
    if(task.item_status === 'complete' )
    {
        console.log(task.resolve_date);
        rd = dhf.createDateObject(task.resolve_date).toISOString();
        console.log(rd);
        if (rd >= cw && rd <= ecw) {
        return 'accomplishments'
        }
        else {
            return null;
        }
    }
    else if(task.item_status === 'backlog') {
        return 'backlog';
    }
    else if ( task.item_status === 'priority') {
        return 'priority';
    }
    else if(['delayed','abandoned','planned'].includes(task.item_status) ) {
        //This needs to return the appropriate list for the edited task, which could be nextWeek (plan date is next week), thisWeek (pl), or current
        //Current logic doesn't work
        if (ipw >= nw && ipw <= enw) {
            return 'next_week'
        }
        else if (ipw <= etw) {
            return 'this_week'
        }
        else if (ipw >= cw && ipw <= ecw) {
            return 'current_tasks'
        }
        
    }
    else {
        return 'none'
    }
    //.in('item_status',['delayed','abandoned','planned'])
    //.lte('item_planned_week',cw)
}

const get_project_name_from_id = (project_id, projects) => {
    try {
    const project_name = Object.values(projects).flatMap(arr => arr).find(p => p.id === project_id).project_name;

    return project_name;
    }
    catch (e) {
        console.log(e);
        return null;
    }
    //return Object.values(projects).flatMap(arr => arr).find(p => p.id === project_id).project_name;
}

function findProjectIdByTaskId(tasks, taskId) {
    for (const projectId in tasks) {
      const lists = tasks[projectId];
      for (const listName in lists) {
        const found = lists[listName].some(task => task.id === taskId);
        if (found) {
          return projectId;
        }
      }
    }
    return null; // or appropriate fallback
  }
  
  
  export default {
    get_color,
    get_colorScheme,
    is_currentWeek,
    is_past_undone,
    is_currentWeek_resolve,
    get_delay_weeks,
    calculateTaskPlannedWeek,
    calculateList,
    get_project_name_from_id,
    findProjectIdByTaskId,
  }