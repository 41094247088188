import React, { useState, useEffect } from 'react';
import { supabase } from '../../uc_constants/supabaseClient';
import { Box, Input, Button, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function PasswordSetter({app}) {
    const toast = useToast();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValidPass, setIsValidPass] = useState(false);
    const navigate = useNavigate();

    //Handle PasswordValidation
    useEffect(() => {
        if(password.length >= 6 && password === confirmPassword) {
                setIsValidPass(true);
        }
        else {
                setIsValidPass(false);
        }
    },[password,confirmPassword])

    const handlePasswordSet = async () => {
        const new_password = password;
        try { 
            let response;
            if(app === 'forward') {
                response =  await supabase.auth.updateUser({password: new_password})
            }
            
            if (response.error) {
                toast({
                    title: "Error",
                    description: response.error.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
            else {
                toast({
                    title: "Password Set!",
                    description: "You can now log in with your email and password",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
                if(app === 'forward') {
                    navigate('/login');
                }
            }
        }
        catch (error) {
                console.error('Failed to update user:', error);
        }
    }

    return (
        <Box as="form" onSubmit={handlePasswordSet} m={4}>
            <Input 
                type="password" 
                placeholder="Set a Password"
                required
                value={password || ''}
                onChange={(e) => setPassword(e.target.value)}
                m={2}
                borderRadius={0}
            />
            <Input 
                type="password" 
                placeholder="Confirm your Password"
                required
                value={confirmPassword || ''}
                onChange={(e) => setConfirmPassword(e.target.value)}
                m={2}
                borderRadius={0}
            />
            <Button width="100%" colorScheme="teal" isDisabled={!isValidPass} onClick={() => handlePasswordSet()} m={2} borderRadius={0}>Set Password</Button>
        </Box>
    )
}
