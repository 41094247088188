const eventReducer = (events, action) => {
    switch (action.type) {
      case 'SET_EVENTS':
        return { events: action.payload};
      case 'UPSERT_EVENT': {
        //Events are just an array of objects
        //So we just need to add the new event to the array, or update the existing event, keyed on id
        const updatedEventProperties = action.payload[0];
        const eventId = updatedEventProperties.id;
        const existingEvent = events.find(event => event.id === eventId);
        // Merge existing event properties with updated properties
        const mergedEvent = existingEvent
          ? { ...existingEvent, ...updatedEventProperties }
          : updatedEventProperties;
        // Construct new events array
        let newEvents = events;
        // Remove event from old area if it was found
        if (existingEvent) {
          newEvents = newEvents.filter(event => event.id !== eventId);
        }
        // Add event to new area
        newEvents = [...newEvents, mergedEvent];
        return { events: newEvents };
      }
      default:
        return {};
    }
  };
  export default eventReducer;
  