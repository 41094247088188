import React, { useState, useContext } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
} from '@chakra-ui/react';
import NewAreaDropdown from '../../areas/components/NewAreaDropdown';
import HabitDropdown from '../../habits/components/HabitDropdown';
import { ForwardContext } from '../../../context/ForwardContext';
import { useNewGoals } from '../../../data_hooks/elements/useNewGoals';

const NewGoalCreate = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { state } = useContext(ForwardContext);
    const { upsertNewGoalFull } = useNewGoals();

    const [formData, setFormData] = useState({
        area_id: state.areas[0].id,
        goal_name: '',
        timeframe: 'months',
        habit_id: -1,
        order_id: -1,
        goal_type:'standard',
        goal_status:1,
    });

    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleChange = (e) => {
        console.log(e.target.name, e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        console.log(formData);
    };

    const handleAreaChange = (selectedOption) => {
        console.log(selectedOption);
        setFormData({
            ...formData,
            area_id: selectedOption,
        });
        console.log(formData);
    };
    const handleHabitChange = (selectedOption) => {
        console.log(selectedOption);
        setFormData({
            ...formData,
            habit_id: selectedOption,
        });
        console.log(formData);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform your submit logic here
        console.log(formData);
        const submitData = {...formData};
        submitData.user_id = state.account.id;
        if(submitData.goal_type !== 'habit') {
            //Remove the habit_id
            delete submitData.habit_id;
        }

        

        upsertNewGoalFull({ goal_data: submitData });

        handleCloseModal();
    };

    return (
        <>
            <Button onClick={handleOpenModal}>+ Goal</Button>

            <Modal isOpen={isOpen} onClose={handleCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Goal</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <FormControl>
                                <FormLabel>Goal Name</FormLabel>
                                <Input
                                    type="text"
                                    name="goal_name"
                                    value={formData.goal_name}
                                    onChange={handleChange}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Life Area</FormLabel>
                                <NewAreaDropdown value={formData.area_id} onChange={handleAreaChange} />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Timeframe</FormLabel>
                                <Select 
                                name="timeframe"
                                value={formData.timeframe}
                                onChange={handleChange} >
                                    <option value="weeks">Weekly</option>
                                    <option value="months">Monthly</option>
                                    <option value="quarters">Quarterly</option>
                                    <option value="years">Yearly</option>
                                </Select>
                            </FormControl>

                            <FormControl>
                                <FormLabel>Goal Type</FormLabel>
                                <Select
                                name="goal_type"
                                value={formData.goal_type}
                                onChange={handleChange}
                                >
                                    <option value="standard">Standard</option>
                                    <option value="habit">Habit</option>
                                </Select>
                            </FormControl>
                            {formData.goal_type === 'habit' && (
                            <FormControl>
                                <FormLabel>Habit</FormLabel>
                                <HabitDropdown value={formData.habit_id} onChange={handleHabitChange} />
                            </FormControl>
                            )}
                            <FormControl>
                                <FormLabel>Goal Status</FormLabel>
                                <Select
                                name="goal_status"
                                value={formData.goal_status}
                                onChange={handleChange}
                                >
                                    <option value="1">Active</option>
                                    <option value="2">Paused</option>
                                    <option value="3">Cancelled</option>
                                </Select>
                            </FormControl>


                           
                        </ModalBody>

                        <ModalFooter>
                            <Button type="submit" colorScheme="blue" mr={3}>
                                Save
                            </Button>
                            <Button onClick={handleCloseModal}>Cancel</Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    );
};

export default NewGoalCreate;