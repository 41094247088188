      // Helper function to ensure all task IDs are integers
      const ensureIntegerIds = (tasks) => tasks.map(task => ({
        ...task,
        id: parseInt(task.id)
      }));
      

const allTaskReducer = (new_tasks, action) => {
  switch (action.type) {
    case 'SET_TASKS_BY_DATE':
      const allTasks = action.payload;
      const task_id_to_date = {};
      Object.entries(allTasks).forEach(([date, tasks]) => {
        tasks.forEach(task => {
          task_id_to_date[task.id] = date;
        });
      });

      return {
        new_tasks: {
          tasks_by_date: allTasks,
          task_id_to_date: task_id_to_date,
        }
      };

    case 'SET_TASKS_DATE_RANGE':
      const task_subset = action.payload;
      const new_tasks_by_date = {...new_tasks.tasks_by_date};
      const new_task_id_to_date = {...new_tasks.task_id_to_date};
      for (const [date, tasks] of Object.entries(task_subset)) {
        new_tasks_by_date[date] = tasks;
        tasks.forEach(task => {
          new_task_id_to_date[task.id] = date;
        });
      }
      return {
        new_tasks: {
          tasks_by_date: new_tasks_by_date,
          task_id_to_date: new_task_id_to_date,
        }
      };

      case 'NEW_UPDATE_TASK_DATE':
  const { taskId: updateId, newDate } = action.payload;
  const oldDate = new_tasks.task_id_to_date[updateId];
  
  // If the task is not moving to a new date, no changes are needed
  if (oldDate === newDate) {
    return {new_tasks:new_tasks}; // Return unchanged state if task not found
  }

  const taskToMove = new_tasks.tasks_by_date[oldDate].find(task => task.id === parseInt(updateId));
  
  if (!taskToMove) {
    console.error(`Task with id ${updateId} not found in ${oldDate}`);
    return {new_tasks:new_tasks}; // Return unchanged state if task not found
  }

  return {
    new_tasks: {
      tasks_by_date: {
        ...new_tasks.tasks_by_date,
        // Remove the task from the old date while keeping other tasks unchanged
        [oldDate]: new_tasks.tasks_by_date[oldDate].filter(task => task.id !== parseInt(updateId)),
        // Add the task to the new date, preserving existing tasks
        [newDate]: [
          ...(new_tasks.tasks_by_date[newDate] || []),
          { ...taskToMove, item_planned_week: newDate }
        ].sort((a, b) => a.id - b.id) // Sort tasks by id to maintain consistent order
      },
      task_id_to_date: {
        ...new_tasks.task_id_to_date,
        [updateId]: newDate
      }
    }
  };
      

      // Update other cases to use this helper function
      case 'NEW_ADD_TASK':
        const task = action.payload;
        const date = task.item_planned_week;
        return {
          new_tasks: {
            tasks_by_date: {
              ...new_tasks.tasks_by_date,
              [date]: ensureIntegerIds([...(new_tasks.tasks_by_date[date] || []), task])
                .sort((a, b) => a.id - b.id)
            },
            task_id_to_date: {
              ...new_tasks.task_id_to_date,
              [parseInt(task.id, 10)]: date
            }
          }
        };
      
      case 'NEW_UPDATE_TASK_CONTENT':
        const { taskId, updatedContent } = action.payload;
        const taskDate = new_tasks.task_id_to_date[taskId];
        return {
          new_tasks: {
            ...new_tasks,
            tasks_by_date: {
              ...new_tasks.tasks_by_date,
              [taskDate]: ensureIntegerIds(
                new_tasks.tasks_by_date[taskDate]
                  .map(task =>
                    task.id === parseInt(taskId) ? { ...task, ...updatedContent } : task
                  ))
                  .sort((a, b) => a.id - b.id)
            }
          }
        };
      
      case 'NEW_REMOVE_TASK':
        const { taskIdToRemove } = action.payload;
        const dateOfTaskToRemove = new_tasks.task_id_to_date[taskIdToRemove];
        const { [taskIdToRemove]: _, ...remainingTaskIdToDate } = new_tasks.task_id_to_date;
        return {
          new_tasks: {
            tasks_by_date: {
              ...new_tasks.tasks_by_date,
              [dateOfTaskToRemove]: ensureIntegerIds(new_tasks.tasks_by_date[dateOfTaskToRemove]
                .filter(task => task.id !== parseInt(taskIdToRemove, 10)))
                .sort((a, b) => a.id - b.id)
            },
            task_id_to_date: remainingTaskIdToDate
          }
        };

    default:
      return { new_tasks };
  }
};

export default allTaskReducer;