import React, { useContext, } from 'react';
import { HStack, VStack, Button, useColorModeValue} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { ForwardContext } from '../../../context/ForwardContext.jsx';
import dhf from '../../../uc_utils/dates/dates.js';

import FastTaskForm from '../../tasks/forms/FastTaskForm.jsx';

import TaskContainerBox from '../../tasks/boxes/TaskContainerBox.jsx';

const ProjectTasks = ({ project, tasks, editProjectOpen, listToShowOverride = false, ...props}) => {
  
  const navigate = useNavigate();
  const {state, dispatch} = useContext(ForwardContext);

  const borderColor = useColorModeValue("forwardWhite.600","forwardBlue.900");
  const backgroundColor = useColorModeValue("forwardWhite.500","forwardBlue.800");
  const bdColor = useColorModeValue("forwardBlue.700","forwardGold.600");
  
  const is_ghost_project = project.project_type === 2 ? true:false;
  const task_planned_week = dhf.getStartOfWeek(new Date());

  let list_to_show = 'current_tasks';
  if (state.ui.currentWeek.toISOString() === state.ui.thisWeek.toISOString()) {
    list_to_show = 'this_week';
  }
  else if (state.ui.currentWeek.toISOString() === state.ui.nextWeek.toISOString()) {
    list_to_show = 'next_week';
  }
  if(listToShowOverride !== false) {
    list_to_show = listToShowOverride
  }

  const handleFastAdd = () => {

  }

  //console.log(list_to_show);
  if(tasks[list_to_show] && tasks[list_to_show].length === 0) {
    return null;
  }

  return (
    <>
      <VStack width="100%" spacing={0} ml={1} {...props}>
        <HStack width="100%" justifyContent="space-between" alignItems="baseline">
      <Button 
      //width="100%" 
      variant="ghost"
      colorScheme="forwardBlue"
      size="2xs"
      p={1}
      borderRadius={0}
      fontSize="xs" 
      textAlign="left" 
      fontStyle="oblique"
      cursor="pointer"
      onClick={() => navigate('/forward/project/'+project.id)}>
        {project.project_name}
      </Button>
      <FastTaskForm project_id={project.id} label={list_to_show} button_style="project_header" />
      </HStack>
    <TaskContainerBox 
            header="" 
            project_id={project.id} 
            tasks={(tasks && tasks[list_to_show]) || []}
            view_to_show={list_to_show}
            timeframe_to_show='currentweek' 
            width="100%"
            showAddButton={false}
            borderLeft="2px solid"
            borderColor={bdColor}/>
    </VStack>
    
    </>
  );
};

export default ProjectTasks;
