import React, { useState, useContext } from 'react';
import {
  ModalFooter,
  ModalBody,
  HStack,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  ButtonGroup,
  useToast,
} from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext';
import { trackCustomEvent } from '../../../general/functions/plausibleEvent';
import NewAreaDropdown from '../../areas/components/NewAreaDropdown';
import dhf from '../../../uc_utils/dates/dates.js'
import { useEvents } from '../../../data_hooks/elements/useEvents.jsx';
//import { useGoals } from '../../../data_hooks/elements/useGoals';

const EventForm = ({
  isOpen, onClose, type, session, editorData, optionsToShow, isDisabled
}) => {

  const showObject = optionsToShow || {'name':true,'area':true,'project':false,'timeframe':true, 'goal_points':false,}

  /*******************/
  //Form Setup and Management
  /*******************/
  const { state, dispatch } = useContext(ForwardContext);
  const toast = useToast();
  //const { upsertFullGoal } = useGoals();
  const { upsertEventFull } = useEvents();
  //console.log(editorData);

  const [newName, setNewName] = useState(editorData.event_name || '');
  const [startDate, setStartDate] = useState(editorData.start_date || dhf.formatDate(new Date()));
    const [startTime, setStartTime] = useState(editorData.start_time || '08:00');
    const [endDate, setEndDate] = useState(editorData.end_date || dhf.formatDate(new Date()));
    const [endTime, setEndTime] = useState(editorData.end_time || '09:00');
    const [eventCategory, setEventCategory] = useState(editorData.event_category || 1);
    const [selectedArea, setSelectedArea] = useState(editorData.area_id || state.areas[0].id);
    const [status, setStatus] = useState(editorData.status || 1);
    const [isAllDay, setIsAllDay] = useState(editorData.is_all_day || false);

  const handleAreaChange = (value) => {
    setSelectedArea(value);
    //console.log(selectedArea);
  };
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  }

  let submit_text = '';
  let submit_mode = '';
  if(Object.keys(editorData).length === 0)
  {
    submit_text = 'Create'
    submit_mode = 'new'
  }
  else {
    submit_text = 'Update'
    submit_mode = 'edit'
  }

  let area_limit = 'none';
  if(editorData && editorData.area_to_limit) {
    area_limit = editorData.area_to_limit;
  }

  const user_id = session.user.id;

  /*******************/
  //Submit to Database
  /*******************/
  async function handleSubmit() {
    let event_data = {
                      'user_id':user_id, 
                      'event_name':newName, 
                      'area_id':selectedArea, 
                      'status':status,
                    'event_category':eventCategory,
                    'is_all_day':isAllDay
                      }
    if(editorData.id > 0) {
        event_data["id"] = editorData.id;
    }
    //Build start and end times:
    //TODO
    event_data["start_time"] = dhf.recombineDateTime(startDate, startTime);
    event_data["end_time"] = dhf.recombineDateTime(endDate, endTime);

    console.log(event_data);

    //let {result, message} = await upsertFullGoal(goal_data);
    //let {result, message} = {result:'success', message:'Success!'};
    let {result, message} = await upsertEventFull({event_data});
    //Submit to Database
    //const new_goal = await upsertGoal(goal_data);
    //trackCustomEvent('FWD Goal Added', {goal_name: newName});
    console.log(result);
    if(result === 'error') {
      toast({
        title: 'Error: Please upgrade your account to add more events!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    else {
      onClose();
      toast({
        title: 'Event Updated!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }

  };

  /*******************/
  // Return the Form
  /*******************/

  return (
    <>
    <FormControl>
        <ModalBody>
            {showObject['name'] && <><FormLabel fontWeight="bold">Event:</FormLabel>
            <Input
              value={newName}
              variant="flushed"
              placeholder="Flying to Dallas"
              onChange={(e) => setNewName(e.target.value)}
            /></>}
            {showObject['area'] &&
            <>
            <FormLabel fontWeight="bold" mt={4}>Life Area:</FormLabel>
            <NewAreaDropdown mt={2} value={selectedArea} onChange={handleAreaChange} variant="flushed" />
            </>
            }
            {showObject['task_category'] && <>
             <FormLabel fontWeight="bold" mt={4}>Category:</FormLabel>
             <ButtonGroup variant="outline" spacing={2}>
                {state.account.task_categories.map((category, index) => (
                  <Button
                    key={index}
                    onClick={() => setEventCategory(category.id)}
                    colorScheme={eventCategory === category.id ? 'forwardOrange' : 'gray'}
                    variant={eventCategory === category.id ? 'solid' : 'outline'}
                  >
                    {category.value}
                  </Button>
                ))}
              </ButtonGroup>
              </>
              }
            {showObject['is_all_day'] && <>
             <FormLabel fontWeight="bold" mt={4}>All Day?</FormLabel>
             <ButtonGroup variant="outline" spacing={2}>
                <Button
                  onClick={() => setIsAllDay(true)}
                  colorScheme={isAllDay ? 'forwardOrange' : 'gray'}
                  variant={isAllDay ? 'solid' : 'outline'}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => setIsAllDay(false)}
                  colorScheme={!isAllDay ? 'forwardOrange' : 'gray'}
                  variant={!isAllDay ? 'solid' : 'outline'}
                >
                  No
                </Button>
              </ButtonGroup>
              </>
              }
            {showObject['start_time'] && <>
            <FormLabel fontWeight="bold" mt={4}>Start Time:</FormLabel>
            <HStack><Input
              type="date"
              variant="flushed"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <Input
              type="time"
              variant="flushed"
              value={startTime}
              step={900}
              onChange={(e) => setStartTime(e.target.value)}
            />
            </HStack>
            </>}
            {showObject['end_time'] && <>
            <FormLabel fontWeight="bold" mt={4}>End Time:</FormLabel>
            <HStack><Input
              type="date"
              variant="flushed"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <Input
              type="time"
              variant="flushed"
              value={endTime}
              step={900}
              onChange={(e) => setEndTime(e.target.value)}
            />
            </HStack>
            </>}
            {showObject['area'] &&
            <>
            <FormLabel fontWeight="bold" mt={4}>Area (optional):</FormLabel>
            <NewAreaDropdown mt={2} value={selectedArea} onChange={handleAreaChange} variant="flushed" />
            </>
            }
            {showObject['status'] && <>
                    <FormLabel fontWeight="bold" mt={4}>Status:</FormLabel>
                    <Select
                                name="goal_status"
                                value={status}
                                onChange={handleStatusChange} isDisabled={false} size={'lg'}
                                >
                                    <option value="1">Active</option>
                                    <option value="2">Paused</option>
                                    <option value="3">Cancelled</option>
                                </Select>
                </>}

        </ModalBody>

        <ModalFooter>
          <Button borderRadius="0px" colorScheme="forwardOrange" mr={3} onClick={handleSubmit} isDisabled={isDisabled}>
            {submit_text}
          </Button>
          <Button borderRadius="0px" variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
        </FormControl>
        </>
  );
};

export default EventForm;