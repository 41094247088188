import { version } from 'os'
import cs from '../uc_constants/fwd_constants.js'

const updates = [
    {
        date:'August 4, 2024',
        version:'Beta 0.7.0',
        items: [
            'Alpha of a new homepage: just getting started but more to come here!',
        ]
    },
    {
        date:'June 7, 2024',
        version:'Beta 0.6.6',
        items: [
            'Added pricing and about pages',
            'Page Name now updates in the browser tab to reflect the current page',
            'Vice tracking progress bar coloring now works correctly',
            'Fixed midnight infinite loop bug',
        ]
    },
    {
        date:'May 5, 2024',
        version:'Beta 0.6.5',
        items: [
            'Backend Code Cleanup! We\'ve separated the repository from Mumma Labs, which will improve performance and reliability going forward.',
            'Bugfixes in the new user experience.',
        ]
    },
    {
        date:'April 30, 2024',
        version:'Beta 0.6.4.2',
        items:[
            'Fixed several bugs related to signing in, might be helpful!',
            'Default Projects now denoted and displayed like all other projects.',
            'Minor bug fixes relating to sorting and handling of projects.',
            'Updated landing page for the site - hopefully to entice new users!',
            'New Product Launch: Intellect Inbox - get GPT4-powered educational lessons straight to your inbox! Check it out at www.mumma.co/intellectinbox',
        ]
    },
    {
        date:'March 22, 2024',
        version:'Beta 0.6.4.1',
        items:[
            'Aggregate history charts are available for habits! Click on the Habit Index, Average Streak, Hit Percentage or Last Week/This Week stats to see a chart of your progress over time!',
            'New Search/Navigate Widget: Just hit CTRL+P to search for any task, habit, project, or area and navigate there instantly by hitting "enter" on your selection!',
            'Area history now visible: see your full history of tasks in a life area on the area detail page!',
            'We took care of some nasty bugs impacting habits without any target',
            'Many minor bug fixes for progress bars, reminders, project timeline, and habit stats',
        ]
    },
    {
        date:'March 15, 2024',
        version:'Beta 0.6.4',
        items:[
            'Goals Revamp - Initial Updates: Goals have been reworked to be more intuitive and easier to use!',
            'Bug Fixes for Date Handling: Time Zones shouldn\'t mess us up anymore!',
            'Task and Project Editing - sorting is now fixed so that editing a task doesn\'t drop it to the bottom of a list.',
            'Ability to set a default color mode (light/dark) and default task view, now within More > My Settings.',
            'Reminders now show what life area they are in and are shared if a life area is shared. Also, anniversary counts are added to yearly reminders!',
            'Reminders have been folded into tasks in order to simplify the UI a bit. Same functionality, just a bit more streamlined.',
            'Various other minor tweaks and fixes to make Forward a bit more reliable and a bit more intuitive!',
        ]

    },
    {
        date:'February 20, 2024',
        version:'Beta 0.6.3.3',
        items:[
            'Recurring Tasks! Now you can set any task to recur on a weekly basis. Just click the "Repeat..." button on any task to get started!',
            'Various UX improvements to make things look a little better!',
            'Much improved data syncing! Habits and tasks now should sync instantly between your phone and your computer, just like magic! Worst case, you can always click the "Refresh Data" option in the top right dropdown.',
            'Improved navigation experience and add buttons in the navigation bar for quick and easy data updates!',
            'Various bug fixes and consolidations that make Forward faster and more reliable.',
            'Animated progress bars for habits!',
            'ALPHA: Initial collaboration infrastructure has been added to enable sharing areas between users. More to come here!',
        ]

    },
    {
        date:'February 14, 2024',
        version:'Beta 0.6.3.2',
        items:[
            'Brand New Navigation Bar: more intuitive and informative design to help you see all that Forward has to offer!',
            'Detail Page Reworks have begun for Areas and Projects, along with easy ways to toggle between them. More to come here!',
            'Timeline view for projects has been updated to show the length of gaps in your project work.',
            'A bunch of performance improvements. Forward was slow, now it is not!',  
        ]

    },
    {
        date:'February 12, 2024',
        version:'Beta 0.6.3.1',
        items:[
            'Free Tier Revamp! Any user can now track a select number of habits, tasks, and projects free... forever!',
            'Account limits: up to 5 active habits, 5 active life areas/projects, 5 goals, 12 reminders, 50 active tasks, and 90 day viewing history. Want more? Upgrade to Premium for less than $3/mo.',
            'Your history is preserved: if you ever upgrade to Premium, it\'ll all be right there for you!',
            'Pinned Tasks: Sorting is now enabled on these, use this to plan out your day!',
            'Toast alerts: see a bit more responsiveness when you create or update a task, project, habit, or goal. It all works, we\'re just leting you know!',
            'User badges revamped to be less ugly.',
            'Fast Task Adds within your weekly tasks: a quicker way to add current week tasks to a specific project!',
            'Some fun asthetic updates to try to make this not look like crap someone shipped from their garage.',
        ]

    },
    {
        date:'February 9, 2024',
        version:'Beta 0.6.3',
        items:[
            'New Feature: Habit Analysis View! See how you\'ve been doing on any habit in a comprehensively detailed view! Just click on any habit to learn more!',
            'Included there: the Habit Calendar! A Git Commit style view for any of your habits',
            'Also: a Habit Index and various benchmarks to help you evaluate how you are doing, as well as an export to csv for your weekly habit data',
            'Vice tracking enabled! Flip any habit that you are trying to do less of (e.g. smoking, drinking, snacking) to be a vice: updates the statistics and coloring to be appropriate.',
            'Habit stats now appear on mobile',
            'Habit Calc Code Revamped - should bring major performance improvements, more accuracy, and more!',
        ],

    },
    {
        date:'February 1, 2024',
        version:'Beta 0.6.2.2',
        items:[
            'Habit charts refined, especially for mobile',
            'Performance improvements for habit stat calculations',
            'Embeddables - adding color mode option, and handling login redirects. Plus fast login option.',
            'Embeddables - added habit and project selectors for the respective embeddables.',
            'Archive Viewer - adding a way via the "manage" modal to view and restore archived areas, habits, projects, and goals.',
            'Various Bug Fixes - task handling after drag and drop, and more!',
        ],

    },
    {
        date:'January 22, 2024',
        version:'Beta 0.6.2.1',
        items:[
            'Pinned Task upgrades: Tasks can be added directly as pinned tasks, pinned task module is collapsible, task can be added directly to pinned section.',
            'Data Export option available via the settings menu.',
            'Version 1 of Embeddables! Embed Forward widgets into Notion or similar! Just add www.mumma.co/forward/embed/reminders or www.mumma.co/forward/embed/pinnedtasks to try it out!',
            'Habit Charts have been revamped! Biger, prettier, and some additional stats are displayed to let you know how you are doing!',
            'Habit charts now accessible from the home page.',
        ],
    },
    {
        date:'January 18, 2024',
        version:'Beta 0.6.2.0',
        items:[
            'Added Pinned Tasks! Now you can keep your most important tasks at the top of your list!',
            'Enabled Data Refresh to run automatically upon revisiting the site, in case you made updates in another tab or on another device.',
            'Overdue tasks now get angrier the longer you leave them sitting. Avoid the red!',
            'Added a link to documentation under the help menu.',
            'Show all life areas by default under homepage tasks',
            'Added year selector to the goals widget on the homepage.',
            'Fixed appearance of user badge on mobile',
            'Various small bug fixes with task handling, and more!',
        ]
    },
    {
        date:'September 15, 2023',
        version:'Beta 0.6.1.2',
        items:[
            'Added brief intro tour for new users (sorry to existing users who have to see this too!)',
            'Removed paused habits from display on the home page, they now only display on the habit page.',
            'Added Forward walkthrough video to the home page.',
            'Manage modal fixed a display bug with long text.',
        ]
    },
    {
        date:'September 8, 2023',
        version:'Hotfix 0.6.1.1',
        items:[
            'Fix to display of habit cells to remove persistent gap',
            'Fix to drag and drop of the new united Project-Backlog View',
        ]
    },
    {
        date:'September 1, 2023',
        version:'Beta 0.6.1',
        items:[
            'Feature: Streak Medals v1! Extend your habit streak to earn bronze, silver, gold, streak medals. Displayed on both the home page and habit page. Get started with just 5 weeks!',
            'Combined Project and Backlog views to simplify the site and give an easy way to toggle between them!',
            'Ability to add a project straight from the add task menu!',
            'Added a link to the Forward Discord! Come join us: '+cs.discord_url,
            'Bug Fixes: Sidebar improvement on Area/Project Detail, Habit Header Alignment',
            'Password reset option added upon sign-in',
            'Small UX improvements around the site.',
        ]
    },
    {
        date:'August 21, 2023',
        version:'Beta 0.6.0',
        items:[
            'Initial Release of Forward!',
            'Habit Tracking: track daily habits and set targets for each week, keep track of streaks and other statistics.',
            'Project and Task Tracking: track your major projects and todos via a seemless drag and drop interface.',
            'Reminders: use reminders to handle long-term recurrences: birthdays, anniversaries, housework, and more.',
            'Goals: setup and track your major and minor goals for each year, keeping them top of mind and tracking your progress.',
        ]
    }
   
    ]


export default {
    updates
  }