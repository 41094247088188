import React, { useState, useContext } from 'react';
import { Box, Text, HStack, Button, useDisclosure } from "@chakra-ui/react";
import ProjectSetGoalForm from "../forms/ProjectSetGoalForm";
import GoalRow from '../../goals/rows/GoalRow';

function ProjectGoals({ project, goals, addGoalOpen, editGoalOpen }) {

  const {isOpen, onOpen, onClose} = useDisclosure();
  // Filter the goals to include only those associated with this project

  const handleOpen = () => {
    onOpen();
    console.log('handle open')
  }

  const projectGoals = Object.values(goals)
                        .flat()
                        .filter(goal => goal.project_id === project.id)
                        .filter(goal => parseInt(goal.goal_timeframe) === new Date().getFullYear())
                        .filter(goal => goal.status <= 1)
                        .filter(goal => goal.goal_progress !== 'complete')
                        .sort((a, b) => (a.goal_points > b.goal_points) ? 1 : -1);
  if (projectGoals.length === 0) {
    return (<>
              <HStack p={1}>
                <Text>Current Goal:</Text>
                <Button  borderRadius={0} size="xs" colorScheme="forwardGold" onClick={handleOpen}>Set One!</Button>
              </HStack>
              <ProjectSetGoalForm project={project} goals={goals} isOpen={isOpen} onClose={onClose} addGoalOpen={addGoalOpen} />
          </>);
  }

  return (
    <Box px={2} py={1} key={project.id}>
      <Text fontSize="2xs" mb={0}>Current Goal: </Text>
      {projectGoals.map(goal => (
        <HStack key={"hs-"+goal.id} alignItems="center" justifyContent="left">
          <GoalRow key={"gr-"+goal.id} goal={goal} user_id={project.user_id} editGoalOpen={editGoalOpen} view="projects" />
        </HStack>
        
      ))}
    </Box>
  );
}

export default ProjectGoals;
