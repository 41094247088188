

import React, {useContext, useEffect} from 'react';
import {
  VStack, HStack, Box, Flex, Link
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import DayHabits from '../../fwd_ui/habits/views/DayHabits';
import WeekTasks from '../../fwd_ui/tasks/views/WeekTasks';
import UpcomingReminders from '../../fwd_ui/reminders/views/UpcomingReminders';
import GoalList from '../../fwd_ui/goals/boxes/GoalList';
import WeekAccomplishments from '../../fwd_ui/tasks/views/WeekAccomplishments';
import PinnedTasks from '../../fwd_ui/tasks/views/PinnedTasks';

const HomeView = ({ session, ...props }) => {

return (<VStack maxWidth="1000px" width="100%" spacing={4} mt={4}>
      <Link width="100%" textAlign="right" fontSize="xs" as={RouterLink} to="/forward/newhome">New Home (ALPHA)</Link>
      <DayHabits session={session} width="100%" />
      <Flex flexDirection={{'base':'column','md':'row'}} gap={{'base':0,'md':4}} w="100%" alignItems="flex-start" mx={0} mt={4}>
      <VStack flex="1" pl={{'base':0,'md':0}} mt={{'base':2,'md':0}} width="100%" spacing={4}>
        <PinnedTasks session={session}  maxWidth="100%"  flex="1"/>
        <WeekTasks session={session} maxWidth="100%"  flex="1" />
        </VStack>
        <VStack flex="1" pl={{'base':0,'md':0}} ml={{'base':0,'md':4}} mt={{'base':4,'md':0}} width="100%" spacing={4}>
        {/*<UpcomingReminders session={session} maxWidth="100%"  flex="1"/>*/}
        <GoalList session={session} maxWidth="100%" flex="1" />
        <WeekAccomplishments session={session} maxWidth="100%" flex="1"/>
        </VStack>
      </Flex>
      </VStack>)
}
export default HomeView;