import React, {useState} from 'react';
import {
  Box,
  IconButton,
  useDisclosure,
  VStack,
  HStack,
  Text,
  Button,
  Input,
  useColorModeValue,
} from '@chakra-ui/react';

const GoalTimeframeSelector = ({ value, onValueChange, isDisabled, size, ...props }) => {

    //console.log(value);

    const [customValue, setCustomValue] = useState(value);
    const cy = new Date().getFullYear();
    const ny = cy + 1;

    const handleCustomValueChange = (value) => {
        setCustomValue(value);
        onValueChange(value); // or you can handle it differently
    };


  const px_size = size || 'md';

  let year_set = null;
  let qtr_set = 'None';
  if (Number.isInteger(Number(value))) {
    console.log(value);
    year_set = value;
  }
  else if (String(value).includes('Q')) {   
    year_set = value.split('Q')[0];
    console.log(year_set);
    const splitValue = value.split('Q');
    if (splitValue.length > 1) {
        qtr_set = 'Q' + splitValue[1];
    }
    }

  const [selectedYear, setSelectedYear] = useState(year_set);
  const [quarterSelected, setQuarterSelected] = useState(qtr_set);

  const btnColors = useColorModeValue('forwardWhite','forwardBlue');
  const brdColor = useColorModeValue('forwardWhite.800','forwardBlue.800');
  const txtColor = useColorModeValue('black','white');
  const selectedColor = useColorModeValue('forwardBlue','forwardGold');

    const handleChange = (newValue) => {
        setSelectedYear(newValue);
        setQuarterSelected(null);
        onValueChange();
    };


    const handleQuarterChange = (newValue) => {
        //console.log(newValue);
        setQuarterSelected(newValue);
        if(newValue !== 'None') {
            //console.log(selectedYear);
            //console.log(`${selectedYear}${newValue}`);
            onValueChange(`${selectedYear}${newValue}`);
        }
        else {
            //console.log(selectedYear);
            onValueChange(selectedYear);
        }
    }
    

    return (
        <VStack width="100%" spacing={0}>
        <HStack width="100%"
                spacing={0}>
        <Button 
        flex="1"
        size={px_size}
        borderRadius={0}
        textColor={txtColor}
        borderRight="1px"
        borderColor={brdColor}
        onClick={() => handleChange(null)}
        colorScheme = {selectedYear === null
                        ? selectedColor
                        : btnColors}
        >None</Button>
            <Button 
            flex="1"
            size={px_size}
            borderRadius={0}
            textColor={txtColor}
            borderRight="1px"
            borderColor={brdColor}
            onClick={() => handleChange(cy)}
            colorScheme = {parseInt(selectedYear) === parseInt(cy)
                            ? selectedColor
                            : btnColors}
            >{cy}</Button>
            <Button 
            flex="1"
            size={px_size}
            borderRadius={0}
            textColor={txtColor}
            borderRight="1px"
            borderColor={brdColor}
            onClick={() => handleChange(ny)}
            colorScheme = {parseInt(selectedYear) === parseInt(ny)
                            ? selectedColor
                            : btnColors}
            >{ny}</Button>
            <Button
                flex="1"
                size={px_size}
                borderRadius={0}
                textColor={txtColor}
                borderRight="1px"
                borderColor={brdColor}
                onClick={() => handleChange('other')}
                colorScheme={selectedYear === 'other' ? selectedColor : btnColors}
            >
                Custom
            </Button>
            </HStack>
            {selectedYear === 'other' && (
                <Input
                    size={px_size}
                    type="text"
                    value={customValue}
                    variant="flushed"
                    onChange={(e) => handleCustomValueChange(e.target.value)}
                    placeholder="Enter custom value of format YYYY or YYYYQ#"
                />
            )}
            {(selectedYear !== null && selectedYear !== 'other') && 
            <VStack width="100%" spacing={0}>
                <Box fontSize="xs">(Optional) Select a Quarter:</Box>
                <HStack width="100%"
                spacing={0}>
                    {['None','Q1', 'Q2', 'Q3', 'Q4'].map((quarter) => (
                        <Button 
                            key={quarter}
                            flex="1"
                            size={px_size}
                            borderRadius={0}
                            textColor={txtColor}
                            borderRight="1px"
                            borderColor={brdColor}
                            onClick={() => handleQuarterChange(quarter)}
                            colorScheme={quarterSelected === quarter ? selectedColor : btnColors}
                        >
                            {quarter}
                        </Button>
                    ))}
            </HStack>
            </VStack>}

            </VStack>
    );
  };
  export default GoalTimeframeSelector;


