import React, {useContext, useState, useEffect} from 'react';
import {
  VStack, HStack, Text, Heading, Accordion, AccordionItem, AccordionPanel, AccordionButton, AccordionIcon, Button
} from '@chakra-ui/react';
import { DragDropContext } from '@hello-pangea/dnd';
import { ForwardContext } from '../../../context/ForwardContext';
import AreaHeader from '../../areas/rows/AreaHeader';
import { useGoalDnd } from '../../../data_hooks/dnd/useGoalDnd';
import { FormContext } from '../../../context/FormContext';

import LoadingAnimation from '../../general/components/LoadingAnimation';
import GoalViewContainerBox from '../boxes/GoalViewContainerBox';
import { useIsMobile } from '../../../data_hooks/general/useIsMobile';
import QuarterlyGoalView from './QuarterlyGoalView';

const NewGoalsView = ({ session, ...props }) => {

  const {state, dispatch} = useContext(ForwardContext);
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState(state?.ui?.goalView || 'quarterly');
  const {addGoal} = useContext(FormContext);
  const {newHandleGoalDragEnd} = useGoalDnd({session});
  const liveYear = state.ui.currentYear;
  const isMobile = useIsMobile();

   const areas = state.areas;
  const defaultIndices = state.areas.map((_, index) => index);

  useEffect(() => {
    setViewMode(state.ui.goalView);
    //console.log(state.ui.goalView)
    console.log(viewMode);
  }, [state.ui.goalView]);

  /*****************************************/
  /*****************************************/
  /* Manage Page Load */
  /*****************************************/
  /*****************************************/
  useEffect(() => {
    if (Object.values(state.goals).flat().length > 0) {
          setIsLoading(false); // Set loading to false after syncing
          //console.log(state.goals)
        };
    }
  , [state.goals]);


    // Loading state
    if (isLoading) {
    return <LoadingAnimation />;
}  
    /*****************************************/
    /*****************************************/
    /* Set what fields we'll need to show    */
    /*****************************************/
    /*****************************************/
    let years = [];
    let quarters = [];
    let point_levels = [];
    let status_levels = [];

    if (viewMode === 'yearly') {
        if(isMobile) {
            years = ['None', liveYear];
        }
        else {
        years = ['None', liveYear-1, liveYear, liveYear+1];
        }
        quarters = ['None','Q1','Q2','Q3','Q4'];
        point_levels = ['all'];
        status_levels = ['all'];
    }
    else if (viewMode === 'quarterly') {
        years = [liveYear];
        quarters = ['Q1','Q2','Q3','Q4'];
        point_levels = ['all'];
        status_levels = ['all'];
    }
    else if (viewMode === 'points') {
        years = [liveYear];
        quarters = ['all'];
        point_levels = [1,2,3,5,8];
        status_levels = ['all'];
    }
    else if (viewMode === 'status') {
        years = [liveYear];
        quarters = ['all'];
        point_levels = ['all'];
        status_levels = ['abandoned','red','orange','yellow','green','complete'];
    }



  /*****************************************/
  /*****************************************/
  /* Main Return - Need to update by viewMode */
  /*****************************************/
  /*****************************************/

return (<VStack width="100%" maxWidth="1600px" alignItems="stretch">
        <VStack width="100%" alignItems="stretch">
        <DragDropContext onDragEnd={newHandleGoalDragEnd}>

        {Object.values(areas).map((area) => {

            const goals = state.goals[area.id].filter((goal) => goal.status < 3) || [];


          return (
            <Accordion key={area.id} defaultIndex={defaultIndices} allowMultiple>
            <AccordionItem>
            <AreaHeader area={area} collapsible={true} addible={true} addType="goal" size="large" />
            <AccordionPanel py={0} px={1}>
                {viewMode === 'yearly' &&
                <HStack width="100%" alignItems="flex-start">
                {/****Yearly View****/}
                {years.map((year) => {
                    let year_goals = goals.filter((goal) => parseInt(goal.goal_timeframe) === parseInt(year));
                    if (year === 'None') {
                        year_goals = goals.filter((goal) => goal.goal_timeframe === null).sort((a, b) => a.order_id - b.order_id)
                    }
                    return (
                        /*************************/
                        /*Each Year has a VStack:*/
                        /*************************/
                        <VStack flex="1">
                        <HStack justifyContent="space-between" width="100%" borderBottom="1px solid gray">
                            <Heading size="sm">{year === 'None' ? 'Someday' : year}</Heading>
                            <Button size="xs" variant="unstyled" onClick={() => addGoal({'area_id':area.id,'goal_timeframe':year},false,{'name':true,'area':true,'goal_points':true})}>+ Add</Button>
                            </HStack>
                            {year !== 'None' && quarters.map((quarter) => {
                                let quarter_goals = year_goals.filter((goal) => 'Q'+goal.goal_timeframe.split('Q')[1] === quarter).sort((a, b) => a.order_id - b.order_id);
                                if (quarter === 'None') {
                                    quarter_goals = year_goals.filter((goal) => goal.goal_timeframe.split('Q').length === 1).sort((a, b) => a.order_id - b.order_id);
                                }
                                return (
                                <VStack flex="1" width="100%" alignItems="stretch">
                                <HStack justifyContent="space-between" width="100%" borderBottom={quarter==='None' ? '' : '0.5px solid gray'}>
                                <Text fontSize="xs" >{quarter === 'None' ? '' : quarter}</Text>
                                {quarter!=='None' && <Button size="xs" variant="unstyled" onClick={() => addGoal({'area_id':area.id, 'goal_timeframe':year+quarter},false,{'name':true,'area':true,'goal_points':true})}>+ Add</Button>}
                                </HStack>
                                <GoalViewContainerBox 
                                goals={quarter_goals} 
                                session={session} 
                                isMobile={isMobile}
                                droppable_id={'goalbox-area-'+area.id+'-year-'+year+'-quarter-'+quarter+'-points-all-progress-all'}
                                showTimeframe={false} />
                                </VStack>
                                )


                            }
                            
                            )}
                            {year === 'None' && 
                            <VStack flex="1" width="100%" alignItems="stretch">
                            <Text fontSize="xs">{''}</Text>
                            <GoalViewContainerBox 
                            width="100%"  
                            goals={year_goals} 
                            session={session} 
                            droppable_id={'goalbox-area-'+area.id+'-year-'+year+'-quarter-None-points-all-progress-all'}
                            showTimeframe={false} />
                            </VStack>
                            }

                        </VStack>
                    )
                })}
                </HStack>
                }
                {/****Quarterly View****/}
                {viewMode === 'quarterly' &&
                <QuarterlyGoalView area={area} goals={goals} session={session} isMobile={isMobile} addGoal={addGoal} liveYear={liveYear}/>
                }



                </AccordionPanel>
                </AccordionItem>
                </Accordion>
          
          )
          })
        }
        </DragDropContext>
        </VStack>
      </VStack>)
}
export default NewGoalsView;