import React, { useState, useContext, useEffect, useMemo} from 'react';
import { Box, HStack, Button, Text, useColorModeValue, IconButton, Flex, 
  VStack, Input, useDisclosure, Menu, MenuButton, MenuList, MenuItem , useToast} from '@chakra-ui/react';
import dhf from '../../../uc_utils/dates/dates.js'
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { FormContext } from '../../../context/FormContext.jsx';
import YesNoSkipSelector from '../forms/YesNoSkipSelector.jsx';
import HabitNumberInput from '../forms/HabitNumberInput.jsx';

import hhf from '../../../uc_utils/habits/habits.js';
import { useHabits } from '../../../data_hooks/elements/useHabits.jsx';

import c from '../../../uc_constants/fwd_constants.js';
import { Link } from 'react-router-dom';
import NewHabitProgress from '../components/NewHabitProgress.jsx';

const HabitCell = ({ habit, dragHandle, liveDay, embed=false, newView=false, ...props}) => {
  const {state, dispatch} = useContext(ForwardContext);
  const {editHabit} = useContext(FormContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toast = useToast();
  const {upsertHabitValue} = useHabits();
  
  const colorMode = useColorModeValue('light','dark');
  const pbBgColor = useColorModeValue('forwardWhite.500','forwardBlue.800');
  const sm_defColor = useColorModeValue('forwardWhite.400','forwardBlue.600');
  const sm_textColor = useColorModeValue('black','white');
  const isMobile = window.innerWidth <= 768;
  const lim = habit?.is_less_better || false;

  // useMemo for expensive calculations
  const habitStats = state?.stats?.['habit_'+habit?.id] || {};
  const startDate = useMemo(() => new Date(liveDay), [liveDay]);
  const startDateAsString = useMemo(() => dhf.formatDate(startDate, 'yyyy-MM-dd'), [startDate]);
  const weekStartDate = useMemo(() => dhf.getStartOfWeek(new Date(liveDay)), [liveDay]);

  const cv = useMemo(() => hhf.getTotal(habit?.habit_type, habit?.values, weekStartDate), [habit, weekStartDate]);
  const ct = useMemo(() => hhf.getMostRecentTarget(habit?.targets, weekStartDate), [habit?.targets, weekStartDate]);
  const sm = useMemo(() => hhf.newStreakMedal(habitStats?.weekly_stats?.[dhf.formatDate(weekStartDate)]?.streak), [habitStats]);

  const value = startDateAsString in (habit?.values || []) ? habit?.values?.[startDateAsString] : '';
  const [bgColor, bgHue] = hhf.getHabitCellColor(habit, value, colorMode, lim);
  const actualBg = bgColor + "." + (parseInt(bgHue)+100);
  const numBorder = bgColor+ "." + (parseInt(bgHue)+useColorModeValue(200,0));

  const isDisabled = state.ui.is_readOnly;
  const handleClick = (event) => {
            event.target.select();
          };

  const v_len = String(value).length;
  let nFontSize;
  if (v_len >= 4) {
      nFontSize = {'base':'2xs','md':'sm'};
  }
  else if (v_len >= 3) {
      nFontSize = {'base':'xs','md':'md'};
  }
  else {
      nFontSize = {'base':'md','md':'lg'};
  }

    const handleEmbedLink = (habit) => {
      const linkText = c.forward_main_url+'/embed/habit/'+habit.id+'/cell';
      navigator.clipboard.writeText(linkText)
        .then(() => {
          toast({
            title: 'Link copied to clipboard',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        })
        .catch((error) => {
          console.error('Failed to copy link to clipboard:', error);
          toast({
            title: 'Failed to copy link to clipboard',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        });
    }
  
  const handleBlur = async (habit_id, date, value) => {
    upsertHabitValue(habit_id, date, value);
  };





    if (habit?.status === 2) {
      return (
        <HStack
      bg={actualBg}
      p={{'base':'0 !important'}}
      w="100%"
      marginLeft="0px !important"
      alignItems="stretch"
      justifyContent="space-between"
      minWidth="170px"
      flex="1"
      {...props}
    >
      <VStack>
      <Text p={4} flex="4" fontSize="2xs" textColor="gray" fontStyle="oblique">Paused</Text>
      <Text fontSize={{'base':'2xs'}} 
                    textAlign="center"
                    w="100%"
                    overflow="hidden">
              {habit.habit_name}</Text>
      </VStack></HStack>
      )
    }

    let width_object = {'base':'50px','md':'60px'};
    let min_width= '90px';
    if (newView) {
      width_object = {'base':'100%','md':'100%'};
      min_width = '100%';
    }

  return (
    <HStack
      bg={actualBg}
      p={{'base':'0 !important'}}
      w="100%"
      marginLeft="0px !important"
      alignItems="stretch"
      justifyContent="space-between"
      minWidth={min_width}
      flex="1"
      {...props}
    >
      {/* The left Buttons */}
      <Flex className="mobile-first-shell" 
            alignItems="center" 
            flexDirection={{'base':'column'}} 
            width="100%"
            flex="1"
            justifyContent="space-between"
            >
      <Box key={startDateAsString} 
            w={width_object} 
            p={newView ? 0 : 1}
            flex="1" >
        { habit?.habit_type === 'numeric' ? (
                  <HabitNumberInput
                  habitId={habit.id}
                  dateString={startDateAsString}
                  value={value}
                  handleClick={handleClick}
                  fontSize={nFontSize}
                  onBlur={handleBlur}
                  isDisabled={isDisabled}
                  borderColor={numBorder}
                  newView={newView}
                />
                ) : (
                  <YesNoSkipSelector
                    value={value}
                    onValueChange={(newValue) => handleBlur(habit.id, startDateAsString, newValue)}
                    isDisabled={isDisabled}
                    newView={newView}
                  />
                )}
              </Box>
                {!newView && <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} >
                  <MenuButton as={Text} fontSize={{'base':'2xs'}} textAlign="center" w="100%" overflow="hidden" cursor="pointer" onClick={() => setIsMenuOpen(true)}>
                    {habit.habit_name}
                  </MenuButton>
                  <MenuList>
                    <MenuItem fontSize="md" onClick={() => editHabit(habit)}>Edit</MenuItem>
                    <MenuItem fontSize="md" onClick={() => handleEmbedLink(habit)}>Get Embed Link</MenuItem>
                    <MenuItem fontSize="md" as={Link} to={"/forward/habit/"+habit.id}>View Analysis</MenuItem>
                  </MenuList>
                </Menu>}
                {!newView && 
                <>
                <NewHabitProgress 
                              area_id = {habit.area_id} 
                              habit_id= {habit.id} 
                              startDate={weekStartDate} 
                              backgroundColor={pbBgColor} 
                             toDoOnClick="set_target" 
                             showHoverText={true} 
                             borderWidth="1px 0px 1px 0px"
                showValues={false}/>
              <HStack m={0} width="100%" 
                            fontSize="2xs" 
                            height="16px" 
                            gap="0px !important"
                            overflowY="hidden" 
                            borderRight="0px solid gray"
                            borderBottom="1px solid gray">
              {  ct !== null &&
              <>
              <Text 
                    textAlign="center" 
                    flex="1"
                    backgroundColor={pbBgColor}
                    m={0}
                    >{cv}/{ct}</Text>
                <Text backgroundColor={sm.name === 'none'? sm_defColor : "streakColor."+sm.color} 
                      textAlign="center" 
                      textColor={sm.name === 'none'? sm_textColor : sm.textColor}
                      m={0}
                      flex="1" 
                      marginInlineStart="0px !important"
                      borderLeft="0.5px solid gray">
                      {(habitStats?.weekly_stats?.[dhf.formatDate(weekStartDate)]?.streak ?? 0) + ' wk'}
                </Text>
                </>}
              </HStack>
              </>}
    </Flex>
    </HStack>
  )
};

export default HabitCell;
