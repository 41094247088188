import { supabase } from '../uc_constants/supabaseClient';
import { format } from 'date-fns';

export async function upsertProject(project_data) {
    // If area_id is the string "null", replace it with actual null
    if (project_data.area_id === "null") {
        project_data.area_id = null;
    }
    
    let { data, error } = await supabase
    .from('forward_projects')
    .upsert([ project_data ], 
            {onConflict: 'id' })
      .select('*');

  if (error) {
      console.error('Error upserting user:', error);
  }
  return data;
}

export async function upsertProject_base(project_data) {
    // If area_id is the string "null", replace it with actual null
    if (project_data.area_id === "null") {
        project_data.area_id = null;
    }
    
    let { data, error } = await supabase
    .from('forward_projects')
    .upsert(project_data, 
            {onConflict: 'id' })
      .select('*');

  if (error) {
      console.error('Error upserting user:', error);
  }
  return data;
}

export async function readProjects(user_id) {
    let { data, error } = await supabase
      .from('forward_projects')
      .select(`*,
              forward_areas (
                id, area_name, date_deleted
            )`)
      .filter('date_deleted','is','null')
      .lte('status',3)
      .order('order_id');
  
    if (error) {
      console.error('Error fetching projects:', error);
    }

    data = data.filter(project => project.forward_areas.date_deleted === null);

  
    // Organize projects by area
    const projectsByArea = {};
    data.forEach((project) => {
      if (!projectsByArea[project.area_id]) {
        projectsByArea[project.area_id] = [];
      }
      projectsByArea[project.area_id].push(project);
    });
  
    return projectsByArea;
  }

  export async function readProjectsForArea(user_id, area_id) {
    let { data, error } = await supabase
      .from('forward_projects')
      .select('*')
      .eq('user_id', user_id)
      .eq('area_id', area_id)
      .filter('date_deleted','is','null')
      .lte('status',3)
      .order('order_id');
  
    if (error) {
      console.error('Error fetching projects:', error);
    }
  
  
    return data;
  }

  export async function deleteProject(user_id, project_id) {
    const { data, error } = await supabase
        .from('forward_areas')
        .update({ date_deleted: new Date() })
        .eq('id', project_id)
        .eq('user_id',user_id)
        
    if (error) {
        console.error('Error deleting area:', error);
        return { success: false, message: error.message };
    }
  }