import React, {useState} from 'react';
import { Box, Button, IconButton, Select, HStack, useColorModeValue, Menu, MenuList, MenuItem, MenuButton, VStack, Text, Link, Heading} from '@chakra-ui/react';
import { AiOutlineSetting } from 'react-icons/ai';
import HabitDropdown from '../components/HabitDropdown';
import { useNavigate } from 'react-router-dom';
import { useHabits } from '../../../data_hooks/elements/useHabits';
import ConfirmDialog from '../../general/components/ConfirmDialog';

const HabitHeaderRow = ({ habit, handleChangeHabit, editHabitOpen, area}) => {
    const headerBg = useColorModeValue('var(--chakra-colors-forwardWhite-100)','var(--chakra-colors-forwardBlue-800)');
    const {upsertHabitProperty} = useHabits();
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const onCloseDelete = () => setIsOpenDelete(false);
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const navigate = useNavigate();

    const handleAreaClick = () => {
        navigate('/forward/area/'+habit.area_id);
     }


    const unpauseHabit = async () => {
        upsertHabitProperty(habit,'status',1)
      }
      const pauseHabit = async () => {
        upsertHabitProperty(habit,'status',2)
      }

      const archiveHabit = async () => {
        upsertHabitProperty(habit, 'status', 3);
        upsertHabitProperty(habit, 'order_id', 999);
        onClose();
      };
    
      const deleteHabit = async () => {
        upsertHabitProperty(habit,'date_deleted',new Date())
        onCloseDelete();
      }
    

    return (

            <HStack width="100%" justifyContent="space-between" backgroundColor={headerBg} p={4}>
            <VStack alignItems="flex-start">
            <HStack>
                <Text fontSize="sm" fontStyle="oblique">Area:</Text>
                <Text as={Link} fontSize="sm" fontStyle="oblique" cursor="pointer"  onClick={() => handleAreaClick()}>{area.area_name}</Text>
            </HStack>
                    <HStack>
                      <Heading size="md">{habit.habit_name}</Heading>
                    </HStack>
            </VStack>
            <Menu>
            <MenuButton
                as={IconButton}
                size="lg"
                borderRadius="0px"
                icon={<AiOutlineSetting />}
                aria-label="Settings"
                mt={{'base':0,'md':1}}
                mr={{'base':1,'md':0}}
            />
            <MenuList>
                <MenuItem onClick={() => editHabitOpen(habit)}>Edit</MenuItem>
                {habit.status===2 && <MenuItem onClick={unpauseHabit}>Unpause</MenuItem>}
                {habit.status===1 && <MenuItem onClick={pauseHabit}>Pause</MenuItem>}
                <MenuItem onClick={() => setIsOpen(true)}>Archive</MenuItem>
                <MenuItem onClick={() => setIsOpenDelete(true)}>Delete</MenuItem>
            </MenuList>
            </Menu>
            <ConfirmDialog
                isOpen={isOpen}
                onClose={onClose}
                onConfirm={() => archiveHabit}
                title="Archive Habit"
                description="Are you sure you want to archive this habit?"
                confirmButtonText="Archive"
                />
                <ConfirmDialog
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                onConfirm={() => deleteHabit}
                title="Delete Habit"
                description="Are you sure you want to delete this habit?"
                confirmButtonText="Delete"
                />
            
            </HStack>
    );
};

export default HabitHeaderRow;
