import { useEffect, useContext, useCallback, useState } from 'react';
import { ForwardContext } from '../../context/ForwardContext.jsx';
import { readHabits_all, upsertHabit } from '../../database_api/habitService.js';
import { upsertHabitValues } from '../../database_api/valuesService.js';
import { upsertHabitTargets } from '../../database_api/targetService.js';
import calc_habits from '../../calc/habits.js'
import { trackCustomEvent } from '../../general/functions/plausibleEvent.js';
import { usePermissions } from '../permissions/usePermissions.jsx';
import { useToast } from '@chakra-ui/react';
import dhf from '../../uc_utils/dates/dates.js';

export const useHabits = () => {
  const { state,dispatch, session } = useContext(ForwardContext);
  const [updateTrigger, setUpdateTrigger] = useState(null);
  const {checkPermissionToAdd} = usePermissions();
  const toast = useToast();

  useEffect(() => {
    //console.log('in use effect')
    if (updateTrigger) {
      //console.log('in trigger')
      const { habit_id, date } = updateTrigger;
      const habit = Object.values(state.habits).flatMap(habitsArray => habitsArray).find(h => h.id === habit_id);
      const old_targets_hit = state?.stats?.['habit_'+habit_id]?.current_stats?.targets_hit || 0;
      if (habit) {
        const new_stats = calc_habits.calcHabits(habit, 'all', date);
        //console.log(new_stats);
        dispatch({type: 'UPSERT_ENTITY', payload: new_stats});
        if(new_stats.current_stats.targets_hit > old_targets_hit) {
          console.log('Target Hit! CONGRATS!')
          toast({ title: "Target Hit!", description: "Congrats! Keep up the good work!", status: "success", duration: 5000, isClosable: true, position: 'top' })
        }
      }
    }
  }, [updateTrigger, state.habits, dispatch]);

  /****************************************/
  //Main Sync Function to Grab all the data
  /****************************************/
  const syncHabits = useCallback(async (userId, account_level) => {
    if (typeof account_level !== 'undefined') {
      console.log('Fetching Habits');
      //console.log(account_level);
      const history_length = account_level >= 2 ? 9999 : 91;
      const history_start = dhf.getStartOfWeek(dhf.addDaysToDate(new Date(),-1*history_length))
      //console.log(history_start);
      try {
          const habitsData = await readHabits_all(userId, history_start);
          //console.log(habitsData);

          dispatch({ type: 'SET_HABITS', payload: habitsData });
          //console.log(habitsData);
          return habitsData; 
        } catch (error) {
          console.error('Failed to fetch habits:', error);
        }
    }
  }, [dispatch, session]);
  /****************************************/
  //Update Habit Value
  /****************************************/
  const realtimeHabitValueUpdate = async (habit_id, date, value) => {
        //This function will update state and update the database once a value is changed
        const habit_value_data = {'habit_id':habit_id, 'date':date, 'value':value}

        //Step 2: fire off dispatch to update the habit object
        dispatch({ type: 'UPSERT_HABIT_VALUE', payload: habit_value_data });
    
        setUpdateTrigger({ habit_id, date });
  }

  const upsertHabitValue = async (habit_id, date, value) => {
    //This function will update state and update the database once a value is changed
    const habit_value_data = {'habit_id':habit_id, 'date':date, 'value':value}

    //Step 2: fire off dispatch to update the habit object
    dispatch({ type: 'UPSERT_HABIT_VALUE', payload: habit_value_data });

    setUpdateTrigger({ habit_id, date });

    //Step 1: fire off update to database
    const new_values = await upsertHabitValues(habit_value_data);
    

    //Step 3 (optional): remediate state if database update fails

  }

  /****************************************/
  //Update Habit Property
  /****************************************/
  const upsertHabitProperty = async(habit, property, value) => {
    const habit_data = {'user_id':habit.user_id, 'id':habit.id, [property]:value  }
 
    console.log(habit_data);
    //Submit to Database
    const new_habit = await upsertHabit(habit_data);
    //Update State

    if (property === 'date_deleted' && value !== null) {
        dispatch({ type: 'REMOVE_HABIT', payload: { habitId:habit.id, areaId: habit.area_id } });
    }
    else {
    dispatch({ type: 'UPSERT_HABIT', payload: new_habit });
    }
    
    console.log('Habit added/updated!');
  //If not, alert to error
   if (!new_habit)  {
      alert("Error Updating Habit!")
    }
  }
  /****************************************/
  //Update Habit Stats
  /****************************************/
  const updateHabitStats =  async(habit, calc_mode, week_from = null) => {
    if(habit.status <= 3) {
    const new_stats = calc_habits.calcHabits(habit, calc_mode, week_from);
    dispatch({type: 'UPSERT_ENTITY', payload:new_stats})

    if (!new_stats)  {
      alert("Error Setting Habit Stats!")
    }
    }
  }

  const updateAllHabitStats = useCallback(async (habits, calc_mode, week_from = null) => {
  
    // Iterate over each array of habits
    Object.values(habits).forEach(habitsArray => {
      // Now iterate over each habit in the array
      habitsArray.forEach(habit => {
        // Perform operations on each habit object here
        updateHabitStats(habit, calc_mode, week_from);
      });
    });
  });

  const upsertFullHabit = async (habit_data) => {
    //Submit to Database
    let mode = 'create';
    if(habit_data.id > 0) {
      mode = 'edit';
    }
    let permission_to_create = checkPermissionToAdd('habits',mode);

    try {
      if(permission_to_create) {
    const new_habit = await upsertHabit(habit_data);
    trackCustomEvent('FWD Habit Added', {habit_name: habit_data.habit_name, habit_type: habit_data.habit_type});
    if (new_habit) {
      if(habit_data.id > 0) {
        new_habit[0]['targets'] = Object.values(state.habits).flatMap(habitsArray => habitsArray).find(h => h.id === habit_data.id).targets;
        new_habit[0]['values'] = Object.values(state.habits).flatMap(habitsArray => habitsArray).find(h => h.id === habit_data.id).values;
      }
      else {
      new_habit[0]['targets'] = [];
      new_habit[0]['values'] = {};
      }
      dispatch({ type: 'UPSERT_HABIT', payload: new_habit });
      console.log('Habit added/updated!');
      if(mode === 'edit' > 0) {
        return {result:'success',message:'habit edit successful'}
      }
      else {
        return {result:'success',message:'habit add successful'}
      }
  //If not, alert to error
  } else  {
      alert("Error Adding Habit!")
      return {result:'error',message:'habit add failure at database level'}
  }
  }
  else {
    return {result:'error',message:'habit limit reached!'}
  }
  }
  catch {
    return {result:'error',message:'habit add failed.'}
  }
}

const upsertHabitTarget = async (habit_target_data) => {
  //This function will update state and update the database once a value is changed
  try {
    //const habit_target_data = {'habit_id':habit_id, 'target_date':target_date, 'target_value':target_value}
    //Step 1: fire off update to database
    const new_target = await upsertHabitTargets(habit_target_data);
    //Step 2: fire off dispatch to update the habit object
    console.log(new_target)
    dispatch({ type: 'UPSERT_HABIT_TARGET', payload: new_target[0] });
    //Step 3 (optional): remediate state if database update fails
    if (!new_target)  {
      alert("Error Updating Habit Target!")
    }
    else {
      return new_target;
    }
  }
  catch {
    alert("Error Updating Habit Target!")
  }
}

  return {syncHabits, upsertHabitValue, realtimeHabitValueUpdate, upsertHabitProperty, 
    updateHabitStats, updateAllHabitStats, upsertFullHabit, upsertHabitTarget };
};