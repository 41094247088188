import React, { useState, useEffect, useRef, useContext} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  useDisclosure,
  useColorModeValue,
  List,
  ListItem,
  Box,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ForwardContext } from '../../../context/ForwardContext';

const NavigationSearchModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { state } = useContext(ForwardContext);
  const [filterText, setFilterText] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const highlightColor = useColorModeValue('forwardBlue.50', 'forwardBlue.700');

  /* Start with the homepage options */
  const options = [
    { name: 'Forward Home', path: '/forward/', subtext: 'Homepage' },
    { name: 'Habits Home', path: '/forward/habits', subtext: 'Homepage' },
    { name: 'Tasks Home', path: '/forward/tasks', subtext: 'Homepage' },
    { name: 'Goals Home', path: '/forward/goals', subtext: 'Homepage' },
  ];
  /* Add option for each life area */
  for (const area of state.areas) {
    options.push({ name: area.area_name, path: `/forward/area/${area.id}`, subtext: 'Life Area' });
  }
  /* And each project */
  for (const project of Object.values(state.projects).flat()) {
    options.push({ name: project.project_name, path: `/forward/project/${project.id}`, subtext: 'Project' });
    }
  /* And each habit */
  for (const habit of Object.values(state.habits).flat()) {
    options.push({ name: habit.habit_name, path: `/forward/habit/${habit.id}`, subtext: 'Habit' });
    }
  /* Lower priority: Settings and Updates */
  options.push({ name: 'Settings', path: '/forward/settings', subtext: 'User Settings' });
  options.push({ name: 'Updates', path: '/forward/updates', subtext: 'View Updates' });


  // Filter options based on input text
  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(filterText.toLowerCase())
  );
  
  useEffect(() => {
    const openModal = () => onOpen();
    
    if(isOpen) {
    //Autoselect the input box
    inputRef.current?.focus();
    }
  
    // Listen for the custom event
    document.addEventListener('openNavigationSearchModal', openModal);
  
    return () => {
      document.removeEventListener('openNavigationSearchModal', openModal);
    };
  }, [onOpen]);

  useEffect(() => {
    if (isOpen) {
        // Set input to be blank
        setFilterText('');
      // Focus on the input field when modal opens and reset selection
      inputRef.current?.focus();
      setSelectedIndex(0);
    }
  }, [isOpen]);

  const handleOnClick = (path) => {
    onClose(); // Close the modal
    setTimeout(() => navigate(path), 150); // Navigate after a short delay
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && filteredOptions[selectedIndex]) {
      // Navigate to the selected item's path
      event.preventDefault(); // Prevent form submission or other default actions
        onClose(); // Close the modal first
        setTimeout(() => navigate(filteredOptions[selectedIndex].path), 150); // Navigate after a short delay
        
    } else if (event.key === 'ArrowDown') {
      // Prevent default to stop scrolling
      event.preventDefault();
      setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, filteredOptions.length - 1));
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered initialFocusRef={inputRef}>
      <ModalOverlay />
      <ModalContent borderRadius={0} style={{ marginTop: '0', position: 'absolute', top: '0' }}>
        <ModalHeader>Fast Navigation</ModalHeader>
        <ModalCloseButton />
        <ModalBody onKeyDown={handleKeyDown}>
          <Input
            ref={inputRef}
            borderRadius="0px"
            placeholder="Type to filter..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            mb={4}
          />
          <Box as="div" style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <List spacing={3}>
              {filteredOptions.map((option, index) => (
                <VStack px={2}  
                        key={index} 
                        spacing={0} 
                        bg={selectedIndex === index ? highlightColor : 'transparent'} 
                        alignItems="flex-start"
                        cursor="pointer"
                        onClick={() => handleOnClick(option.path)}
                        >
                <ListItem
                  key={index}
                  p={0}
                  fontWeight="bold"
                  
                >
                  {option.name}
                </ListItem>
                <Text fontSize="sm" ml={0} color="gray.500">{option.subtext}</Text>
                </VStack>
              ))}
            </List>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NavigationSearchModal;
