import React, { useContext } from 'react';
import { VStack, Heading, useToast, useColorMode } from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext';
import { useAccounts } from '../../../data_hooks/accounts/useAccounts';
import SettingRow from '../rows/SettingRow';

const SettingsView = ({ session }) => {
    const { state } = useContext(ForwardContext);
    const { setAccountFlag } = useAccounts({ session });
    const { setColorMode } = useColorMode();
    const toast = useToast();

    const updateSetting = (settingKey, newValue) => {
        setAccountFlag(session.user, settingKey, newValue);
        if (settingKey === 'default_color_mode') {
            setColorMode(newValue);
        }
        toast({
            title: `${settingKey} updated.`,
            description: `You have selected ${newValue}.`,
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    const settingBoxes = [
        {
            title: "Default Color Mode",
            context: "This will set the default color mode for your account.",
            initialValue: state.account.default_color_mode,
            onUpdate: (newValue) => updateSetting('default_color_mode', newValue),
            options: [
                { label: "Light", value: "light" },
                { label: "Dark", value: "dark" },
            ],
        },
        {
            title: "Default Task View",
            context: "This will set the default view for your tasks.",
            initialValue: state.account.default_task_view,
            onUpdate: (newValue) => updateSetting('default_task_view', newValue),
            options: [
                { label: "Kanban", value: "kanban" },
                { label: "Pinned", value: "pinned" },
                { label: "This Week", value: "thisWeek" },
            ],
        },
        {
            title: "Goal Timeframes",
            context: "You can track goals by year or by quarter.",
            initialValue: state.account.default_goal_period,
            onUpdate: (newValue) => updateSetting('default_goal_period', newValue),
            options: [
                { label: "Yearly", value: "yearly" },
                { label: "Quarterly", value: "quarterly" },
            ],
        },
        // Add more settings here as needed
    ];

    return (
        <VStack width="100%" maxWidth="1100px" alignItems="stretch" minWidth="70vw" px={2}>
            <Heading size="md">My Settings</Heading>
            {settingBoxes.map((box, index) => (
                <SettingRow key={index} {...box} />
            ))}
        </VStack>
    );
};

export default SettingsView;
