import React, { useState, useContext, useEffect } from 'react';
import { Box, HStack, Button, useMediaQuery, Text, useColorModeValue, IconButton, Flex, VStack, Input, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Icon } from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext';
import { useNavigate } from 'react-router-dom';
import { FaRegUserCircle, FaUserTie } from 'react-icons/fa';

const AccountBadge = ({view="standard"}) => {
  const {state, dispatch} = useContext(ForwardContext);

  const navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  // Initialize color states
  const [bgColor, setBgColor] = useState('forwardBlue.300');
  const [bdColor, setBdColor] = useState('forwardBlue.400');
  const [textColor, setTextColor] = useState('white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  //console.log(state.account);
  const al = state.account.account_type;

  // Update color states whenever `state.account.account_type` changes
  useEffect(() => {
    let newBgColor = 'forwardBlue.300';
    let newBdColor = 'forwardBlue.400';
    let newTextColor = 'white';


    if (al === 'Owner' || al === 'Employee' || al === 'Lifetime Gold') {
      newBgColor = 'black';
      newBdColor = 'black';
      newTextColor = 'black';
    }
    else if (al === 'Lifetime') {
      newBgColor = 'forwardOrange.300';
      newBdColor = 'forwardOrange.500';
      newTextColor = 'black';
    }
    else if (al === 'Member') {
      newBgColor = 'forwardOrange.300';
      newBdColor = 'forwardOrange.500';
      newTextColor = 'black';
    }
    else if (al === 'Trial') {
      newBgColor = 'forwardBlue.300';
      newBdColor = 'forwardBlue.500';
      newTextColor = 'black';
    }
    else if (al === 'Read Only') {
      newBgColor = 'gray.300';
      newBdColor = 'gray.500';
      newTextColor = 'black';
    }

    // Update color states
    setBgColor(newBgColor);
    setBdColor(newBdColor);
    setTextColor(newTextColor);
  }, [state.account]);

  const newColor = useColorModeValue("white","white");

  return (
    <>
      <HStack>
        {isMobile ? (
          <Button variant="ghost" px={0} colorScheme="white" borderRadius={0} as={IconButton} icon={al==='Free' ? <FaRegUserCircle color={newColor} /> : <FaUserTie color={newColor} />} size="lg" onClick={onOpen} />
        ) : (
          <>
            {al === "Free" && <FaRegUserCircle size="30px" color={newColor} />}
            {al !== "Free" && <FaUserTie size="30px"  color={newColor}/>}
            <VStack spacing="0px" alignItems="flex-start" textColor={newColor}>
              <Text fontSize="xs" fontWeight="semibold">{state.account.email}</Text>
              <Text fontSize="2xs" fontStyle="oblique" textDecoration="underline" cursor="pointer" onClick={() => navigate('/account')}>
                Manage
              </Text>
            </VStack>
          </>
        )}
      </HStack>

      {/* Modal for mobile */}
      {isMobile && (
        <Modal isOpen={isOpen} onClose={onClose} borderRadius="0px" >
          <ModalOverlay />
          <ModalContent borderRadius="0px" >
            <ModalHeader>Account Information</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontWeight="semibold">Email: {state.account.email}</Text>
              <Text fontSize="md" fontStyle="oblique" textDecoration="underline" cursor="pointer" onClick={() => navigate('/account')}>
                Manage my Account
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default AccountBadge;
