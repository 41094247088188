/* This component should display as a box that fills space and has a title and then shows TaskRows for each task.
It will also need to accept a list of tasks to display (calc should be done elsewhere - TBD)
It will also need to eventually have a function that runs if a task is dragged and dropped into this component, and ideally allow drag/drop sorting for tasks within the box.
*/
import React, { useState, useContext } from 'react';
import { 
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Button,
  Heading,
  Box,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import ProjectGoals from '../../projects/boxes/ProjectGoals';

const GoalContainerBox = ({project, goals, addGoalOpen, editGoalOpen}) => {

  const backgroundColor = useColorModeValue("forwardWhite.200","forwardBlue.700");
  const borderColor = useColorModeValue("forwardWhite.600","forwardBlue.900");



    //console.log(goals);
    return (<Box px={1} key={project.id} width="100%" backgroundColor={backgroundColor} borderBottom="1px" borderColor={borderColor}>
        <ProjectGoals key={project.id} project={project} goals={goals} addGoalOpen={addGoalOpen} editGoalOpen={editGoalOpen}  />
    </Box>)
}

export default GoalContainerBox;