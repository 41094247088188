import React from 'react';
import { Box, Text, Table, Thead, Tbody, Tr, Th, Td, useColorModeValue } from '@chakra-ui/react';
import habit_calc from '../../../calc/habits.js'
import HstStatCell from '../components/HstStatCell.jsx';

const HabitStatTable = ({ habit, habit_stats }) => {

    const shh = habit_calc.singleHabitHistory(habit_stats)

    let hp_rating = [];
    let p2t_rating = [];
    let as_rating = [];

    const hp = (100.*(shh.targetsHit/shh.totalTargets));
    let p2t = (100.*(shh.averageValue/shh.averageTarget));
    if(habit.is_less_better) {
        p2t = (100.*(shh.averageTarget/shh.averageValue));
    }
    const asv = shh.averageStreak;

    if(isNaN(hp)) {
        hp_rating = ['','']
    }
    else if(hp < 30) {
        hp_rating = ['red.300','F']
    }
    else if (hp<50) {
        hp_rating = ['orange.400','E']
    }
    else if (hp<65) {
        hp_rating = ['yellow.400','D']
    }
    else if (hp<80) {
        hp_rating = ['green.200','C']
    }
    else if (hp<90) {
        hp_rating = ['green.500','B']
    }
    else if (hp<100) {
        hp_rating = ['green.700','A']
    }
    else {
        hp_rating = ['forwardGold.500','A+']
    }
    if(isNaN(p2t)) {
        p2t_rating = ['','']
    }
    else if(p2t < 50) {
        p2t_rating = ['red.300','F']
    }
    else if (p2t<70) {
        p2t_rating = ['orange.400','E']
    }
    else if (p2t<80) {
        p2t_rating = ['yellow.400','D']
    }
    else if (p2t<90) {
        p2t_rating = ['green.200','C']
    }
    else if (p2t<100) {
        p2t_rating = ['green.500','B']
    }
    else if (p2t<120) {
        p2t_rating = ['green.700','A']
    }
    else {
        p2t_rating = ['forwardGold.500','A+']
    }



    if(isNaN(asv)) {
        as_rating = ['','']
    }
    else if(asv < -1) {
        as_rating = ['red.300','F']
    }
    else if (asv<0) {
        as_rating = ['orange.400','E']
    }
    else if (asv<1) {
        as_rating = ['yellow.400','D']
    }
    else if (asv<2) {
        as_rating = ['green.200','C']
    }
    else if (asv<3) {
        as_rating = ['green.500','B']
    }
    else if (asv<5) {
        as_rating = ['green.700','A']
    }
    else {
        as_rating = ['forwardGold.500','A+']
    }





    return (
        <Box>
            <Table className="habitStatTable" variant="simple" size="sm" >
                <Thead>
                    <Tr>
                        <Th>Statistic</Th>
                        <Th>Value</Th>
                        <Th>Rating</Th>
                    </Tr>
                </Thead>
                <Tbody>
                        <Tr key={"hst-row-0"}>
                            <Td colSpan="3"  fontStyle="oblique"  textAlign="center">Weekly Targets</Td>
                        </Tr>
                        <Tr key={"hst-row-1"}>
                            <Td>Weeks Tracked</Td>
                            <HstStatCell stat={shh.totalCount} label="weeks" />
                            <Td></Td>
                        </Tr>
                        <Tr key={"hst-row-2"}>
                            <Td>Weeks With a Target</Td>
                            <HstStatCell stat={shh.totalTargets} label="weeks" />
                            <Td></Td>
                        </Tr>
                        <Tr key={"hst-row-3"}>
                            <Td>Weekly Targets Hit</Td>
                            <HstStatCell stat={shh.targetsHit} label="weeks" />
                            <Td></Td>
                        </Tr>
                        <Tr key={"hst-row-4"} fontWeight="bold">
                            <Td>Hit Percentage </Td>
                            <HstStatCell stat={hp.toFixed(2)} label="%" />
                            <Td background={hp_rating[0]} textAlign="center">{hp_rating[1]}</Td>
                        </Tr>
                        <Tr key={"hst-row-5"}>
                            <Td colSpan="3"   fontStyle="oblique"  textAlign="center">Performance</Td>
                        </Tr>
                        <Tr key={"hst-row-6"}>
                            <Td>Avg. Performance</Td>
                            <HstStatCell stat={shh.averageValue} label="" />
                            <Td></Td>
                        </Tr>
                        <Tr key={"hst-row-7"}>
                            <Td>Avg. Target</Td>
                            <HstStatCell stat={shh.averageTarget} label="" />
                            <Td></Td>
                        </Tr>
                        <Tr key={"hst-row-8"} fontWeight="bold">
                            <Td>Perf/Target </Td>
                            <HstStatCell stat={p2t.toFixed(2)} label="%" />
                            <Td background={p2t_rating[0]} textAlign="center">{p2t_rating[1]}</Td>
                        </Tr>
                        <Tr key={"hst-row-9"}>
                            <Td colSpan="3"  fontStyle="oblique" textAlign="center">Streaks</Td>
                        </Tr>
                        <Tr key={"hst-row-10"}>
                            <Td>Best Streak </Td>
                            <HstStatCell stat={habit_stats.current_stats.best_streak} label="weeks" />
                            <Td></Td>
                        </Tr>
                        <Tr key={"hst-row-11"}>
                            <Td>Worst Streak</Td>
                            <HstStatCell stat={habit_stats.current_stats.worst_streak} label="weeks" />
                            <Td></Td>
                        </Tr>
                        <Tr key={"hst-row-12"} fontWeight="bold">
                            <Td>Avg Streak</Td>
                            <HstStatCell stat={asv} label="weeks" />
                            <Td background={as_rating[0]} textAlign="center">{as_rating[1]}</Td>
                        </Tr>
                </Tbody>
            </Table>
        </Box>
    );
};


export default HabitStatTable;
