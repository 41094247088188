import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button } from "@chakra-ui/react"
import { useRef, useState } from "react"

const ConfirmDialog = ({ isOpen, onClose, onConfirm, title, description, confirmButtonText, cancelButtonText }) => {
  const cancelRef = useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {description}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button borderRadius="0px" ref={cancelRef} onClick={onClose}>
              {cancelButtonText || "Cancel"}
            </Button>
            <Button borderRadius="0px" colorScheme="red" onClick={onConfirm} ml={3}>
              {confirmButtonText || "Confirm"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};


export default ConfirmDialog;