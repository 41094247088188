import React from 'react';
import YouTube from 'react-youtube';


function DisplayVideo({ url, ...props }) {
    const videoId = new URL(url).searchParams.get('v');

    return <YouTube opts={{...props}} videoId={videoId} />;
}

export default DisplayVideo;