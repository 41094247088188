import dates from '../dates/dates.js'


const getEarliestDate = (dates) => {
    // Sort the array of date strings
    const sortedDates = dates.sort();
  
    // Return the earliest date (the first element in the sorted array)
    return sortedDates[0] || null;
  };


const getWeeksRange = (startDate, endDate) => {
    //console.log(endDate)
    //console.log(startDate);
    const weeks = [];
    let current = startDate;
    current.setHours(0, 0, 0, 0);

    // Set the currentDate to the first Monday on or before the startDate
    while (current.getDay() !== 1) {
    current.setDate(current.getDate() - 1);
    }

    while (current <= endDate) {
    const weekStart = new Date(current);
    current.setDate(current.getDate() + 6); // Move to the next Sunday
    const weekEnd = new Date(current);
    weeks.push({ start: weekStart, end: weekEnd });
    current.setDate(current.getDate() + 1); // Move to the next Monday
    }

    //console.log(weeks);

    return weeks;
};

const getPeriodKey = (date, aggPeriod) => {
    const d = dates.createDateObject(date);
    const year = d.getFullYear();
    let key;
    switch (aggPeriod) {
        case 'weekly':
        let weekDay = d.getDay();
        let diff = d.getDate() - weekDay + (weekDay === 0 ? -6 : 1); // adjust when day is Sunday
        let weekStart = new Date(d.setDate(diff));
        key = dates.formatDate(weekStart,'yyyy-MM-dd');
            break;
        case 'monthly':
            key = year + "-" + ((d.getMonth() + 1) < 10 ? ("0" + (d.getMonth() + 1)) : (d.getMonth() + 1));
            break;
        case 'quarterly':
            key = year + "-Q" + (Math.floor(d.getMonth() / 3) + 1);
            break;
        case 'yearly':
            key = year.toString();
            break;
    }
    return key;
};

export default { getEarliestDate, getWeeksRange, getPeriodKey };