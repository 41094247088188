/* This component should display as a box that fills space and has a title and then shows TaskRows for each task.
It will also need to accept a list of tasks to display (calc should be done elsewhere - TBD)
It will also need to eventually have a function that runs if a task is dragged and dropped into this component, and ideally allow drag/drop sorting for tasks within the box.
*/
import React, { useContext } from 'react';
import { 
  Heading,
  Box,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Droppable } from '@hello-pangea/dnd';

import { ForwardContext } from '../../../context/ForwardContext.jsx';
import FastTaskForm from '../forms/FastTaskForm.jsx';
import TaskRow from '../rows/TaskRow.jsx';
import dhf from '../../../uc_utils/dates/dates.js';


const TaskContainerBox = ({header, project_id, tasks, view_to_show, timeframe_to_show, drop_function, showAddButton, showProjects = false, ...props}) => {
    //console.log(tasks);
    const {state, dispatch } = useContext(ForwardContext);
    //console.log(tasks);
    let tasks_to_show = tasks;
    const backgroundColor = useColorModeValue("forwardWhite.200","forwardBlue.700");
    const hoverColor = useColorModeValue("forwardOrange.100","forwardBlue.500");
    const borderColor = useColorModeValue("forwardWhite.600","forwardBlue.900");

    const task_count = tasks_to_show.length;

    let task_planned_week = state.ui.currentWeek;

    if (view_to_show === 'backlog' || view_to_show === 'priority' || view_to_show === 'toschedule') {
      task_planned_week = null;
    }
    else if (view_to_show === 'this_week') {
      task_planned_week = dhf.getStartOfWeek(new Date());
    }
    else if (view_to_show === 'next_week') {
      task_planned_week = dhf.getStartOfWeek(dhf.addDaysToDate(new Date(),7));
    }

    return (
      <Flex 
      backgroundColor={backgroundColor} 
      {...props}>
        <Droppable droppableId={'tc-project-'+project_id+'-'+view_to_show}>
          {(provided, snapshot) => (
            <Flex 
            flexDirection = "column"
            width="100%"
            justifyContent="space-between"
              {...provided.droppableProps}
              ref={provided.innerRef}
              backgroundColor={snapshot.isDraggingOver ? hoverColor : backgroundColor}

            >
              <Heading size="xs" textAlign="center">{header}</Heading>
              <Box flex="1">
              {tasks_to_show.map((task, index) => (
                <TaskRow key={'task-'+task.id} task={task} taskList={view_to_show} index={index} px={2} showProject={showProjects} />
              ))}
              {provided.placeholder}
              </Box>
              {showAddButton && <FastTaskForm project_id={project_id} week_date={task_planned_week} label={view_to_show}/>}
            </Flex>
          )}
        </Droppable>
        </Flex>

    )
}

export default TaskContainerBox;