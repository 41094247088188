import React from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Box,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillMinusCircle } from 'react-icons/ai';
import { trackCustomEvent } from '../../../general/functions/plausibleEvent';

const YesNoSkipSelector = ({ value, onValueChange, isDisabled, newView=false, ...props }) => {


  const { isOpen, onToggle, onClose } = useDisclosure();
  let height_object = '30px';
  if (newView) {
    height_object = '30px';
  }

  const handleChange = (newValue) => {
    onClose();
    onValueChange(newValue);
    trackCustomEvent('FWD Habit Entry', { habit_type: 'yesno'});

  };
 

  const getIcon = () => {
    switch (value) {
      case 1:
        return <AiFillCheckCircle size={height_object} color="var(--chakra-colors-green-500)" filter="drop-shadow(0px 1px 1px rgba(0,0,0,0.3))" />;
      case -1:
        return <AiFillCloseCircle size={height_object} color="var(--chakra-colors-red-600)" filter="drop-shadow(0px 1px 1px rgba(0,0,0,0.3))" />;
      case 0:
        return <AiFillMinusCircle size={height_object} color="var(--chakra-colors-gray-300)" filter="drop-shadow(0px 1px 1px rgba(0,0,0,0.3))" />;
      default:
        return <AiFillMinusCircle size={height_object} color="var(--chakra-colors-gray-300)" filter="drop-shadow(0px 1px 1px rgba(0,0,0,0.3))" />;
    }
  };

  return (
    <Popover
        isOpen={isOpen}
        onClose={onClose}

    >
      <PopoverTrigger>
        <Box as="button" p={0} borderRadius={0} w="100%" h={height_object} display="flex" justifyContent="center" {...props}
        onClick={() => onToggle()} disabled={isDisabled} alignItems="center"
      _disabled={{ opacity: 0.4 }}>
          {getIcon()}
        </Box>
      </PopoverTrigger>
      <PopoverContent  borderRadius={0}>
        <PopoverArrow />
        <PopoverBody display="flex" justifyContent="center">
          <IconButton
            icon={<AiFillCheckCircle size="25px" />}
            colorScheme="green"
            onClick={() => handleChange(1)}
            aria-label="Yes"
            mr={2}
            width="100%"
            alignItems="center"
            borderRadius={0}
          />
          <IconButton
            icon={<AiFillCloseCircle size="25px" />}
            colorScheme="red"
            onClick={() => handleChange(-1)}
            aria-label="No"
            mr={2}
            width="100%"
            alignItems="center"
            borderRadius={0}
          />
          <IconButton
            icon={<AiFillMinusCircle size="25px" />}
            colorScheme="gray"
            onClick={() => handleChange(0)}
            aria-label="Skip"
            width="100%"
            alignItems="center"
            borderRadius={0}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default YesNoSkipSelector;