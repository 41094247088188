import React, { useState, useContext, useEffect} from 'react';
import { supabase } from '../../uc_constants/supabaseClient';
import { Box, Input, Heading, Text, Button, VStack, HStack, Center, Divider, useColorModeValue} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import MyHeader from '../../general/components/MyHeader';
import AlertBar from '../../general/components/AlertBar';
import Footer from '../../general/components/Footer';
import { ForwardContext } from '../../context/ForwardContext';
import { useAccounts } from '../../data_hooks/accounts/useAccounts';

export default function Password({loadingSession, session, alert, ...props}) {

  const {state, dispatch} = useContext(ForwardContext);

  const {syncAccount, setAccountFlag} = useAccounts();

  const account = state.account;

  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingSu, setLoadingSu] = useState(false);
  const [isValidPass, setIsValidPass] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
 
  const bgColor = useColorModeValue('forwardWhite.500','forwardBlue.800');
  const bgColorLite = useColorModeValue('forwardWhite.200','forwardBlue.600');  

  useEffect(() => {
    //Sync account:
    syncAccount();
  }, []);  // Empty dependency array so this only runs once on mount

  //Handle PasswordValidation
  useEffect(() => {
    if(password.length >= 6 && password === confirmPassword) {
        setIsValidPass(true);
    }
    else {
        setIsValidPass(false);
    }

  },[password,confirmPassword])


  const handlePasswordSet = async () => {
    //Read Password in
    const new_password = password;

    try { 
    //Set Password in Supabase
    const { data, error } =  await supabase.auth.updateUser({password: new_password})

    //Set has_password = true
    const update_hp_flag = await setAccountFlag(session.user, 'has_password', 'true');
    }
    catch (error) {
        console.error('Failed to update user:', error);
    }
    //Navigate to Forward, show an alert
    console.log('Moving over to Forward!');
    navigate('/forward')
    //TODO: Show an alert
  }

  return (
    <>
    <MyHeader session={session}/>
    {alert && <AlertBar status={alert.status} message={alert.message} duration={alert.duration} />}
    <Center p={4} backgroundColor={bgColorLite} minHeight="70vh" >
      <VStack p={8} backgroundColor={bgColor} boxShadow="lg">
        <VStack maxWidth="600px" spacing={1}>
            <Text>Welcome to Mumma Labs, home of Forward</Text>
            <Text>Please set a password for your account:</Text>
            <Input 
            variant="flushed" 
            type="password" 
            placeholder="Set a Password"
            required
            value={password || ''}
            onChange={(e) => setPassword(e.target.value)}></Input>
            <Input 
            variant="flushed" 
            type="password" 
            placeholder="Confirm your Password"
            required
            value={confirmPassword || ''}
            onChange={(e) => setConfirmPassword(e.target.value)}></Input>
            <Button borderRadius={0} isDisabled={!isValidPass} width="100%" colorScheme="forwardBlue" onClick={() => handlePasswordSet()}>Set Password</Button>
            <Text fontSize="xs" fontStyle="oblique">Once set, you will be redirected to Forward.</Text>
    </VStack>
    </VStack>
    </Center>
    <Footer mt="0px !important"/>
    </>
  )
}
