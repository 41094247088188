
import { Box, GridItem } from '@chakra-ui/react'

import dh from '../dates/dates.js'


const processValuesIntoWeeks = (values, yearShown) => {
    // Custom function to parse dates in yyyy-mm-dd format as local dates
    const parseDate = (dateStr) => {
      const [year, month, day] = dateStr.split('-').map(Number);
      return new Date(year, month - 1, day); // months are 0-indexed in JavaScript Dates
    };
  
    // Convert object into an array of { date, value }
    const entries = Object.entries(values).map(([date, value]) => ({
      date: parseDate(date),
      value
    }));
  
    // Sort by date
    entries.sort((a, b) => a.date - b.date);
  
    let weeks = [];
    let currentWeek = [];
    let currentDate = entries.length > 0 ? startOfWeek(parseDate(Object.keys(values)[0])) : startOfWeek(new Date());
  
    const today = new Date();
    today.setHours(23, 59, 59, 999);

    const end_date = parseInt(yearShown) === new Date().getFullYear() ? today : new Date(yearShown, 11, 31);

    console.log(end_date);
  
    while (currentDate <= end_date) {
      const entry = entries.find(e => e.date.toDateString() === currentDate.toDateString());
  
      if (entry) {
        currentWeek.push(entry);
      } else {
        currentWeek.push({ date: new Date(currentDate), value: 0 });
      }
  
      currentDate.setDate(currentDate.getDate() + 1);
  
      if (currentWeek.length === 7) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    }
  
    if (currentWeek.length > 0) {
      weeks.push(currentWeek);
    }
    return weeks;
  };

  const processFirstSundays = (values, yearShown) => {
    const parseDate = (dateStr) => {
      const [year, month, day] = dateStr.split('-').map(Number);
      return new Date(year, month - 1, day);
    };
  
    const findFirstSunday = (year, month) => {
      const date = new Date(year, month, 1);
      while (date.getDay() !== 0) {
        date.setDate(date.getDate() + 1);
      }
      return date;
    };
  
    const countSundaysInMonth = (year, month, startDate) => {
      let date = new Date(year, month, 1);
      let count = 0;
      while (date.getMonth() === month) {
        if (date.getDay() === 0 && date >= startDate && date <= dh.addDaysToDate(new Date(),7)) {
          count++;
        }
        date.setDate(date.getDate() + 1);
      }
      return count;
    };
  
    const entries = Object.entries(values);
    if (entries.length === 0) {
      return [];
    }
  
    let earliestDate = parseDate(entries[0][0]);
    let currentDate = new Date();

    const end_date = parseInt(yearShown) === new Date().getFullYear() ? currentDate : new Date(yearShown, 11, 31);


    
    let result = [];
  
    while (earliestDate <= end_date) {
      const year = earliestDate.getFullYear();
      const month = earliestDate.getMonth();
      const firstSunday = findFirstSunday(year, month);
      const sundayCount = countSundaysInMonth(year, month, earliestDate);
  
      result.push({
        date: firstSunday,
        value: sundayCount
      });
  
      // Move to the next month
      earliestDate = new Date(year, month + 1, 1);
    }
    return result;
  };
  
  // Helper function to get the start of the week for a given date
  const startOfWeek = (date) => {
    const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1); // adjust when day is Sunday
    return new Date(date.setDate(diff));
  };

  // Function to determine color based on value
  const getColorForValue = (value, lim) => {
    let nvalue = parseFloat(value)
    if(!lim) {
    // Define your logic for color based on value
    if (nvalue === 0) return 'lightgray';
    if(nvalue < 0) return 'red.200';
    if (nvalue <= 3 ) return 'green.100';
    if (nvalue <= 6) return 'green.300';
    if (nvalue > 6) return 'green.500';
    return 'red'; // Customize this as per your requirements
    }
    else {
        // Define your logic for color based on value
        if (nvalue <= 0) return 'green.200';
        if(nvalue > 6) return 'red.500';
        if (nvalue >= 3 ) return 'red.300';
        if (nvalue >= 1) return 'red.100';
        return 'lightgray'; // Customize this as per your requirements
    }
  };

  const renderWeekdays = (boxSize, isMobile) => {
    const weekdays = ['M', 'T', 'W', 'Th', 'F', 'Sa', 'Su'];

    if(!isMobile) {
      return (weekdays.map((weekday, index) => (
          <Box
              key={index + 1}
              height={boxSize + "px"}
              width={boxSize + "px"}
              fontSize="xs"
              textAlign="center"
              borderBottom="1px solid gray"
                borderRight="1px solid gray"
                display="flex"
                alignItems="center"
                justifyContent="center"
          >
              {weekday}
          </Box>
      )));
    }
    else {
        return (weekdays.map((weekday, index) => (
            <GridItem key={index+1} >
            <Box
                key={index + 1}
                height={boxSize + "px"}
                width={boxSize + "px"}
                fontSize="xs"
                textAlign="center"
                borderBottom="1px solid gray"
                borderRight="1px solid gray"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {weekday}
            </Box>
            </GridItem>)));
  }
};






  
export default {
    processValuesIntoWeeks,
    processFirstSundays,
    startOfWeek,
    getColorForValue,
    renderWeekdays,

}