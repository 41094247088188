import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  VStack, Text, Center, Heading, Box, 
  Accordion, AccordionItem, AccordionPanel
} from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import ProjectRow from '../../projects/rows/ProjectRow.jsx';
import AreaHeader from '../../areas/rows/AreaHeader.jsx';
import { DragDropContext } from '@hello-pangea/dnd';
import { useTaskDnd } from '../../../data_hooks/dnd/useTaskDnd.jsx';
import LoadingAnimation from '../../general/components/LoadingAnimation.jsx';
import UpcomingReminders from '../../reminders/views/UpcomingReminders.jsx';
import WeekTasks from './WeekTasks.jsx';
import PinnedTasks from './PinnedTasks.jsx';

const TasksView = ({ session, startingView="", ...props }) => {

  //Set all the main definitions necessary
  const { state, dispatch } = useContext(ForwardContext);
  const [viewMode, setViewMode] = useState(state.ui.projectView);
  const {handleDragEndWell} = useTaskDnd();
  const [loadedAreas, setLoadedAreas] = useState([]);
  const [hasMoreAreas, setHasMoreAreas] = useState(true);
  const observer = useRef(null);

  //Update the startingView (weekly or backlog)
  useEffect(() => {
    if (startingView !== "") {
      setViewMode(startingView);
    }
  }, [startingView]);

  //Save that view within state to keep consistent in a session
  
  useEffect(() => {
    setViewMode(state.ui.projectView);
    setLoadedAreas([Object.values(state.areas)[0]]);
  }, [state.ui.projectView]);

  //Track which areas have been lazy loaded to screen
  useEffect(() => {
    const allAreas = Object.values(state.areas);
    if (allAreas.length > 0) {
      setLoadedAreas([allAreas[0]]);
      setHasMoreAreas(allAreas.length > 1);
    }
  }, [state.areas, viewMode]);

  //Check if the user can see the end of the page. If so, load another area if one exists.
  useEffect(() => {
    //console.log(viewMode);
    if(state.areas.length <= 5) {
      setLoadedAreas(Object.values(state.areas));
      setHasMoreAreas(false);
    }
    else if(viewMode === 'backlog' || viewMode === 'project' || viewMode === 'kanban') {
    //console.log('in the observer');
    observer.current = new IntersectionObserver((entries) => {
      //console.log(entries[0].isIntersecting);
      //console.log(hasMoreAreas);
      if (entries[0].isIntersecting && hasMoreAreas) {
        //console.log('have a new area to show!');
        const allAreas = Object.values(state.areas);
        setLoadedAreas((prevLoadedAreas) => {
          const nextIndex = prevLoadedAreas.length;
          const nextArea = allAreas[nextIndex];
          if (nextArea) {
            console.log('returning the new area!');
            const newLoadedAreas = [...prevLoadedAreas, nextArea];
            setHasMoreAreas(newLoadedAreas.length < allAreas.length);
            return newLoadedAreas;
          }
          return prevLoadedAreas;
        });
      }
    }, {
      rootMargin: '100px',
    });

    const target = document.getElementById('loadMoreTrigger');
    if (target) observer.current.observe(target);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }
  }, [state.areas, hasMoreAreas, viewMode]);

  /************************************************/
  /* Edit and modal handlers */
  /************************************************/

  const defaultIndices = state.areas.map((_, index) => index);

  /**************************/
  /**************************/
  /* Main body of the element: */
  /**************************/
  /**************************/
  if(viewMode === 'thisWeek') {
    return (
      <WeekTasks location="tasks" mode="thisWeek" session={session} />
    )
  }
  else if (viewMode === 'nextWeek') {
    return (
      <WeekTasks location="tasks" mode="nextWeek" session={session} />
    )  
  }
  else if (viewMode === 'pinned') {
    return (
      <PinnedTasks location="tasks" session={session} />
    )  
  }
  else if (viewMode === 'reminders') {
    return(
    <UpcomingReminders location="tasks" session={session} />
    )
  }


  return (
    <VStack w="100%" maxWidth="1100px">
      <Center w="100%">
      <DragDropContext onDragEnd={handleDragEndWell}>
        <VStack spacing={0} w="100%" maxWidth="1200px" minWidth={{'base':"300px",'md':"800px",'lg':"1200px"}}>
        {/******* Loop through all areas that we have loaded ******/}
              {loadedAreas.map((area) => {
                // Get the projects for this area
                let projectsInArea = state.projects[area.id] || [];
                projectsInArea = projectsInArea.filter(project => project.status <= 2);
                if (projectsInArea.length === 0) {
                  return null;
                }
                return (
                  <Box key={area.id} width="100%">
                    <Accordion defaultIndex={defaultIndices} allowMultiple>
                  <AccordionItem>
                    <AreaHeader area={area} collapsible={true} size="large" addible={true} addType="project" addText="+ Project" />
                    <AccordionPanel py={0} px={1}>
                      {/******* Loop through all projects within that area, and print a row ******/}
                    {projectsInArea.flatMap((project) => (
                      <ProjectRow 
                      key={'project-'+project.id} 
                      project={project} 
                      tasks={state.tasks[project.id]} 
                      viewMode={viewMode}
                      />
                    ))}
                    </AccordionPanel>
                    </AccordionItem>
                    </Accordion>
                  </Box>
                );
                
              })}
      <div id="loadMoreTrigger">
        {hasMoreAreas && <LoadingAnimation />} {/* Loading animation */}
      </div>
        </VStack>
        </DragDropContext>
      </Center>
    </VStack>
  );
}

export default TasksView;
