
import React from 'react';
import { Box, VStack, Text, Popover, PopoverTrigger, Button, useDisclosure, Tooltip, Flex } from '@chakra-ui/react';
import NewTaskRow from '../rows/NewTaskRow';
import { Droppable } from '@hello-pangea/dnd';
import { FormContext } from '../../../context/FormContext';
import dhf from '../../../uc_utils/dates/dates.js'

const TaskPeriodContainerBox = ({ tasks, date, task_category, ...props }) => {
    const cellbg="gray.200";
    const { addTask } = React.useContext(FormContext);
    const backgroundColor = "gray.200";
    const hoverColor = "forwardOrange.300";

    let cat_to_set = 1;
    let pin_to_set = false;
    let editor_bucket = 'planned'

    let dateToShow = date;
    if(date === null) {
        dateToShow = 'null-null-null';
    }

    const handleAddTask = () => {
        let ipw = null;
        let task_list = null;
        let options_to_show = {
            'name':true,
        'project':false,
        'task_list_selector':false,
        'pinned':true,
        'recur_weeks':false,
        'planned_week':true,
        'task_category':true,
        }
        if(task_category === 'pinned') {
            cat_to_set = 1;
            pin_to_set = true;
            ipw = dhf.formatDate(date);
        }
        else if (task_category === 'backlog') {
            editor_bucket = 'backlog';
            options_to_show['task_list_selector'] = true;
            options_to_show['planned_week'] = false;
            task_list = 'backlog';
            options_to_show['planned_week'] = false;
            options_to_show['task_list_selector'] = true;
        }
        else if (task_category === 'priority') {
            editor_bucket = 'priority';
            options_to_show['task_list_selector'] = true;
            options_to_show['planned_week'] = false;
            task_list = 'priority';
            options_to_show['planned_week'] = false;
            options_to_show['task_list_selector'] = true;
        }
        else if (task_category === 'toschedule') {
            editor_bucket = 'toschedule';
            options_to_show['task_list_selector'] = true;
            options_to_show['planned_week'] = false;
            task_list = 'toschedule';
            options_to_show['planned_week'] = false;
            options_to_show['task_list_selector'] = true;
        }
        else {
            ipw = dhf.formatDate(date);
            cat_to_set = task_category;
        }
        const editorData = {
            'item_planned_week': ipw,
            'task_category': cat_to_set,
            'is_pinned': pin_to_set,
            'item_status': editor_bucket,
            'taskList': task_list,
        }

        addTask(editorData,false, options_to_show);
    }

    return (
        <>
        <Droppable droppableId={'tc-newhome-date-'+dateToShow+'-cat-'+task_category} width="100%">
          {(provided, snapshot) => (
            <Flex 
            flexDirection = "column"
            width="100%"
            minHeight="100px"
           
            
              {...provided.droppableProps}
              ref={provided.innerRef}
              backgroundColor={snapshot.isDraggingOver ? hoverColor : backgroundColor}
              {...props}
            >
{tasks?.map((entry, index) => {

    return (
        <NewTaskRow key={entry.id} bg={cellbg} width="100%" task={entry} index={index} taskList="all_tasks" />
    );
})}
{provided.placeholder}
</Flex>
          )}
</Droppable>
<Button size="xs" borderRadius={0} fontSize="sm" colorScheme="blue" variant="outline" width="100%" onClick={() => handleAddTask()}>+ Task</Button>
</>
    )
}

export default TaskPeriodContainerBox;