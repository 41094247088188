import React, { useState, useContext } from 'react';
import { 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalCloseButton, 
  ModalBody, 
  ModalFooter, 
  Button,
  Box,
  Text,
} from "@chakra-ui/react";


import { ForwardContext } from '../../context/ForwardContext.jsx';
import { useAccounts } from '../../data_hooks/accounts/useAccounts.jsx';



const Slide1 = () => <Box>
    <Text><b>Forward</b> is designed to manage all your habits, tasks, and goals!</Text>
    <Text><b>Habit</b> - something you want to do on a daily or weekly basis</Text>
    <Text><b>Task</b> - any one-time action that you need to take</Text>
    <Text><b>Goal</b> - any broader mission you want to accomplish</Text>
</Box>;
const Slide2 = () => <Box>
<Text>Forward organizes all of this into <b>Life Areas</b> which you can see as the big blue headers around the software!</Text>
<Text>Tasks can be further organized into <b>Projects</b> to help with organizing your work.</Text>
</Box>
const Slide3 = () => <Box>
<Text>That's all you need to get started :-) </Text>
<Text>Click on the <b>Manage</b> button in the top left to get started and adjust these for your own life!</Text>
</Box>;

const slides = [<Slide1 />, <Slide2 />, <Slide3 />];

const OnboardingModal = ({ session, isOpen, setIsOpen, ...props }) => {
    const { state, dispatch } = useContext(ForwardContext);
    //const [isOpen, setIsOpen] = useState(!state.account.had_tour);  // Assuming the modal is open initially for the sake of this example
    const [currentSlide, setCurrentSlide] = useState(0);
    const [showComplete, setShowComplete] = useState(false);

    const {setAccountFlag} = useAccounts();


  const goToNextSlide = () => {
    if(currentSlide === slides.length - 2) {
        setShowComplete(true);
    }
    else {
        setShowComplete(false)
    }
    setCurrentSlide((prevSlide) => Math.min(prevSlide + 1, slides.length - 1));
    
  };

  const goToPreviousSlide = () => {
    if(currentSlide === slides.length) {
        setShowComplete(true);
    }
    else {
        setShowComplete(false)
    }
    setCurrentSlide((prevSlide) => Math.max(prevSlide - 1, 0));
  };

  const handleClose = async () => {
    setIsOpen(false);
    await setAccountFlag (session.user, 'had_tour', true)
    // Handle closing logic
    //Need to update the DB and the current state

    
  }

  return (
    <Modal isOpen={isOpen} size="xl" onClose={handleClose} borderRadius="0px" >
      <ModalOverlay />
      <ModalContent borderRadius="0px" >
        <ModalHeader>Welcome to Forward!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {slides[currentSlide]}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="forwardBlue" mr={1} borderRadius="0px" onClick={goToPreviousSlide} disabled={currentSlide === 0}>
            Previous
          </Button>
          {!showComplete && <Button colorScheme="forwardBlue" mr={1}  borderRadius="0px" onClick={goToNextSlide} disabled={currentSlide === slides.length - 1}>
            Next
          </Button>}
          {showComplete && <Button colorScheme="forwardGold" borderRadius="0px" onClick={handleClose} >Got it!</Button>}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OnboardingModal;
