import React, {useEffect, useState, useContext} from 'react';
import {
  VStack, Text, HStack, Box, Heading, Button, Flex, useColorModeValue, Menu, MenuList, MenuItem, MenuButton, IconButton, Divider
} from '@chakra-ui/react';
import { AiOutlineSetting } from 'react-icons/ai';
import { useProjects } from '../../../data_hooks/elements/useProjects';

const StatBox = ({ stat_heading, stat_value, stat_label, style="basic", colorScheme='basic', ...props }) => {

    let bgColor = 'forwardBlue.600';
    let bdColor = 'forwardBlue.800';
    let textColor = "white"

    if (colorScheme === 'bronze') {
        bgColor = 'streakColor.400';
        bdColor = 'streakColor.400';
        textColor = 'white';
    }
    else if (colorScheme === 'silver') {
        bgColor = 'streakColor.500';
        bdColor = 'streakColor.500';
        textColor = 'black';
    }
    else if (colorScheme === 'gold') {
        bgColor = 'streakColor.600';
        bdColor = 'streakColor.600';
        textColor = 'black';
    }
    else if (colorScheme === 'diamond') {
        bgColor = 'streakColor.700';
        bdColor = 'streakColor.700';
        textColor = 'white';
    }

    

    if(style==='basic') {
    return (
        <Box 
            backgroundColor={bgColor}
            border="1px solid"
            borderColor={bdColor}    
            py={1}
            px={2} 
            boxShadow="xl"
            width="150px"
            textColor={textColor} 
            {...props} 
        >
            <VStack height="100%" spacing={0} justifyContent="space-around" alignItems="center" alignContent="center">
            <Text>{stat_heading}</Text>
            <Divider />
            <HStack>
                <Text fontWeight="bold">{stat_value}</Text>
                <Text fontSize="sm">{stat_label}</Text>
            </HStack>
            </VStack>

        </Box>
    )
    }
    if(style==='small') {
        return (
            <Box 
            backgroundColor={bgColor}
            border="1px solid"
            borderColor={bdColor}    
            py={0}
            px={0} 
            boxShadow="rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;"
            width="75px"
            textColor={textColor}
            {...props} 
        >
            <VStack spacing={1} height="100%" justifyContent="space-between">
            <Text width="100%" textAlign="left" fontSize="2xs" fontWeight="semibold" px={1} marginBottom="-7px">{stat_heading}</Text>
            <VStack spacing={0} px={1} width="100%" justifyContent="flex-end">
                <Text width="100%" marginBottom="0px !important" fontSize="md" textAlign="right">{stat_value}</Text>
            </VStack>
            </VStack>

        </Box>
        )
    }
}

export default StatBox;
