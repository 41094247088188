import React, { useState, useContext } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from "@chakra-ui/react";
import { useGoals } from "../../../data_hooks/elements/useGoals.jsx";

import { ForwardContext } from "../../../context/ForwardContext.jsx";

const ProjectSetGoalForm = ({project, goals, isOpen, onClose, addGoalOpen }) => {
    const { state, dispatch } = useContext(ForwardContext);
    const { upsertFullGoal } = useGoals();

    const handleNewGoalClick = (project) => {
        onClose();
        addGoalOpen({'project_id':project.id});
    }

    const handleGoalAttach = async (project_id, goal_id) => {
        //Build data to send:
        const data_to_send = {'user_id':project.user_id,
                              'id':goal_id,
                              'project_id':project_id}
        //Send update to database
        //const updated_goal = await upsertGoal(data_to_send);
        const result = await upsertFullGoal(data_to_send);

        if (result.result === 'success') {
          //Send Dispatch to Context
          //dispatch({ type: 'UPSERT_GOAL', payload: updated_goal });
          onClose();
          //Close Modal
          console.log('Goal updated!');
        } else  {
          console.log('Error updating goal');

        }
        
    }

    const unattachedGoalCount = Object.values(goals).flat().filter(goal => goal.project_id  === null).length;

  return (
    <Modal isOpen={isOpen} onClose={onClose} borderRadius="0px" >
      <ModalOverlay />
      <ModalContent borderRadius="0px" >
        <ModalHeader>Set a goal for {project.project_name}</ModalHeader>
        
        <ModalCloseButton />
        <ModalBody>
                {unattachedGoalCount > 0 && <Text width="100%" textAlign="center" mb={1}>Choose an unattached goal:</Text>}
                {Object.values(goals).flat().filter(goal => goal.project_id  === null).filter(goal => goal.status < 3).map(goal => (
                <Button key={goal.id} borderRadius={0} marginBottom={2} colorScheme="forwardGold" width="100%" onClick={() => handleGoalAttach(project.id,goal.id)}>{goal.goal_name}</Button>
            ))}
                <Text width="100%" textAlign="center" mb={1}>{unattachedGoalCount > 0 && 'Or, '}create a new one:</Text>
                <Button borderRadius={0} colorScheme="green" width="100%" onClick={() => handleNewGoalClick(project)}>Set a Goal</Button>
            

        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProjectSetGoalForm;