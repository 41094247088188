const goalReducer = (goals, action) => {
  switch (action.type) {
    case 'SET_GOALS':
      return { goals: action.payload };
      case 'UPSERT_GOAL': {
        console.log(action.payload)
        const updatedGoalProperties = action.payload[0];
        const goalId = updatedGoalProperties.id;
        const newAreaId = updatedGoalProperties.area_id;
      
        // Find the old area ID if it exists
        let oldAreaId = null;
        let existingGoal = null;
        for (let areaId in goals) {
          const goalIndex = goals[areaId].findIndex(goal => goal.id === goalId);
          if (goalIndex !== -1) {
            oldAreaId = areaId;
            existingGoal = goals[areaId][goalIndex];
            break;
          }
        }
      
        // Merge existing goal properties with updated properties
        const mergedGoal = existingGoal
          ? { ...existingGoal, ...updatedGoalProperties }
          : updatedGoalProperties;
      
        // Construct new goals object
        let newGoals = { ...goals };
      
        // Remove goal from old area if it was found
        if (oldAreaId) {
          newGoals[oldAreaId] = newGoals[oldAreaId].filter(goal => goal.id !== goalId);
        }
      
        // Add goal to new area
        newGoals[newAreaId] = newGoals[newAreaId]
          ? [...newGoals[newAreaId], mergedGoal]
          : [mergedGoal];
      
        return { goals: newGoals };
      }
      
      case 'REMOVE_GOAL': {
        const goalId = action.payload.goalId;
      const areaId = action.payload.areaId;
    
      return {
        goals: {
          ...goals,
          [areaId]: goals[areaId].filter(goal => goal.id !== goalId),
        },
      };
  
      }

      case 'UPDATE_GOAL_ORDER': {
        const { areaId, goalList } = action.payload;
      
        // Step 1: Retrieve the current list of goals for the specified areaId.
        const currentGoals = goals[areaId] || [];
      
        // Step 2: Create a map from the current goals for easier access by goal.id.
        const goalMap = currentGoals.reduce((acc, goal) => {
          acc[goal.id] = goal;
          return acc;
        }, {});
      
        // Step 3: Iterate over the goalList and update the goalMap with the new goals.
        goalList.forEach(newGoal => {
          goalMap[newGoal.id] = newGoal; // This updates or adds the new goal by id.
        });
      
        // Step 4: Convert the updated map back into an array.
        const updatedGoals = Object.values(goalMap);

        console.log(updatedGoals);
      
        // Step 5: Return the updated state with this merged list.
        return {
          goals: {
            ...goals,
            [areaId]: updatedGoals,
          },
        };
      }
      
    default:
      return {};
  }
};
export default goalReducer;
