import React, { useState, useContext } from 'react';
import { Button, Modal, Text, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Heading, useToast, Divider, Input, HStack, VStack} from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext';
import { useAreaSharing } from '../../../data_hooks/sharing/useAreaSharing';
import SharingRow from '../../general/rows/SharingRow';
import axios from 'axios';
import { API_BASE_URL } from '../../../uc_constants/constants';
import NewAreaDropdown from '../../areas/components/NewAreaDropdown';


function ShareRequestsModal({ session, shares, setShares, isOpen, onClose}) {
    const { state, dispatch } = useContext(ForwardContext);
    const toast = useToast();
    const {upsertShareProperty, shareLifeArea } = useAreaSharing();
    const [shareAreaId, setShareAreaId] = useState(state.areas?.[0].id || null);
    const [shareEmail, setShareEmail] = useState('');

    const handleSubmit = () => {
        console.log('Sharing area with:', shareEmail);
        shareLifeArea(state.account.id, state.areas.find((area) => area.id === shareAreaId), shareEmail);
        onClose();
    }

    const handleAreaChange = (value) => {
        setShareAreaId(value);
    }

    const handleAccept = (share_object) => { 

        //Update DB
        const new_share = upsertShareProperty(share_object, 'target', 'share_status', 1);
        //Update local state
        const updatedShares = shares.map(share => {
            if (share.id === share_object.id) {
                return { ...share, share_status: 1 };
            }
            return share;
        });
        setShares(updatedShares);
        //Update the user:
        toast({
            title: "Share Accepted!!",
            description: "Refresh your browser to see the shared life area!",
            status: "success",
            duration: 9000,
            isClosable: true,
            });


    }
    const handleRevoke = (share_object) => {
        const new_share = upsertShareProperty(share_object,'principal', 'share_status', 100, );
        const updatedShares = shares.map(share => {
            if (share.id === share_object.id) {
                return { ...share, share_status: 100 };
            }
            return share;
        });
        setShares(updatedShares);
        //Update the user:
        toast({
            title: "Share Revoked!!",
            description: "The user's access to this life area has been revoked. They will not be able to see any further updates.",
            status: "warning",
            duration: 9000,
            isClosable: true,
            });
    }
    const handleRemind = async (share) => {
        console.log('Sending e-mail');
                  const response = await axios.post(API_BASE_URL+'forward/mail/', 
                  { userEmail:share.principal_user_email, 
                    toAddress:share.target_user_email, 
                    message_type: 'area_share_invite', 
                    message_information: share.area_name});
                  console.log(response);
                    toast({
                        title: "Reminder Sent!",
                        description: "We've sent a reminder to " + share.target_user_email,
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                        });
    }
    const handleReject = (share_object) => {
        const new_share = upsertShareProperty(share_object, 'target', 'share_status', 101);
        const updatedShares = shares.map(share => {
            if (share.id === share_object.id) {
                return { ...share, share_status: 101 };
            }
            return share;
        });
        setShares(updatedShares);
        //Update the user:
        toast({
            title: "Share Rejected!!",
            description: "You have decided not to access this life area.",
            status: "warning",
            duration: 9000,
            isClosable: true,
            });
    }
    const handleCancel = (share_object) => {
        const new_share = upsertShareProperty(share_object, 'principal', 'share_status', 102);
        const updatedShares = shares.map(share => {
            if (share.id === share_object.id) {
                return { ...share, share_status: 102 };
            }
            return share;
        });
        setShares(updatedShares);
        //Update the user:
        toast({
            title: "Share Cancelled!!",
            description: "The user will no longer be able to accept this share.",
            status: "warning",
            duration: 9000,
            isClosable: true,
            });
    }
    const handleLeave = (share_object) => {
        const new_share = upsertShareProperty(share_object, 'target', 'share_status', 103);
        const updatedShares = shares.map(share => {
            if (share.id === share_object.id) {
                return { ...share, share_status: 103 };
            }
            return share;
        });
        setShares(updatedShares);
        //Update the user:
        toast({
            title: "Share Left!!",
            description: "You will no longer be able to access this life area.",
            status: "warning",
            duration: 9000,
            isClosable: true,
            });
    }


    return (
        <>
            <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius={0}>
                    <ModalHeader>Sharing Center</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Heading size="md" mb={2}>Choose A Life Area to Share:</Heading>
                        <VStack>
                        <HStack width="100%" flexDirection={{'base':'column','md':'row'}} alignItems={{'base':'stretch','md':'flex-start'}}>
                        <NewAreaDropdown value={shareAreaId} onChange={handleAreaChange} />
                        <Input placeholder="e-mail address" borderRadius={0} type="email" value={shareEmail} onChange={(e) => setShareEmail(e.target.value)} />
                        <Button className="forwardButton" colorScheme="forwardBlue" size="md" onClick={() => handleSubmit()}>Share</Button>
                        </HStack>
                        <Text fontSize="sm">Sharing a life area will give that user access to all goals, habits, projects, and tasks within the life area, enabling them to
                        modify that data and collaborate with you in real time!</Text>
                        </VStack>
                        <Divider my={4} />
                        <Heading size="md" mb={2}>My Life Areas Shared with Others:</Heading>
                        {shares.filter((share) => share.principal_user_id === state.account.id).filter((share) => share.share_status < 100).length === 0 && 
                        <Text textAlign="center" fontStyle="oblique" p={4}>Nothing Shared Yet!</Text>}
                        {shares
                                .filter((share) => share.principal_user_id === state.account.id)
                                .filter((share) => share.share_status < 100)
                                .map((share) => { 
                            const share_type = share.target_user_id === state.account.id ? 'guest' : 'owner';  
                            return (
                                <SharingRow key={share.area_id} 
                                            share={share} 
                                            area_name={share.area_name} 
                                            email={share.target_user_email} 
                                            type={share_type} 
                                            status={share.share_status}
                                            handleRemind={handleRemind} 
                                            handleCancel={handleCancel}
                                            handleAccept={handleAccept}
                                            handleReject={handleReject}
                                            handleRevoke={handleRevoke}
                                            handleLeave={handleLeave}
                                             />
                            )
                        }
                        )}
                        <Divider my={4} />
                        <Heading size="md" mb={2}>Life Areas Shared with Me:</Heading>
                        {shares.filter((share) => share.target_user_id === state.account.id).filter((share) => share.share_status < 100).length === 0 && 
                        <Text textAlign="center" fontStyle="oblique" p={4}>Nothing shared with me yet!</Text>}

                        {shares
                        .filter((share) => share.target_user_id === state.account.id)
                        .filter((share) => share.share_status < 100)
                        .map((share) => { 
                            const share_type = share.target_user_id === state.account.id ? 'guest' : 'owner';  
                            return (
                                <SharingRow 
                                            key={share.area_id} 
                                            share={share} 
                                            area_name={share.area_name} 
                                            email={share.target_user_email} 
                                            type={share_type} 
                                            status={share.share_status}
                                            handleRemind={handleRemind} 
                                            handleCancel={handleCancel}
                                            handleAccept={handleAccept}
                                            handleReject={handleReject}
                                            handleRevoke={handleRevoke}
                                            handleLeave={handleLeave}
                                             />
                            )
                        }
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button borderRadius={0} colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default ShareRequestsModal;