import { Spacer, Button, Text, AccordionButton, AccordionIcon, HStack, useColorModeValue, Flex, Box, Tooltip} from '@chakra-ui/react';
import React, { useState, useContext } from 'react';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { useNavigate } from 'react-router-dom';
import { FormContext } from '../../../context/FormContext.jsx';
import c from '../../../uc_constants/fwd_constants.js';

const AreaHeader = ({  area, 
                            addible = false,
                            addType = '',
                            addText = 'default',
                            collapsible = false, 
                            size="small",
                            count_display = false,
                            fixedHeight = false,
                            count = 0, ...props}) => {



  const {state, dispatch} = useContext(ForwardContext);
  const { addTask, addHabit, addProject, addGoal, addReminder } = useContext(FormContext);
  const my_shares = state.shares.filter(share => share.area_id === area.id).filter(share => share.share_status === 1);
  let share_label = '';
  if(my_shares.length > 0) {
    if (state.account.id === my_shares[0].principal_user_id) {
    share_label = "Shared with: "+my_shares.map(share => share.target_user_email).join(', ');
    }
    else {
      share_label = "Shared by: "+my_shares.map(share => share.principal_user_email);
    }
  }
  const navigate = useNavigate();
  const {task_list, default_data} = {...props};
  let default_project = null;
  let addFunction = null;
  if (addType === 'task') {
    addFunction = addTask;
  }
  else if (addType === 'habit') {
    addFunction = addHabit;
  }
  else if (addType === 'project') {
    addFunction = addProject;
  }
  else if (addType === 'goal') {
    addFunction = addGoal;
  }
  else if (addType === 'reminder') {
    addFunction = addReminder;
  }



  if( state.projects[area.id] && 
      state.projects[area.id].length > 0) {
    default_project = state.projects[area.id].filter(proj => proj.status <= 1)[0].id;
  }
  const defaultData = default_data || { 'area_to_limit':area.id,
                        'project_id':default_project, 
                        'area_id':area.id,
                        'submit_text':'Create',
                        'submit_mode':'new'};
  if(task_list) {
    defaultData.taskList = task_list;
  }

  let bgColor = useColorModeValue('forwardBlue.600','forwardBlue.600');
  let bdColor = useColorModeValue('forwardBlue.900','forwardBlue.300');
  let textColor = "forwardWhite.500";
  let elementWidth = "100%";
  let fontWeight = 'normal';
  let boxShadow="sm";
  let fontSize ="md";
  let px = "2px";
  let py = "2px";
  let ml = "0px";
  let maxHeight = "30px";
  let buttonSize = "md";
  let addButtonText = " + ";

  if(size === 'large') {
    fontSize="lg";
    fontWeight="semibold";
    boxShadow="md";
    elementWidth="calc(100%)";
    px = 2;
    py = 2;
    maxHeight="48px";
    buttonSize="lg"
    addButtonText="+ Add"
  }

  if(addText != 'default') {
    addButtonText = addText;
  }
  let text_props = {}
  if(fixedHeight) {
    text_props = {
      height:maxHeight,
      alignItems:"baseline"
    }
  }


  return (
    <HStack 
    width={elementWidth}
    py={py} 
    px={px}
    ml={ml}
    backgroundColor={bgColor}
    textColor={textColor}
    overflow="hidden"
      flexWrap="nowrap" 
      boxShadow={boxShadow} 
      borderBottom="1px"
      borderColor={bdColor}
      maxHeight={maxHeight}
      justifyContent="space-between"
      alignItems="center"
      cursor="default"
      {...props}>
        <Flex alignItems="center" onClick={(e) => e.stopPropagation()} cursor="pointer" >
        <Text 
        paddingLeft="4px" 
        py="1px" 
        width="100%"  
        fontSize={fontSize}
        fontWeight={fontWeight}
        curor="pointer"
        overflowY="clip"
        _hover={{ textDecoration:"underline" }}
        {...text_props}
        onClick={() => navigate('/forward/area/'+area.id)}>{area.area_name}{count_display && " ("+count.toString()+")"}</Text>
        </Flex>
        <Spacer flex="1"/>
        <Box position="relative" px={2}>
            {my_shares.length > 0 &&
            <Tooltip placement="top" hasArrow cursor="pointer" label={share_label}>
            <span>{c.main_icons.shared}</span>
            </Tooltip>
          }
        </Box>
        <HStack height={addible ? "34px" : "0px"} padding="0px !important">
        {addible && 
        <Button   borderRadius="0px" 
                  size={buttonSize}
                  height="100%"
                  colorScheme="forwardBlue" 
                  fontWeight={fontWeight}
                  onClick={() => addFunction(defaultData)}>{addButtonText}</Button>}
        {collapsible && (
          <AccordionButton flex="1" padding="4px !important" cursor="pointer" size={buttonSize}>
              <AccordionIcon />
          </AccordionButton>
        )}
        </HStack>
    </HStack>
  );
};

export default AreaHeader;
