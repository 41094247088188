import React, { useState, useContext } from 'react';
import {

  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import NewAreaDropdown from '../../areas/components/NewAreaDropdown.jsx';
import GoalStatusDropdown from '../../goals/components/GoalStatusDropdown.jsx';
import dhf from '../../../uc_utils/dates/dates.js';
import { useReminders } from '../../../data_hooks/elements/useReminders.jsx';

const ReminderForm = ({
  isOpen, onClose, type, session, editorData, optionsToShow, isDisabled,
}) => {
  const toast = useToast();
  const {upsertFullReminder} = useReminders();


  const showObject = optionsToShow || {'name':true,
                                       'area':true,
                                       'start_date':true,
                                       'cadence':true
                                       };


  /*******************/
  //Form Setup and Management
  /*******************/
  const { state, dispatch } = useContext(ForwardContext);

  const today = new Date();

  const [newName, setNewName] = useState(editorData.task_name || '');
  const [startDate, setStartDate] = useState(editorData.starting_due_date || dhf.formatDate(today,'yyyy-MM-dd'));
  const [cadence, setCadence] = useState(editorData.cadence || 0);
  const [nextDueDate, setNextDueDate] = useState(editorData.next_due_date || null);
  const [status, setStatus] = useState(editorData.status || 'active');
  const [selectedArea, setSelectedArea] = useState(editorData.area_id || state.areas[0].id);

  const handleAreaChange = (value) => {
    setSelectedArea(value);
    //console.log(selectedArea);
  };
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  }

  let submit_text = '';
  let submit_mode = '';
  if(Object.keys(editorData).length === 0)
  {
    submit_text = 'Create'
    submit_mode = 'new'
  }
  else {
    submit_text = 'Update'
    submit_mode = 'edit'
  }
  const user_id = session.user.id;

  /*******************/
  //Submit to Database
  /*******************/
  async function handleSubmit() {

    const reminder_data = {
                      'task_name':newName, 
                      'area_id':selectedArea, 
                      'status':status,
                      'starting_due_date':startDate,
                      'cadence':cadence,
                      'next_due_date':nextDueDate,
                    }
    if(editorData.id > 0) {
      reminder_data["id"] = editorData.id;

    }
    //if (reminder_data['next_due_date'] === null) {
      //console.log('null due date');

      //let next_due_date = new Date(reminder_data['starting_due_date']);
      let next_due_date = dhf.createDateObject(reminder_data['starting_due_date']);
      //let cadence = parseInt(reminder_data['cadence']);
      setCadence(parseInt(reminder_data['cadence']))
    
      // Check if cadence is valid
      if (isNaN(cadence) || cadence < 0) {
        throw new Error("Cadence must be a positive integer");
      }
    
      let today = new Date();
    
      while (next_due_date < today && cadence > 0) {
        console.log(cadence);
        console.log(next_due_date);
        next_due_date = dhf.addMonthsToDate(next_due_date, parseInt(cadence));
      }
      reminder_data['next_due_date'] = next_due_date;
      //console.log(reminder_data);
    //}
    
    console.log(reminder_data);
    //Use the UseProject Hook to coordinate actions: adding to database and updating state
    let {result, message} = await upsertFullReminder(reminder_data);

    //Provide Feedback to user based on the outcome
    if(result === 'limit') {
      toast({
        title: 'Error: Please upgrade your account to add more reminders!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    else if (result === 'error') {
      toast({
        title: 'Error: '+message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    else {
      onClose();
      toast({
        title: 'Reminder Updated!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
    
  };

  /*******************/
  // Return the Form
  /*******************/

  return (
    <>
    <FormControl>
        <ModalBody>
            {showObject['name'] && <><FormLabel fontWeight="bold">Reminder:</FormLabel>
            <Input
              value={newName}
              variant="flushed"
              placeholder="Do a Portfolio Review"
              onChange={(e) => setNewName(e.target.value)}
            /></>}
            {showObject['area'] &&
            <>
            <FormLabel fontWeight="bold" mt={4}>Life Area:</FormLabel>
            <NewAreaDropdown mt={2} value={selectedArea} onChange={handleAreaChange} variant="flushed" />
            </>
            }
              {showObject['status'] && <>
                <FormLabel fontWeight="bold" mt={4}>Status:</FormLabel>
                <GoalStatusDropdown mt={2} value={status} onChange={handleStatusChange} variant="flushed" />  
              </>}
              {showObject['start_date'] && <>
                <FormLabel fontWeight="bold" mt={4}>Starting:</FormLabel>
                <Input
              type="date"
              variant="flushed"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
              </>}
              {showObject['cadence'] && <>
                <FormLabel fontWeight="bold" mt={4}>Repeat every:</FormLabel>
                <HStack>
                <Input
                  type="number"
                  variant="flushed"
                  value={cadence}
                  onChange={(e) => setCadence(e.target.value)}
                />
                <Text>Months</Text>
                </HStack>
              </>}
              {showObject['next_due'] && <>
                <FormLabel fontWeight="bold" mt={4}>Next Due Date:</FormLabel>
                <Input
                  type="date"
                  variant="flushed"
                  value={nextDueDate}
                  onChange={(e) => setNextDueDate(e.target.value)}
                />
              </>}


        </ModalBody>

        <ModalFooter>
          <Button  borderRadius={0} colorScheme="forwardOrange" mr={3} onClick={handleSubmit} isDisabled={isDisabled}>
            {submit_text}
          </Button>
          <Button  borderRadius={0} variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
        </FormControl>
        </>
  );
};

export default ReminderForm;