import React, { useState, useContext } from 'react';
import {
  VStack, Center, Heading, Box, HStack,  useColorModeValue, useStyleConfig,
  Accordion, AccordionItem, AccordionPanel
} from '@chakra-ui/react';
import { DragDropContext } from '@hello-pangea/dnd';
import { useNavigate } from 'react-router-dom';

import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { FormContext } from '../../../context/FormContext.jsx';
import c  from '../../../uc_constants/fwd_constants.js';

import AreaHeader from '../../areas/rows/AreaHeader.jsx';
import ProjectTasks from '../../projects/boxes/ProjectTasks.jsx';
import { useTaskDnd } from '../../../data_hooks/dnd/useTaskDnd.jsx';
import AreaRemindersBox from '../../areas/boxes/AreaRemindersBox.jsx';

import AddButton from '../../general/components/AddButton.jsx';

const WeekTasks = ({ session, location="home", mode="thisWeek", ...props }) => {

  const navigate = useNavigate();
  const {handleDragEndWell} = useTaskDnd();
  const { state, dispatch } = useContext(ForwardContext);
  const { addTask} = useContext(FormContext);

  const style = useStyleConfig('Box', {variant: 'widgetBox'});

  const handleAddTask = () => {
    addTask({}, false, {
      'name':true,
      'project':true,
      'task_list_selector':false,
      'pinned':true,
      'recur_weeks':false,
  })
  }


  const bgColor = useColorModeValue('forwardWhite.500','forwardBlue.800')
  const bdColor = useColorModeValue('forwardWhite.700','forwardBlue.900')

  let list_to_show = "this_week";
  let headingText = "This Week";
  let reminder_days = 7;
  if (mode === 'nextWeek') {
    list_to_show = 'next_week';
    headingText = "Next Week";
    reminder_days = 14;
  }

    const defaultIndices = state.areas.map((_, index) => index);

    let total_count = 0;
    Object.values(state.tasks).flat().map((area) => {
      if(area && area[list_to_show]) {
        total_count += area[list_to_show].length ;
      }
    })

  /*****************************/
  /*****************************/
  /* Main body of the element: */
  /*****************************/
  /*****************************/

  return (
    <>
    <Box 
     width="100%"
     maxWidth="1000px"
      __css={style}
    {...props}>
    <HStack
    width="100%"
    justifyContent="space-between"
    px={0} pt={0} pb={2} >
    <HStack>
    {c.main_icons.tasks} 
    <Heading size="sm">{headingText} ({total_count})</Heading>
    </HStack>
    <HStack>
    <AddButton entities={['task','recurring_task','reminder',]}  />
    </HStack>
    </HStack>

    <VStack w="100%" maxWidth="1100px">
      <Center w="100%">
      <DragDropContext onDragEnd={handleDragEndWell}>
      <VStack spacing={0} w="100%" maxWidth="1100px" minWidth="300px">
  {Object.values(state.areas).map((area) => {
    // Get the projects for this area
    let area_tasks = 0;
    const projectsInArea = state.projects[area.id] || [];


    //if (projectsInArea.length === 0) {
    //  return null;
    //}
    let task_count = 0;
    {projectsInArea.flatMap((project) => {
        task_count = task_count + state.tasks[project.id][list_to_show].length;
        area_tasks = area_tasks = task_count;
    }
    )}
    //if (task_count === 0) {
    //    return null;
    //}
    return (
      <Box key={area.id} width="100%" marginTop="2px !important" >
        <Accordion defaultIndex={defaultIndices} allowMultiple >
          <AccordionItem border="0px !important">
                <Box flex="1" textAlign="left">
                  <AreaHeader area={area} addible={true} addType='task' collapsible={true} count_display={true} count={area_tasks} task_list={list_to_show} />
                </Box>
            <AccordionPanel py={0} px={1} border="0px">
              <AreaRemindersBox areaId={area.id} days={reminder_days} />
              {projectsInArea.flatMap((project) => (
                <ProjectTasks 
                    key={'project-'+project.id} 
                    project={project} 
                    tasks={state.tasks[project.id]} 
                    listToShowOverride={list_to_show}/>
              ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    );
  })}
</VStack>
        </DragDropContext>
      </Center>
    </VStack>
    </Box>
    </>
  );
}

export default WeekTasks;
