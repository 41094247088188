import cd from '../uc_utils/charting/charting_dates.js'
import dhf from '../uc_utils/dates/dates.js'

//We need a function that takes in any complex object and returns an object keyed off date 

const getPeriodStats = (data, data_field, date_field='key') => {
    //For any object, this needs to return a new object that is keyed off the date (in format yyyy-MM-dd) and has the data_field as the value
    //Assume the data is an array of objects
    const dateData = {};
    if(date_field==='key') {
        Object.keys(data).forEach((key) => {
            dateData[key] = data[key][data_field];
        });
    }
    else {
    data.forEach((d) => {
        const date = dhf.formatDate(d[date_field],'yyyy-MM-dd');
        dateData[date] = d[data_field];
    });
    }
    return dateData;

}

const aggPeriodStats = ({dataSets, aggMethod}) => {
    //Given a set of objects keyed off date, aggregate the data based on the aggMethod
    //Assume the dataSets is an array of objects
    const aggData = {};
    dataSets.forEach((data) => {
        Object.keys(data).forEach((date) => {
            if(!aggData[date]) {
                aggData[date] = [];
            }
            aggData[date].push(data[date]);
        });
    });

    const aggResults = {};
    Object.keys(aggData).forEach((date) => {
        switch(aggMethod) {
            case 'sum':
                aggResults[date] = aggData[date].reduce((a,b) => a+b,0);
                break;
            case 'avg':
                aggResults[date] = aggData[date].reduce((a,b) => a+b,0)/aggData[date].length;
                break;
            case 'min':
                aggResults[date] = Math.min(...aggData[date]);
                break;
            case 'max':
                aggResults[date] = Math.max(...aggData[date]);
                break;
        }
    });

    return aggResults;
}


const timeSeriesAggregation = ({dataObject, dataType='timeseries', calcMode='actual', calcSettings={}}) => {
    //TODO: Implement null as zero
    //TODO: Implement projection of current period (if timeseries)

    //console.log(dataObject);
    let xValues = [];
    let yValues = [];
    if(dataType === 'timeseries') {
        //Assume the dataObject is a dictionary of date strings and values (e.g. {'2021-01-01': 5, '2021-01-02': 6})
        //Sort the date strings
        if(calcMode === 'actual') {
        const sortedDates = Object.keys(dataObject).sort();
        for (let date of sortedDates) {
            xValues.push(date);
            yValues.push(dataObject[date]);
        }
        }
        else if(calcMode === 'aggregated') {
            const aggMethod = calcSettings.aggMethod;
            const aggPeriod = calcSettings.aggPeriod;
            const groupedData = {};


            // Group data by period
            Object.keys(dataObject).forEach(date => {
                const periodKey = cd.getPeriodKey(date, aggPeriod); // Assuming getPeriodKey is defined and works correctly
                if (!groupedData[periodKey]) {
                    groupedData[periodKey] = [];
                }
                groupedData[periodKey].push(dataObject[date]);
            });

            // Sort keys and prepare for aggregation
            const sortedKeys = Object.keys(groupedData).sort();

            // Aggregate data within each group
            sortedKeys.forEach((key, index) => {
                const values = groupedData[key];
                let aggValue;

                switch (aggMethod) {
                    case 'min':
                        aggValue = Math.min(...values);
                        break;
                    case 'max':
                        aggValue = Math.max(...values);
                        break;
                    case 'sum':
                        aggValue = values.reduce((a, b) => a + b, 0);
                        break;
                    case 'avg':
                        aggValue = values.reduce((a, b) => a + b, 0) / values.length;
                        break;
                    case 'count':
                        aggValue = values.length;
                        break;
                    case 'countif':
                        aggValue = values.filter(v => v === calcSettings.countifValue).length;
                        break;
                }

                    xValues.push(key);
                    yValues.push(aggValue);
            });

            if (calcSettings.calcMovingAvg) {
                const maLength = calcSettings.maLength; // Ensure this is defined somewhere
                const movingAverages = yValues.map((_, idx, arr) => {
                    if (idx < maLength - 1) {
                        // Not enough data points to calculate moving average, use actual avg up to current
                        return arr.slice(0, idx + 1).reduce((a, b) => a + b, 0) / (idx + 1);
                    } else {
                        // Calculate moving average for the current window
                        return arr.slice(idx - maLength + 1, idx + 1).reduce((a, b) => a + b, 0) / maLength;
                    }
                });
            
                // Update yValues with the calculated moving averages
                yValues = movingAverages;
            }

                 // Determine the type of xValues for appropriate comparison
                const valueType = typeof xValues[0];

                // Convert dates to a comparable form (timestamp) if xValues are Date objects
                if (valueType === 'object' && xValues[0] instanceof Date) {
                    xValues = xValues.map(date => dhf.formatDate(date, 'yyyy-MM-dd'));
                }

                // Pair xValues and yValues together to maintain their relationship
                const pairedValues = xValues.map((x, index) => ({ x, y: yValues[index] }));

                // Sort the paired values based on xValues
                pairedValues.sort((a, b) => {
                    if (valueType === 'string') {
                        return a.x.localeCompare(b.x);
                    } else { // Works for numbers and converted dates (now as numbers)
                        return a.x - b.x;
                    }
                });

                // Unpair the values after sorting
                xValues = pairedValues.map(pair => pair.x);
                yValues = pairedValues.map(pair => pair.y);

                // If original xValues were dates, convert timestamps back to Date objects
                if (valueType === 'object' && xValues[0] instanceof Number) {
                    xValues = xValues.map(timestamp => new Date(timestamp));
                }

        }
    }

    return {xValues, yValues};
}

const calcHabitChart = ({my_stats, aggField, aggMethod = 'sum'}) => {
    if(!my_stats) {
        return null;
    }
    //My Stats has an object for each habit. Within that, we want to grab and get weekly stats and aggregate them
    //console.log(my_stats)
    const my_periods = [];
    Object.values(my_stats).forEach((habit) => {
        const weekly_stats = habit.weekly_stats;
        const period_stats = getPeriodStats(weekly_stats, aggField, 'key');
        my_periods.push(period_stats);
    })
    const agg_stats = aggPeriodStats({dataSets:my_periods, aggMethod:aggMethod});
    //console.log(agg_stats);
    return agg_stats;


}

const calcSeries = ({series_a, operation, series_b}) => {
    //Given two objects keyed on date, create a new series and return it to the user, keyed on date that does the operation on series_a and series_b
    const new_series = {};
    Object.keys(series_a).forEach((date) => {
        switch (operation) {
            case 'add':
                new_series[date] = series_a[date] + series_b[date];
                break;
            case 'subtract':
                new_series[date] = series_a[date] - series_b[date];
                break;
            case 'multiply':
                new_series[date] = series_a[date] * series_b[date];
                break;
            case 'divide':

                new_series[date] = (series_b[date] > 0) ? series_a[date] / series_b[date] : 0;
                break;
            case 'percent':
                new_series[date] = (series_b[date] > 0) ? (100.*series_a[date] / series_b[date]) : 0;
                break;
        }
    });
    return new_series;
}

export default { getPeriodStats, aggPeriodStats , timeSeriesAggregation, calcHabitChart, calcSeries};