import React from 'react';
import {
  Select
} from '@chakra-ui/react';
const GoalStatusDropdown = ({ value, onChange, ...props }) => {

    const statuses = ['abandoned','backlog','priority','active'];
  
    return (
      <Select value={value} onChange={onChange} {...props}>
        {statuses.map((status, index) => (
          <option key={index} value={status}>
            {status}
          </option>
        ))}
      </Select>
    );
  };
  export default GoalStatusDropdown;
