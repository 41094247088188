import { supabase } from '../uc_constants/supabaseClient';
import { format } from 'date-fns';


export async function upsertHabitTargets(habit_target_data) {
    let { data, error } = await supabase
    .from('forward_targets')
    .upsert([ habit_target_data ])
      .select('*');

  if (error) {
      console.error('Error upserting user:', error);
  }
  return data;
}


export async function readHabitTargets(userId) {
  let { data, error } = await supabase
  .from('forward_targets')
  .select(`
          id, habit_id, date, target_value, is_greater_than,
          forward_habits (
            id, user_id
          )
        `)
  .eq('forward_habits.user_id', userId)
  

if (error) {
    console.error('Error upserting user:', error);
}
return data;
}