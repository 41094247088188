const taskReducer = (tasks, action) => {
  switch (action.type) {

          
      /*********************************************/
      /*********************************************/
      /* Tasks */
      /*********************************************/
      /*********************************************/

      case 'SET_TASKS':
        const updatedTasks = action.payload;
        return {
          tasks: {
            ...tasks, // keep existing state
            ...Object.keys(updatedTasks).reduce((result, projectId) => {
              // for each project, merge new tasks with existing tasks
              result[projectId] = {
                ...(tasks[projectId] || {}), // existing tasks for this project
                ...updatedTasks[projectId], // new tasks for this project
              };
              return result;
            }, {}),
          },
        };
        case 'SET_CW_TASKS':
          const newTasks = action.payload;
          return {
            tasks: {
              ...tasks, // keep existing state
              ...Object.keys(newTasks).reduce((result, projectId) => {
                // for each project, merge new tasks with existing tasks
                result[projectId] = {
                  ...tasks[projectId], // existing tasks for this project
                  // overwrite current_tasks and accomplishments with new data
                  current_tasks: newTasks[projectId].current_tasks,
                  accomplishments: newTasks[projectId].accomplishments,
                  // keep rest of new tasks for this project, without overwriting
                  ...Object.keys(newTasks[projectId]).reduce((remainingTasks, taskKey) => {
                    // skip current_tasks and accomplishments
                    if (taskKey !== "current_tasks" && taskKey !== "accomplishments") {
                      remainingTasks[taskKey] = newTasks[projectId][taskKey];
                    }
                    return remainingTasks;
                  }, {}),
                };
                return result;
              }, {}),
            },
          };
        

      case 'SET_TASKS_NEW_PROJECT':
        const project_id = action.payload;
        return {
          tasks: {
            ...tasks,
            [project_id]:{
              ['current_tasks']:[],
              ['accomplishments']:[],
              ['backlog']:[],
              ['priority']:[],
              ['this_week']:[],
              ['next_week']:[],
            }
          }
        }
        case 'UPSERT_TASK': {
          const task = action.payload.task;
          const projectId = task.project_id;
          const listName = action.payload.listName;
      
          const currentProject = tasks[projectId] || {};
          const currentList = currentProject[listName] || [];
      
          let newList;
          if (currentList.find(item => item.id === task.id)) {
              // Update existing task
              newList = currentList.map(item => item.id === task.id ? task : item);
          } else {
              // Insert new task
              newList = [...currentList, task];
          }
      
            // Sort the newList by order_id, ensuring anything with a null order_id appears last
            // If multiple tasks have null order_id, they should be sorted by the created_at date
            newList.sort((a, b) => {
            if (a.order_id === null && b.order_id === null) {
              // If both order_id are null, sort by created_at date
              return new Date(a.created_at) - new Date(b.created_at);
            } else if (a.order_id === null) {
              // If only a's order_id is null, a should come after b
              return 1;
            } else if (b.order_id === null) {
              // If only b's order_id is null, b should come after a
              return -1;
            } else {
              // If neither order_id is null, sort by order_id
              return a.order_id - b.order_id;
            }
            });
          return {
              tasks: {
                  ...tasks,
                  [projectId]: {
                      ...currentProject,
                      [listName]: newList,
                  },
              },
          };
      }
           

        case 'REMOVE_TASK': {
          //console.log(action.payload.task);
          const taskId = action.payload.task.id;
          const projectId = action.payload.task.project_id;
          const listName = action.payload.listName;
          const currentProject = tasks[projectId] || {};
          const currentList = currentProject[listName] || [];
  
          return {
            tasks: {
              ...tasks,
              [projectId]: {
                ...currentProject,
                [listName]: currentList.filter(item => item.id !== taskId),
              },
            },
          };
        }

        case 'REMOVE_TASK_ALL_LISTS': {
          console.log('removing task: '+action.payload.task.id);
          console.log('from: '+action.payload.task.project_id);
          const taskId = action.payload.task.id;
          const projectId = action.payload.task.project_id;
          const currentProject = tasks[projectId] || {};
        
          const updatedProject = Object.keys(currentProject).reduce((project, listName) => {
            const list = currentProject[listName] || []; // Ensure the list is an array
            return {
              ...project,
              [listName]: list.filter(item => item.id !== taskId),
            };
          }, {});

          console.log(updatedProject);
        
          return {
            tasks: {
              ...tasks,
              [projectId]: updatedProject,
            },
          };
        }
        
  
        case 'UPDATE_TASK_ORDER': {
          //console.log(action.payload);
          const { droppableId, tasksOrdered } = action.payload;
          const projectId = droppableId.split('-')[2];
          const listId = droppableId.split('-')[3];
          
          if (!tasks[projectId][listId]) {
            //console.log('got nothin')
            return {};
          }
  
          const updatedTasks = [...tasks[projectId][listId]];

          tasks[projectId][listId].forEach(({ id, order_id }) => {
            const taskIndex = updatedTasks.findIndex(task => task.id === id);
            if (taskIndex !== -1) {
              updatedTasks[taskIndex] = { ...updatedTasks[taskIndex], 'order_id':order_id };
            }
          });
  
          updatedTasks.sort((a, b) => a.order_id - b.order_id);
          return {
            tasks: {
              ...tasks,
              [projectId]: {
                ...tasks[projectId],
                [listId]: updatedTasks,
              },
            },
          };
        }
        case 'UPDATE_PIN_TASK_ORDER': {
          const { items } = action.payload;
            // Iterate over each pinned task
            items.forEach(({ id, project_id, pin_order_id }) => {
              // Find the project and this_week array in the tasks object
              const project = tasks[project_id];
              const thisWeek = project?.this_week;

              // If project or this_week array doesn't exist, skip the task
              if (!project || !thisWeek) {
                return;
              }

              // Find the task in the this_week array by id
              const taskIndex = thisWeek.findIndex(task => task.id === id);

              // If task not found, skip the task
              if (taskIndex === -1) {
                return;
              }

              // Update the pin_order_id of the task
              thisWeek[taskIndex].pin_order_id = pin_order_id;
            });

            return {tasks: { ...tasks }};
          
        }

        case 'MOVE_TASK_WITHIN_PROJECT': {
          const {
              projectId,
              sourceListId,
              destinationListId,
              sourceIndex,
              destinationIndex,
              newItemStatus,
              newResolveDate,
              newPlanDate,
              newOPW,
          } = action.payload;
      
          // Get the task that's being moved
          const taskBeingMoved = tasks[projectId][sourceListId][sourceIndex];
          //console.log(taskBeingMoved);
          
          // If no task found, return the current state
          if (!taskBeingMoved) {
              return tasks;
          }
      
          // Update the properties of the task
          let updatedTask;
          if(newPlanDate === 'unchanged') {
          updatedTask = {
              ...taskBeingMoved,
              item_status: newItemStatus,
              resolve_date: newResolveDate,
          };
        } else {
          updatedTask = {
            ...taskBeingMoved,
            item_status: newItemStatus,
            resolve_date: newResolveDate,
            item_planned_week:newPlanDate,
            original_plan_date:newOPW,
        };
        }

          //console.log(updatedTask);
      
          // Create a new state with the updated task
          let newTasks = {
              ...tasks,
              [projectId]: {
                  ...tasks[projectId],
                  [sourceListId]: [
                      ...tasks[projectId][sourceListId].slice(0, sourceIndex),
                      ...tasks[projectId][sourceListId].slice(sourceIndex + 1)
                  ],
                  [destinationListId]: Array.isArray(tasks[projectId][destinationListId])
                      ? [
                          ...tasks[projectId][destinationListId].slice(0, destinationIndex),
                          updatedTask,
                          ...tasks[projectId][destinationListId].slice(destinationIndex)
                      ]
                      : [updatedTask]
              }
          };
          
          return {tasks: newTasks};
      }
        case 'MOVE_TASK_BETWEEN_PROJECTS': {
          const updatedProjects = action.payload;
        
          // Create a new tasks state
          let newTasks = { ...tasks };
        
          // Iterate over each project in the updatedProjects
          for (let projectId in updatedProjects) {
            // Go through each sub-category (e.g., 'this_week') in the project
            for (let category in updatedProjects[projectId]) {
              // Map through the tasks and update the project_id
              newTasks[projectId][category] = updatedProjects[projectId][category].map(task => ({
                ...task,
                project_id: parseInt(projectId) // Set the new project_id
              }));
            }
          }
        
          return {
            tasks: newTasks
          };
        }
        
        

        case 'MOVE_TASK_DOUBLE': {
          console.log(action.payload);
          const {
            sourceProjectId,
            destinationProjectId,
            sourceListId,
            destinationListId,
            sourceIndex,
            destinationIndex,
            newItemStatus,
            newResolveDate,
            newPlanDate,
        } = action.payload;

        
    
        // Get the task that's being moved
        const taskBeingMoved = tasks[sourceProjectId][sourceListId][sourceIndex];
    
        // If no task found, return the current state
        if (!taskBeingMoved) {
            return tasks;
        }
    
        // Update the properties of the task
        let updatedTask;
        if(newPlanDate === 'unchanged') {
        updatedTask = {
            ...taskBeingMoved,
            item_status: newItemStatus,
            resolve_date: newResolveDate,
            project_id: destinationProjectId,
        };
      }
      else {
        updatedTask = {
          ...taskBeingMoved,
          item_status: newItemStatus,
          resolve_date: newResolveDate,
          item_planned_week:newPlanDate,
          project_id: destinationProjectId,
      };
      }
    
        // Create a new state with the updated task
        let newTasks = { ...tasks };
    
        // Check if source and destination projects are the same
        if (sourceProjectId === destinationProjectId) {
            // Task is being moved within the same project
            newTasks[sourceProjectId] = {
                ...newTasks[sourceProjectId],
                [sourceListId]: [
                    ...newTasks[sourceProjectId][sourceListId].slice(0, sourceIndex),
                    ...newTasks[sourceProjectId][sourceListId].slice(sourceIndex + 1)
                ],
                [destinationListId]: Array.isArray(newTasks[sourceProjectId][destinationListId])
                    ? [
                        ...newTasks[sourceProjectId][destinationListId].slice(0, destinationIndex),
                        updatedTask,
                        ...newTasks[sourceProjectId][destinationListId].slice(destinationIndex)
                    ]
                    : [updatedTask]
            };
        } else {
            // Task is being moved between different projects
    
            // Remove the task from the source project/list
            newTasks[sourceProjectId] = {
                ...newTasks[sourceProjectId],
                [sourceListId]: [
                    ...newTasks[sourceProjectId][sourceListId].slice(0, sourceIndex),
                    ...newTasks[sourceProjectId][sourceListId].slice(sourceIndex + 1)
                ],
            };
    
            // Add the task to the destination project/list
            newTasks[destinationProjectId] = {
                ...newTasks[destinationProjectId],
                [destinationListId]: Array.isArray(newTasks[destinationProjectId][destinationListId])
                    ? [
                        ...newTasks[destinationProjectId][destinationListId].slice(0, destinationIndex),
                        updatedTask,
                        ...newTasks[destinationProjectId][destinationListId].slice(destinationIndex)
                    ]
                    : [updatedTask]
            };
        }
    
        return {tasks: newTasks};

        }
        
  
    default:
            return {};
 
    }
};
export default taskReducer;
